import { Link } from 'react-router-dom';

import styles from '../styles.module.css';

import React, { useEffect, useState } from 'react';
import { PresetInterface } from '../Preset';
import getUrlParamsFromData from '../../../utils/get-url-params-from-data';
import { SelectedItem } from '../../SelectionPopup/SelectionPopup';

interface PresetLinkProps {
    data?: PresetInterface;
    img: string;
    name: string;
    link: string;
    children?: React.ReactNode;
    isOptimisation?: boolean;
}

const PresetLink = ({ data, img, name, link, children, isOptimisation }: PresetLinkProps) => {
    const [date, setDate] = useState('');

    useEffect(() => {
        if (data) {
            const d = new Date(data.createdAt);
            const month = '0' + (d.getMonth() + 1);
            const day = '0' + d.getDate();
            setDate(
                `${day.substring(day.length - 2, day.length)}/${month.substring(
                    month.length - 2,
                    month.length
                )}/${d.getFullYear()}`
            );
        }
    }, [data]);

    const getName = (item: SelectedItem) => {
        return `${item.countryCode} ${item.name}`;
    };

    return (
        <Link to={`/${link}${data ? `?${getUrlParamsFromData(data.data).toString()}` : ''}`} className={styles.link}>
            <div className={styles.imgWrapper}>
                <img src={img} alt={''} className={styles.mainImg} />
                <span>{name}</span>

                {isOptimisation && <img src="./images/optimisation.png" alt="" className={styles.secondaryImg} />}
            </div>
            <div className={styles.choice}>
                {data ? (
                    <>
                        <p>
                            Investor:{' '}
                            {data.data && data.data.selectedInvestor
                                ? getName(data.data.selectedInvestor)
                                : data.data.investors &&
                                  data.data.investors.map((inv: SelectedItem) => getName(inv)).join(', ')}
                            {data.data && data.data.investor && getName(data.data.investor)}
                        </p>
                        {data.data && data.data.fundOfFunds && <p>Fund of funds: {getName(data.data.fundOfFunds)}</p>}
                        {data.data && data.data.investmentFund && (
                            <p>Investment structure: {getName(data.data.investmentFund)}</p>
                        )}
                        <p>
                            Asset:{' '}
                            {data.data &&
                                data.data.assets &&
                                data.data.assets.map((inv: SelectedItem) => getName(inv)).join(', ')}
                            {data.data && data.data.asset && getName(data.data.asset)}
                        </p>
                        <p>Date: {date}</p>
                    </>
                ) : (
                    children
                )}
            </div>
        </Link>
    );
};

export default PresetLink;
