import { linkVertical, line, curveNatural } from 'd3-shape';
import { max, scaleBand, scaleLinear } from 'd3';
import {
    getF12,
    getF15,
    getF6,
    getF8,
    getF9,
    getFundsData,
    getT1,
    getT10,
    getT11,
    getT12,
    getT2,
    getT3,
    getT4,
    getT5,
    getT6,
    getT7,
    getT8,
    getT9,
    getV2F7,
    getV4F10,
    getV6F11,
    getV7F16,
    getV8F14,
    NodeDataItem,
} from './diagram-data';

import { ITipData } from '../components/Sankey/components/Tip';
import {
    DiagramData,
    EventCategory,
    FundLevel,
    Link,
    SankeyFundNode,
    SankeyNode,
    SankeyTip,
    TipPositionConfig,
} from '../types/deal-taxes';
import { SelectionLevel } from '../types/general';

export const generateSankey = (data: DiagramData) => {
    // const levels = ['investor', 'fund', 'asset'];
    const categories = ['investment', 'holding', 'distribution', 'selling'];

    const width = 1000;
    const height = 880;
    const margin = { top: 120, bottom: 120, left: 100, right: 0 };

    const fofMainClass = 'fof-main';
    const fundMainClass = 'fund-main';

    let fundNodes: SankeyFundNode[] = [];
    let nodes: SankeyNode[] = [];
    let tips: SankeyTip[] = [];
    let links: Link[] = [];
    let xAxis: any = [];
    let yAxis: any = [];
    let lines: any = [];
    let bottomLines: any = [];

    const createPath = linkVertical()
        .source((d) => d.source)
        .target((d) => d.target);
    const createCurvedPath = line().curve(curveNatural);

    const getLinkWidth = (node: SankeyNode | SankeyFundNode, tip: SankeyTip) => {
        let width = Math.max(...tip.data.map((data) => data.value));

        if (width < 1.5) {
            return 2;
        }
        return width + 2;
    };

    const createLink = (sourceNode: SankeyNode, targetNode: SankeyNode) => {
        const offset = sourceNode.width - targetNode.width;

        let link: Link = {
            path: createPath({
                source: [sourceNode.x + (targetNode.width / 2 + offset), sourceNode.y + sourceNode.height / 2],
                target: [targetNode.x + targetNode.width / 2, targetNode.y + targetNode.height / 2],
            }),
            color: '#7d9dd8',
            width: targetNode.width,
            opacity: 0.4,
            class: `${sourceNode.category}`,
        };

        if (sourceNode.level === 'investor' && sourceNode.category === 'holding') {
            link.path = createPath({
                source: [sourceNode.x + sourceNode.width / 2, sourceNode.y + sourceNode.height / 2],
                target: [targetNode.x + targetNode.width / 2, targetNode.y + targetNode.height / 2],
            });
        }

        links.push(link);
    };

    const createTipLink = (
        tip: SankeyTip,
        { sourceNode, targetNode, align, direction, fofNodes }: TipPositionConfig,
        isFof?: boolean
    ) => {
        const middleXPoint = tip.x + tip.width / 2;

        const className = isFof
            ? fofMainClass
            : targetNode.level === SelectionLevel.Fund && sourceNode.level === SelectionLevel.Fund
            ? fundMainClass
            : '';

        const tipArrowPosition: {
            source: [number, number];
            target: [number, number];
        } = {
            source: direction > 0 ? [middleXPoint, tip.y - 25] : [middleXPoint, tip.y + tip.height + 25],
            target: direction > 0 ? [middleXPoint, tip.y - 10] : [middleXPoint, tip.y + tip.height + 10],
        };

        const tipArrow = {
            path: createPath(tipArrowPosition),
            source: tipArrowPosition.source,
            target: tipArrowPosition.target,
            color: '#ff7400',
            width: getLinkWidth(sourceNode, tip),
            opacity: 1,
            class: `${sourceNode.category} ${className} tip-arrow`,
            isFoF: isFof,
        };

        let linkSource: [number, number] =
            align > 0
                ? [sourceNode.x + tipArrow.width / 2, sourceNode.y + sourceNode.height / 2]
                : [sourceNode.x + (sourceNode.width + tipArrow.width / 2) - 3, sourceNode.y + sourceNode.height / 2];

        if (targetNode.level === 'investor' && targetNode.category === 'holding') {
            linkSource =
                align > 0
                    ? [sourceNode.x + tipArrow.width / 2 - 10, sourceNode.y + sourceNode.height]
                    : [sourceNode.x + (sourceNode.width + tipArrow.width / 2) + 10, sourceNode.y + sourceNode.height];
        }

        let isDashed =
            sourceNode.level === SelectionLevel.Fund &&
            (targetNode.level === SelectionLevel.Investor || targetNode.level === SelectionLevel.Asset);

        let linkPath;
        if (isFof) {
            const points = [
                linkSource,
                [tipArrow.source[0] + tipArrow.width, linkSource[1] - (direction < 0 ? 12 : 12 * -1)],
                // [tipArrow.source[0] + 6, tipArrow.source[1] + 30],
                tipArrow.source,
            ];
            // @ts-ignore
            linkPath = createCurvedPath(points);
        } else {
            linkPath = createPath({
                source: linkSource,
                target: !isDashed ? tipArrow.source : [middleXPoint, targetNode.y + targetNode.height],
            });
        }

        const linkToTip = {
            path: linkPath,
            color: '#ff5900',
            width: getLinkWidth(sourceNode, tip),
            opacity: 0.4,
            class: `${sourceNode.category} ${className} tip-link`,
            isFoF: isFof,
            isDashed,
        };

        if (isDashed) {
            links.push({
                ...linkToTip,
                isDashed: false,
                path: createPath({
                    source: [middleXPoint, targetNode.y + targetNode.height],
                    target: tipArrow.source,
                }),
            });
        }

        let triangleLink;

        const triangleLinkTarget: [number, number] =
            align > 0
                ? [sourceNode.x + tipArrow.width / 2 - 5, sourceNode.y + sourceNode.height - 40]
                : [sourceNode.x + (sourceNode.width + tipArrow.width / 2) + 5, sourceNode.y + sourceNode.height - 40];

        if (targetNode.level === 'investor' && targetNode.category === 'holding') {
            triangleLink = {
                path: createPath({
                    source: linkSource,
                    target: triangleLinkTarget,
                }),
                source: linkSource,
                target: triangleLinkTarget,
                color: '#ff5900',
                width: getLinkWidth(sourceNode, tip),
                opacity: 0.4,
                class: `${sourceNode.category} tip-link`,
                type: 'triangle',
            };
        }

        links.push(tipArrow, linkToTip);
        if (triangleLink) {
            links.push(triangleLink);
        }

        if (isFof) {
            links.push({
                path: createPath({
                    source: [linkSource[0], sourceNode.y + 4],
                    target: [linkSource[0], sourceNode.y + sourceNode.height - 4],
                }),
                color: '#ff5900',
                width: 2,
                opacity: 1,
                class: fofMainClass,
                isFoF: true,
            });
        }
    };

    const createTip = (config: TipPositionConfig) => {
        const { sourceNode, targetNode, tipData, align, direction, offset, overlapping, shiftedX, shiftedY, fofNodes } =
            config;

        if (!tipData.length) return;

        const tipWidth = Math.max(
            // 160,
            ...tipData.map((data) => (data.tax.trim().length < 12 ? 100 : data.tax.trim().length * 6 + 10))
        );

        const tipHeight = 56;

        let tipX;

        if (align < 0) {
            tipX = targetNode.x + targetNode.width / 2 - 10;
        } else {
            if (targetNode.level === 'investor' && targetNode.category === 'selling') {
                tipX = targetNode.x - (tipWidth / 2 + 20);
            } else if (targetNode.level === 'investor' && targetNode.category === 'distribution') {
                tipX = targetNode.x - (tipWidth / 2 + 80);
            } else {
                tipX = targetNode.x - (tipWidth / 2 + 20);
            }
        }

        if (
            (targetNode.level === 'investor' && targetNode.category === 'selling') ||
            (targetNode.level === 'asset' && targetNode.category === 'selling')
        ) {
            tipX += 160 - tipWidth;
        }

        const yOffset = offset ? 120 : 200;

        let tipY =
            direction > 0
                ? targetNode.y + (offset ? 50 : Math.min(90, 170 - tipHeight * tipData.length))
                : targetNode.y - yOffset;

        if (overlapping) {
            tipX += 70;
            tipY += 70;
        }

        if (shiftedX) {
            tipX += shiftedX;
        }

        if (shiftedY) {
            tipY += shiftedY;
        }

        const tip: SankeyTip = {
            x: tipX,
            y: tipY,
            width: tipWidth,
            height: tipHeight,
            category: sourceNode.category!,
            data: tipData.map((data: any) => ({
                value: data.value,
                tax: data.tax,
                taxBase: data.taxBase,
                percent: data.percent,
                fullComments: data.fullComments?.length ? data.fullComments : null,
                legendId: 0,
            })),
        };

        tips.push(tip);

        createTipLink(tip, config);
        if (fofNodes) {
            for (const fofNode of fofNodes) {
                if (fofNode.text) {
                    createTipLink(tip, { ...config, sourceNode: fofNode }, true);
                }
            }
        }
    };

    const generateFundLayer = (data: DiagramData) => {
        const fundNode = getNode(EventCategory.investment, SelectionLevel.Fund)!;

        const nodeHeight = 90;
        const singleNodeHeight = 26;
        const singleNodeHeightOffset = 12;

        const node = {
            x: 0,
            y: fundNode.y + fundNode.height / 2 - nodeHeight / 2,
            width: width,
            height: nodeHeight,
            className: fofMainClass,
        };

        fundNodes.push(node);

        for (const category of Object.values(EventCategory)) {
            const eventNode = getNode(category, SelectionLevel.Fund)!;

            const funds = getFundsData(data, category);
            const width = 120;

            const singleNode: SankeyFundNode = {
                x: eventNode.x + (eventNode.width - width) / 2,
                y: fundNode.y + fundNode.height / 2 - nodeHeight / 2 + singleNodeHeightOffset,
                width: width,
                height: singleNodeHeight,
                level: FundLevel.fof,
                category: category,
                text: funds.fof ? 'Fund of Funds' : '',
                className: fofMainClass,
            };
            fundNodes.push(singleNode);
            fundNodes.push({
                ...singleNode,
                y: singleNode.y + nodeHeight - singleNodeHeight - singleNodeHeightOffset * 2,
                text: funds.fund ? 'Inv. structure' : '',
                level: FundLevel.fund,
            });

            if (category === EventCategory.investment) {
                yAxis.push({
                    x: node.x + 49,
                    y: singleNode.y + 17,
                    fontSize: 11,
                    value: 'FUND OF FUNDS',
                    className: fofMainClass,
                });

                yAxis.push({
                    x: node.x + 49,
                    y: node.y + node.height - 21,
                    fontSize: 11,
                    value: 'INV. STRUCTURE',
                    className: fofMainClass,
                });
            }
        }

        let x1 = node.x - 15;
        let x2 = node.x + node.width + 15;

        lines.push({
            x1,
            x2,
            y: node.y,
            className: fofMainClass,
        });

        lines.push({
            x1,
            x2,
            y: node.y + node.height,
            className: fofMainClass,
        });
    };

    const generateDefaultData = () => {
        let data = [];

        for (const level of Object.values(SelectionLevel)) {
            for (const category of Object.values(EventCategory)) {
                if (level !== 'fundOfFunds') {
                    const node: SankeyNode = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 18,
                        level: level,
                        category: category,
                        value: 0,
                    };

                    if (level === 'asset') node.y = height - margin.bottom - 40;
                    if (level === 'investor') node.y = margin.top;
                    if (level === 'fund') {
                        node.y = height - height / 2 - 40;
                        node.className = fundMainClass;
                    }

                    if (category === 'holding') node.value = 120;
                    if (category === 'distribution') node.value = 10;
                    if (category === 'investment') node.value = 100;
                    if (category === 'selling') node.value = 120;

                    data.push(node);
                }
            }
        }

        const xScale = scaleLinear()
            // @ts-ignore
            .domain([0, max(data, (d) => d.value)])
            .range([3, 140]);

        const xPosition = scaleBand()
            .domain(categories)
            .range([margin.left, width - margin.right]);

        nodes = data.map((d) => {
            d.width = xScale(d.value);
            d.x = xPosition(d.category) ?? 0 - d.width / 2 + xPosition.bandwidth() / 2;

            if (d.category === 'holding') {
                d.x += 20;
            }

            if (d.category === 'distribution') {
                d.x += 80;
            }

            return d;
        });
    };

    const getNode = (category: EventCategory, level: SelectionLevel) => {
        return nodes.find((d) => d.category === category && d.level === level);
    };

    const getNodes = (category: EventCategory) => {
        return {
            investorNode: getNode(category, SelectionLevel.Investor),
            fundNode: getNode(category, SelectionLevel.Fund),
            assetNode: getNode(category, SelectionLevel.Asset),
        };
    };

    const createLine = (startNode: SankeyNode, endNode: SankeyNode) => {
        let x1 = startNode.x - 15;
        let x2 = endNode.x + endNode.width + 15;

        const className = startNode.level === SelectionLevel.Fund ? fundMainClass : undefined;

        lines.push({
            x1,
            x2,
            y: startNode.y + startNode.height,
            className,
        });

        bottomLines.push({
            x1,
            x2,
            y: startNode.y + startNode.height + 4,
            className,
        });
    };

    const createLines = () => {
        const investmentInvestor = getNode(EventCategory.investment, SelectionLevel.Investor);
        const sellingInvestor = getNode(EventCategory.selling, SelectionLevel.Investor);
        createLine(investmentInvestor!, sellingInvestor!);

        const investmentFund = getNode(EventCategory.investment, SelectionLevel.Fund);
        const sellingFund = getNode(EventCategory.selling, SelectionLevel.Fund);
        createLine(investmentFund!, sellingFund!);

        const investmentAsset = getNode(EventCategory.investment, SelectionLevel.Asset);
        const sellingAsset = getNode(EventCategory.selling, SelectionLevel.Asset);
        createLine(investmentAsset!, sellingAsset!);
    };

    const calculateInvestmentFund = (data: DiagramData) => {
        const fofNodes = fundNodes.filter((item) => item.category === EventCategory.investment);

        const fundNode = getNode(EventCategory.investment, SelectionLevel.Fund);

        const f6 = getF6(data);
        fundNode!.value = f6.value;

        const investorNode = getNode(EventCategory.investment, SelectionLevel.Investor);

        const [t1] = getT1(data);

        if (t1) {
            createTip({
                fofNodes,
                sourceNode: investorNode!,
                targetNode: investorNode!,
                tipData: [t1.tip],
                align: 1,
                direction: 1,
            });
        }
    };

    const calculateInvestmentAsset = (data: DiagramData) => {
        const { investorNode, fundNode, assetNode } = getNodes(EventCategory.investment);

        const f7 = getV2F7(data);
        assetNode!.value = f7.value;
        assetNode!.width = (assetNode!.width * fundNode!.value) / assetNode!.value;

        const [t2] = getT2(data);

        const [t3] = getT3(data);

        if (t2 && t2.tip) {
            createTip({ sourceNode: fundNode!, targetNode: fundNode!, tipData: [t2.tip], align: 1, direction: 1 });
        }
        if (t3 && t3.tip) {
            createTip({
                sourceNode: fundNode!,
                targetNode: assetNode!,
                tipData: [t3.tip],
                align: -1,
                direction: 1,
                offset: true,
                overlapping: undefined,
                shiftedX: 30,
            });
        }

        createLink(investorNode!, fundNode!);
        createLink(fundNode!, assetNode!);
    };

    const calculateHoldingInvestor = (data: DiagramData) => {
        const { investorNode, fundNode, assetNode } = getNodes(EventCategory.holding);

        investorNode!.value = getF8(data).value;
        investorNode!.x -= 25;

        const t6 = getT6(data);

        createTip({
            sourceNode: investorNode!,
            targetNode: investorNode!,
            tipData: t6.map((item) => item.tip),
            align: -1,
            direction: 1,
            offset: false,
            overlapping: true,
        });

        createLink(investorNode!, fundNode!);
        createLink(fundNode!, assetNode!);
    };

    const calculateHoldingFund = (data: DiagramData) => {
        const fofNodes = fundNodes.filter((item) => item.category === EventCategory.holding);

        const { fundNode } = getNodes(EventCategory.holding);
        const f9 = getF9(data);

        fundNode!.value = f9.value;

        const t5 = getT5(data);

        createTip({
            sourceNode: fundNode!,
            targetNode: fundNode!,
            tipData: t5.map((item) => item.tip),
            align: 1,
            direction: 1,
            fofNodes,
        });
    };

    const calculateHoldingAsset = (data: DiagramData) => {
        const { fundNode, assetNode } = getNodes(EventCategory.holding);
        const f10 = getV4F10(data);
        assetNode!.value = f10.value;
        assetNode!.x += 25;

        const t4 = getT4(data);

        createTip({
            sourceNode: fundNode!,
            targetNode: fundNode!,
            tipData: t4.map((item) => item.tip),
            align: -1,
            direction: 1,
            offset: false,
            overlapping: true,
        });
    };

    const calculateDistributionFund = (data: DiagramData) => {
        const { fundNode, assetNode } = getNodes(EventCategory.distribution);

        assetNode!.x -= 25;

        const f12 = getF12(data);
        fundNode!.value = f12.value;

        const t7 = getT7(data);

        createTip({
            sourceNode: assetNode!,
            targetNode: assetNode!,
            tipData: t7.map((item) => item.tip),
            align: -1,
            direction: -1,
        });
    };

    const calculateDistributionInvestor = (data: DiagramData) => {
        const fofNodes = fundNodes.filter((item) => item.category === EventCategory.distribution);

        const { investorNode, fundNode, assetNode } = getNodes(EventCategory.distribution);

        const f11 = getV6F11(data);
        investorNode!.value = f11.value;
        investorNode!.x += 25;

        const t9 = getT9(data);
        const t8 = getT8(data);

        let count = 0;

        createTip({
            sourceNode: fundNode!,
            targetNode: fundNode!,
            tipData: t8.map((item) => item.tip),
            align: -1,
            direction: -1,
            offset: true,
            shiftedY: -50,
            shiftedX: 50,
            fofNodes,
        });

        createTip({
            sourceNode: fundNode!,
            targetNode: investorNode!,
            tipData: t9.map((item) => item.tip),
            align: 1,
            direction: -1,
            offset: true,
        });

        createLink(investorNode!, fundNode!);
        createLink(fundNode!, assetNode!);
    };

    const calculateSellingAsset = (data: DiagramData) => {
        const { assetNode } = getNodes(EventCategory.selling);
        const f16 = getV7F16(data);
        assetNode!.value = f16.value;
    };

    const calculateSellingFund = (data: DiagramData) => {
        const fofNodes = fundNodes.filter((item) => item.category === EventCategory.selling);

        const { assetNode, fundNode } = getNodes(EventCategory.selling);

        const f15 = getF15(data);
        fundNode!.value = f15.value;

        const t10 = getT10(data);

        createTip({
            fofNodes,
            sourceNode: assetNode!,
            targetNode: assetNode!,
            tipData: t10.map((item) => item.tip),
            align: -1,
            direction: -1,
        });
    };

    const calculateSellingInvestor = (data: DiagramData) => {
        const { investorNode, fundNode, assetNode } = getNodes(EventCategory.selling);

        const f14 = getV8F14(data);
        investorNode!.value = f14.value;

        const t11 = getT11(data);
        const t12 = getT12(data);

        for (const item of t11) {
            if (item.tip.taxBase === 'NAV') {
                createTip({
                    sourceNode: fundNode!,
                    targetNode: investorNode!,
                    tipData: [item.tip],
                    align: 1,
                    direction: -1,
                    offset: true,
                });
            }
        }

        createTip({
            sourceNode: fundNode!,
            targetNode: investorNode!,
            tipData: t12.map((item: NodeDataItem) => item.tip),
            align: -1,
            direction: -1,
            offset: true,
        });

        createLink(fundNode!, investorNode!);
        createLink(assetNode!, fundNode!);
    };

    const createXAxis = () => {
        for (const node of nodes) {
            if (node.level === SelectionLevel.Investor && node.category === EventCategory.investment) {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y - 65,
                    value: 'INVESTMENT',
                    top: true,
                };
                xAxis.push(ax);
            }
            if (node.level === SelectionLevel.Asset && node.category === EventCategory.holding) {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y + 50,
                    value: 'HOLDING',
                    top: false,
                };
                xAxis.push(ax);
            }
            if (node.level === SelectionLevel.Asset && node.category === EventCategory.distribution) {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y + 50,
                    value: 'DISTRIBUTION',
                    top: false,
                };
                xAxis.push(ax);
            }
            if (node.level === SelectionLevel.Asset && node.category === EventCategory.selling) {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y + 50,
                    value: 'SELLING',
                    top: false,
                };
                xAxis.push(ax);
            }
        }
    };

    const createYAxis = () => {
        for (const node of nodes) {
            if (node.level === SelectionLevel.Investor && node.category === EventCategory.investment) {
                const ay = {
                    x: node.x - node.width / 2,
                    y: node.y + node.height,
                    value: 'INVESTOR',
                };
                yAxis.push(ay);
            }
            if (node.level === SelectionLevel.Fund && node.category === EventCategory.investment) {
                const ay = {
                    x: node.x - node.width / 2,
                    y: node.y + node.height,
                    value: 'FUND',
                    className: fundMainClass,
                };
                yAxis.push(ay);
            }
            if (node.level === SelectionLevel.Asset && node.category === EventCategory.investment) {
                const ay = {
                    x: node.x - node.width / 2,
                    y: node.y + node.height,
                    value: 'ASSET',
                };
                yAxis.push(ay);
            }
        }
    };

    const setTipsLegendId = (tips: SankeyTip[]) => {
        const filteredFullComments: ITipData[] = [];

        for (const tip of tips) {
            for (const data of tip.data) {
                if (data.fullComments !== null) {
                    filteredFullComments.push(data);
                }
            }
        }
        const uniqueFullComments = Array.from(new Set(filteredFullComments.map((i) => i.fullComments))).map((i) => {
            return filteredFullComments.find((c) => c.fullComments === i);
        });

        // TODO: ???
        // @ts-ignore
        for (const [i, uc] of uniqueFullComments.entries()) {
            uc.legendId = i === 0 ? '*' : i.toString();
        }

        for (const uc of filteredFullComments) {
            if (!uc.legendId) {
                const sameComment = uniqueFullComments.find((t) => t!.fullComments === uc.fullComments);
                uc.legendId = sameComment!.legendId;
            }
        }
    };

    generateDefaultData();

    generateFundLayer(data);

    calculateInvestmentFund(data);
    calculateInvestmentAsset(data);
    calculateHoldingAsset(data);
    calculateHoldingFund(data);
    calculateHoldingInvestor(data);
    calculateDistributionFund(data);
    calculateDistributionInvestor(data);
    calculateSellingAsset(data);
    calculateSellingFund(data);
    calculateSellingInvestor(data);

    createXAxis();
    createYAxis();

    setTipsLegendId(tips);

    createLines();

    return {
        width,
        height,
        margin,
        nodes,
        tips,
        links,
        lines,
        bottomLines,
        xAxis,
        yAxis,
        fundNodes,
    };
};
