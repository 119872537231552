import styles from './styles.module.css';
import { DiagramDataItem } from '../../../../types/deal-taxes';

interface DealTaxesInfoProps {
    data: DiagramDataItem | undefined;
    initialInvestment?: number;
    returnOnAsset?: number;
    distribution?: number;
    inAside?: boolean;
}

const DealTaxesInfo = ({
    data,
    initialInvestment,
    returnOnAsset,
    distribution,
    inAside = false,
}: DealTaxesInfoProps) => {
    return (
        <div className={`${styles.info} ${inAside ? styles.visible : ''}`}>
            <div>
                <h3>
                    This <span className={'highlight'}>tax review</span> example is for:
                </h3>

                <h5>
                    <span>Initial investment: </span>
                    <span>${initialInvestment || 100}</span>
                </h5>
                <h5>
                    <span>Return on asset: </span>
                    <span>{returnOnAsset || 20}%</span>
                </h5>
                <h5>
                    <span>Distribution: </span>
                    <span>${distribution || 10}</span>
                </h5>

                <h5>
                    <span>Return on investment</span>

                    <span>{((data?.returnOnInvestment || 1) * 100).toFixed(2)}%</span>
                </h5>
            </div>

            <img src="/icons/arrow-icon.svg" alt="" />
        </div>
    );
};

export default DealTaxesInfo;
