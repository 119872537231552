import { useState, useEffect } from 'react';

import styles from './deal-taxes-template.module.css';
import { generateSankey } from '../../utils';
import { Sankey } from '../../components/Sankey';
import { DealTaxesTips } from '../DealTaxes/components/DealTaxesTips';
import { DealTaxesInfo } from '../DealTaxes/components/DealTaxesInfo';
import { DealTaxesExpandedTable } from '../DealTaxes/components/DealTaxesExpandedTable';
import { PdfTemplateGraphLayout, PdfTemplateLayout } from '../../components/PdfTemplate';
import { DealTaxesSelectedData } from '../DealTaxes/DealTaxes';
import { GraphTypes } from '../../types/general';
import { DiagramDataItems } from '../../types/deal-taxes';

const DealTaxesTemplate = () => {
    const [selectedData, setSelectedData] = useState<DealTaxesSelectedData>({
        selectedInvestor: undefined,
        investmentFund: {
            id: undefined,
            name: undefined,
            countryCode: undefined,
            countryName: undefined,
            countryId: undefined,
        },
        fundOfFunds: undefined,
        booked: undefined,
        corporate: false,
        ifBusinessAssets: undefined,
        assets: [],
        fundStatus: '',
        investors: [],
        openEnded: false,
        ifCommercialPartnership: undefined,
        fofOpenEnded: undefined,
        fofIfCommercialPartnership: undefined,
        fofCorporate: undefined,

        ukInvesting: undefined,
        fofUkInvesting: undefined,
    });

    const [legends, setLegends] = useState<any>([]);
    const [taxResult, setTaxResult] = useState<any>(null);
    const [sankeyData, setSankeyData] = useState<any>(null);
    const [rawData, setRawData] = useState<DiagramDataItems | null>(null);

    const [fund, setFund] = useState({
        country: '',
        fund: '',
    });

    const [initialInvestment, setInitialInvestment] = useState(100);
    const [returnOnAsset, setReturnOnAsset] = useState(20);
    const [distribution, setDistribution] = useState(10);

    const [isOptimisation, setIsOptimisation] = useState(false);

    useEffect(() => {
        // setSelectedData(tmp);
    }, []);

    useEffect(() => {
        // @ts-ignore
        const data = window.pdfData;

        setSelectedData(data.selectedData);

        const sankey = generateSankey(data.diagram.result);

        setRawData(data.diagram.result);
        setTaxResult(data.table);
        setSankeyData(sankey);
        setLegends(
            Array.from(new Set(sankey.tips.map((i: any) => i.legendId))).map((i) => {
                return sankey.tips.find((c: any) => c.legendId === i);
            })
        );
        // @ts-ignore
    }, [window.pdfData]);

    return (
        <>
            <PdfTemplateGraphLayout
                tableInfo={
                    rawData && (
                        <DealTaxesInfo
                            data={rawData.nav}
                            initialInvestment={initialInvestment}
                            returnOnAsset={returnOnAsset}
                            distribution={distribution}
                        />
                    )
                }
                tips={<DealTaxesTips />}
                comments={legends}
                selectedData={selectedData}
                isOptimisation={isOptimisation}
                fundData={fund}
                graphType={GraphTypes.Taxes}
            >
                {sankeyData && sankeyData.links && (
                    <div className={selectedData.fundOfFunds ? `fof-active` : ''}>
                        <Sankey sankey={sankeyData} />
                    </div>
                )}
            </PdfTemplateGraphLayout>

            <PdfTemplateLayout>
                <div className={styles.pageHeader}>
                    <h2>Tax review: expanded view</h2>
                </div>
                {taxResult && (
                    <div className={styles.tableContainer}>
                        <DealTaxesExpandedTable data={taxResult} assets={selectedData?.assets} />
                    </div>
                )}
            </PdfTemplateLayout>
        </>
    );
};

export default DealTaxesTemplate;
