import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { CurrencyFormat, DaysFormat, DescriptionCurrencyFormat, DescriptionFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD9Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd9 ? (
        <table>
            <TableHeader sdKey={'sd9'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd9'} colKey={'legalForm'} />

                <Tr
                    featName={'Legal fee drafting documents'}
                    dataKey={'sd9'}
                    colKey={'legalFeeDraftingDocuments'}
                    formatFunc={CurrencyFormat}
                />

                <Tr
                    featName={'Legal structuring advise'}
                    dataKey={'sd9'}
                    colKey={'legalStructuringAdvise'}
                    formatFunc={CurrencyFormat}
                />

                <Tr
                    featName={'Regulator examination and registration fee'}
                    dataKey={'sd9'}
                    colKey={'regulatorExaminationAndRegistrationFee'}
                    formatFunc={CurrencyFormat}
                />
                <Tr
                    featName={'Incorporation notary fee'}
                    dataKey={'sd9'}
                    colKey={'incorporationNotaryFee'}
                    formatFunc={CurrencyFormat}
                />

                <TrProvider>
                    <Tr
                        featName={'Incorporation notary fee comments'}
                        dataKey={'sd9'}
                        colKey={'incorporationNotaryFeeComments'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>

                <Tr
                    featName={'Incorporation government fee'}
                    dataKey={'sd9'}
                    colKey={'incorporationGovernmentFee'}
                    formatFunc={CurrencyFormat}
                />
                <TrProvider>
                    <Tr
                        featName={'Total setup costs'}
                        dataKey={'sd9'}
                        colKey={'totalSetupCost'}
                        formatFunc={DescriptionCurrencyFormat}
                        passData={true}
                    />
                </TrProvider>
                <Tr featName={'Directors fee'} dataKey={'sd9'} colKey={'directorsFee'} formatFunc={CurrencyFormat} />

                <Tr
                    featName={'Government annual fee'}
                    dataKey={'sd9'}
                    colKey={'governmentAnnualFee'}
                    formatFunc={CurrencyFormat}
                />

                <Tr
                    featName={'Regulator annual fee'}
                    dataKey={'sd9'}
                    colKey={'regulatorAnnualFee'}
                    formatFunc={CurrencyFormat}
                />

                <Tr
                    featName={'Total annual structuring fee'}
                    dataKey={'sd9'}
                    colKey={'totalAnnualStructureCost'}
                    formatFunc={DescriptionCurrencyFormat}
                    passData={true}
                />

                <Tr
                    featName={'Incorporation time (days)'}
                    dataKey={'sd9'}
                    colKey={'incorporationTime'}
                    formatFunc={DaysFormat}
                />
                <Tr
                    featName={'Authorization time (days)'}
                    dataKey={'sd9'}
                    colKey={'authorizationTime'}
                    formatFunc={DaysFormat}
                />
            </tbody>
        </table>
    ) : null;
};

export default SD9Table;
