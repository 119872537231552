import styles from './FormLayout.module.css';

const FormLayoutFooter = ({ children, footerAdditional }: any) => {
    return (
        <footer className={styles.footer}>
            {!!footerAdditional && <div className={styles.footerAdditional}>{footerAdditional}</div>}

            {children}
        </footer>
    );
};

export default FormLayoutFooter;
