import styles from '../styles.module.css';

const StorageFormSummaryItem = ({ index, title, selected, ...props }: any) => {
    return (
        <div className={`${styles.item} ${selected ? styles.selected : ''}`} {...props}>
            <span className={styles.index}>{index}</span>

            <h5 className={styles.header}>{title}</h5>
        </div>
    );
};

export default StorageFormSummaryItem;
