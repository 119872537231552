interface Axis {
    x: number;
    y: number;
    value: number;
    top: boolean;
    fontSize?: number;
    className?: string;
}

interface AxisProps {
    axis: Axis;
}

export const XAxis = ({ axis }: AxisProps) => {
    if (axis.top) {
        return (
            <g transform={`translate(${axis.x}, ${axis.y})`}>
                <text x={0} y={0} dy="2.6em" fontSize={20} fontWeight={100} textAnchor="middle" fill="#1040b5">
                    {axis.value}
                </text>
                <polygon transform={`translate(-8, 0)`} points="0,0 18,0 8,18" style={{ fill: '#1040b5' }} />
            </g>
        );
    } else {
        return (
            <g transform={`translate(${axis.x}, ${axis.y})`}>
                <text x={0} y={0} fontSize={20} fontWeight={100} textAnchor="middle" fill="#0c3b8d">
                    {axis.value}
                </text>
                <polygon transform={`translate(-8, 8)`} points="8,0 18,18 0,18" style={{ fill: '#0c3b8d' }} />
            </g>
        );
    }
};

export const YAxis = ({ axis }: AxisProps) => {
    return (
        <g transform={`translate(${axis.x}, ${axis.y})`} className={axis.className}>
            <text x={0} y={0} fontSize={axis.fontSize || 16} fontWeight={100} textAnchor="middle" fill="#000000">
                {axis.value}
            </text>
        </g>
    );
};
