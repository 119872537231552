// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_page__5BEPx {\n    display: flex;\n    grid-gap: 20px;\n    justify-content: center;\n    /*align-items: flex-start;*/\n    margin: 0 auto;\n    max-width: 100%;\n    /*width: 1890px;*/\n    width: 2170px;\n    padding: 24px 30px 34px;\n}\n\n.styles_content__uutki {\n    width: 1100px;\n    max-width: calc(100vw - 450px);\n}\n\n.styles_flex__S8cIN {\n    flex: 1 1;\n}\n\n.styles_navigation__5bU9Y {\n    margin: auto 0;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-lg-min"] + " {\n    .styles_page__5BEPx {\n        grid-gap: 35px;\n        margin: 0 auto;\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + " {\n    .styles_navigation__5bU9Y {\n        display: none;\n    }\n\n    .styles_margin__Mq4cm {\n        margin: 0 auto;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Storage/StorageForm/styles.module.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,2BAA2B;IAC3B,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;QACd,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":["@value screen-lg-min, screen-md-max from '../../../breakpoints.css';\n\n.page {\n    display: flex;\n    grid-gap: 20px;\n    justify-content: center;\n    /*align-items: flex-start;*/\n    margin: 0 auto;\n    max-width: 100%;\n    /*width: 1890px;*/\n    width: 2170px;\n    padding: 24px 30px 34px;\n}\n\n.content {\n    width: 1100px;\n    max-width: calc(100vw - 450px);\n}\n\n.flex {\n    flex: 1;\n}\n\n.navigation {\n    margin: auto 0;\n}\n\n@media screen-lg-min {\n    .page {\n        grid-gap: 35px;\n        margin: 0 auto;\n    }\n}\n\n@media screen-md-max {\n    .navigation {\n        display: none;\n    }\n\n    .margin {\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen-lg-min": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-lg-min"] + "",
	"screen-md-max": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + "",
	"page": "styles_page__5BEPx",
	"content": "styles_content__uutki",
	"flex": "styles_flex__S8cIN",
	"navigation": "styles_navigation__5bU9Y",
	"margin": "styles_margin__Mq4cm"
};
export default ___CSS_LOADER_EXPORT___;
