import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { DescriptionProgressFormat, ProgressFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD5Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd5 ? (
        <table>
            <TableHeader sdKey={'sd5'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd5'} colKey={'legalForm'} />

                <Tr
                    featName={'Min required capital'}
                    dataKey={'sd5'}
                    colKey={'minCapital'}
                    formatFunc={ProgressFormat}
                    formatData={{
                        currencyFormat: true,
                    }}
                    progressCol={'minCapitalBar'}
                />

                <Tr
                    featName={'Min capital at launch of the entity'}
                    dataKey={'sd5'}
                    colKey={'minCapitalAtLaunch'}
                    formatFunc={ProgressFormat}
                    formatData={{
                        currencyFormat: true,
                    }}
                    progressCol={'minCapitalAtLaunchBar'}
                />

                <Tr featName={'To be reached'} dataKey={'sd5'} colKey={'toBeReachedWithinNumMonth'} />

                <Tr featName={'Min required capital details'} dataKey={'sd5'} colKey={'minCapitalDetails'} />

                <Tr
                    featName={'Min capital statutory'}
                    dataKey={'sd5'}
                    colKey={'minCapitalStatury'}
                    formatFunc={ProgressFormat}
                    progressCol={'minCapitalStatutoryBar'}
                    formatData={{
                        currencyFormat: true,
                    }}
                />

                <TrProvider>
                    <Tr
                        featName={'Statutory reserve'}
                        dataKey={'sd5'}
                        colKey={'statutoryReserve'}
                        formatFunc={DescriptionProgressFormat}
                        progressCol={'statutoryReserveBar'}
                        formatData={{
                            currencyFormat: true,
                        }}
                    />
                </TrProvider>
            </tbody>
        </table>
    ) : null;
};

export default SD5Table;
