import React, { useEffect, useState } from 'react';
import styles from './checkbox.module.css';

interface CheckboxProps {
    label: string;
    isLabelFirst?: boolean;
    name?: string;
    value: string | number | boolean;
    checked: boolean;
    disabled?: boolean;
    info?: string | React.ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: 'radio' | 'checkbox';
}

const Checkbox = ({
    type = 'checkbox',
    label,
    name,
    value,
    checked,
    disabled,
    info,
    onChange,
    isLabelFirst,
}: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState(checked || false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);

        onChange(event);
    };

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    return (
        <label className={`${styles.label} ${disabled ? styles.disabled : ''}`}>
            <input
                type={type}
                name={name}
                value={value.toString()}
                checked={checked}
                onChange={handleChange}
                className={styles.input}
                disabled={disabled}
            />
            {isLabelFirst && <span className={styles.label}>{label}</span>}

            <span className={`${styles.checkbox} ${isChecked ? styles.checked : ''}`} />

            {!isLabelFirst && <span className={styles.label}>{label}</span>}

            {info && (
                <span className={`${styles.tip} ${disabled ? styles.disabled : ''}`}>
                    <span className={styles.tipBtn}>
                        <span className={styles.tipBtnImg}>
                            <img alt={''} src={'/icons/info.png'} />
                            <img alt={''} src={'/icons/info-active.png'} />
                        </span>
                    </span>

                    <p className={styles.tipBox}>{info}</p>
                </span>
            )}
        </label>
    );
};

export default Checkbox;
