import styles from './styles.module.css';

const OverviewFolder = ({
    itemLength,
    image,
    name,
    type = 'secondary',
    responsive = false,
    ...props
}: {
    name: string;
    image: string;
    itemLength: number;
    type?: 'secondary';
    onClick?: any;
    responsive?: boolean;
}) => {
    return (
        <div className={`${styles.folderContainer} ${responsive ? styles.responsive : ''}`} {...props}>
            <div className={styles.folder}>
                <div className={styles.itemWrapper}>{itemLength > 0 && <div className={styles.item}></div>}</div>

                <div className={styles.imgContainer}>
                    <img
                        className={`${styles.folderImg} ${type ? styles.secondary : ''}`}
                        src={`/images/folder${!!type ? '-secondary' : ''}.png`}
                        alt=""
                    />
                    <img className={`${styles.typeImg} ${type ? styles.secondary : ''}`} src={image} alt="" />
                </div>
            </div>

            <p className={styles.type}>{name}</p>
        </div>
    );
};

export default OverviewFolder;
