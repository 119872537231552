import { DealTaxesSelectedData } from '../pages/DealTaxes/DealTaxes';
import { SelectionLevel } from '../types/general';

export enum DealTaxesCheckboxes {
    openEnded = 'openEnded',
    corporate = 'corporate',
    ifCommercialPartnership = 'ifCommercialPartnership',
    ifBusinessAssets = 'ifBusinessAssets',
    booked = 'booked',

    ukInvesting = 'ukInvesting',
    fofUkInvesting = 'fofUkInvesting',

    fofOpenEnded = 'fofOpenEnded',
    fofCorporate = 'fofCorporate',
    fofIfCommercialPartnership = 'fofIfCommercialPartnership',
}

export const dealTaxesCheckboxes = [
    {
        type: 'booked',
        group: SelectionLevel.Investor,
        label: 'booked at NAV',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            selection.selectedInvestor?.countryCode?.toLowerCase() === 'ch',
        isDisabled: (selectedData: DealTaxesSelectedData) =>
            !!(selectedData.selectedInvestor && [5, 6, 15, 16].indexOf(+selectedData.selectedInvestor.id!) === -1),
        info: 'If the investment was booked at NAV or at cost.',
    },
    {
        type: 'corporate',
        group: SelectionLevel.Fund,
        label: 'corporate',
        defaultValue: () => false,
        isAvailable: () => true,
        isDisabled: (selectedData: DealTaxesSelectedData) =>
            selectedData.investmentFund.id && [4].indexOf(+selectedData.investmentFund.id) > -1,
        info: `A corporation is an independent legal entity owned by its shareholders (Lux: Sàrl,
                                    SA, SCA; Cayman: company, LLC, SPC). <br />
                                    Whereas a limited partnership is a partnership that is established by contract
                                    between one or more unlimited partners (or general partners) and one or more limited
                                    partners (Lux: SCS, SCSp, SCA; Cayman: LP).
                               `,
    },
    {
        type: 'openEnded',
        group: SelectionLevel.Fund,
        label: 'open-ended',
        defaultValue: () => false,
        isAvailable: () => true,
        isDisabled: (selectedData: DealTaxesSelectedData, data: any) => {
            return !!(
                selectedData.investmentFund.id && [1, 5, 6, 7, 8, 9].indexOf(+selectedData.investmentFund.id) > -1
            );
        },
        info: `Collective investment scheme whose investors have a legal right to redeem 
        or withdraw their shares or interest at least once per year.`,
    },
    {
        type: 'ifCommercialPartnership',
        group: SelectionLevel.Fund,
        label: 'commercial',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            selection.selectedInvestor?.countryCode?.toLowerCase() === 'de',
        isDisabled: (selectedData: DealTaxesSelectedData) => selectedData.corporate,
        info: `A partnership is qualified as deemed commercial partnership, if only the 
        general partner is entitled to manage the partnership. On the other hand, 
        if one or more limited partners manage the partnership together with the general partner, 
        such a partnership is deemed to be non-commercial.`,
    },
    {
        type: 'ifBusinessAssets',
        group: SelectionLevel.Investor,
        label: 'business asset',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) => {
            return selection.selectedInvestor?.countryCode?.toLowerCase() === 'de';
        },
        isDisabled: (selectedData: DealTaxesSelectedData) => false,
        // !(selectedData.currentInvestor && [14].indexOf(+selectedData.currentInvestor) === -1),
        info: 'If the investor books this allocation as business or non-business assets.',
    },
    {
        type: 'ukInvesting',
        group: SelectionLevel.Fund,
        label: (selection: DealTaxesSelectedData) =>
            selection.openEnded && selection.corporate ? 'reporting' : 'investing',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) => {
            return selection.selectedInvestor?.countryCode?.toLowerCase() === 'uk';
        },
        isDisabled: () => false,
        info: `The fund is classified as trading or investing
                according to HMRC's 'badges of trade' guidance:
                including numbe
                r of transactions, type of assets,
                source of funding, holding period etc. Careful
                analysis is required`,
    },

    {
        type: 'fofCorporate',
        group: SelectionLevel.FundOfFunds,
        label: 'corporate',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) => !!selection.fundOfFunds,
        isDisabled: (selectedData: DealTaxesSelectedData, { fofLegalTypesDisabled }: any) => {
            return fofLegalTypesDisabled;
        },
        info: `A corporation is an independent legal entity owned by its shareholders (Lux: Sàrl, SA,
                                SCA; Cayman: company, LLC, SPC). <br />
                                Whereas a limited partnership is a partnership that is established by contract between
                                one or more unlimited partners (or general partners) and one or more limited partners
                                (Lux: SCS, SCSp, SCA; Cayman: LP).`,
    },
    {
        type: 'fofOpenEnded',
        group: SelectionLevel.FundOfFunds,
        label: 'open-ended',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) => !!selection.fundOfFunds,
        isDisabled: (selectedData: DealTaxesSelectedData, { fofOpenEndedDisabled }: any) => fofOpenEndedDisabled,
        info: `Collective investment scheme whose investors have a legal right to redeem 
        or withdraw their shares or interest at least once per year.`,
    },
    {
        type: 'fofIfCommercialPartnership',
        group: SelectionLevel.FundOfFunds,
        label: 'commercial',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            !!selection.fundOfFunds && selection.selectedInvestor?.countryCode?.toLowerCase() === 'de',
        isDisabled: (selectedData: DealTaxesSelectedData) => selectedData.fofCorporate,
        info: `A partnership is qualified as deemed commercial partnership, if only the general 
        partner is entitled to manage the partnership. On the other hand, if one or more 
        limited partners manage the partnership together with the general partner, 
        such a partnership is deemed to be non-commercial.`,
    },
    {
        type: 'fofUkInvesting',
        group: SelectionLevel.FundOfFunds,
        label: (selection: DealTaxesSelectedData) =>
            selection.fofOpenEnded && selection.fofCorporate ? 'reporting' : 'investing',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            selection.selectedInvestor?.countryCode?.toLowerCase() === 'uk',
        isDisabled: () => false,
        info: `A fund that has been granted reporting offshore
                fund status by HM Revenu
                e and Customs: it is an
                offshore fund that has applied to HMRC, been
                approved and maintains its status as a reporting
                fund`,
    },
];

export const isDealTaxesCheckboxAvailable = (
    checkboxType: DealTaxesCheckboxes,
    selectedData: DealTaxesSelectedData
) => {
    return dealTaxesCheckboxes.find((check) => check.type === checkboxType)?.isAvailable(selectedData);
};
