import Select from '../../../../../../components/Select/Select';
import { useEffect, useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import { Portal } from 'react-portal';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Icons } from '../../../../../../components/Icon/Icon';
import { v4 as uuidv4 } from 'uuid';
import styles from '../../../../layout/FormLayout/FormLayout.module.css';

enum Forms {
    Role = 'Role',
    Base = 'Base',
}

const AssetManagerRoleForm = ({ selectedData, onClose, onSubmit }: any) => {
    const [data, setData] = useState<any>({
        roleManual: '',
        ...selectedData,
    });

    const handleSubInputChange = (prop: string) => (e: any) => {
        let value = e.target.value;

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        setData(selectedObj);
    };

    return (
        <>
            <Portal node={document && document.getElementById('formWrapper')}>
                <FormLayout
                    title={'Asset manager'}
                    description={'Please provide asset manager name, domicile and regulatory status.'}
                    isAbsolute={true}
                    footerMain={
                        <Button form={'subForm'} disabled={!data.roleManual}>
                            Upload
                        </Button>
                    }
                    onClose={onClose}
                >
                    <form
                        id={'subForm'}
                        onSubmit={(e) => {
                            onSubmit(e, data, {
                                roleId: uuidv4(),
                                role: data.roleManual,
                            });
                        }}
                    >
                        <Input
                            required
                            value={data.roleManual}
                            onChange={handleSubInputChange('roleManual')}
                            style={'text'}
                            max={50}
                        >
                            Short name
                        </Input>
                    </form>
                </FormLayout>
            </Portal>
        </>
    );
};

const AssetManagerBaseForm = ({ selectedData, generalInfo, onClose, onSubmit }: any) => {
    const [showRegulatoryStatusInput, setShowRegulatoryStatusInput] = useState(!!selectedData.regulatoryStatusManual);

    console.log('selectedData', selectedData);

    const [data, setData] = useState<any>({
        name: '',
        regulatoryStatusManual: undefined,
        regulatoryStatus: undefined,
        domicile: '',
        ...selectedData,
    });

    const handleSubInputChange = (prop: string) => (e: any) => {
        let value = e.target.value;

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        if (prop === 'regulatoryStatusManual' && !!value) {
            selectedObj.regulatoryStatus = undefined;
            setShowRegulatoryStatusInput(false);
        }

        setData(selectedObj);
    };

    const handleSubSelectChange = (prop: string) => (value: any) => {
        console.log('value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        if (prop === 'regulatoryStatus') {
            selectedObj.regulatoryStatusManual = '';
        }

        setData(selectedObj);
    };

    return (
        <>
            <Portal node={document && document.getElementById('formWrapper')}>
                <FormLayout
                    title={'Asset manager'}
                    description={'Please provide asset manager name, domicile and regulatory status.'}
                    isAbsolute={true}
                    footerMain={<Button form={'subForm'}>Upload</Button>}
                    onClose={onClose}
                >
                    <form id={'subForm'} onSubmit={(e) => onSubmit(e, data)}>
                        <Input value={data.name} onChange={handleSubInputChange('name')} style={'text'} max={100}>
                            Full name
                        </Input>

                        <Select
                            value={data.domicile}
                            onChange={handleSubSelectChange('domicile')}
                            options={generalInfo.countries}
                            valueKey={'domicileId'}
                            labelKey={'domicileName'}
                            style={'secondary'}
                        >
                            Domicile
                        </Select>

                        <Select
                            value={data.regulatoryStatus}
                            onChange={handleSubSelectChange('regulatoryStatus')}
                            options={generalInfo.amRegulatoryStatuses}
                            valueKey={'regulatoryStatusId'}
                            labelKey={'regulatoryStatus'}
                            onCreateOption={() => {
                                setShowRegulatoryStatusInput(true);
                            }}
                            style={'secondary'}
                        >
                            Regulatory status
                        </Select>

                        {showRegulatoryStatusInput && (
                            <Input
                                onChange={handleSubInputChange('regulatoryStatusManual')}
                                style={'text'}
                                max={100}
                                value={data.regulatoryStatusManual}
                            ></Input>
                        )}
                    </form>
                </FormLayout>
            </Portal>
        </>
    );
};

const AssetManagerSingle = ({ selectedData, generalInfo, setGeneralInfo, onUpdate, handleRemoveItem }: any) => {
    const [activeForm, setActiveForm] = useState<Forms | undefined | null>();

    const [data, setData] = useState<any>({
        domicile: undefined,
        roleManual: '',
        role: undefined,
        regulatoryStatusManual: '',
        ...selectedData,
    });

    const handleSelectChange = (prop: string) => (value: any) => {
        console.log('value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        setData(selectedObj);
        onUpdate(selectedObj);
    };

    const handleShowCreateForm = (form: Forms) => () => {
        setActiveForm(form);
    };

    const submitActiveForm = (e: any, selectedData: any, selectedObj?: any) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('closeActiveForm', selectedObj);

        const tmp = {
            ...data,
            ...selectedData,
        };

        if (selectedObj) {
            console.log('selectedObj ROLes', [selectedObj, ...generalInfo.amRoles]);

            tmp.role = selectedObj;

            setGeneralInfo({
                ...generalInfo,
                amRoles: [selectedObj, ...generalInfo.amRoles],
            });
        }

        console.log('tmp', tmp);

        setData(tmp);
        onUpdate(tmp);
        setActiveForm(null);
    };

    console.log('generalInfo.amRoles', generalInfo.amRoles);

    return (
        <>
            <div className={styles.formGridRow}>
                <button
                    style={{
                        minWidth: 32,
                        marginTop: -10,
                        marginLeft: -20,
                        position: 'absolute',
                    }}
                    type={'button'}
                    onClick={handleRemoveItem}
                >
                    <Icon icon={Icons.closeRounded} />
                </button>

                <Select
                    value={data.role}
                    onChange={handleSelectChange('role')}
                    options={generalInfo.amRoles}
                    valueKey={'roleId'}
                    labelKey={'role'}
                    onCreateOption={handleShowCreateForm(Forms.Role)}
                >
                    Asset manager role
                </Select>

                <Select disabled={true}>Asset manager agreement with</Select>
            </div>

            <div
                className={styles.formGridRow}
                style={{
                    marginBottom: '40px',
                }}
            >
                <Input
                    type={'button'}
                    value={data.name || 'Asset manager name'}
                    onClick={handleShowCreateForm(Forms.Base)}
                >
                    Asset manager name
                </Input>

                <Input
                    type={'button'}
                    value={data.regulatoryStatusManual || data.regulatoryStatus?.label || 'Asset manager role'}
                    onClick={handleShowCreateForm(Forms.Base)}
                >
                    Asset manager role
                </Input>
            </div>

            {activeForm === Forms.Role && (
                <AssetManagerRoleForm onSubmit={submitActiveForm} onClose={() => setActiveForm(null)} />
            )}

            {activeForm === Forms.Base && (
                <AssetManagerBaseForm
                    selectedData={data}
                    generalInfo={generalInfo}
                    onSubmit={submitActiveForm}
                    onClose={() => setActiveForm(null)}
                />
            )}
        </>
    );
};

export default AssetManagerSingle;
