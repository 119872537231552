import React from 'react';
import { SelectionLevel, SelectionLevelProps } from '../../types/general';
import { Icon } from '../index';

interface SelectionLabelProps {
    type: SelectionLevel;
}
const SelectionLabel = ({ type }: SelectionLabelProps) => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '13px',
                }}
            >
                <Icon icon={SelectionLevelProps[type]?.icon} /> <h6>{SelectionLevelProps[type]?.title}</h6>
            </div>
        </>
    );
};

export default SelectionLabel;
