import { TableDescriptionContext } from './context';

import React, { useEffect, useState } from 'react';

const ComparisonTableSectionProvider = ({ children }: { children: React.ReactNode }) => {
    const [expanded, setExpanded] = useState(false);
    const [totalExpandableRows, setTotalExpandableRows] = useState(0);

    const [expandedRows, setExpandedRows] = useState(0);

    useEffect(() => {
        setTotalExpandableRows(0);
    }, []);

    useEffect(() => {
        if (expandedRows <= 0) {
            setExpanded(false);
        } else if (expandedRows > 0) {
            if (totalExpandableRows === expandedRows) {
                setExpanded(true);
            }
        }
    }, [expandedRows]);

    return (
        <TableDescriptionContext.Provider
            value={{
                expandedRows: expandedRows,
                setExpandedRows: setExpandedRows,
                globalExpanded: expanded,
                setGlobalExpanded: setExpanded,
                totalExpandableRows: totalExpandableRows,
                setTotalExpandableRows: setTotalExpandableRows,
            }}
        >
            {children}
        </TableDescriptionContext.Provider>
    );
};

export default ComparisonTableSectionProvider;
