const ChevronDown = () => {
    return (
        <svg
            id="open_close_section"
            data-name="open&amp;close section"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="15"
            viewBox="0 0 22 15"
        >
            <g id="Polygon_1" data-name="Polygon 1" transform="translate(22 15) rotate(180)" fill="currentcolor">
                <path
                    d="M 21.01330184936523 14.5 L 0.9866986870765686 14.5 L 11 0.8454982042312622 L 21.01330184936523 14.5 Z"
                    stroke="none"
                />
                <path
                    d="M 11 1.690996170043945 L 1.973398208618164 14 L 20.02660179138184 14 L 11 1.690996170043945 M 11 0 L 22 15 L 0 15 L 11 0 Z"
                    stroke="none"
                    fill="currentcolor"
                />
            </g>
        </svg>
    );
};

export default ChevronDown;
