// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon__zj710 {\n    --icon-color: var(--icon-color-custom, currentcolor);\n    --icon-fill-color: var(--icon-fill-color-custom, #fff);\n}\n\n.styles_icon__zj710 svg {\n    max-width: 100%;\n}\n\n.styles_icon__zj710 svg * {\n    transition: 0.2s linear;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oDAAoD;IACpD,sDAAsD;AAC1D;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".icon {\n    --icon-color: var(--icon-color-custom, currentcolor);\n    --icon-fill-color: var(--icon-fill-color-custom, #fff);\n}\n\n.icon svg {\n    max-width: 100%;\n}\n\n.icon svg * {\n    transition: 0.2s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon__zj710"
};
export default ___CSS_LOADER_EXPORT___;
