import { useState } from 'react';
import axios from '../../../../../utils/axios';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import { StorageType } from '../../StorageForm';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import { Button } from '../../../../../components';
import LicenseCreateForm from './components/LicenseCreateForm';
import LawsCreateForm from './components/LawsCreateForm';

enum Forms {
    License = 'License',
    Law = 'Law',
}
const StorageOtherProperties = ({ handleNextStep, handleClose, selectedData, generalInfo, handleSubmit }: any) => {
    const [activeForm, setActiveForm] = useState<Forms | null>(null);

    const [data, setData] = useState<any>({
        licenses: '',
        laws: '',
        ...selectedData,
    });

    const [dropdownData, setDropdownData] = useState({
        licenses: generalInfo.licenses,
        laws: generalInfo.laws,
    });

    const handleInputChange = (prop: string) => (e: any) => {
        const value = e.target.checked;

        setData({
            ...data,
            [prop]: value,
        });
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        console.log('selected', value);
        console.log('dropdownData.licenses', dropdownData.licenses);
        if (prop === 'licenses') {
            value = dropdownData.licenses.filter(
                (item: any) => value.findIndex((selected: any) => item.regulatoryStatus === selected.label) > -1
            );
            value = value.map((v: any) => ({
                ...v,
                label: v.label || v.regulatoryStatus,
            }));
        }
        console.log(value);

        setData({
            ...data,
            [prop]: value,
        });

        console.log('set', {
            ...data,
            [prop]: value,
        });
    };

    const handleCreateItems = (prop: keyof typeof dropdownData, newItems: []) => () => {
        setDropdownData({
            ...dropdownData,
            [prop]: [...newItems, ...dropdownData[prop]],
        });

        setData({
            ...data,
            [prop]: [...newItems, ...data[prop]],
        });

        console.log('data', {
            ...data,
            [prop]: [...newItems, ...data[prop]],
        });

        setActiveForm(null);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Investment vehicle'}
                description={'Other information about this structure needed for regulatory and compliance tracking.'}
                footerMain={<Button form={'mainForm'}>Next</Button>}
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.ServiceProviders)}
            >
                <form id={'mainForm'} onSubmit={handleSubmit(data)}>
                    <div
                        style={{
                            width: '100%',
                            margin: 0,
                        }}
                    >
                        <Input type={'checkbox'} checked={data.openEnded} onChange={handleInputChange('openEnded')}>
                            Open ended structure?
                        </Input>
                        <Input type={'checkbox'} checked={data.evergreen} onChange={handleInputChange('evergreen')}>
                            Evergreen
                        </Input>
                    </div>
                    <Select
                        value={data.licenses}
                        onChange={handleSelectChange('licenses')}
                        options={dropdownData.licenses}
                        valueKey={'regulatoryStatusId'}
                        labelKey={'regulatoryStatus'}
                        onCreateOption={() => {
                            setActiveForm(Forms.License);
                        }}
                        isMulti={true}
                        info={'Licences and regulatory approvals obtained by the investment vehicle.'}
                    >
                        Licenses
                    </Select>

                    <Select
                        value={data.domicileId}
                        onChange={handleSelectChange('domicileId')}
                        options={generalInfo.distributionCountries}
                        valueKey={'domicileId'}
                        labelKey={'domicileName'}
                        isMulti={true}
                        info={'Countries where the fund can be offered / marketed.'}
                    >
                        Distribution countries
                    </Select>

                    <Select
                        value={data.laws}
                        onChange={handleSelectChange('laws')}
                        options={dropdownData.laws}
                        valueKey={'lawId'}
                        labelKey={'laws'}
                        onCreateOption={() => {
                            setActiveForm(Forms.Law);
                        }}
                        isMulti={true}
                        info={'List of laws and regulations relevant to this vehicle, its investors and assets.'}
                    >
                        Relevant laws and regulations
                    </Select>
                </form>
            </FormLayout>

            {activeForm === Forms.License && (
                <LicenseCreateForm countries={generalInfo.countries} onSave={handleCreateItems} />
            )}

            {activeForm === Forms.Law && (
                <LawsCreateForm countries={generalInfo.countries} onSave={handleCreateItems} />
            )}
        </FormWrapper>
    );
};

export default StorageOtherProperties;
