import styles from './styles.module.css';
import InputLabel from '../../../../components/InputLabel/InputLabel';
import { Icon } from '../../../../components';
import { Icons } from '../../../../components/Icon/Icon';
import { useState } from 'react';

const FormCollapsableRow = ({
    titleLabel,
    titleValue,
    disabled,
    labelFullWidth,
    children,
    hideHeader = true,
    useGrid = true,
}: any) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${styles.row} ${isOpen ? styles.open : ''} ${disabled ? styles.disabled : ''}`}>
            <header
                className={`${styles.header} ${hideHeader ? 'hide' : ''}`}
                onClick={() => {
                    if (!disabled) {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                <InputLabel fullWidth={false} labelFullWidth={labelFullWidth || !children} label={titleLabel} />

                <div
                    style={{
                        flex: 1,
                    }}
                >
                    {titleValue}
                </div>
            </header>

            <div className={styles.contentOuter}>
                <div className={`${styles.content} ${useGrid ? styles.grid : ''}`}>{children}</div>

                <button
                    className={styles.toggle}
                    disabled={disabled}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <Icon icon={Icons.chevronDown} />
                </button>
            </div>
        </div>
    );
};

export default FormCollapsableRow;
