import styles from './result-table-styles.module.css';
import React, { useEffect, useState } from 'react';
import { Button, CloseButton } from '../index';
import TransitionComponent from '../TransitionComponent/TransitionComponents';

interface ResultTableRightProps {
    showDetails: boolean;
    details: React.ReactNode;
    checkboxes?: React.ReactNode;
    tips?: React.ReactNode;
    isOptimisation?: boolean;
    actions?: React.ReactNode;
    isFoF?: boolean;
}

const ResultTableRight = ({
    showDetails,
    details,
    checkboxes,
    tips,
    isOptimisation,
    actions,
    isFoF,
}: ResultTableRightProps) => {
    const [isSelectionOpen, setIsSelectionOpen] = useState(isFoF);

    useEffect(() => {
        setIsSelectionOpen(isFoF);
    }, [isFoF]);

    const toggleSelection = (open: boolean) => (e: any) => {
        setIsSelectionOpen(open);
    };

    return (
        <div className={styles.tableRight}>
            <TransitionComponent inProp={showDetails} isAbsolute={true}>
                <div className={styles.details}>{details}</div>
            </TransitionComponent>

            <TransitionComponent inProp={!showDetails} isAbsolute={false}>
                <></>

                {tips && <div className={`${styles.tipsRight} ${isSelectionOpen ? styles.checkOpen : ''}`}>{tips}</div>}
            </TransitionComponent>
        </div>
    );
};
export default ResultTableRight;
