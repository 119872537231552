import styles from './styles.module.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { data } from './glossary-data';

const Glossary = () => {
    return (
        <div className={styles.container}>
            <h2>Glossary</h2>
            <PerfectScrollbar className={`${styles.sectionWrapper}`}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Term</th>
                            <th></th>
                            <th>Definition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((d, index) => (
                                <tr key={index}>
                                    <td className={styles.term}>{d.term}</td>
                                    <td className={styles.decryption}>{d.decryption}</td>
                                    <td className={styles.definition}>{d.definition}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </PerfectScrollbar>
        </div>
    );
};

export default Glossary;
