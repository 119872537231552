const QuestionMarkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
            <g id="Group_6112" data-name="Group 6112" transform="translate(-1777 -5218)">
                <g id="Component_37_43" data-name="Component 37 – 43" transform="translate(1777 5218)">
                    <g id="Ellipse_5" data-name="Ellipse 5" fill="#fff" stroke="#000" strokeWidth="1">
                        <circle cx="17" cy="17" r="17" stroke="none" />
                        <circle cx="17" cy="17" r="16.5" fill="none" />
                    </g>
                </g>
                <text
                    id="_"
                    data-name="?"
                    transform="translate(1790 5243)"
                    fontSize="20"
                    fontFamily="Roboto-Regular, Roboto"
                    letterSpacing="0.05em"
                >
                    <tspan x="0" y="0">
                        ?
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export default QuestionMarkIcon;
