import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import Input from '../../../../../components/Input/Input';

const StorageViewGeneralPartner = ({ selectedData, onClose }: any) => {
    return (
        <FormWrapper>
            <FormLayout title={'General Partner'} description={'Here is the general partner.'} onClose={onClose}>
                <Input disabled value={selectedData.gpName}>
                    Name
                </Input>
                <Input disabled value={selectedData.fullName}>
                    Full name
                </Input>
                <Input disabled value={selectedData.domicileName}>
                    Domicile
                </Input>
                <Input disabled value={selectedData.legalForm}>
                    Legal form
                </Input>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageViewGeneralPartner;
