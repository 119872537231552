import { useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Portal } from 'react-portal';
import { Icons } from '../../../../../../components/Icon/Icon';
import { v4 as uuidv4 } from 'uuid';

const InvestorCreateForm = ({ onSave, onClose }: any) => {
    const [investors, setInvestors] = useState<any[]>([{}]);

    const handleInputChange = (index: number) => (event: any) => {
        let tmp = [...investors];
        tmp[index] = { ...tmp[index], label: event.target.value };
        setInvestors(tmp);
    };

    const handleAddInvestor = () => {
        setInvestors([
            ...investors,
            {
                value: uuidv4(),
                label: '',
            },
        ]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...investors];
        tmp.splice(index, 1);
        setInvestors(tmp);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Investor type'}
                description={'Please provide investor type(s).'}
                isAbsolute={true}
                footerMain={<Button form={'subForm'}>Upload</Button>}
                footerAdditional={
                    <Button outlined={true} type={'button'} onClick={handleAddInvestor}>
                        Add investor
                    </Button>
                }
                onClose={onClose}
            >
                <form id="subForm" onSubmit={onSave(investors)}>
                    {investors.map((investor: any, index: number) => (
                        <div key={index}>
                            <button
                                style={{
                                    width: 20,
                                    height: 20,
                                    marginTop: -10,
                                    marginLeft: -10,
                                    position: 'absolute',
                                    zIndex: 1,
                                    background: '#fff',
                                    borderRadius: '50%',
                                    padding: 0,
                                    border: 0,
                                }}
                                type={'button'}
                                onClick={handleRemoveItem(index)}
                            >
                                <Icon icon={Icons.closeRounded} />
                            </button>

                            <Input required onChange={handleInputChange(index)} />
                        </div>
                    ))}
                </form>
            </FormLayout>
        </Portal>
    );
};

export default InvestorCreateForm;
