import React, { useEffect, useState } from 'react';
import styles from './checkbox.module.css';

interface CheckboxProps {
    name?: string;
    value?: string | number | boolean;
    checked: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
}

const CheckboxRounded = ({ name, value, checked, disabled, readOnly, onChange }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);

        if (onChange) onChange(event);
    };

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    return (
        <>
            <input
                type={'checkbox'}
                name={name}
                value={value?.toString()}
                // defaultChecked={checked}
                checked={checked}
                onChange={handleChange}
                className={styles.input}
                disabled={disabled}
                readOnly={readOnly}
            />

            <span className={`${styles.checkbox} ${isChecked ? styles.checked : ''}`} />
        </>
    );
};

export default CheckboxRounded;
