import styles from '../../pages/Account/styles.module.css';
import { useEffect, useState } from 'react';
import { Preset } from '../Preset';
import { PresetInterface } from '../Preset/Preset';
import { GraphTypes } from '../../types/general';

const OverviewCategoryItems = ({
    type,
    presets,
    handleDeletePopup,
    setShowDownload,
}: {
    type: GraphTypes;
    presets: PresetInterface[];
    handleDeletePopup: any;
    setShowDownload: any;
}) => {
    const [items, setItems] = useState<PresetInterface[]>([]);

    useEffect(() => {
        setItems(presets.filter((item) => item.presetType === type));
    }, [presets]);

    return (
        <>
            <div className={styles.wrapper}>
                {items.length ? (
                    <>
                        {items.map((p: PresetInterface, i) => (
                            <Preset
                                key={i}
                                data={p}
                                handleDelete={handleDeletePopup}
                                setShowDownload={setShowDownload}
                            />
                        ))}
                    </>
                ) : (
                    <p className={styles.placehodler}>No structures stored yet.</p>
                )}
            </div>
        </>
    );
};

export default OverviewCategoryItems;
