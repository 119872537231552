import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormCollapsableRow from '../../../layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../../../components/Input/Input';
import SelectedValuesList from '../../../../../components/SelectedValuesList/SelectedValuesList';
import Select from '../../../../../components/Select/Select';
import { StorageType } from '../../../StorageForm/StorageForm';

const StorageOtherPartTwo = ({ selectedData, onClose, handleNextStep }: any) => {
    return (
        <FormWrapper>
            <FormLayout
                title={'Other items (2/2)'}
                description={'Other information about this structure needed for regulatory and compliance tracking.'}
                onClose={onClose}
                handlePrevStep={() => {
                    handleNextStep(StorageType.Other);
                }}
            >
                <Select
                    value={selectedData.laws?.map((item: any) => ({
                        label: item.laws,
                        value: item.laws,
                    }))}
                    options={selectedData.laws}
                    valueKey={'laws'}
                    labelKey={'laws'}
                    isMulti={true}
                    placeholder={'No laws selected'}
                    readOnly={true}
                    disabled={!selectedData.doubleTreaties?.length}
                    info={'List of laws and regulations relevant to this vehicle, its investors and assets.'}
                >
                    Relevant laws And regulations
                </Select>

                <br />

                <Input
                    value={selectedData.notes}
                    readOnly={true}
                    info={'Comments or notes relevant for this structure.'}
                >
                    Notes
                </Input>

                <Input
                    value={'No files stored'}
                    disabled={true}
                    info={'Stored files and documents relevant for this structure.'}
                >
                    Files
                </Input>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageOtherPartTwo;
