import create from 'zustand';

import axios from '../utils/axios';
import { useStorageStore } from './useStorageStore';
import {
    StorageNestedTypes,
    StorageStructure,
    StorageStructureGP,
    StorageStructureGPSelection,
    StorageStructureS4,
    StorageStructureSelection,
    StorageStructureShortSelection,
} from '../types/storage';
import { STORAGE_GENERAL_PARTNER_API, STORAGE_STRUCTURE_API } from '../utils/constants';

interface StorageStructureStore {
    children: StorageNestedTypes[];

    clearDeleted: () => void;

    structureOptions: StorageStructure;
    structureSelection: StorageStructureSelection;
    gpSelection: StorageStructureGPSelection;
    generalPartner: StorageStructureGP | undefined;
    getStructureOptions: () => Promise<void>;
    setStructureSelection: (data: StorageStructureSelection, options?: StorageStructure) => void;
    setGPSelection: (data: StorageStructureGPSelection) => void;
    setGeneralPartner: (data: StorageStructureGP) => void;
    investmentStructure: undefined | StorageStructureShortSelection;
    setInvestmentStructure: (data: StorageStructureShortSelection) => Promise<void>;
    getMD2Options: (currentStructureDomicile: number) => Promise<void>;
    getMD3Options: (currentStructureDomicile: number, currentStructure: number) => Promise<void>;
    getOptions: (currentStructure: number, currentFundLegalForm: number) => Promise<{ md4: StorageStructureS4[] }>;
    generalPartnerRequired: boolean;
    getMD7Options: (domicile: number) => Promise<void>;
    // setGeneralPartnerRequired: () => Promise<void>;
}

export const useStorageStructureStore = create<StorageStructureStore>((set, get) => ({
    children: [
        StorageNestedTypes.generalPartner,
        StorageNestedTypes.investors,
        StorageNestedTypes.assets,
        StorageNestedTypes.serviceProvider,
        StorageNestedTypes.assetManagement,
    ],
    structureOptions: {
        s1: [],
        md1: [],
        md2: [],
        md3: [],
        md4: [],
        md5: [],
        md6: [],
        md7: [],
    },
    structureSelection: {
        currentFundLegalForm: undefined,
        currentFundName: '',
        currentFundRegime: undefined,
        currentIfSelfManaged: false,
        currentOpenEnded: false,
        currentStructure: undefined,
        currentStructureDomicile: undefined,
        currentStructureLevel: undefined,
        currentGPName: '',
        currentFundLaunchDate: '',
    },
    gpSelection: {
        currentGPDomicile: undefined,
        currentGPLegalForm: undefined,
        currentGPName: '',
        currentGPShortName: '',
        currentGPLaunchDate: '',
    },

    investmentStructure: undefined,
    generalPartner: undefined,
    generalPartnerRequired: false,

    setInvestmentStructure: async (selection: StorageStructureShortSelection) => {
        console.log(
            "get().structureSelection.currentFundLaunchDate.replaceAll('.', '/')",
            get().structureSelection.currentFundLaunchDate,
            get().structureSelection.currentFundLaunchDate.replaceAll('.', '/'),
            Date.parse(get().structureSelection.currentFundLaunchDate.replaceAll('.', '/'))
        );

        // useStorageStore.getState().toggleDeleteItem({
        //     type: StorageNestedTypes.structure,
        //     selected: true,
        // });

        // await useStorageStore.getState().deleteItems();

        const currentStructureId = get().investmentStructure?.currentStructureId;

        let result;
        if (currentStructureId) {
            useStorageStore.getState().clearSelectedStateByParent(StorageNestedTypes.structure);

            result = await axios.put(`${STORAGE_STRUCTURE_API}/${currentStructureId}`, get().structureSelection);
        } else {
            result = await axios.post(`${STORAGE_STRUCTURE_API}`, get().structureSelection);
        }

        const { data } = result;

        set({
            investmentStructure: { ...selection, currentStructureId: data.id },
            generalPartnerRequired: data.legalType === 'partnership',
        });
    },

    getStructureOptions: async () => {
        const { data } = await axios.get(`${STORAGE_STRUCTURE_API}/base`);

        set({
            structureOptions: data,
        });

        return data;
    },
    getMD2Options: async (currentStructureDomicile: number) => {
        const { data } = await axios.get(`${STORAGE_STRUCTURE_API}/md2`, {
            params: {
                currentStructureDomicile: currentStructureDomicile,
            },
        });

        set({
            structureOptions: { ...get().structureOptions, md2: data },
        });
    },

    getMD3Options: async (currentStructureDomicile: number, currentStructure: number) => {
        const { data } = await axios.get(`${STORAGE_STRUCTURE_API}/md3`, {
            params: {
                currentStructure: currentStructure,
                currentStructureDomicile: currentStructureDomicile,
            },
        });

        set({
            structureOptions: { ...get().structureOptions, md3: data },
        });
    },

    getMD7Options: async (currentGPDomicile: number) => {
        const { data } = await axios.get(`${STORAGE_STRUCTURE_API}/md7`, {
            params: {
                currentGPDomicile: currentGPDomicile,
            },
        });

        set({
            structureOptions: { ...get().structureOptions, md7: data },
        });
    },

    getOptions: async (currentStructure: number, currentFundLegalForm: number) => {
        const { data } = await axios.get(`${STORAGE_STRUCTURE_API}/options`, {
            params: {
                currentStructure: currentStructure,
                currentFundLegalForm: currentFundLegalForm,
            },
        });

        // const gpData = await axios.get(`${STORAGE_STRUCTURE_API}/gp`, {
        //     params: {
        //         currentFundLegalForm: currentFundLegalForm,
        //     },
        // });

        set({
            // generalPartnerRequired: gpData.data.legalType === 'partnership',
            structureOptions: { ...get().structureOptions, ...data },
        });

        return { md4: data.md4 };
    },
    setStructureSelection: (structureSelection: StorageStructureSelection, structureOptions?: StorageStructure) => {
        if (structureOptions && Object.keys(structureOptions).length) {
            set({
                structureSelection,
                structureOptions: { ...get().structureOptions, ...structureOptions },
            });
        } else {
            set({
                structureSelection,
            });
        }
    },

    setGPSelection: (gpSelection: StorageStructureGPSelection) => {
        set({
            gpSelection,
        });
    },
    setGeneralPartner: async (generalPartner: StorageStructureGP) => {
        console.log('generalPartner', generalPartner);

        const { data } = await axios.post(`${STORAGE_GENERAL_PARTNER_API}`, {
            currentStructureId: get().investmentStructure!.currentStructureId,
            ...generalPartner,
        });

        set({
            generalPartner: { ...generalPartner, currentGPId: data.id },
        });
    },

    clearDeleted: () => {
        // if (useStorageStore.getState().itemsToDelete.generalPartner) {
        set({
            generalPartner: undefined,

            gpSelection: {
                currentGPDomicile: undefined,
                currentGPLegalForm: undefined,
                currentGPName: '',
                currentGPShortName: '',
                currentGPLaunchDate: '',
            },
        });
        // }
        //
        // if (useStorageStore.getState().itemsToDelete.structure) {
        //     set({
        //         investmentStructure: undefined,
        //         generalPartnerRequired: false,
        // structureSelection: {
        //     currentFundLegalForm: undefined,
        //     currentFundName: '',
        //     currentFundRegime: undefined,
        //     currentIfSelfManaged: false,
        //     currentOpenEnded: false,
        //     currentStructure: undefined,
        //     currentStructureDomicile: undefined,
        //     currentStructureLevel: undefined,
        //     currentGPName: '',
        //     currentFundLaunchDate: '',
        // },
        // });
        // }
    },
}));
