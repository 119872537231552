import create from 'zustand';
import axios from '../utils/axios';
import { useStorageAssetManagement } from './useStorageAssetManagement';
import { useStorageStructureStore } from './useStorageStructureStore';
import { useStorageInvestorsStore } from './useStorageInvestorsStore';
import { useStorageServiceProviderStore } from './useStorageServiceProviderStore';
import { useStorageAssetsStore } from './useStorageAssetsStore';
import {
    StorageAssetManagement,
    StorageNestedTypes,
    StorageServiceProvider,
    StorageServiceProviderSelection,
    StorageStates,
} from '../types/storage';
import { STORAGE_BASE_API } from '../utils/constants';
import { StorageType } from '../pages/Storage/StorageForm/StorageForm';

const getStorageByType = (type: StorageNestedTypes) => {
    switch (type) {
        case StorageNestedTypes.structure:
            return useStorageStructureStore;
        case StorageNestedTypes.generalPartner:
            return useStorageStructureStore;
        case StorageNestedTypes.investors:
            return useStorageInvestorsStore;
        case StorageNestedTypes.assets:
            return useStorageAssetsStore;
        case StorageNestedTypes.serviceProvider:
            return useStorageServiceProviderStore;
        case StorageNestedTypes.assetManagement:
            return useStorageAssetManagement;
    }
};

type TypesKeys = keyof typeof StorageNestedTypes;
type TypesKeyFields = {
    [key in TypesKeys]: undefined | number | number[] | StorageAssetManagement[] | StorageServiceProviderSelection[];
};

export interface StorageDeleteItems extends TypesKeyFields {
    structure: undefined | number;
    generalPartner: undefined | number;
    investors: number[];
    assets: number[];
    serviceProvider: StorageServiceProvider[];
    assetManagement: StorageAssetManagement[];
}

interface StorageStore {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;

    isSmallStructure: boolean;
    setIsSmallStructure: (state: boolean) => void;

    confirmDeletion: boolean;
    setConfirmDeletion: (state: boolean) => void;

    disabledState: boolean;
    setDisabledState: (state: boolean) => void;

    openType: StorageType | StorageNestedTypes | undefined;
    setOpenType: (type: StorageType | StorageNestedTypes | undefined) => void;

    openModal: any;
    setOpenModal: (modal: any) => void;
    handleCloseModal: () => void;

    progress: number;
    setProgress: (number: number) => void;

    storageState: StorageStates;
    setStorageState: (state: StorageStates) => void;

    isInTestView: boolean;
    isInDeleteView: boolean;

    itemsToDelete: StorageDeleteItems;
    toggleDeleteItem: (data: { type: StorageNestedTypes; id?: number; selected: boolean }) => void;

    deleteItems: () => Promise<void>;

    clearSelectedStateByParent: (type: StorageNestedTypes) => void;
}

export const useStorageStore = create<StorageStore>((set, get) => ({
    isSmallStructure: true,
    setIsSmallStructure: (state: boolean) => {
        set({
            isSmallStructure: state,
        });
    },

    isOpen: false,
    setIsOpen: (state: boolean) => {
        set({
            isOpen: state,
        });
    },

    confirmDeletion: true,
    setConfirmDeletion: (state: boolean) => {
        console.log('setConfirmDeletion', state);
        set({
            confirmDeletion: state,
        });
    },

    disabledState: false,
    setDisabledState: (state: boolean) => {
        set({
            disabledState: state,
        });
    },

    openType: undefined,
    setOpenType: (type: StorageType | StorageNestedTypes | undefined) => {
        console.log('openType', type);
        set({
            openType: type,
        });
    },

    openModal: undefined,
    setOpenModal: (modal: any) => {
        set({
            openModal: modal,
        });
    },
    handleCloseModal: () => {
        set({
            openModal: undefined,
        });
    },

    progress: 0,
    setProgress: (percent) => {
        set({
            progress: percent,
        });
    },

    storageState: StorageStates.View,
    setStorageState: (state: StorageStates) => {
        set({
            storageState: state,
            isInTestView: state === StorageStates.Test,
            isInDeleteView: state === StorageStates.Edit,
        });
    },
    isInTestView: false,
    isInDeleteView: false,

    itemsToDelete: {
        structure: undefined,
        generalPartner: undefined,
        investors: [],
        assets: [],
        serviceProvider: [],
        assetManagement: [],
    },
    toggleDeleteItem: ({ type, id, selected }: { type: StorageNestedTypes; id?: number; selected: boolean }) => {
        const tmp = get().itemsToDelete;

        const store = getStorageByType(type);

        for (const childType of [type, ...store.getState().children]) {
            if (childType === StorageNestedTypes.generalPartner || childType === StorageNestedTypes.structure) {
                if (childType === StorageNestedTypes.structure) {
                    tmp[StorageNestedTypes.structure] =
                        useStorageStructureStore.getState().structureSelection?.currentStructure;
                }

                if (childType === StorageNestedTypes.generalPartner) {
                    tmp[StorageNestedTypes.generalPartner] =
                        useStorageStructureStore.getState().generalPartner?.currentGPId;
                }
            } else {
                if (!id || type !== childType) {
                    if (childType === StorageNestedTypes.assetManagement) {
                        tmp[StorageNestedTypes.assetManagement] = useStorageAssetManagement
                            .getState()
                            .assetManagement.filter((item) => !!item.id);
                    } else if (childType === StorageNestedTypes.serviceProvider) {
                        console.log(
                            'useStorageServiceProviderStore\n' +
                                '                            .getState()\n' +
                                '                            .serviceProviders',
                            useStorageServiceProviderStore.getState().serviceProviders
                        );

                        tmp[StorageNestedTypes.serviceProvider] = useStorageServiceProviderStore
                            .getState()
                            .serviceProviders.filter((item) => !!item.id);
                    } else if (childType === StorageNestedTypes.investors) {
                        tmp[StorageNestedTypes.investors] = useStorageInvestorsStore
                            .getState()
                            .selectedInvestors.map((i) => i.id!);
                    } else if (childType === StorageNestedTypes.assets) {
                        tmp[StorageNestedTypes.assets] = useStorageAssetsStore
                            .getState()
                            .selectedAssets.map((i) => i.id!);
                    }
                } else {
                    let index;
                    let item;

                    if (
                        childType === StorageNestedTypes.assetManagement ||
                        childType === StorageNestedTypes.serviceProvider
                    ) {
                        index = tmp[childType].findIndex((i) => i.id === id);

                        if (childType === StorageNestedTypes.assetManagement) {
                            item = useStorageAssetManagement.getState().assetManagement.find((i) => i.id === id);
                        } else if (childType === StorageNestedTypes.serviceProvider) {
                            item = useStorageServiceProviderStore.getState().serviceProviders.find((i) => i.id === id);
                        }
                    } else if (childType === StorageNestedTypes.investors || childType === StorageNestedTypes.assets) {
                        index = tmp[childType].indexOf(id);
                        item = id;
                    }

                    if (index === -1 && selected) {
                        // @ts-ignore
                        tmp[childType].push(item);
                        // @ts-ignore
                    } else if (index > -1 && !selected) {
                        // @ts-ignore
                        tmp[childType].splice(index, 1);
                    }
                }
            }
        }

        // tmp[StorageNestedTypes.assetManagement] = useStorageAssetManagement.getState().assetManagement;
        // tmp[StorageNestedTypes.serviceProvider] = useStorageServiceProviderStore.getState().serviceProviders;
        //
        // tmp[StorageNestedTypes.investors] = useStorageInvestorsStore.getState().selectedInvestors.map((i) => i.id!);
        //
        // tmp[StorageNestedTypes.generalPartner] = useStorageStructureStore.getState().generalPartner?.currentGPId;
        //
        // tmp[StorageNestedTypes.structure] = useStorageStructureStore.getState().structureSelection?.currentStructure;
        //
        // tmp[StorageNestedTypes.assets] = useStorageAssetsStore.getState().selectedAssets.map((i) => i.id!);

        console.log('items to delete tmp', tmp);

        set({
            itemsToDelete: tmp,
        });
    },

    clearSelectedStateByParent: (type: StorageNestedTypes) => {
        console.log('clearSelectedStateByParent', type);
        if (type === StorageNestedTypes.assetManagement || type === StorageNestedTypes.serviceProvider) return;

        if (type === StorageNestedTypes.assets) return;

        getStorageByType(StorageNestedTypes.serviceProvider).getState().clearDeleted();
        getStorageByType(StorageNestedTypes.assetManagement).getState().clearDeleted();

        if (type === StorageNestedTypes.investors) return;

        getStorageByType(StorageNestedTypes.investors).getState().clearDeleted();
        getStorageByType(StorageNestedTypes.assets).getState().clearDeleted();

        if (type === StorageNestedTypes.generalPartner) return;

        getStorageByType(StorageNestedTypes.generalPartner).getState().clearDeleted();
    },

    deleteItems: async () => {
        // const params = new URLSearchParams();
        // const params = {}
        // for (const key of Object.keys(get().itemsToDelete)) {
        //     // @ts-ignore
        //     const item = get().itemsToDelete[key];
        //     console.log('item', item, item?.toString());
        //     if (item) {
        //         // @ts-ignore
        //         params[key] =  typeof item === 'object' ? JSON.stringify(item) : item
        //     }
        // }

        console.log(
            'useStorageAssetManagement.getState().assetManagement',
            useStorageAssetManagement.getState().assetManagement
        );

        try {
            await axios.delete(`${STORAGE_BASE_API}`, {
                data: get().itemsToDelete,
            });

            for (const type of Object.keys(get().itemsToDelete)) {
                getStorageByType(type as StorageNestedTypes)
                    .getState()
                    .clearDeleted();
            }
        } catch (e) {
            console.log('e', e);
        }

        set({
            itemsToDelete: {
                structure: undefined,
                generalPartner: undefined,
                investors: [],
                assets: [],
                serviceProvider: [],
                assetManagement: [],
            },
            openType: undefined,
        });
    },
}));
