import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { BooleanFormat, DescriptionFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD3Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd3 ? (
        <table>
            <TableHeader sdKey={'sd3'} />
            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd3'} colKey={'legalForm'} />
                <Tr featName={'Fund regime'} dataKey={'sd3'} colKey={'fundRegime'} />
                <Tr featName={'Redemptions'} dataKey={'sd3'} colKey={'redemptions'} formatFunc={BooleanFormat} />
                <TrProvider>
                    <Tr
                        featName={'Redemption details'}
                        dataKey={'sd3'}
                        colKey={'redemptionDetails'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>
                <Tr featName={'Dividends'} dataKey={'sd3'} colKey={'dividends'} formatFunc={BooleanFormat} />
                <TrProvider>
                    <Tr
                        featName={'Dividends details'}
                        dataKey={'sd3'}
                        colKey={'dividendsDetails'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>
                <Tr featName={'Min redemption frequency'} dataKey={'sd3'} colKey={'minRedemptionFrequency'} />
                <Tr featName={'Required valuation frequency'} dataKey={'sd3'} colKey={'requiredValuationFrequency'} />
            </tbody>
        </table>
    ) : null;
};

export default SD3Table;
