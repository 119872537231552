// @ts-nocheck
import styles from './formats-styles.module.css';

const ProgressBar = (d: any) => {
    return (
        <div className={styles.progress} dataBar={d}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default ProgressBar;
