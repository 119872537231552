import { useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Portal } from 'react-portal';
import Select from '../../../../../../components/Select/Select';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from '../../../../../../components/Icon/Icon';

const DistributionCountriesCreateForm = ({ selectedData, countries, onSave, onClose }: any) => {
    const [items, setItems] = useState<any[]>(selectedData);

    const handleSelectChange = (index: number, prop: string) => (value: any) => {
        let tmp = [...items];
        tmp[index] = { ...tmp[index], [prop]: value?.target ? value?.target.checked : value };
        setItems(tmp);

        console.log('tmp', tmp);
    };

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                value: uuidv4(),
                domicile: {},
                investors: false,
            },
        ]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...items];
        tmp.splice(index, 1);
        setItems(tmp);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Distribution countries'}
                description={'Countries where the fund can be offered / marketed.'}
                isAbsolute={true}
                footerMain={<Button onClick={onSave('distributionCountries', items)}>Upload</Button>}
                footerAdditional={
                    <Button
                        secondary={true}
                        outlined={true}
                        type={'button'}
                        before={<Icon icon={Icons.add} />}
                        onClick={handleAddItem}
                        minWidth={true}
                    >
                        Add Line
                    </Button>
                }
                onClose={onClose}
            >
                {items.map((item: any, index: number) => (
                    <div
                        key={index}
                        style={{
                            marginBottom: '40px',
                            gridGap: '48px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <button
                            style={{
                                minWidth: 32,
                                marginTop: -10,
                                marginLeft: -20,
                                position: 'absolute',
                            }}
                            type={'button'}
                            onClick={handleRemoveItem(index)}
                        >
                            <Icon icon={Icons.closeRounded} />
                        </button>

                        <Select
                            value={item.domicile.value}
                            onChange={handleSelectChange(index, 'domicile')}
                            options={countries}
                            valueKey={'domicileId'}
                            labelKey={'domicileName'}
                            extended={true}
                        >
                            Domicile
                        </Select>

                        <Input
                            type={'checkbox'}
                            name={'investors'}
                            checked={item.investors}
                            onChange={handleSelectChange(index, 'investors')}
                        >
                            Including retail investors
                        </Input>
                    </div>
                ))}
            </FormLayout>
        </Portal>
    );
};

export default DistributionCountriesCreateForm;
