import styles from './result-table-styles.module.css';

interface ResultTableMiddleProps {
    children: any;
}

const ResultTableMiddle = ({ children }: ResultTableMiddleProps) => {
    return (
        <>
            {children}

            <div className={styles.actions}></div>
        </>
    );
};

export default ResultTableMiddle;
