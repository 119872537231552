import LoaderVariantRoller from './components/LoaderRoller/LoaderVariantRoller';
import LoaderVariantGrid from './components/LoaderGrid/LoaderVariantGrid';
import LoaderVariantSpinner from './components/LoaderSpinner/LoaderVariantSpinner';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import TransitionComponent, { TransitionComponentProps } from '../TransitionComponent/TransitionComponents';

const Loader = ({ inProp, isAbsolute, top }: TransitionComponentProps) => {
    const loaders = [<LoaderVariantRoller />, <LoaderVariantGrid />, <LoaderVariantSpinner />];

    const [random, setRandom] = useState<undefined | number>();

    useEffect(() => {
        setRandom(Math.floor(Math.random() * loaders.length));
    }, []);

    return (
        <TransitionComponent inProp={inProp} isAbsolute={isAbsolute}>
            <div
                className={styles.loader}
                style={{
                    top: top || 0,
                }}
            >
                <>{random !== undefined && loaders[random || 0]}</>
            </div>
        </TransitionComponent>
    );
};

export default Loader;
