import React, { useContext, useEffect, useState } from 'react';

import styles from './comparison-table-styles.module.css';
import { CSSTransition } from 'react-transition-group';
import { TableDescriptionContext } from './context';
import ComparisonTableSectionScrolling from './ComparisonTableSectionScrolling';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface ComparisonTableSectionProps {
    title: string;
    children?: React.ReactNode;
    row?: boolean;
    triggerCollapse: boolean;
    tablesCollapsed: number;
    setTablesCollapsed: (value: number) => void;
    collapseOnClick: boolean;
    setInitCollapsed?: (value: number) => void;
    initCollapsed?: number;
    excludeFeat?: boolean;
    isExpanded?: boolean;
}

const ComparisonTableSection = ({
    title,
    children,
    row,
    triggerCollapse,
    tablesCollapsed,
    setTablesCollapsed,
    collapseOnClick,
    setInitCollapsed,
    initCollapsed,
    excludeFeat,
    isExpanded,
}: ComparisonTableSectionProps) => {
    const [collapsed, setCollapsed] = useState(!isExpanded);
    const [initExpanded, setInitExpanded] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);

    const {
        expandedRows,
        setExpandedRows,
        globalExpanded,
        setGlobalExpanded,
        totalExpandableRows,
        setTotalExpandableRows,
    } = useContext(TableDescriptionContext);

    useEffect(() => {
        if (totalExpandableRows > 0 && !isExpandable) setIsExpandable(true);
    }, [totalExpandableRows]);

    useEffect(() => {
        if (expandedRows <= 0) {
            setInitExpanded(false);
            setGlobalExpanded(false);
        } else if (expandedRows > 0) {
            setInitExpanded(true);

            if (totalExpandableRows === expandedRows) {
                setGlobalExpanded(true);
            }
        }
    }, [expandedRows]);

    useEffect(() => {
        if (totalExpandableRows > 0 && !isExpandable) setIsExpandable(true);
    }, [totalExpandableRows]);

    useEffect(() => {
        setTotalExpandableRows(0);
    }, [collapsed]);

    useEffect(() => {
        if (!collapseOnClick) return;
        if (triggerCollapse) {
            setCollapsed(true);
        }
    }, [triggerCollapse, collapseOnClick]);

    const toggleCollapse = () => {
        if (initCollapsed) {
            if (collapsed) {
                setTablesCollapsed(tablesCollapsed + 1);
                if (setInitCollapsed) {
                    setInitCollapsed(initCollapsed + 1);
                }
            } else {
                setTablesCollapsed(tablesCollapsed - 1);
                if (setInitCollapsed) {
                    setInitCollapsed(initCollapsed - 1);
                }
            }
        }

        setCollapsed(!collapsed);
    };

    const toggleInitExpanded = () => {
        setGlobalExpanded(!initExpanded);
        setInitExpanded(!initExpanded);

        if (globalExpanded) {
            setExpandedRows(0);
        } else {
            setExpandedRows(totalExpandableRows);
        }
    };

    return (
        <PerfectScrollbar className={`${styles.sectionWrapper}`}>
            <h3 className={styles.title}>
                {title}

                <div className={styles.titleBtns}>
                    <CSSTransition
                        in={!collapsed && isExpandable}
                        timeout={300}
                        classNames={{
                            appear: styles.fadeAnimation,
                            appearActive: styles.fadeAnimationActive,
                            appearDone: styles.fadeAnimationActive,
                            enter: styles.fadeAnimation,
                            enterActive: styles.fadeAnimationActive,
                            enterDone: styles.fadeAnimationActive,
                            exit: styles.fadeAnimation,
                            exitActive: styles.fadeAnimation,
                            exitDone: styles.fadeAnimation,
                        }}
                        unmountOnExit
                    >
                        <button
                            className={`${styles.expand} ${globalExpanded ? styles.expanded : ''}`}
                            onClick={toggleInitExpanded}
                        >
                            {!globalExpanded ? 'Expand all' : 'Collapse all'}
                        </button>
                    </CSSTransition>

                    <button
                        className={`${styles.collapse} ${collapsed ? styles.collapsed : ''}`}
                        onClick={toggleCollapse}
                    />
                </div>
            </h3>

            <CSSTransition
                in={!collapsed}
                timeout={300}
                classNames={{
                    appear: styles.tableAnimation,
                    appearActive: styles.tableAnimationActive,
                    appearDone: styles.tableAnimationActive,
                    enter: styles.tableAnimation,
                    enterActive: styles.tableAnimationActive,
                    enterDone: styles.tableAnimationActive,
                    exit: styles.tableAnimation,
                    exitActive: styles.tableAnimation,
                    exitDone: styles.tableAnimation,
                }}
                unmountOnExit
            >
                <div>
                    <div className={`${styles.section}`}>
                        <ComparisonTableSectionScrolling
                            row={row}
                            collapsed={collapsed}
                            excludeFeat={excludeFeat ?? false}
                        >
                            {children}
                        </ComparisonTableSectionScrolling>
                    </div>
                </div>
            </CSSTransition>
        </PerfectScrollbar>
    );
};

export default ComparisonTableSection;
