// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-lg-min"] + " {\n    .styles_selectRow__YRyKt {\n        display: none;\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + " {\n    .styles_checkboxRow__XxFIo {\n        display: none;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/SelectionPopup/SelectionPopupRowMulti/styles.module.css"],"names":[],"mappings":"AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@value screen-lg-min, screen-md-max from '../../../breakpoints.css';\n\n@media screen-lg-min {\n    .selectRow {\n        display: none;\n    }\n}\n\n@media screen-md-max {\n    .checkboxRow {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen-lg-min": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-lg-min"] + "",
	"screen-md-max": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + "",
	"selectRow": "styles_selectRow__YRyKt",
	"checkboxRow": "styles_checkboxRow__XxFIo"
};
export default ___CSS_LOADER_EXPORT___;
