import styles from './styles.module.css';
import axios from '../../utils/axios';

import React, { useState } from 'react';
import PresetLink from './components/PresetLink';
import { PresetActions } from './index';
import { GraphTypes } from '../../types/general';

export interface PresetInterface {
    id: number;
    presetType: GraphTypes;
    data: any;
    createdAt: string;
}

interface PresetProps {
    data: PresetInterface;
    handleDelete: (data: PresetInterface) => React.MouseEventHandler<HTMLButtonElement>;
    setShowDownload: (value: boolean) => void;
}

const Preset = ({ data, handleDelete, setShowDownload }: PresetProps) => {
    const [loading, setLoading] = useState(false);

    const createPDF = (type: string) => (_: React.ChangeEvent<HTMLInputElement>) => {
        if (loading) return;
        setLoading(true);
        setShowDownload(true);

        axios
            .get(`/pdf/${data.id}`, {
                responseType: 'blob',
                // onDownloadProgress: (progressEvent) => {
                //     let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //     (percentCompleted);
                // },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                const createdAt = new Date(data.createdAt).toDateString();
                const fileName = `WT-${createdAt}.pdf`;
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => {
                setLoading(false);
                setShowDownload(false);
            });
    };

    const createExcel = () => {
        if (loading) return;
        setLoading(true);
        setShowDownload(true);

        // const url =
        //     data.url +
        //     `&investorName=${data.investorName}&assetName=${data.assetName}&fundName=${data.fundName}&fofName=${data.fofName}&createdAt=${data.createdAt}`;

        axios
            .get(`/excel/${data.id}`, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const createdAt = new Date(data.createdAt).toDateString();
                const fileName = `WT-${createdAt}.xlsx`;
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => {
                setLoading(false);
                setShowDownload(false);
            });
    };

    const deleteItem = () => {
        handleDelete(data);
    };

    return (
        <div className={styles.col}>
            {data.presetType === GraphTypes.Taxes ? (
                <>
                    <PresetActions
                        handleDelete={deleteItem}
                        createExcel={createExcel}
                        createPDF={createPDF('deal-taxes')}
                    />
                    <PresetLink
                        data={data}
                        img={'./images/thumb-taxes.png'}
                        name={'Deal taxes'}
                        link={'deal-taxes'}
                        // isOptimisation={data.url.includes('optimisation=true')}
                    />
                </>
            ) : data.presetType === GraphTypes.Legal ? (
                <>
                    <PresetActions handleDelete={deleteItem} createPDF={createPDF('legal-structures')} />
                    <PresetLink
                        data={data}
                        img={'./images/thumb-structure.png'}
                        name={'Legal structures'}
                        link={'legal-structures'}
                    />
                </>
            ) : (
                <>
                    <PresetActions handleDelete={deleteItem} createPDF={createPDF('tax-optimisation')} />
                    <PresetLink
                        data={data}
                        img={'./images/optimisation.png'}
                        name={'Tax optimisation'}
                        link={'tax-optimisation'}
                    />
                </>
            )}
        </div>
    );
};

export default Preset;
