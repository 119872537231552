import { useEffect, useState } from 'react';
import { StorageType } from '../../StorageForm';
import { Button, Icon } from '../../../../../components';
import InvestorSingle from './components/InvestorSingle';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import { Icons } from '../../../../../components/Icon/Icon';

const StorageItems = ({ selectedData, generalInfo, handleNextStep, handleClose }: any) => {
    const [optionsByCountry, setOptionsByCountry] = useState<any>({});

    const [data, setData] = useState<any[]>(selectedData ? [...selectedData] : [{}]);
    const [selectionsValid, setSelectionsValid] = useState<any>(false);

    useEffect(() => {
        setSelectionsValid(!data.some((item) => !(item.domicile?.value && item.investors?.length)));
    }, [data]);

    const handleAddItem = () => {
        setData([...data, {}]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...data];
        tmp.splice(index, 1);
        setData(tmp);
    };

    const handleItemUpdate = (index: number) => (item: any) => {
        const tmp = [...data];
        tmp[index] = item;
        setData(tmp);
    };

    const handleSetOptionsByCountry = (id: number, items: any[]) => {
        setOptionsByCountry({
            ...optionsByCountry,
            [id]: [...(optionsByCountry[id] || []), ...items],
        });
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Investor'}
                description={'Please fill in information about investor(s).'}
                footerMain={<Button form={'mainForm'}>Next</Button>}
                footerAdditional={
                    <Button
                        secondary={true}
                        outlined={true}
                        type={'button'}
                        before={<Icon icon={Icons.add} />}
                        onClick={handleAddItem}
                        fullWidth={true}
                        disabled={!selectionsValid}
                    >
                        Add line
                    </Button>
                }
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.GeneralPartner)}
            >
                <form id="mainForm" onSubmit={handleNextStep(data, StorageType.Assets)}>
                    {data.map((item: any, index: number) => (
                        <InvestorSingle
                            selectedData={item}
                            key={index}
                            countries={generalInfo.countries}
                            handleRemoveItem={handleRemoveItem(index)}
                            onUpdate={handleItemUpdate(index)}
                            optionsByCountry={optionsByCountry}
                            setOptionsByCountry={handleSetOptionsByCountry}
                        />
                    ))}
                </form>
            </FormLayout>
        </FormWrapper>
    );
};
export default StorageItems;
