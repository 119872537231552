import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

// Response interceptor for API calls
// instance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async function (err) {
//         if (err.response.status === 401) {
//             window.location.href = '/login';
//             return Promise.reject(err);
//         }
//
//         return Promise.reject(err);
//     }
// );

export default instance;
