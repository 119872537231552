import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserContext } from '../context';

const PrivateRouteWithLayout = () => {
    const { user, isLoading } = useContext(UserContext);
    const location = useLocation();

    if (isLoading) {
        // return <Outlet />;
        return null;
    } else {
        if (user) {
            const path = location.pathname;
            if (user.agreement || path === '/disclaimer') {
                return <Outlet />;
            } else {
                return <Navigate to={'/disclaimer'} />;
            }
        }

        return <Navigate to={'/login'} />;
    }
};

export default PrivateRouteWithLayout;
