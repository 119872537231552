import { useEffect, useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Portal } from 'react-portal';
import { Icons } from '../../../../../../components/Icon/Icon';
import { v4 as uuidv4 } from 'uuid';

const InvestorCreateForm = ({ onSave, onClose }: any) => {
    const [investors, setInvestors] = useState<any[]>([
        {
            value: uuidv4(),
            label: '',
        },
    ]);

    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    useEffect(() => {
        setIsFormValid(!investors.find((i) => !i.label));
    }, [investors]);

    const handleInputChange = (index: number) => (event: any) => {
        let tmp = [...investors];
        tmp[index] = { ...tmp[index], label: event.target.value };
        setInvestors(tmp);
    };

    const handleAddInvestor = () => {
        setInvestors([
            ...investors,
            {
                value: uuidv4(),
                label: '',
            },
        ]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...investors];
        console.log('tmp', tmp);
        console.log('index', index);

        tmp.splice(index, 1);

        console.log('tmp', tmp);
        setInvestors(tmp);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Investor type'}
                description={'Please provide investor type(s).'}
                isAbsolute={true}
                footerMain={
                    <Button form={'subForm'} disabled={!isFormValid}>
                        Upload
                    </Button>
                }
                footerAdditional={
                    <Button
                        secondary={true}
                        outlined={true}
                        type={'button'}
                        before={<Icon icon={Icons.add} />}
                        onClick={handleAddInvestor}
                        minWidth={true}
                        disabled={!isFormValid}
                    >
                        Add investor
                    </Button>
                }
                onClose={onClose}
            >
                <form id="subForm" onSubmit={onSave(investors)}>
                    {investors.map((investor: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                position: 'relative',
                            }}
                        >
                            {investors.length > 1 && (
                                <button
                                    style={{
                                        width: 20,
                                        height: 20,
                                        marginTop: -10,
                                        marginLeft: 10,
                                        right: -10,
                                        position: 'absolute',
                                        zIndex: 1,
                                        background: '#fff',
                                        borderRadius: '50%',
                                        padding: 0,
                                        border: 0,
                                    }}
                                    type={'button'}
                                    onClick={handleRemoveItem(index)}
                                >
                                    <Icon icon={Icons.closeRounded} />
                                </button>
                            )}

                            <Input
                                required
                                style={'text'}
                                max={50}
                                value={investor.label}
                                onChange={handleInputChange(index)}
                            />
                        </div>
                    ))}
                </form>
            </FormLayout>
        </Portal>
    );
};

export default InvestorCreateForm;
