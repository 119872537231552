import StorageFormSummaryItem from './components/StorageFormSummaryItem';
import styles from './styles.module.css';
import { StorageType } from '../../StorageForm';

const StorageFormSummary = ({ selectedData, setActiveStorageType }: any) => {
    return (
        <div className={styles.container}>
            <StorageFormSummaryItem
                index={'01'}
                title={'Investing vehicle'}
                onClick={() => {
                    setActiveStorageType(StorageType.Structure);
                }}
                selected={!!selectedData[StorageType.Structure]?.fundName}
            />
            <StorageFormSummaryItem
                index={'02'}
                title={'Investors'}
                onClick={() => {
                    setActiveStorageType(StorageType.Investors);
                }}
                selected={!!selectedData[StorageType.Investors]?.length}
            />
            <StorageFormSummaryItem
                index={'03'}
                title={'Assets'}
                onClick={() => {
                    setActiveStorageType(StorageType.Assets);
                }}
                selected={!!selectedData[StorageType.Assets]?.length}
            />
            <StorageFormSummaryItem
                index={'04'}
                title={'Asset manager'}
                onClick={() => {
                    setActiveStorageType(StorageType.AssetManager);
                }}
                selected={!!selectedData[StorageType.AssetManager]?.length}
            />
            <StorageFormSummaryItem
                index={'05'}
                title={'Service provides'}
                onClick={() => {
                    setActiveStorageType(StorageType.ServiceProviders);
                }}
                selected={!!selectedData[StorageType.ServiceProviders]?.length}
            />
            <StorageFormSummaryItem
                index={'06'}
                title={'Other parameters '}
                onClick={() => {
                    setActiveStorageType(StorageType.Other);
                }}
                selected={!!selectedData[StorageType.Other]?.laws}
            />
        </div>
    );
};

export default StorageFormSummary;
