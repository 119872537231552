const InvestorIcon = () => {
    return (
        <svg
            id="Component_79_13"
            data-name="Component 79 – 13"
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
        >
            <g
                id="Ellipse_24"
                data-name="Ellipse 24"
                fill="var(--icon-fill-color, none)"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                <circle cx="18.5" cy="18.5" r="17.75" fill="var(--icon-fill-color)" />
            </g>
            <g id="Group_306" data-name="Group 306" transform="translate(9 9)">
                <rect id="Area" width="20" height="20" fill="none" opacity="0" />
                <g id="Icon" transform="translate(3.333 2.5)">
                    <path
                        id="Path"
                        d="M16.667,17.5V15.833A3.334,3.334,0,0,0,13.333,12.5H6.667a3.334,3.334,0,0,0-3.334,3.333V17.5"
                        transform="translate(-3.333 -2.5)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0.5"
                    />
                    <circle
                        id="Path-2"
                        data-name="Path"
                        cx="3.333"
                        cy="3.333"
                        r="3.333"
                        transform="translate(3.334)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default InvestorIcon;
