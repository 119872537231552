import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormCollapsableRow from '../../../layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../../../components/Input/Input';
import SelectedValuesList from '../../../../../components/SelectedValuesList/SelectedValuesList';
import { useEffect, useState } from 'react';

const StorageViewAssets = ({ selectedData, onClose }: any) => {
    const [groupedData, setGroupedData] = useState([]);

    useEffect(() => {
        setGroupedData(
            selectedData.reduce((array: any, item: any) => {
                const index = array.findIndex((arrayItem: any) => arrayItem.domicileName === item.domicileName);
                if (index > -1) {
                    array[index].values = [
                        ...(array[index].values || []),
                        `${item.assetClass} / ${item.participationForm}`,
                    ];
                } else {
                    array.push({
                        domicileName: item.domicileName,
                        values: [`${item.assetClass} / ${item.participationForm}`],
                    });
                }

                return array;
            }, [])
        );
    }, [selectedData]);

    return (
        <FormWrapper>
            <FormLayout title={'Asset'} description={'Here are the assets.'} onClose={onClose}>
                {groupedData.map((item: any, index: number) => (
                    <FormCollapsableRow key={index} titleLabel={`${index + 1}. asset`} titleValue={item.domicileName}>
                        <Input disabled value={item.domicileName}>
                            Domicile
                        </Input>

                        <SelectedValuesList values={item.values}>Asset type</SelectedValuesList>
                    </FormCollapsableRow>
                ))}
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageViewAssets;
