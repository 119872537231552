import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';
import React, { useEffect, useState } from 'react';
import TransitionComponent from '../../components/TransitionComponent/TransitionComponents';
import OverviewNavigation from '../../components/Overview/OverviewNavigation/OverviewNavigation';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import { GraphTypes } from '../../types/general';

const AccountLayout = ({ children }: any) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedType, setSelectedType] = useState<any>(
        searchParams.get('t') || (location.pathname.indexOf('compliance') > -1 ? 'compliance' : '')
    );

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (searchParams.has('save')) {
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 2500);
            // setSearchParams('');
        }

        setSelectedType(searchParams.get('t') || (location.pathname.indexOf('compliance') > -1 ? 'compliance' : ''));
    }, [searchParams]);

    useEffect(() => {
        setSelectedType(searchParams.get('t') || (location.pathname.indexOf('compliance') > -1 ? 'compliance' : ''));
    }, [location]);

    const handleCategoryChange = (type: GraphTypes) => (e: any) => {
        setSelectedType(type);
        if (type === GraphTypes.Storage) {
            navigate(`/storage`);
        } else if (type === GraphTypes.Compliance) {
            navigate(`/dashboard/${type}`);
        } else if (!!type) {
            navigate(`/dashboard?t=${type}`);
        } else {
            navigate(`/dashboard`);
        }
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    {/*<TransitionComponent inProp={!selectedType} isAbsolute={!!selectedType}>*/}
                    {/*    <div className={styles.categoriesWrapper}>*/}
                    {/*        <div className={styles.categories}>*/}
                    {/*            <OverviewCategories onClick={handleCategoryChange} presets={presets} />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</TransitionComponent>*/}

                    <TransitionComponent inProp={true}>
                        <div className={selectedType ? styles.grid : styles.categoriesWrapper}>
                            <div>
                                <Outlet />
                            </div>

                            {!!selectedType && (
                                <div>
                                    <OverviewNavigation
                                        menuItems={[
                                            { label: 'Deal taxes', type: GraphTypes.Taxes },
                                            { label: 'Legal structures', type: GraphTypes.Legal },
                                            { label: 'Tax optimisation', type: GraphTypes.Optimisation },
                                            { label: 'Stored structures', type: GraphTypes.Storage },
                                            { label: 'Compliance', type: GraphTypes.Compliance },
                                            { label: 'Library', type: GraphTypes.Library },
                                            { label: 'Multi-layered', type: GraphTypes.MultiLayered },
                                            { label: 'Evergreen', type: GraphTypes.Evergreen },
                                            { label: 'AI: nodes & RAG & status', type: GraphTypes.AI },
                                        ]}
                                        selectedType={selectedType!}
                                        onClick={handleCategoryChange}
                                    />
                                </div>
                            )}
                        </div>
                    </TransitionComponent>
                </div>
            </div>
        </>
    );
};

export default AccountLayout;
