// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".close-button_close__2PjcD {\n    background: transparent;\n    border: 0;\n    position: absolute;\n    z-index: 4;\n    top: 14px;\n    right: 14px;\n    padding: 6px;\n    display: flex;\n    align-items: center;\n    height: 30px !important;\n    width: 30px;\n}\n\n.close-button_close__2PjcD:hover {\n    background: transparent !important;\n}\n\n.close-button_close__2PjcD span {\n    display: inline-flex;\n    width: 100%;\n    height: 100%;\n}\n.close-button_close__2PjcD span svg {\n    width: 100%;\n    height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CloseButton/close-button.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".close {\n    background: transparent;\n    border: 0;\n    position: absolute;\n    z-index: 4;\n    top: 14px;\n    right: 14px;\n    padding: 6px;\n    display: flex;\n    align-items: center;\n    height: 30px !important;\n    width: 30px;\n}\n\n.close:hover {\n    background: transparent !important;\n}\n\n.close span {\n    display: inline-flex;\n    width: 100%;\n    height: 100%;\n}\n.close span svg {\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "close-button_close__2PjcD"
};
export default ___CSS_LOADER_EXPORT___;
