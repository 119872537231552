import { SankeyNode } from '../../../types/deal-taxes';

interface NodeProps {
    node: SankeyNode;
}

const Node = ({ node }: NodeProps) => {
    return (
        <g transform={`translate(${node.x}, ${node.y})`} className={node.className}>
            <rect x={0} y={0} width={node.width} height={node.height} fill="#1040b5" />
            <text
                x={node.width / 2}
                y={14}
                dx={node.width < 80 ? '2.5em' : '0'}
                fontSize={15}
                textAnchor="middle"
                fontWeight={600}
                fill={node.width < 50 ? '#1040b5' : '#ffffff'}
            >
                {`$${node.value}`}
            </text>
        </g>
    );
};

export default Node;
