import styles from './Input.module.css';

const InputLabel = ({
    label,
    children,
    fullWidth = true,
    align,
}: {
    label: any;
    children?: any;
    fullWidth?: boolean;
    align?: 'top';
}) => {
    return (
        <label className={`${styles.input} ${fullWidth ? styles.full : ''} ${align ? styles[`align-${align}`] : ''}`}>
            {!!label && <span className={styles.label}>{label}</span>}

            {children}
        </label>
    );
};

export default InputLabel;
