import styles from './styles-grid.module.css';

const LoaderVariantGrid = () => {
    return (
        <div className={styles.ldsGrid}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default LoaderVariantGrid;
