import styles from './Input.module.css';

const Input = ({ children, required, ...props }: any) => {
    return (
        <label className={`${styles.input} ${!!children ? styles.offset : ''}`}>
            {!!children && <span className={styles.label}>{children}</span>}

            <input required={required} {...props} />

            {required && <span className={styles.note}>*required</span>}
        </label>
    );
};

export default Input;
