import styles from '../../../../layout/FormLayout/FormLayout.module.css';
import { useEffect, useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import Select from '../../../../../../components/Select/Select';
import { Portal } from 'react-portal';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from '../../../../../../components/Icon/Icon';

const AssetCreateForm = ({ onSave, onClose, generalInfo }: any) => {
    const [items, setItems] = useState<any[]>([{}]);

    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    useEffect(() => {
        setIsFormValid(!items.find((i) => !i.label || !i.participationFormId));
    }, [items]);

    const handleInputChange = (index: number) => (event: any) => {
        let tmp = [...items];
        tmp[index] = { ...tmp[index], label: event.target.value };
        setItems(tmp);
    };

    const handleSelectChange = (index: number) => (selectedData: any) => {
        let tmp = [...items];
        tmp[index] = {
            ...tmp[index],
            value: selectedData,
            participationFormId: selectedData.value,
            assetParticipation: `${tmp[index].label} // ${selectedData.label}`,
            participationForm: selectedData.label,
        };
        setItems(tmp);
    };

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                value: uuidv4(),
                label: '',
            },
        ]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...items];
        tmp.splice(index, 1);
        setItems(tmp);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Asset type'}
                description={'Please provide asset type(s).'}
                isAbsolute={true}
                footerAdditional={
                    <Button
                        secondary={true}
                        outlined={true}
                        type={'button'}
                        before={<Icon icon={Icons.add} />}
                        onClick={handleAddItem}
                        minWidth={true}
                        disabled={!isFormValid}
                    >
                        Add item
                    </Button>
                }
                footerMain={
                    <Button form={'subForm'} disabled={!isFormValid}>
                        Upload
                    </Button>
                }
                onClose={onClose}
            >
                <form id={'subForm'} onSubmit={onSave(items)}>
                    {items.map((item: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                position: 'relative',
                            }}
                            className={styles.formRow}
                        >
                            {items.length > 1 && (
                                <button
                                    style={{
                                        width: 20,
                                        height: 20,
                                        marginTop: -10,
                                        marginLeft: 10,
                                        right: -10,
                                        position: 'absolute',
                                        zIndex: 1,
                                        background: '#fff',
                                        borderRadius: '50%',
                                        padding: 0,
                                        border: 0,
                                    }}
                                    type={'button'}
                                    onClick={handleRemoveItem(index)}
                                >
                                    <Icon icon={Icons.closeRounded} />
                                </button>
                            )}

                            <Input
                                value={item.label}
                                required
                                onChange={handleInputChange(index)}
                                style={'text'}
                                max={50}
                            ></Input>
                            <Select
                                value={item.value}
                                onChange={handleSelectChange(index)}
                                options={generalInfo.participationForms}
                                valueKey={'participationFormId'}
                                labelKey={'participationForm'}
                                style={'secondary'}
                            >
                                Allocation type
                            </Select>
                        </div>
                    ))}
                </form>
            </FormLayout>
        </Portal>
    );
};

export default AssetCreateForm;
