const OtherIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
            <g id="Group_7397" data-name="Group 7397" transform="translate(-1111 -2894)">
                <g
                    id="Ellipse_26"
                    data-name="Ellipse 26"
                    transform="translate(1111 2894)"
                    fill="var(--icon-fill-color, none)"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                >
                    <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                    <circle cx="18.5" cy="18.5" r="18" fill="none" />
                </g>
                <circle
                    id="Ellipse_31"
                    data-name="Ellipse 31"
                    cx="6.5"
                    cy="6.5"
                    r="6.5"
                    transform="translate(1144.465 2908.305) rotate(90)"
                    fill="transparent"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                />
                <circle
                    id="Ellipse_32"
                    data-name="Ellipse 32"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(1136 2897.59) rotate(90)"
                    fill="transparent"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                />
                <circle
                    id="Ellipse_33"
                    data-name="Ellipse 33"
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    transform="translate(1124.336 2905.59) rotate(90)"
                    fill="transparent"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                />
                <circle
                    id="Ellipse_53"
                    data-name="Ellipse 53"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(1129.336 2914.305) rotate(90)"
                    fill="transparent"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                />
            </g>
        </svg>
    );
};

export default OtherIcon;
