import styles from './result-table-styles.module.css';
import React from 'react';
import { useDiagramStore } from '../../store/useDiagramStore';

interface ResultTableProps {
    children: React.ReactNode;
}

const ResultTable = ({ children }: ResultTableProps) => {
    const [graphType] = useDiagramStore((state) => [state.graphType]);

    return <div className={`${styles.table} ${graphType ? styles[graphType] : ''}`}>{children}</div>;
};

export default ResultTable;
