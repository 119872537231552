const TypeOfSecurityFormat = (d: any, { securityType }: any) => {
    const trimSecurityStr = (d: any, type: string) => {
        const types = ['debt:', 'equity:', 'hybrid:'];
        const start = d.indexOf(type);
        let end = 0;
        for (const t of types) {
            if (d.indexOf(t) > -1 && t !== type) {
                end = d.indexOf(t);
            }
        }
        end = start > end ? d.length : end;

        if (start >= end) return d;

        return d.slice(start, end);
    };

    if (!d) return '-';
    d = d.toString().trim();

    securityType += ':';
    d = trimSecurityStr(d, securityType);
    d = trimSecurityStr(d, securityType);

    return d.slice(securityType.length, d.length);
};

export default TypeOfSecurityFormat;
