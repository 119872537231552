import { useState } from 'react';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import { StorageType } from '../../StorageForm';
import Select from '../../../../../components/Select/Select';
import { Button } from '../../../../../components';
import LawsCreateForm from './components/LawsCreateForm';
import Input from '../../../../../components/Input/Input';
import NotesCreateForm from './components/NotesCreateForm';

enum Forms {
    Law = 'Law',
    Notes = 'Notes',
}
const StorageOtherPropertiesPartTwo = ({
    handleNextStep,
    handleClose,
    selectedData,
    generalInfo,
    setGeneralInfo,
    handleSubmit,
}: any) => {
    const [activeForm, setActiveForm] = useState<Forms | null>(null);

    const [data, setData] = useState<any>({
        laws: undefined,
        notes: '',
        ...selectedData,
    });

    const handleSelectChange = (prop: string) => (value: any) => {
        setData({
            ...data,
            [prop]: value,
        });
    };

    const handleCreateItems = (prop: keyof typeof generalInfo, newItems: []) => () => {
        setGeneralInfo({
            ...generalInfo,
            [prop]: [...newItems, ...generalInfo[prop]],
        });

        setData({
            ...data,
            [prop]: [...newItems, ...data[prop]],
        });

        console.log('data', {
            ...data,
            [prop]: [...newItems, ...data[prop]],
        });

        setActiveForm(null);
    };

    const handleSelectData = (prop: string, value: any) => () => {
        setData({
            ...data,
            [prop]: value,
        });

        setActiveForm(null);
    };

    const handleCloseSubForm = () => {
        setActiveForm(null);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Other items (2/2)'}
                description={'Other information about this structure needed for regulatory and compliance tracking.'}
                footerMain={<Button form={'mainForm'}>Next</Button>}
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.Other)}
            >
                <form id={'mainForm'} onSubmit={handleNextStep(data)}>
                    <Select
                        value={data.laws}
                        onChange={handleSelectChange('laws')}
                        options={generalInfo.laws}
                        valueKey={'lawId'}
                        labelKey={'laws'}
                        onCreateOption={() => {
                            setActiveForm(Forms.Law);
                        }}
                        isMulti={true}
                        info={'List of laws and regulations relevant to this vehicle, its investors and assets.'}
                    >
                        Relevant laws and regulations
                    </Select>

                    <Input
                        type={'button'}
                        info={'Please add any comments or notes relevant to this structure.'}
                        onClick={() => {
                            setActiveForm(Forms.Notes);
                        }}
                        value={'Your comments and notes'}
                        secondary={true}
                        outlined={true}
                    >
                        Comments and notes
                    </Input>

                    <Input
                        type={'button'}
                        info={'Please add any files relevant to this structure.'}
                        value={'Add your files'}
                        secondary={true}
                        outlined={true}
                    >
                        Files
                    </Input>
                </form>
            </FormLayout>

            {activeForm === Forms.Law && (
                <LawsCreateForm
                    countries={generalInfo.countries}
                    onSave={handleCreateItems}
                    onClose={handleCloseSubForm}
                />
            )}

            {activeForm === Forms.Notes && (
                <NotesCreateForm selectedData={data.notes} onSave={handleSelectData} onClose={handleCloseSubForm} />
            )}
        </FormWrapper>
    );
};

export default StorageOtherPropertiesPartTwo;
