import create from 'zustand';
import axios from '../utils/axios';
import { useStorageStore } from './useStorageStore';
import { StorageNestedTypes, StorageInvestor, StorageInvestorData, StorageStructureSelection } from '../types/storage';
import { STORAGE_INVESTORS_API } from '../utils/constants';

interface Domicile {
    domicileName: string;
    domicileId: number;
}

interface StorageStore {
    children: StorageNestedTypes[];

    clearDeleted: () => void;

    domiciles: Domicile[];
    // investorsData: StorageInvestorData[];
    getSelectedInvestors: () => void;
    // setInvestorsData: (country: string) => void;
    selectedInvestors: StorageInvestor[];
    setSelectedInvestors: (currentStructureId: number, investors: StorageInvestor[]) => Promise<void>;
    getDomiciles: (structureSelection: StorageStructureSelection) => Promise<Domicile[]>;
    getInvestorsByCountry: (domicile: number, selection: StorageStructureSelection) => Promise<StorageInvestorData[]>;
    saveSelectedInvestors: (investorsIds: number[], structureId: number) => any;
}

export const useStorageInvestorsStore = create<StorageStore>((set, get) => ({
    children: [StorageNestedTypes.assetManagement, StorageNestedTypes.serviceProvider],
    domiciles: [],
    // investorsData: [],
    selectedInvestors: [],
    getDomiciles: async ({ currentStructure, currentFundRegime, currentIfSelfManaged }: StorageStructureSelection) => {
        const { data } = await axios.get(`${STORAGE_INVESTORS_API}/domiciles`, {
            params: {
                currentStructure,
                currentFundRegime,
                currentIfSelfManaged,
            },
        });

        set({
            domiciles: data,
        });

        return data;
    },
    getSelectedInvestors: async () => {
        const { data } = await axios.get(`${STORAGE_INVESTORS_API}`);

        set({
            selectedInvestors: data,
            // data.reduce(function (res: any, obj: any) {
            //     let domicileItem = res.find((item: any) => item.domicileName === obj['domicileName']);
            //
            //     console.log('domicileItem', domicileItem);
            //     if (!domicileItem) {
            //         domicileItem = { domicileName: obj['domicileName'], items: [] };
            //         res.push(domicileItem);
            //     }
            //     domicileItem.items.push(obj);
            //
            //     console.log('res', res);
            //     return res;
            // }, []),
        });

        return data;
    },
    setSelectedInvestors: async (currentStructureId: number, investors: StorageInvestor[]) => {
        console.log('investors', investors, {
            currentStructureId,
            currentInvestorId: investors.map((item) => item.investorId),
        });

        const { data: savedInvestors } = await axios.post(`${STORAGE_INVESTORS_API}`, {
            currentStructureId,
            currentInvestorId: investors.map((item) => item.investorId),
        });

        console.log('savedInvestors', savedInvestors);

        for (const investor of investors) {
            const saved = savedInvestors.find((i: any) => i.investorId === investor.investorId);
            investor.id = saved.jTblInvestorStructureId;
        }

        console.log('investors', investors);

        set(() => ({
            selectedInvestors: investors,
        }));
    },
    // setInvestorsData: async (country: string) => {
    //     const { data } = await axios.get(`${STORAGE_INVESTORS_API}/domicile?country=${country}`);
    //
    //     set((prev) => ({
    //         investorsData: [
    //             ...prev.investorsData,
    //             {
    //                 domicile: country,
    //                 items: data,
    //             },
    //         ],
    //     }));
    //
    //     return data;
    // },
    getInvestorsByCountry: async (
        countryId: number,
        selection: StorageStructureSelection
    ): Promise<StorageInvestorData[]> => {
        const { data } = await axios.get(`${STORAGE_INVESTORS_API}/domicile`, {
            params: {
                currentStructure: selection.currentStructure,
                currentFundRegime: selection.currentFundRegime,
                currentInvestorDomicile: countryId,
                currentIfSelfManaged: selection.currentIfSelfManaged,
            },
        });

        return data;
    },
    saveSelectedInvestors: async (investorsIds, structureId) => {
        const { data } = await axios.post(`${STORAGE_INVESTORS_API}`, {
            currentInvestorId: investorsIds,
            currentStructureId: structureId,
        });

        return data;
    },
    clearDeleted: () => {
        console.log('clear investors', !!useStorageStore.getState().itemsToDelete.investors.length);
        if (!!useStorageStore.getState().itemsToDelete.investors.length) {
            console.log('clear partly investors');

            const tmp = [...get().selectedInvestors];

            for (const id of useStorageStore.getState().itemsToDelete.investors) {
                const index = tmp.findIndex((i) => i.id === id);
                tmp.splice(index, 1);
            }

            console.log('left investors', tmp);

            set({
                selectedInvestors: tmp,
            });
        } else {
            console.log('clear all investors');
            set({
                selectedInvestors: [],
            });
        }
    },
}));
