import ComparisonTableSection from '../ComparisonTableSection';
import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD1Table from './SD1Table';
import { SDProps } from './sd.interface';
import { useTableStore } from '../../../store/useTableStore';

const SD1 = ({ triggerCollapse, setTablesCollapsed, tablesCollapsed, collapseOnClick, isExpanded }: SDProps) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd1 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                isExpanded={isExpanded}
                title={'Legal & regulatory features'}
            >
                <SD1Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD1;
