import styles from './styles.module.css';
import { useState } from 'react';
import { GraphTypes } from '../../../types/general';
import { StorageType } from '../../../pages/Storage/StorageForm/StorageForm';

const Item = ({
    children,
    type,
    onClick,
    selectedType,
    onMouseEnter,
    menuPosition,
    rotateDegree,
}: {
    type: GraphTypes | StorageType;
    onClick: any;
    onMouseEnter: any;
    selectedType: GraphTypes | StorageType;
    children: any;
    menuPosition: 'left' | 'right';
    rotateDegree: number;
}) => (
    <button
        className={`${styles.listItem} ${selectedType === type ? styles.active : ''} ${
            styles[`listItem-${menuPosition}`]
        }`}
        onClick={onClick(type)}
        onMouseEnter={onMouseEnter(type)}
        style={{
            transform: `rotate(${rotateDegree * (menuPosition === 'right' ? -1 : 1)}deg)`,
        }}
    >
        <span
            style={{
                transform: `rotate(${rotateDegree * (menuPosition === 'right' ? 1 : -1)}deg) translateY(70%)`,
            }}
        >
            {children}
        </span>
    </button>
);

const OverviewNavigation = ({
    menuItems,
    onClick,
    selectedType,
    menuPosition = 'right',
}: {
    menuItems: any[];
    onClick: any;
    selectedType: GraphTypes | StorageType;
    menuPosition?: 'left' | 'right';
}) => {
    const [hoverItem, setHoverItem] = useState<GraphTypes | StorageType | undefined>(undefined);

    const onMouseEnter = (type: GraphTypes | StorageType) => (e: any) => {
        setHoverItem(type);
    };

    const onMouseLeave = () => {
        setHoverItem(undefined);
    };

    const calculateRotation = (index: number) => {
        const min = -30;
        const max = 30;

        const range = max - min; // Assuming a range from -30 to 30
        const stepSize = range / (menuItems.length - 1); // Divide by arrayLength - 1 for 6 intervals

        return min + index * stepSize;
    };

    return (
        <aside className={styles.nav}>
            <nav>
                <button className={`${styles.menu} ${styles[`menu-${menuPosition}`]}`} onClick={onClick(undefined)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className={`${styles.list} ${styles[`left-${menuPosition}`]}`} onMouseLeave={onMouseLeave}>
                    {menuItems.map((item: any, index: number) => (
                        <Item
                            key={index}
                            type={item.type}
                            selectedType={hoverItem || selectedType}
                            onClick={onClick}
                            onMouseEnter={onMouseEnter}
                            menuPosition={menuPosition}
                            rotateDegree={calculateRotation(index)}
                        >
                            {item.label}
                        </Item>
                    ))}
                </div>
            </nav>
        </aside>
    );
};

export default OverviewNavigation;
