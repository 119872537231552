import styles from './styles.module.css';
import React, { useContext } from 'react';
import { SelectionRowContext } from '../../../context';
import { Country } from '../../../types/general';
import SelectionLayoutRow, { SelectionLayoutRowWrapperProps } from '../SelectionLayoutRow/SelectionLayoutRow';
import Select from '../../Select/Select';

export interface SelectionPopupCountryRowProps extends SelectionLayoutRowWrapperProps {
    isSingle?: boolean;
}

const SelectionPopupCountryRow = ({ children, isSingle, ...props }: SelectionPopupCountryRowProps) => {
    const { onCountrySelect, countries, selectedCountry } = useContext(SelectionRowContext);

    return (
        // <div className={styles.row}>
        <SelectionLayoutRow {...props}>
            <div className={styles.left}>
                <Select
                    options={countries}
                    onChange={onCountrySelect}
                    value={selectedCountry}
                    valueKey={'domicile'}
                    labelKey={'domicileName'}
                />
            </div>
            {children}
        </SelectionLayoutRow>
    );
};

export default SelectionPopupCountryRow;
