import styles from './FormLayout.module.css';
import { Button, Icon } from '../../../../components';
import { useStorageStore } from '../../../../store/useStorageStore';
import { Icons } from '../../../../components/Icon/Icon';

const FormLayout = ({
    title,
    description,
    children,
    onClose,
    isAbsolute = false,
    footerMain,
    footerAdditional,
    handleClose,
    handlePrevStep,
    useMargin = true,
}: any) => {
    const [setIsSmallStructure, activeStorageType, setOpenType] = useStorageStore((state) => [
        state.setIsSmallStructure,
        state.openType,
        state.setOpenType,
    ]);

    // const handleClose = () => {
    //     onClose ? onClose() : setOpenType(undefined);
    // };

    return (
        <div className={`${styles.form} ${isAbsolute ? styles.absolute : ''}`}>
            <div className={styles.formContent}>
                <header className={`${styles.header} ${useMargin ? styles.margin : ''}`}>
                    {!!title && (
                        <div>
                            <h3>{title}</h3>

                            <Button
                                onClick={handleClose || onClose}
                                color={'neutral'}
                                after={<Icon icon={Icons.closeRounded} />}
                            >
                                Close
                            </Button>
                        </div>
                    )}
                    {!!description && <p>{description}</p>}
                </header>

                {children}

                {!!footerAdditional && (
                    <div className={styles.footerAdditional}>
                        <div className={styles.footerAdditionalInner}>{footerAdditional}</div>
                    </div>
                )}

                <footer className={styles.footer}>
                    {!!handlePrevStep ? (
                        <Button color={'neutral'} onClick={handlePrevStep}>
                            Back
                        </Button>
                    ) : (
                        <span />
                    )}
                    {footerMain}
                </footer>
            </div>
        </div>
    );
};

export default FormLayout;
