import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import axios from '../../utils/axios';
import styles from './styles.module.css';
import ContactFormInput from './components/ContactFormInput';
import { Button, Response } from '../../components';
import { Turnstile } from '@marsidev/react-turnstile';

interface MessageProps {
    name: string;
    email: string;
    subject: string;
    message: string;
}

const Contact = () => {
    const { user } = useContext(UserContext);

    const [data, setData] = useState<MessageProps>({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [response, setResponse] = useState<string | null>(null);
    const turnsliteRef = React.useRef<any>();

    useEffect(() => {
        if (user) {
            setData({ ...data, name: user.username, email: user.email });
        }
    }, [user]);

    const handleChange = (prop: string) => (event: { target: { value: any } }) => {
        setData({ ...data, [prop]: event.target.value });
    };

    const handleContactForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setResponse(null);

        try {
            await axios.post('/contact', {
                name: data.name,
                email: data.email,
                subject: data.subject,
                message: data.message,
                token: turnsliteRef.current?.getResponse(),
            });

            setResponse('success');
        } catch (err) {
            setResponse('error');
        }

        // @ts-ignore
        e.target.reset();
        turnsliteRef.current?.reset();
    };

    const Error = () => {
        return (
            <Response type={'error'} setResponse={setResponse}>
                Error! Your message was not sent.
            </Response>
        );
    };

    const Success = () => {
        return (
            <Response type={'success'} setResponse={setResponse}>
                Your request was successfully submitted.
            </Response>
        );
    };

    return (
        <>
            {response === 'error' && <Error />}
            {response === 'success' && <Success />}
            <form className={styles.form} onSubmit={handleContactForm}>
                <h3 className={styles.title}>Send us a message</h3>

                <ContactFormInput defaultValue={data.name} label={'Name'} onChange={handleChange('name')} />
                <ContactFormInput
                    defaultValue={data.email}
                    label={'Email'}
                    type={'email'}
                    onChange={handleChange('email')}
                />
                <ContactFormInput label={'Subject'} onChange={handleChange('subject')} />
                <ContactFormInput label={'Message'} isTextarea={true} onChange={handleChange('message')} />

                <div style={{ marginLeft: '16px' }}>
                    <Turnstile
                        siteKey={process.env.REACT_APP_TRANSLIT_KEY!}
                        options={{
                            theme: 'light',
                            appearance: 'interaction-only',
                        }}
                        ref={turnsliteRef}
                    />
                </div>

                <div className={styles.btnWrapper}>
                    <Button type={'submit'} disabled={!data.email || !data.subject || !data.name || !data.message}>
                        Send message
                    </Button>
                </div>
            </form>
        </>
    );
};

export default Contact;
