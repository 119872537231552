import { useEffect, useState } from 'react';
import { StorageType } from '../../StorageForm';
import { Button, Icon } from '../../../../../components';
import AssetManagerSingle from './components/AssetManagerSingle';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import { Icons } from '../../../../../components/Icon/Icon';

export enum AssetManagerForm {
    Role = 'Role',
    AMBase = 'AMBase',
}

const StorageAssetManager = ({ selectedData, generalInfo, setGeneralInfo, handleNextStep, handleClose }: any) => {
    const [showCreateForm, setShowCreateForm] = useState<undefined | null | AssetManagerForm>();

    const [data, setData] = useState<any[]>(selectedData ? [...selectedData] : [{}]);

    const [selectionsValid, setSelectionsValid] = useState<any>(false);

    useEffect(() => {
        setSelectionsValid(!data.some((item) => !(item.domicile?.value && item.role?.value)));
    }, [data]);

    const handleAddItem = () => {
        setData([...data, {}]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...data];
        tmp.splice(index, 1);
        setData(tmp);
    };

    const handleShowCreateForm = (type: AssetManagerForm) => () => {
        setShowCreateForm(type);
    };

    const handleItemUpdate = (index: number) => (item: any) => {
        const tmp = [...data];
        tmp[index] = item;
        setData(tmp);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Asset manager(s)'}
                description={'Please fill in information about asset manager(s).'}
                footerAdditional={
                    <Button
                        secondary={true}
                        outlined={true}
                        type={'button'}
                        before={<Icon icon={Icons.add} />}
                        onClick={handleAddItem}
                        fullWidth={true}
                        disabled={!selectionsValid}
                    >
                        Add line
                    </Button>
                }
                footerMain={<Button form={'mainForm'}>Next</Button>}
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.Assets)}
            >
                <form id={'mainForm'} onSubmit={handleNextStep(data, StorageType.ServiceProviders)}>
                    {data.map((item: any, index: number) => (
                        <AssetManagerSingle
                            key={index}
                            selectedData={item}
                            generalInfo={generalInfo}
                            handleRemoveItem={handleRemoveItem(index)}
                            handleShowCreateForm={handleShowCreateForm}
                            onUpdate={handleItemUpdate(index)}
                            setGeneralInfo={setGeneralInfo}
                        />
                    ))}
                </form>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageAssetManager;
