import { Icons } from '../components/Icon/Icon';

export enum GraphTypes {
    Taxes = 'deal-taxes',
    Legal = 'legal-structures',
    Optimisation = 'tax-optimisation',
    Storage = 'storage',
    Compliance = 'compliance',
    Library = 'library',
    MultiLayered = 'multi-layered',
    Evergreen = 'evergreen',
    AI = 'AI: nodes & RAG & status',
}

export const CountryCode = {
    lu: 'lu',
    ch: 'ch',
    de: 'de',
    ky: 'ky',
    uk: 'uk',
    fr: 'fr',
    sg: 'sg',
} as const;

// Convert object key in a type
export type TCountryCode = typeof CountryCode[keyof typeof CountryCode];

export interface Country {
    domicileId: string | number;
    domicileName: string;
    domicile: string;
    img: string;
}

export const Countries: { [key in TCountryCode]: Country } = {
    sg: {
        domicileName: 'Singapore',
        img: '/flags/sg.svg',
        domicileId: 'sg',
        domicile: 'sg',
    },
    fr: {
        domicileName: 'France',
        img: '/flags/fr.png',
        domicileId: 'fr',
        domicile: 'fr',
    },
    lu: {
        domicileName: 'Luxembourg',
        img: '/flags/lu.png',
        domicileId: 'lu',
        domicile: 'lu',
    },
    ch: {
        domicileName: 'Switzerland',
        img: '/flags/ch.png',
        domicileId: 'ch',
        domicile: 'ch',
    },
    de: {
        domicileName: 'Germany',
        img: '/flags/de.png',
        domicileId: 'de',
        domicile: 'de',
    },
    ky: {
        domicileName: 'Cayman Islands',
        img: '/flags/cym.png',
        domicileId: 'ky',
        domicile: 'ky',
    },
    uk: {
        domicileName: 'United Kingdom',
        img: '/flags/uk.png',
        domicileId: 'uk',
        domicile: 'uk',
    },
};

export enum SelectionLevel {
    Investor = 'investor',
    Fund = 'fund',
    Asset = 'asset',
    FundOfFunds = 'fundOfFunds',
}

export const SelectionLevelProps: { [key in SelectionLevel]: any } = {
    investor: {
        name: 'currentInvestor',
        valueKey: 'investorId',
        labelKey: 'investor',
        titleKey: 'investor',
        infoKey: 'description',
        icon: Icons.investor,
        title: 'Investor(s)',
        label: 'investorExtended',
        placeholder: 'Choose investor type',
    },
    fundOfFunds: {
        name: 'currentFoF',
        valueKey: 'investmentFundId',
        labelKey: 'investmentFund',
        titleKey: 'investmentFund',
        infoKey: 'fullName',
        icon: Icons.fundOfFunds,
        title: 'Fund of funds',
        placeholder: 'Choose structure type',
    },
    fund: {
        name: 'currentFund',
        valueKey: 'investmentFundId',
        labelKey: 'investmentFund',
        titleKey: 'investmentFund',
        infoKey: 'fullName',
        title: 'Investment structure',
        label: 'investmentFundExtended',
        icon: Icons.investment,
        placeholder: 'Choose structure type',
    },
    asset: {
        name: 'currentAsset',
        valueKey: 'assetId',
        labelKey: 'assetClass',
        titleKey: 'assetClass',
        infoKey: 'description',
        title: 'Asset(s)',
        icon: Icons.asset,
        placeholder: 'Choose asset type',
    },
};

export interface Investor {
    investor: string;
    investorExtended: string;
    investorId: number;
    description: string;
}

export interface Asset {
    assetClass: string;
    assetExtended: string;
    assetId: number;
    description: string;
    domicile: string;
    jTblAssetDomicileId: number;
}

export interface InvestmentFund {
    domicile: string;
    fullName: string;
    investmentFund: string;
    investmentFundId: number;
    investmentFundExtended: string;
}

export interface LegalForm {
    legalFormShort: string;
    legalFormFull: string;
    legalType: number;
}
