import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.css';

import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';

import { UserContext } from './context';

import useFindUser from './hooks/useFindUser';

function App() {
    const { user, setUser, isLoading } = useFindUser();

    return (
        <BrowserRouter>
            <UserContext.Provider value={{ user, setUser, isLoading }}>
                <AppRouter />
            </UserContext.Provider>
        </BrowserRouter>
    );
}

export default App;
