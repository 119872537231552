import { DescriptionFormat, ProgressBar } from './index';

const DescriptionProgressFormat = (d: any, { progressVal, descriptionVal }: any) => {
    const desc = DescriptionFormat(d, { descriptionVal });
    let progress = ProgressBar(progressVal);

    if (desc === '-' || desc === 'no requirements' || desc === 'no') {
        progress = <></>;
    }

    return (
        <>
            {progress}
            {desc}
        </>
    );
};

export default DescriptionProgressFormat;
