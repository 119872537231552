import styles from './styles.module.css';
import InputLabel from '../../../../components/InputLabel/InputLabel';
import { Icon } from '../../../../components';
import { Icons } from '../../../../components/Icon/Icon';
import { useState } from 'react';

const FormCollapsableRow = ({ titleLabel, titleValue, children }: any) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${styles.row} ${isOpen ? styles.open : ''}`}>
            <header
                style={{
                    display: 'flex',
                    gridGap: '16px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <InputLabel fullWidth={false} label={titleLabel} />

                <div
                    style={{
                        flex: 1,
                    }}
                >
                    {titleValue}
                </div>
            </header>

            <div className={styles.contentOuter}>
                <div className={styles.content}>{children}</div>

                <button
                    className={styles.toggle}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <Icon icon={Icons.chevronDown} />
                </button>
            </div>
        </div>
    );
};

export default FormCollapsableRow;
