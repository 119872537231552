import { useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import { CloseButton, DeletePopup, Response } from '../../components';
import { PresetInterface } from '../../components/Preset/Preset';
import OverviewCategories from '../../components/Overview/OverviewCategories/OverviewCategories';
import TransitionComponent from '../../components/TransitionComponent/TransitionComponents';
import OverviewCategoryItems from '../../components/Overview/OverviewCategoryItems';
import OverviewNavigation from '../../components/Overview/OverviewNavigation/OverviewNavigation';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import { GraphTypes } from '../../types/general';

const Account = () => {
    const [selectedType, setSelectedType] = useState<undefined | GraphTypes>(undefined);

    const [searchParams] = useSearchParams();

    const [presets, setPresets] = useState<PresetInterface[]>([]);
    const [loading, setLoading] = useState(true);

    const [showPopup, setShowPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [currentPreset, setCurrentPreset] = useState<number | null>(null);
    const [showDownload, setShowDownload] = useState(false);

    useEffect(() => {
        if (searchParams.has('save')) {
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 2500);
            // setSearchParams('');
        }
    }, [searchParams]);

    useEffect(() => {
        (async () => {
            const data = await axios.get('/presets');
            setPresets(data.data);
            setLoading(false);
        })();
    }, []);

    const handleDeletePopup = async (data: PresetInterface) => {
        setShowDeletePopup(true);
        setCurrentPreset(data.id);
    };

    const handleDelete = async () => {
        await axios.delete(`/presets/${currentPreset}`);
        setPresets(presets.filter((p) => p.id !== currentPreset));
        setShowDeletePopup(false);
        setCurrentPreset(null);
    };

    const handleCategoryChange = (type: GraphTypes) => (e: any) => {
        setSelectedType(type);
    };

    return (
        <>
            {showDeletePopup && (
                <DeletePopup onDelete={() => handleDelete()} onCancel={() => setShowDeletePopup(false)} />
            )}
            {showDownload && (
                <div className={styles.popupContainer}>
                    <div className={styles.popup}>
                        <div className={styles.close}>
                            <CloseButton
                                onClick={() => {
                                    setShowDownload(false);
                                }}
                            />
                        </div>
                        <h4>Downloading</h4>
                        <div className={styles.linearActivity}>
                            <div className={styles.indeterminate}></div>
                        </div>
                        <p>It takes just couple of minutes…</p>
                    </div>
                </div>
            )}

            <LoaderComponent inProp={!loading}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <TransitionComponent inProp={!selectedType} isAbsolute={!!selectedType}>
                            <div className={styles.categoriesWrapper}>
                                <h3>Your saved overviews:</h3>

                                <div className={styles.categories}>
                                    <OverviewCategories onClick={handleCategoryChange} presets={presets} />
                                </div>
                            </div>
                        </TransitionComponent>

                        <TransitionComponent inProp={!!selectedType} isAbsolute={!selectedType}>
                            <div className={styles.grid}>
                                <div>
                                    <TransitionComponent
                                        inProp={selectedType === GraphTypes.Taxes}
                                        isAbsolute={selectedType !== GraphTypes.Taxes}
                                    >
                                        <OverviewCategoryItems
                                            type={GraphTypes.Taxes}
                                            presets={presets}
                                            handleDeletePopup={handleDeletePopup}
                                            setShowDownload={setShowDownload}
                                        />
                                    </TransitionComponent>

                                    <TransitionComponent
                                        inProp={selectedType === GraphTypes.Legal}
                                        isAbsolute={selectedType !== GraphTypes.Legal}
                                    >
                                        <OverviewCategoryItems
                                            type={GraphTypes.Legal}
                                            presets={presets}
                                            handleDeletePopup={handleDeletePopup}
                                            setShowDownload={setShowDownload}
                                        />
                                    </TransitionComponent>

                                    <TransitionComponent
                                        inProp={selectedType === GraphTypes.Optimisation}
                                        isAbsolute={selectedType !== GraphTypes.Optimisation}
                                    >
                                        <OverviewCategoryItems
                                            type={GraphTypes.Optimisation}
                                            presets={presets}
                                            handleDeletePopup={handleDeletePopup}
                                            setShowDownload={setShowDownload}
                                        />
                                    </TransitionComponent>

                                    <TransitionComponent
                                        inProp={selectedType === GraphTypes.Storage}
                                        isAbsolute={selectedType !== GraphTypes.Storage}
                                    >
                                        <OverviewCategoryItems
                                            type={GraphTypes.Storage}
                                            presets={presets}
                                            handleDeletePopup={handleDeletePopup}
                                            setShowDownload={setShowDownload}
                                        />
                                    </TransitionComponent>
                                </div>

                                <div>
                                    <OverviewNavigation
                                        menuItems={[
                                            { label: 'Deal taxes', type: GraphTypes.Taxes },
                                            { label: 'Legal structures', type: GraphTypes.Legal },
                                            { label: 'Stored structures', type: GraphTypes.Storage },
                                            { label: 'Tax optimisation', type: GraphTypes.Optimisation },
                                        ]}
                                        selectedType={selectedType!}
                                        onClick={handleCategoryChange}
                                    />
                                </div>
                            </div>
                        </TransitionComponent>
                    </div>

                    {showPopup && (
                        <Response type={'success'} aligned={false} setResponse={setShowPopup}>
                            Saved successfully
                        </Response>
                    )}
                </div>
            </LoaderComponent>
        </>
    );
};

export default Account;
