import { useContext, useEffect, useState } from 'react';

import styles from './tr-styles.module.css';
import { getGroupValues, getGroupValuesByLegalForm, getInvestmentFunds, getUnique } from '../utils';

import { TableDescriptionContext } from '../context';
import { TrContext } from '../context/TrContext';
import { useTableStore } from '../../../store/useTableStore';

interface TrProps {
    featName: string;
    dataKey: string;
    colKey: string;
    fundKey?: string;
    formatFunc?: any;
    formatData?: any;
    progressCol?: string;
    firstCol?: string;
    skipFirstCol?: boolean;
    passData?: boolean;
    descriptionCol?: string;
}

const Tr = ({
    featName,
    dataKey,
    fundKey,
    colKey,
    formatFunc,
    formatData,
    progressCol,
    firstCol,
    skipFirstCol,
    passData,
    descriptionCol,
}: TrProps) => {
    const [changeRows, setChangeRows] = useState(false);

    const [data, setActiveFund, activeFund] = useTableStore((state) => [
        state.data,
        state.setActiveFund,
        state.activeFund,
    ]);

    const { expandedRows, setExpandedRows, globalExpanded, setTotalExpandableRows, totalExpandableRows } =
        useContext(TableDescriptionContext);
    const { hasDescription, expanded, setExpanded } = useContext(TrContext);

    useEffect(() => {
        setTimeout(() => {
            setChangeRows(hasDescription);
        });
    }, [hasDescription]);

    useEffect(() => {
        if (changeRows) {
            setTotalExpandableRows(totalExpandableRows + 1);
            setChangeRows(false);
        }
    }, [changeRows, totalExpandableRows]);

    useEffect(() => {
        setExpanded(globalExpanded);
    }, [globalExpanded]);

    fundKey = fundKey || dataKey;
    const firstColKey = firstCol || 'legalForm';

    const funds = getInvestmentFunds(data, fundKey);

    const toggleExpand = () => {
        if (expanded) {
            setExpandedRows(expandedRows - 1);
        } else {
            setExpandedRows(expandedRows + 1);
        }

        setExpanded(!expanded);
    };

    return (
        <tr className={`${expanded ? 'rowExpanded' : ''} ${colKey === 'legalForm' ? styles.fixedRow : ''}`}>
            <td>
                {featName}
                {hasDescription && (
                    <>
                        <br />
                        <img
                            alt={''}
                            src={'/images/chevron-down-lg.svg'}
                            onClick={toggleExpand}
                            className={'rowExpandedImg'}
                        />
                    </>
                )}
            </td>
            {funds &&
                funds.map((f: any, i: number) => {
                    const tdData = [];
                    let tdStyle: any = {};

                    if (skipFirstCol) {
                        if (colKey === 'assetManagerOptions') {
                            const d = getGroupValues(data, dataKey, f)
                                .map((d: any) => {
                                    return {
                                        assetManager: d.assetManager,
                                        assetManagerOptions: d[colKey],
                                    };
                                })
                                .reduce((result: any, current: any) => {
                                    if (!result[current.assetManager]) {
                                        result[current.assetManager] = {
                                            assetManagerOptions: [],
                                        };
                                    }
                                    result[current.assetManager].assetManagerOptions.push(current.assetManagerOptions);
                                    return result;
                                }, {});

                            for (const value of Object.values(d)) {
                                tdData.push({
                                    // @ts-ignore
                                    data: value.assetManagerOptions.filter(getUnique).join(' \n '),
                                    colspan: 1,
                                });
                            }

                            tdStyle.whiteSpace = 'break-spaces';
                        } else {
                            const d = getGroupValues(data, dataKey, f)
                                .map((d: any) => d[colKey])
                                .filter(getUnique);
                            tdData.push(
                                ...d.map((col: any) => {
                                    return {
                                        data: col,
                                        colspan: 1,
                                    };
                                })
                            );
                        }
                    } else {
                        // get first row Legal form
                        const legalForm = getGroupValues(data, dataKey, f)
                            .map((d: any) => d[firstColKey])
                            .filter(getUnique);

                        if (firstColKey === colKey) {
                            // skip counting for Legal form
                            for (const lf of legalForm) {
                                tdData.push({
                                    data: lf,
                                    colspan: 1,
                                });
                            }
                        } else {
                            // let init = true;
                            // let prevLfData = null;
                            // let colspan = 1;
                            const values: any = [];
                            for (let j = 0; j < legalForm.length; j++) {
                                const lfData = getGroupValuesByLegalForm(data, dataKey, f, firstColKey, legalForm[j])
                                    .map((d: any) => d[colKey])
                                    .filter(getUnique)[0];

                                values.push(lfData);

                                // if (init) {
                                //     init = false;
                                //     prevLfData = lfData;
                                // } else {
                                //     if (prevLfData === lfData) {
                                //         colspan++;
                                //     } else {
                                //         tdData.push({
                                //             data: prevLfData,
                                //             colspan,
                                //         });
                                //
                                //         colspan = 1;
                                //     }
                                // }
                                //
                                // // check if this is the last item in loop
                                // if (j === legalForm.length - 1) {
                                //     tdData.push({
                                //         data: lfData,
                                //         colspan,
                                //     });
                                // }
                                //
                                // prevLfData = lfData;
                                // counter++;
                            }

                            if (values.every((val: any) => val === values[0])) {
                                tdData.push({
                                    data: values[0],
                                    colspan: values.length,
                                });
                            } else {
                                tdData.push(
                                    ...values.map((v: any) => {
                                        return {
                                            data: v,
                                            colspan: 1,
                                        };
                                    })
                                );
                            }
                        }
                    }

                    return (
                        <td
                            key={`row-${i}`}
                            className={`${styles.tdGroup} ${f === activeFund ? styles.activeTable : ''}`}
                        >
                            <table className={styles.tdGroupTable} onClick={() => setActiveFund(f)}>
                                <tbody>
                                    <tr style={tdStyle}>
                                        {tdData.map((td, key) => {
                                            const d = td.data;
                                            const colspan = td.colspan;

                                            if (progressCol) {
                                                if (!formatData) {
                                                    formatData = {};
                                                }

                                                formatData.progressVal = getGroupValues(data, dataKey, f).map(
                                                    (d: any) => d[progressCol]
                                                )[key];
                                            }

                                            if (descriptionCol) {
                                                formatData.descriptionVal = getGroupValues(data, dataKey, f)
                                                    .map((d: any) => d[descriptionCol])
                                                    .filter(getUnique)[0];
                                            }

                                            return (
                                                <td key={key} colSpan={colspan}>
                                                    {formatFunc ? (
                                                        <>
                                                            {passData ? (
                                                                <>{formatFunc(data, d, dataKey, colKey)}</>
                                                            ) : (
                                                                <>
                                                                    {formatFunc &&
                                                                        formatData &&
                                                                        formatFunc(d, formatData)}

                                                                    {formatFunc && !formatData && formatFunc(d)}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>{d === '' || d === null ? '-' : d}</>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    );
                })}
        </tr>
    );
};

export default Tr;
