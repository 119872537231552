import React, { useEffect, useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from './comparison-table-styles.module.css';

interface ComparisonTableSectionScrollingProps {
    children: React.ReactNode;
    row?: boolean;
    collapsed: boolean;
    excludeFeat: boolean;
}

const ComparisonTableSectionScrolling = ({
    children,
    row,
    collapsed,
    excludeFeat,
}: ComparisonTableSectionScrollingProps) => {
    const [scrollBarRef, setScrollBarRef] = useState<any>(null);
    const [scrollable, setScrollable] = useState(true);

    const [reachedStart, setReachedStart] = useState(false);
    const [reachedEnd, setReachedEnd] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [ths, setThs] = useState<Element[]>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [fixedColWidth, setFixedColWidth] = useState(150);

    useEffect(() => {
        if (excludeFeat) {
            setFixedColWidth(0);
        }
    }, [excludeFeat]);

    useEffect(() => {
        if (scrollBarRef) {
            let totalWidth = 0;

            const container = scrollBarRef._container;
            const columns = container.querySelectorAll('table > thead >tr > th.fund-th');

            setThs(columns);

            columns.forEach((th: HTMLTableColElement) => {
                totalWidth += th.clientWidth;
            });

            const perPage = Math.round((container.clientWidth - fixedColWidth) / (totalWidth / columns.length));

            setItemsPerPage(perPage);

            setTotalPages(Math.round(columns.length / perPage));

            const scrollable = container.scrollWidth > container.clientWidth;
            setScrollable(scrollable);

            if (scrollable) {
                setReachedEnd(false);
            }
        }
    }, [scrollBarRef, collapsed]);

    const isElementInViewport = (el: Element) => {
        const rect = el.getBoundingClientRect();

        const elRight = rect.right;
        const containerRight = scrollBarRef._container.getBoundingClientRect().right;

        const containerLeft = scrollBarRef._container.getBoundingClientRect().left;
        const leftOffset = containerLeft + (excludeFeat ? fixedColWidth : 0);

        return rect.left >= leftOffset && elRight <= containerRight;
    };

    const handleXReachEnd = () => {
        setReachedEnd(true);
        setCurrentPage(totalPages);
    };

    const handleXReachStart = () => {
        setReachedStart(true);
        setCurrentPage(1);
    };

    const handleScroll = () => {
        let lastVisible = 0;

        ths.forEach((th: Element, index: number) => {
            if (isElementInViewport(th)) {
                if (index > lastVisible) {
                    lastVisible = index;
                }
            }
        });

        setCurrentPage(Math.ceil(lastVisible / itemsPerPage));
    };

    const handleScrollLeft = () => {
        setReachedEnd(false);

        handleScroll();
    };

    const handleScrollRight = () => {
        setReachedStart(false);

        handleScroll();
    };

    const scrollLeft = () => {
        let firstVisible = 0;

        ths.forEach((th: Element, index: number) => {
            if (isElementInViewport(th)) {
                if (firstVisible === 0 && index > firstVisible) {
                    firstVisible = index;
                }
            }
        });

        const index = firstVisible - 1 <= 0 ? 1 : firstVisible - 1;

        // @ts-ignore
        const item = ths.item(index);

        if (!item) return;

        const scrollLeft =
            item.getBoundingClientRect().left - scrollBarRef._container.getBoundingClientRect().left - fixedColWidth;

        scrollBarRef._container.scrollLeft += scrollLeft;
    };

    const scrollRight = () => {
        let lastVisible = 0;

        ths.forEach((th: Element, index: number) => {
            if (isElementInViewport(th)) {
                if (index > lastVisible) {
                    lastVisible = index;
                }
            }
        });

        // @ts-ignore
        const item = ths.item(lastVisible + 1);

        if (!item) return;

        const lastOffset = lastVisible + 1 === ths.length - 1 ? 34 : 4;

        const scrollRight =
            item.getBoundingClientRect().right - scrollBarRef._container.getBoundingClientRect().right + lastOffset;

        scrollBarRef._container.scrollLeft += scrollRight;
    };

    return (
        <>
            {scrollable && !reachedStart && (
                <button className={`${styles.scrollBtn} ${styles.scrollBtnLeft}`} onClick={scrollLeft}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
                        <g id="Icon" transform="translate(-3.296 -5.087)">
                            <path
                                id="Path"
                                d="M6,9l6,6,6-6"
                                transform="translate(-1.29 -2.499)"
                                fill="none"
                                stroke="#1650b4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                </button>
            )}

            <div
                className={`${row ? styles.tableWrapperRow : styles.tableWrapper} ${collapsed ? styles.collapsed : ''}`}
            >
                <PerfectScrollbar
                    onXReachEnd={handleXReachEnd}
                    onXReachStart={handleXReachStart}
                    onScrollLeft={handleScrollLeft}
                    onScrollRight={handleScrollRight}
                    ref={(ref) => {
                        setScrollBarRef(ref);
                    }}
                    className={styles.tableScrolling}
                >
                    {children}
                </PerfectScrollbar>

                {scrollable && (
                    <div className={styles.nav}>
                        <span>{currentPage}</span>
                        <span>{totalPages}</span>
                    </div>
                )}
            </div>

            {scrollable && !reachedEnd && (
                <button className={`${styles.scrollBtn} ${styles.scrollBtnRight}`} onClick={scrollRight}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
                        <g id="Icon" transform="translate(-3.296 -5.087)">
                            <path
                                id="Path"
                                d="M6,9l6,6,6-6"
                                transform="translate(-1.29 -2.499)"
                                fill="none"
                                stroke="#1650b4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                </button>
            )}
        </>
    );
};

export default ComparisonTableSectionScrolling;
