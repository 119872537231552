import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { BooleanFormat, DescriptionFormat, DescriptionProgressFormat, PercentFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD4Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd4 ? (
        <table>
            <TableHeader sdKey={'sd4'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd4'} colKey={'legalForm'} />

                <Tr featName={'Fund regime'} dataKey={'sd4'} colKey={'fundRegime'} />

                <Tr featName={'Capital type'} dataKey={'sd4'} colKey={'capitalType'} />

                <TrProvider>
                    <Tr
                        featName={'Issue of shares complexity'}
                        dataKey={'sd4'}
                        colKey={'issueOfSharesComplexity'}
                        formatFunc={DescriptionProgressFormat}
                        progressCol={'issueOfSharesComplexityBar'}
                    />
                </TrProvider>

                <Tr
                    featName={'Min share payment'}
                    dataKey={'sd4'}
                    colKey={'minSharePayment'}
                    formatFunc={PercentFormat}
                />

                <TrProvider>
                    <Tr featName={'Issue price'} dataKey={'sd4'} colKey={'issuePrice'} formatFunc={DescriptionFormat} />
                </TrProvider>

                <TrProvider>
                    <Tr
                        featName={'Pre-emption rights'}
                        dataKey={'sd4'}
                        colKey={'preEmptionRights'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>

                <Tr
                    featName={'Pre-emption rights statutory?'}
                    dataKey={'sd4'}
                    colKey={'ifPreEmptionRightsStatutory'}
                    formatFunc={BooleanFormat}
                />
                <Tr
                    featName={'Commitment structure'}
                    dataKey={'sd4'}
                    colKey={'commitmentStructure'}
                    formatFunc={BooleanFormat}
                />
                <Tr
                    featName={'Special requirements for commitments?'}
                    dataKey={'sd4'}
                    colKey={'ifSpecialRequirementsForCommitments'}
                    formatFunc={BooleanFormat}
                />
                <TrProvider>
                    <Tr
                        featName={'Capital calls details'}
                        dataKey={'sd4'}
                        colKey={'capitalCallsDetails'}
                        formatFunc={DescriptionProgressFormat}
                        progressCol={'capitalCallsDetailsBar'}
                    />
                </TrProvider>
            </tbody>
        </table>
    ) : null;
};

export default SD4Table;
