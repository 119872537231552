import styles from '../selected-combination.module.css';

import { ItemsByCountry } from '../../../store/useDiagramStore';
import {
    Asset,
    InvestmentFund,
    Investor,
    SelectionLevel,
    SelectionLevelProps,
    TCountryCode,
} from '../../../types/general';
import { Checkbox, Icon } from '../../index';
import { SelectedItem } from '../../SelectionPopup/SelectionPopup';
import React from 'react';

interface SelectionCombinationRowProps {
    items: ItemsByCountry<Investor> | ItemsByCountry<Asset> | ItemsByCountry<InvestmentFund>;
    itemId: number | null | undefined | SelectedItem[] | SelectedItem[];
    type: SelectionLevel;
    selectedCountry?: TCountryCode | null;
    selectedData?: any;
    name?: string;
    isSelectable?: boolean;
    handleSelectedInvestor?: any;
}

const SelectionCombinationRow = ({
    items,
    itemId,
    type,
    selectedData,
    name,
    isSelectable,
    handleSelectedInvestor,
    selectedCountry,
}: SelectionCombinationRowProps) => {
    const findById = (id: number) =>
        items &&
        // @ts-ignore
        items[selectedCountry]?.find((i) => +i[SelectionLevelProps[type].valueKey] === +id)?.[
            SelectionLevelProps[type].labelKey
        ];

    return (
        <>
            <div
                className={`${styles.label} ${styles.labelMargin} ${
                    typeof itemId === 'object' ? styles.alignTop : styles.alignMiddle
                }`}
            >
                {itemId && (
                    <>
                        <Icon icon={SelectionLevelProps[type].icon} />
                        <div>
                            <h6>{SelectionLevelProps[type].title}</h6>
                            <div className={styles.block}>
                                {selectedCountry?.toUpperCase() || ' '}{' '}
                                {typeof itemId === 'object'
                                    ? itemId.map((item, i) => (
                                          <React.Fragment key={i}>
                                              {isSelectable ? (
                                                  <button
                                                      className={`${styles.selectableItem} ${
                                                          selectedData.selectedInvestor?.id === item.id
                                                              ? styles.selected
                                                              : ''
                                                      }`}
                                                      disabled={selectedData.investors.length === 1}
                                                      onClick={handleSelectedInvestor(item.id!)}
                                                  >
                                                      {item.countryCode?.toUpperCase()} {item.name}
                                                  </button>
                                              ) : (
                                                  <span
                                                      style={{
                                                          display: 'block',
                                                          marginBottom: '10px',
                                                          color:
                                                              item.id === selectedData?.selectedInvestor?.id
                                                                  ? '#1650B4'
                                                                  : undefined,
                                                      }}
                                                  >
                                                      {item.countryCode?.toUpperCase()} {item.name}
                                                  </span>
                                              )}
                                          </React.Fragment>
                                      ))
                                    : name || findById(+itemId)}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default SelectionCombinationRow;
