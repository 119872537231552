import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { TypeOfSecurityFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD6Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd6 ? (
        <table>
            <TableHeader sdKey={'sd6'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd6'} colKey={'legalForm'} />

                <Tr featName={'Form of securities'} dataKey={'sd6'} colKey={'formOfSecurities'} />

                <Tr featName={'Form of contribution'} dataKey={'sd7'} colKey={'formOfContribution'} fundKey={'sd6'} />

                <Tr featName={'Participation form'} dataKey={'sd8'} fundKey={'sd6'} colKey={'participationForm'} />
                <TrProvider>
                    <Tr
                        featName={'Type of securities: equity'}
                        dataKey={'sd8'}
                        fundKey={'sd6'}
                        colKey={'typeSecurities'}
                        formatFunc={TypeOfSecurityFormat}
                        formatData={{
                            securityType: 'equity',
                        }}
                    />
                </TrProvider>

                <TrProvider>
                    <Tr
                        featName={'Type of securities: debt'}
                        dataKey={'sd8'}
                        fundKey={'sd6'}
                        colKey={'typeSecurities'}
                        formatFunc={TypeOfSecurityFormat}
                        formatData={{
                            securityType: 'debt',
                        }}
                    />
                </TrProvider>

                <TrProvider>
                    <Tr
                        featName={'Type of securities: hybrid'}
                        dataKey={'sd8'}
                        fundKey={'sd6'}
                        colKey={'typeSecurities'}
                        formatFunc={TypeOfSecurityFormat}
                        formatData={{
                            securityType: 'hybrid',
                        }}
                    />
                </TrProvider>
            </tbody>
        </table>
    ) : null;
};

export default SD6Table;
