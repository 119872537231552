// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list__3GwO9 {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    background: #ffffff;\n    border: 1px solid #0c3b8d;\n    border-radius: 2px;\n    width: 100%;\n    min-height: 44px;\n}\n\n.styles_list__3GwO9 li {\n    padding: 9px 20px;\n    display: flex;\n    align-items: center;\n    min-height: 44px;\n}\n\n.styles_list__3GwO9 li:not(:last-child) {\n    border-bottom: 1px solid #d6d6d6;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SelectedValuesList/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".list {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    background: #ffffff;\n    border: 1px solid #0c3b8d;\n    border-radius: 2px;\n    width: 100%;\n    min-height: 44px;\n}\n\n.list li {\n    padding: 9px 20px;\n    display: flex;\n    align-items: center;\n    min-height: 44px;\n}\n\n.list li:not(:last-child) {\n    border-bottom: 1px solid #d6d6d6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list__3GwO9"
};
export default ___CSS_LOADER_EXPORT___;
