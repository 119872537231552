import { useState } from 'react';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import { StorageType } from '../../StorageForm';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import { Button, Icon } from '../../../../../components';
import LicenseCreateForm from './components/LicenseCreateForm';
import DistributionCountriesCreateForm from './components/DistributionCountriesCreateForm';
import { Icons } from '../../../../../components/Icon/Icon';
import LocalTaxStatusCreateForm from './components/LocalTaxStatusCreateForm';

enum Forms {
    License = 'License',
    DistributionCountries = 'DistributionCountries',
    LocalTaxStatus = 'LocalTaxStatus',
}
const StorageOtherProperties = ({
    handleNextStep,
    handleClose,
    selectedData,
    generalInfo,
    setGeneralInfo,
    handleSubmit,
}: any) => {
    const [activeForm, setActiveForm] = useState<Forms | null>(null);

    const [data, setData] = useState<any>({
        evergreen: false,
        openEnded: false,
        licenses: undefined,
        distributionCountries: [],
        doubleTaxTreaties: [],
        localTaxStatus: [],
        ...selectedData,
    });

    const handleInputChange = (prop: string) => (e: any) => {
        const value = e.target.checked;

        setData({
            ...data,
            [prop]: value,
        });
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        if (prop === 'licenses') {
            value = generalInfo.licenses.filter(
                (item: any) => value.findIndex((selected: any) => item.regulatoryStatus === selected.label) > -1
            );
            value = value.map((v: any) => ({
                ...v,
                label: v.label || v.regulatoryStatus,
            }));
        }
        console.log(value);

        setData({
            ...data,
            [prop]: value,
        });

        console.log('set', {
            ...data,
            [prop]: value,
        });
    };

    const handleCreateItems = (prop: keyof typeof generalInfo, newItems: []) => () => {
        setGeneralInfo({
            ...generalInfo,
            [prop]: [...newItems, ...generalInfo[prop]],
        });

        setData({
            ...data,
            [prop]: [...newItems, ...data[prop]],
        });

        console.log('data', {
            ...data,
            [prop]: [...newItems, ...data[prop]],
        });

        setActiveForm(null);
    };

    const handleSelectData = (prop: string, value: any) => () => {
        setData({
            ...data,
            [prop]: value,
        });

        console.log('on save', {
            ...data,
            [prop]: value,
        });

        setActiveForm(null);
    };

    const handleCloseSubForm = () => {
        setActiveForm(null);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Other items (1/2)'}
                description={'Other information about this structure needed for regulatory and compliance tracking.'}
                footerMain={<Button form={'mainForm'}>Next</Button>}
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.ServiceProviders)}
            >
                <form id={'mainForm'} onSubmit={handleNextStep(data, StorageType.OtherPartTwo)}>
                    <div
                        style={{
                            width: '100%',
                            margin: 0,
                        }}
                    >
                        <Input type={'checkbox'} checked={data.openEnded} onChange={handleInputChange('openEnded')}>
                            Open ended structure?
                        </Input>
                        <Input type={'checkbox'} checked={data.evergreen} onChange={handleInputChange('evergreen')}>
                            Evergreen
                        </Input>
                    </div>
                    <Select
                        value={data.licenses}
                        onChange={handleSelectChange('licenses')}
                        options={generalInfo.licenses}
                        valueKey={'regulatoryStatusId'}
                        labelKey={'regulatoryStatus'}
                        onCreateOption={() => {
                            setActiveForm(Forms.License);
                        }}
                        isMulti={true}
                        info={'Licences and regulatory approvals obtained by the investment vehicle.'}
                        placeholder={'Choose licenses'}
                    >
                        Licenses
                    </Select>

                    <Input
                        type={'button'}
                        info={'Countries where the fund can be offered / marketed.'}
                        onClick={() => {
                            setActiveForm(Forms.DistributionCountries);
                        }}
                        value={
                            !!data.distributionCountries?.length
                                ? `(${data.distributionCountries?.length} selected)`
                                : 'Add distribution countries'
                        }
                        secondary={true}
                        outlined={true}
                        before={<Icon icon={Icons.add} />}
                    >
                        Distribution countries
                    </Input>

                    <Select
                        value={data.doubleTaxTreaties}
                        onChange={handleSelectChange('doubleTaxTreaties')}
                        options={generalInfo.doubleTaxTreaties}
                        valueKey={'dttId'}
                        labelKey={'dttTitle'}
                        isMulti={true}
                        info={'Double tax treaties that actually allow for withholding tax reductions.'}
                        placeholder={'Choose domicile'}
                    >
                        Double Tax Treaties
                    </Select>

                    <Input
                        type={'button'}
                        info={'Countries where the fund can be offered / marketed.'}
                        onClick={() => {
                            setActiveForm(Forms.LocalTaxStatus);
                        }}
                        value={
                            !!data.localTaxStatus?.length
                                ? `(${data.localTaxStatus?.length} selected)`
                                : 'Add local tax status'
                        }
                        secondary={true}
                        outlined={true}
                        before={<Icon icon={Icons.add} />}
                    >
                        Local tax status
                    </Input>
                </form>
            </FormLayout>

            {activeForm === Forms.License && (
                <LicenseCreateForm
                    countries={generalInfo.countries}
                    onSave={handleCreateItems}
                    onClose={handleCloseSubForm}
                />
            )}

            {activeForm === Forms.DistributionCountries && (
                <DistributionCountriesCreateForm
                    countries={generalInfo.countries}
                    selectedData={data.distributionCountries}
                    onSave={handleSelectData}
                    onClose={handleCloseSubForm}
                />
            )}

            {activeForm === Forms.LocalTaxStatus && (
                <LocalTaxStatusCreateForm
                    taxStatus={generalInfo.taxStatus}
                    countries={generalInfo.countries}
                    selectedData={data.localTaxStatus}
                    onSave={handleSelectData}
                    onClose={handleCloseSubForm}
                />
            )}
        </FormWrapper>
    );
};

export default StorageOtherProperties;
