import { Link } from 'react-router-dom';

import styles from './styles.module.css';

import { TaxSortedPerStructure } from '../../TaxOptimisation';
import BooleanImage from '../../../../components/BooleanImage/BooleanImage';
import { useEffect, useState } from 'react';
import { Countries, TCountryCode } from '../../../../types/general';

interface ContentProps {
    data: TaxSortedPerStructure;
    img: string;
    name: string;
    isOpen: boolean;
    collapsedOpenEnded: boolean | string | undefined;
    collapsedLegalType: string | undefined;
    collapsedOpenDomicile: string | undefined | Array<string>;
}

interface TaxBoxProps extends ContentProps {
    link: string;
    isSelecting?: boolean;
    isSelected?: boolean;
    handleSelect?: (data: TaxSortedPerStructure) => void;
}

const TaxBox = ({
    data,
    img,
    name,
    link,
    isOpen,
    collapsedOpenEnded,
    collapsedLegalType,
    collapsedOpenDomicile,
    isSelecting,
    isSelected,
    handleSelect,
}: TaxBoxProps) => {
    const [domicile, setDomicile] = useState('');

    const handleClick = () => {
        if (isSelecting && handleSelect) {
            handleSelect(data);
        }
    };

    useEffect(() => {
        setDomicile(typeof collapsedOpenDomicile === 'object' ? collapsedOpenDomicile[0] : collapsedOpenDomicile || '');
    }, [collapsedOpenDomicile]);

    const Content = ({
        data,
        img,
        name,
        isOpen,
        collapsedOpenEnded,
        collapsedLegalType,
        collapsedOpenDomicile,
    }: ContentProps) => (
        <>
            <div className={styles.imgWrapper}>
                <span className={styles.groupId}>{data.groupId}</span>
                <img src={img} alt={''} />
                <span>{name}</span>
            </div>
            <div className={styles.choice}>
                <p>
                    <span>Investment structure:</span> <span>{isOpen ? data.fund : 'various'}</span>
                </p>
                <p>
                    <span> Domicile:</span>
                    <span>
                        {!isOpen && domicile?.toLowerCase() === 'various'
                            ? collapsedOpenDomicile
                            : Countries[
                                  isOpen
                                      ? (data.domicile?.toLowerCase() as TCountryCode)
                                      : (domicile?.toLowerCase() as TCountryCode)
                              ]?.domicileName}
                    </span>
                </p>
                <p>
                    <span>Legal type: </span>
                    <span>{isOpen ? data.legalType : collapsedLegalType}</span>
                </p>
                <p>
                    <span>Open ended:</span>
                    <span>
                        {!isOpen && collapsedOpenEnded === 'various' ? (
                            collapsedOpenEnded
                        ) : (
                            <BooleanImage value={isOpen ? data.ifOpenEnded : collapsedOpenEnded === true} />
                        )}
                    </span>
                </p>
            </div>
        </>
    );

    return (
        <div
            className={`${styles.link} ${isOpen ? styles.open : styles.closed} ${
                isSelecting ? `${styles.selecting} ${isSelected ? styles.selected : ''}` : ''
            }`}
            onClick={handleClick}
        >
            {/*{isSelecting && isSelected && (*/}
            <div className={styles.selectedCheck}>
                <BooleanImage value={true} />
            </div>
            {/*)}*/}

            {!isSelecting ? (
                <Link to={`/${link}`} className={styles.content}>
                    <Content
                        data={data}
                        img={img}
                        name={name}
                        isOpen={isOpen}
                        collapsedOpenEnded={collapsedOpenEnded}
                        collapsedLegalType={collapsedLegalType}
                        collapsedOpenDomicile={collapsedOpenDomicile}
                    />
                </Link>
            ) : (
                <div className={styles.content}>
                    <Content
                        data={data}
                        img={img}
                        name={name}
                        isOpen={isOpen}
                        collapsedOpenEnded={collapsedOpenEnded}
                        collapsedLegalType={collapsedLegalType}
                        collapsedOpenDomicile={collapsedOpenDomicile}
                    />
                </div>
            )}
        </div>
    );
};

export default TaxBox;
