import styles from './aside-styles.module.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import axios from '../../../utils/axios';
import { CSSTransition } from 'react-transition-group';
import { menuTypes, useMenuStore } from '../../../store/useMenuStore';

interface AsideProps {}

const Aside = ({}: AsideProps) => {
    const [isOpen, setIsOpen, menuType] = useMenuStore((state) => [state.isOpen, state.setIsOpen, state.menuType]);

    const { user, setUser } = useContext(UserContext);

    const navigate = useNavigate();

    const logout = async (e: any) => {
        e.preventDefault();

        await axios.post('/auth/logout');
        setUser(null);
        localStorage.removeItem('t');

        navigate('/login');
    };

    return (
        <>
            <button
                className={`${styles.hamburger} ${styles[`menu-${menuType}`]} ${isOpen ? styles.active : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span />
            </button>

            <CSSTransition
                in={menuType === menuTypes.aside && isOpen}
                timeout={400}
                classNames={{
                    appear: styles.fadeAnimation,
                    appearActive: styles.fadeAnimationActive,
                    appearDone: styles.fadeAnimationActive,
                    enter: styles.fadeAnimation,
                    enterActive: styles.fadeAnimationActive,
                    enterDone: styles.fadeAnimationActive,
                    exit: styles.fadeAnimation,
                    exitActive: styles.fadeAnimation,
                    exitDone: styles.fadeAnimation,
                }}
                unmountOnExit
            >
                <div className={`${styles.wrapper}`} onClick={() => setIsOpen(false)}>
                    <aside className={styles.aside}>
                        <Link to={'/'}>
                            <img src={'/logo-white.svg'} alt={'White Thesis'} />
                        </Link>

                        <nav>
                            <NavLink to={'/deal-taxes'} className={`${!user ? styles.disabled : ''}`}>
                                Deal taxes
                            </NavLink>
                            <NavLink to={'/legal-structures'} className={`${!user ? styles.disabled : ''}`}>
                                Legal structures
                            </NavLink>
                            <NavLink to={'/contact'} className={`${!user ? styles.disabled : ''}`}>
                                Other requests{' '}
                            </NavLink>
                            <NavLink to={'/contact'}>Contact us</NavLink>

                            <NavLink to={'/account'} className={`${!user ? styles.disabled : ''} ${styles.account}`}>
                                Your account
                            </NavLink>

                            {!user && <NavLink to={'/disclaimer'}>Disclaimer</NavLink>}

                            {user && (
                                <>
                                    <a href={'/'} onClick={logout}>
                                        Log out
                                    </a>

                                    <NavLink to={'/disclaimer'}>Disclaimer</NavLink>
                                    <NavLink to={'/glossary'}>Glossary</NavLink>
                                </>
                            )}
                        </nav>
                    </aside>
                </div>
            </CSSTransition>
        </>
    );
};

export default Aside;
