import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';
import { TaxOptimisationSelectedData } from '../../TaxOptimisation';

//TaxOptimisationSelectedData
//    initialInvestment: number;
//     returnOnAsset: number;
//     distribution: number;

interface BaseInputsProps {
    selectedData: TaxOptimisationSelectedData;
    setSelectedData: (prop: string, value: number) => void;
}

const BaseInputs = ({ selectedData, setSelectedData }: BaseInputsProps) => {
    const [initialInvestment, setInitialInvestment] = useState(`$${selectedData.initialInvestment}`);
    const [returnOnAsset, setReturnOnAsset] = useState(`${selectedData.returnOnAsset}%`);
    const [distribution, setDistribution] = useState(`$${selectedData.distribution}`);

    useEffect(() => {
        handleDistributionValue(selectedData.distribution);
    }, [selectedData.initialInvestment]);

    const handleDistributionValue = (value: number) => {
        const percent = (value / selectedData.initialInvestment) * 100;
        const max = Math.floor((selectedData.initialInvestment / 100) * 80);
        value = Math.min(value, max);

        setDistribution(`$${value}`);
        setSelectedData('distribution', value);
    };

    const handleInputChange = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = +e.target.value.replace(/\D/g, '');

        if (value < 0) value = 0;

        if (prop === 'initialInvestment' || prop === 'returnOnAsset') {
            if (value === 0) value = 1;

            if (prop === 'initialInvestment') {
                value = Math.min(value, 1000000);

                setInitialInvestment(`$${value}`);
            } else {
                value = Math.min(value, 100);

                setReturnOnAsset(`${value}%`);
            }
            setSelectedData(prop, value);
        } else if (prop === 'distribution') {
            handleDistributionValue(value);
        }
    };

    return (
        <div className={styles.content}>
            <h3 className={styles.title}>
                These <span>tax proposals</span> are for:
            </h3>

            <form action="" className={styles.form}>
                <div>
                    <label>
                        <span>Initial investment:</span>
                        <input
                            type="text"
                            value={initialInvestment}
                            onChange={handleInputChange('initialInvestment')}
                        />
                    </label>
                    <label>
                        <span>Return on asset:</span>
                        <input type="text" value={returnOnAsset} onChange={handleInputChange('returnOnAsset')} />
                    </label>
                    <label>
                        <span>Distribution:</span>
                        <input type="text" value={distribution} onChange={handleInputChange('distribution')} />
                    </label>
                    <p>
                        Investment horizon: <span>1 year</span>
                    </p>
                </div>

                <img src="/icons/arrow-icon.svg" alt="" />
            </form>
        </div>
    );
};

export default BaseInputs;
