import styles from './tips.module.css';

import React from 'react';

const Tips = () => {
    return (
        <>
            <div className={`${styles.graphTip} ${styles.initial}`}>
                Cost to launch <span className={styles.footnote}>1</span>
            </div>
            <div className={`${styles.graphTip} ${styles.nav}`}>
                Annual cost for $100m <span className={styles.footnote}>1</span>
            </div>
            <div className={`${styles.graphTip} ${styles.taxed}`}>Complexity</div>

            <div className={`${styles.graphTip} ${styles.days}`}>
                Days to launch <span className={styles.footnote}>2</span>
            </div>
        </>
    );
};

export default Tips;
