import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormCollapsableRow from '../../../layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../../../components/Input/Input';
import SelectedValuesList from '../../../../../components/SelectedValuesList/SelectedValuesList';

const StorageOther = ({ selectedData, onClose }: any) => {
    return (
        <FormWrapper>
            <FormLayout
                title={'Other items'}
                description={'Other information about this structure needed for regulatory and compliance tracking.'}
                onClose={onClose}
            >
                <FormCollapsableRow titleLabel={`Licences, regulations, Laws, distribution`} titleValue={``}>
                    <SelectedValuesList values={selectedData.licenses.map((item: any) => item.regulatoryStatus)}>
                        Licences
                    </SelectedValuesList>

                    <br />

                    <SelectedValuesList values={selectedData.countries.map((item: any) => item.domicile)}>
                        Distribution countries
                    </SelectedValuesList>

                    <br />

                    <SelectedValuesList values={selectedData.laws.map((item: any) => item.laws)}>
                        Relevant laws And regulations
                    </SelectedValuesList>
                </FormCollapsableRow>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageOther;
