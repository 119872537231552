import { useState } from 'react';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import { StorageType } from '../../StorageForm';
import { Button } from '../../../../../components';
import { AssetManagerForm } from './StorageAssetManager';
import ServiceProviderSingle from './components/ServiceProviderSingle';

const StorageServiceProviders = ({ selectedData, generalInfo, handleNextStep, handleClose }: any) => {
    const [additionalRoles, setAdditionalRoles] = useState<any[]>([]);
    const [showCreateForm, setShowCreateForm] = useState<undefined | null | AssetManagerForm>();

    const [data, setData] = useState<any[]>(selectedData ? [...selectedData] : [{}]);

    const handleAddItem = () => {
        setData([...data, {}]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...data];
        tmp.splice(index, 1);
        setData(tmp);
    };

    const handleShowCreateForm = (type: AssetManagerForm) => () => {
        setShowCreateForm(type);
    };

    const handleItemUpdate = (index: number) => (item: any) => {
        const tmp = [...data];
        tmp[index] = item;
        setData(tmp);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Services providers'}
                description={'Please fill in information about service provider(s).'}
                footerAdditional={
                    <Button outlined={true} type={'button'} onClick={handleAddItem}>
                        Add line
                    </Button>
                }
                footerMain={<Button form={'mainForm'}>Next</Button>}
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.AssetManager)}
            >
                <form id={'mainForm'} onSubmit={handleNextStep(data, StorageType.Other)}>
                    {data.map((item: any, index: number) => (
                        <ServiceProviderSingle
                            key={index}
                            selectedData={item}
                            generalInfo={generalInfo}
                            handleRemoveItem={handleRemoveItem(index)}
                            handleShowCreateForm={handleShowCreateForm}
                            onUpdate={handleItemUpdate(index)}
                            additionalRoles={additionalRoles}
                        />
                    ))}
                </form>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageServiceProviders;
