import styles from '../selected-combination.module.css';
import { LegalStructuresSelectedData } from '../../../pages/LegalStructures/LegalStructures';
import { DealTaxesSelectedData } from '../../../pages/DealTaxes/DealTaxes';
import { TaxOptimisationSelectedData } from '../../../pages/TaxOptimisation/TaxOptimisation';
import { GraphTypes } from '../../../types/general';
import { DealTaxesCheckboxes, isDealTaxesCheckboxAvailable } from '../../../utils/deal-taxes-checkboxes';

export interface AdditionalParamsProps {
    selectedData: LegalStructuresSelectedData | DealTaxesSelectedData | TaxOptimisationSelectedData;
    // selectedData: DealTaxesSelectedData | TaxOptimisationSelectedData;
    graphType?: GraphTypes;
    selectionType?: SELECTION_TYPE;
}

interface RowProps {
    keyValue: string;
    positiveValue: string;
    negativeValue?: string;
    selectedData: LegalStructuresSelectedData | DealTaxesSelectedData | TaxOptimisationSelectedData;
    graphType?: GraphTypes;
}

const Row = ({ keyValue, positiveValue, negativeValue, selectedData, graphType }: RowProps) => {
    return (
        <>
            {keyValue in selectedData &&
                (graphType !== GraphTypes.Taxes ||
                    (graphType === GraphTypes.Taxes &&
                        keyValue in DealTaxesCheckboxes &&
                        isDealTaxesCheckboxAvailable(
                            keyValue as DealTaxesCheckboxes,
                            selectedData as DealTaxesSelectedData
                        ))) && (
                    <>
                        {
                            // @ts-ignore
                            (selectedData[keyValue] === true || selectedData[keyValue] === 'true') && (
                                <div className={`${styles.labelSm} `}>
                                    <span>{positiveValue}</span>
                                </div>
                            )
                        }

                        {negativeValue &&
                            // @ts-ignore
                            (selectedData[keyValue] === false || selectedData[keyValue] === 'false') && (
                                <div className={`${styles.labelSm} `}>
                                    <span>{negativeValue}</span>
                                </div>
                            )}
                    </>
                )}
        </>
    );
};

export enum SELECTION_TYPE {
    investor = 'investor',
    fof = 'fof',
    fund = 'fund',
    asset = 'asset',
}

const getUkLabel = (
    value: boolean,
    selectedData: LegalStructuresSelectedData | DealTaxesSelectedData | TaxOptimisationSelectedData,
    // selectedData: DealTaxesSelectedData | TaxOptimisationSelectedData,
    openEndedKey: string,
    corporateKey: string
) => {
    return openEndedKey in selectedData &&
        // @ts-ignore
        selectedData[openEndedKey] &&
        corporateKey in selectedData &&
        // @ts-ignore
        selectedData[corporateKey]
        ? value
            ? 'reporting'
            : 'non-reporting'
        : value
        ? 'investing'
        : 'trading';
};

const AdditionalPdfRows = ({ selectedData, graphType, selectionType }: AdditionalParamsProps) => {
    const isRowVisible = (type: SELECTION_TYPE) => {
        return !selectionType || (selectionType && selectionType === SELECTION_TYPE[type]);
    };

    return (
        <div
            style={{
                margin: '0 0 24px',
            }}
        >
            {isRowVisible(SELECTION_TYPE.fund) && (
                <>
                    <Row
                        keyValue={'selfManaged'}
                        positiveValue={'self - managed'}
                        negativeValue={'not self - managed'}
                        selectedData={selectedData}
                        graphType={graphType}
                    />
                    <Row
                        keyValue={'openEnded'}
                        positiveValue={'open - ended'}
                        negativeValue={'closed - ended'}
                        selectedData={selectedData}
                        graphType={graphType}
                    />
                    <Row
                        keyValue={'corporate'}
                        positiveValue={'corporate'}
                        negativeValue={'partnership'}
                        selectedData={selectedData}
                        graphType={graphType}
                    />

                    <Row
                        keyValue={'noFixedCapital'}
                        positiveValue={'no fixed capital (e.g. SICAF)'}
                        negativeValue={'fixed capital (e.g. SICAF)'}
                        selectedData={selectedData}
                        graphType={graphType}
                    />

                    <Row
                        keyValue={'ifCommercialPartnership'}
                        positiveValue={'commercial partnership'}
                        negativeValue={'non-commercial partnership'}
                        selectedData={selectedData}
                        graphType={graphType}
                    />
                    <Row
                        keyValue={'ukInvesting'}
                        positiveValue={getUkLabel(true, selectedData, 'openEnded', 'corporate')}
                        negativeValue={getUkLabel(false, selectedData, 'openEnded', 'corporate')}
                        selectedData={selectedData}
                        graphType={graphType}
                    />
                </>
            )}

            {/*{isRowVisible(SELECTION_TYPE.fof) && 'currentFoF' in selectedData && selectedData.currentFoF && (*/}
            {/*    <>*/}
            {/*        <Row*/}
            {/*            keyValue={'fofOpenEnded'}*/}
            {/*            positiveValue={'open - ended'}*/}
            {/*            negativeValue={'closed - ended'}*/}
            {/*            selectedData={selectedData}*/}
            {/*            graphType={graphType}*/}
            {/*        />*/}
            {/*        <Row*/}
            {/*            keyValue={'fofCorporate'}*/}
            {/*            positiveValue={'corporate'}*/}
            {/*            negativeValue={'partnership'}*/}
            {/*            selectedData={selectedData}*/}
            {/*            graphType={graphType}*/}
            {/*        />*/}
            {/*        <Row*/}
            {/*            keyValue={'fofIfCommercialPartnership'}*/}
            {/*            positiveValue={'commercial partnership'}*/}
            {/*            negativeValue={'non-commercial partnership'}*/}
            {/*            selectedData={selectedData}*/}
            {/*            graphType={graphType}*/}
            {/*        />*/}
            {/*        <Row*/}
            {/*            keyValue={'fofUkInvesting'}*/}
            {/*            positiveValue={getUkLabel(true, selectedData, 'fofOpenEnded', 'fofCorporate')}*/}
            {/*            negativeValue={getUkLabel(false, selectedData, 'fofOpenEnded', 'fofCorporate')}*/}
            {/*            selectedData={selectedData}*/}
            {/*            graphType={graphType}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*)}*/}

            {isRowVisible(SELECTION_TYPE.investor) && (
                <>
                    <Row
                        keyValue={'booked'}
                        positiveValue={'booked at NAV'}
                        negativeValue={'booked at cost'}
                        selectedData={selectedData}
                    />

                    <Row
                        keyValue={'ifBusinessAssets'}
                        positiveValue={'business assets'}
                        negativeValue={'non-business assets'}
                        selectedData={selectedData}
                        graphType={graphType}
                    />
                </>
            )}
        </div>
    );
};

export default AdditionalPdfRows;
