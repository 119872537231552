// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen-sm": "(max-width: 970px)",
	"screen-md": "(min-width: 971px) and (max-width: 1919px)",
	"screen-md-max": "(max-width: 1919px)",
	"screen-lg": "(min-width: 1920px) and (max-width: 4479px)",
	"screen-lg-min": "(min-width: 1920px)",
	"screen-xl": "(min-width: 4480px)"
};
export default ___CSS_LOADER_EXPORT___;
