import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';
import { useStorageStore } from '../../../../../../store/useStorageStore';
import { StorageNestedTypes } from '../../../../../../types/storage';
import { Icon } from '../../../../../../components';
import { Icons } from '../../../../../../components/Icon/Icon';

interface StorageBoxGroupProps {
    children: React.ReactNode;
    onClick?: () => void;
    type?: StorageNestedTypes;
    isHorizontal?: boolean;
    isAbsolute?: boolean;
    isOpen?: boolean;
    name?: string;
    icon?: Icons;
    isMainStructure?: boolean;
    isGP?: boolean;
    hasYOffest?: boolean;
    isOther?: boolean;
    isDisabled?: boolean;
    isBottom?: boolean;
}
const StorageBoxGroup = ({
    name,
    icon,
    isOpen,
    type,
    children,
    onClick,
    isHorizontal,
    isAbsolute,
    isMainStructure,
    isGP,
    hasYOffest,
    isOther,
    isDisabled,
    isBottom,
}: StorageBoxGroupProps) => {
    const [openType] = useStorageStore((state) => [state.openType]);

    // const [isOpen, setIsOpen] = useState(false);
    //
    // useEffect(() => {
    //     setIsOpen(type === openType);
    // }, [openType]);

    return (
        <div
            className={`${styles.box} 
            ${isMainStructure || isGP || isOther ? '' : isHorizontal ? styles.horizontal : styles.vertical} 
            ${isOpen && !isDisabled ? styles.open : ''} 
            ${hasYOffest ? styles.yOffest : ''}
            ${isOther || isGP ? styles.smaller : ''}
            `}
        >
            <div
                className={`${styles.boxInner} 
                ${isMainStructure || isGP || isOther ? '' : isHorizontal ? styles.horizontal : styles.vertical} 
                ${!isDisabled && isOpen ? styles.open : ''} 
                ${isAbsolute ? styles.absolute : ''}
                ${isBottom ? styles.bottom : ''}
                `}
                onClick={onClick}
            >
                {children}
            </div>
        </div>
    );
};

export default StorageBoxGroup;
