import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SelectionResult from './SelectionResult/SelectionResult';
import { useDiagramStore } from '../../store/useDiagramStore';
import SelectionPopupBase from './SelectionPopupBase/SelectionPopupBase';
import { SelectionLevel, TCountryCode } from '../../types/general';
import SelectionPopupRowMulti from './SelectionPopupRowMulti/SelectionPopupRowMulti';
import SelectionPopupRowSingle from './SelectionPopupRowSingle/SelectionPopupRowSingle';
import { Button, Icon } from '../index';
import { Icons } from '../Icon/Icon';
import { getValidAllocation } from '../../utils/asset-allocation';

interface SelectionPopupProps {
    isMulti?: boolean;
    onSubmit: (selectedData: any) => Promise<void>;
    validateSelection: any;
    // useInvestmentStructure: boolean;
    // type: GraphTypes;
}

export interface SelectedDataParamsAsset {
    currentAsset: number | null;
    currentAssetTaxId: number | null;
    assetClass?: string | null;
    assetCountry: TCountryCode | null;
}

export interface SelectedDataParamsInvestor {
    currentInvestor: number | null;
    investorCountry: TCountryCode | null;
}

export interface SelectedDataParams extends SelectedDataParamsInvestor, SelectedDataParamsAsset {
    currentFund?: number | null;
    currentFoF?: number | null;
    investmentFundCountry?: TCountryCode | null;
    fofCountry?: TCountryCode | null;
}

export interface SelectedDataFund extends SelectedDataMulti {
    investmentFund: SelectedItem;
    fundOfFunds?: SelectedItem;
}

export interface SelectedItem {
    id: number | undefined;
    name: string | undefined;
    countryCode: TCountryCode | undefined;
    countryName: string | undefined;
    countryId: string | number | undefined;
}

export interface SelectedItemExtended extends SelectedItem {
    assetAllocation: number;
}

export interface SelectedItemAsset extends SelectedItemExtended {
    currentAssetTaxId: number | undefined;
}

export interface SelectedDataSingle {
    investor: SelectedItemExtended;
    asset: SelectedItemAsset;
}

export interface SelectedDataMulti {
    investors: SelectedItemExtended[];
    assets: SelectedItemAsset[];
}

const SelectionPopup = ({ onSubmit, isMulti = true, validateSelection }: SelectionPopupProps) => {
    const [
        investors,
        investmentFunds,
        assets,
        setInvestors,
        setInvestmentFunds,
        fundOfFunds,
        setFundOfFunds,
        setAssets,
        useInvestmentStructure,
        countries,
        getCountries,
        fundCountries,
    ] = useDiagramStore((state) => [
        state.investors,
        state.investmentFunds,
        state.assets,
        state.setInvestors,
        state.setInvestmentFunds,
        state.fundOfFunds,
        state.setFundOfFunds,
        state.setAssets,
        state.useInvestmentStructure,
        state.countries,
        state.getCountries,
        state.fundCountries,
    ]);
    const [searchParams, setSearchParams] = useSearchParams();

    const [useFoF, setUseFoF] = useState(false);

    const [ready, setReady] = useState(false);

    const [isCompleteSelection, setIsCompleteSelection] = useState(false);
    const [isValidSelection, setIsValidSelection] = useState(false);
    const [showInvalidPopup, setShowInvalidPopup] = useState(false);

    const [focusedType, setFocusedType] = useState(SelectionLevel.Investor);

    const getSelectedDataFromContext = (): SelectedDataSingle | SelectedDataMulti | SelectedDataFund => {
        const item: SelectedItem | SelectedItemAsset = {
            id: undefined,
            name: undefined,
            countryCode: undefined,
            countryName: undefined,
            countryId: undefined,
            assetAllocation: 0,
        };

        let data: SelectedDataSingle | SelectedDataMulti | SelectedDataFund = isMulti
            ? ({
                  investors: [],
                  assets: [],
              } as SelectedDataMulti)
            : ({
                  investor: item,
                  asset: item,
              } as SelectedDataSingle);

        if (useInvestmentStructure) {
            data = {
                ...data,
                investmentFund: item,
                fundOfFunds: undefined,
            } as SelectedDataFund;
        }

        return data;
    };

    const [selectedData, setSelectedData] = useState<SelectedDataMulti | SelectedDataSingle | SelectedDataFund>({
        ...getSelectedDataFromContext(),
    });

    useEffect(() => {
        (async () => {
            await getCountries();

            let currentInvestors, currentAssets;
            let currentFund: string | null = null;
            let currentFoF: string | null = null;

            if (isMulti) {
                currentInvestors = searchParams.get('investors');
                currentAssets = searchParams.get('assets');
            } else {
                currentInvestors = searchParams.get('investor');
                currentAssets = searchParams.get('asset');
            }

            if (useInvestmentStructure) {
                currentFoF = searchParams.get('fundOfFunds');
                currentFund = searchParams.get('investmentFund');
            }

            if (!currentInvestors || !currentAssets || (useInvestmentStructure && !currentFund)) {
                setTimeout(() => {
                    setReady(true);
                }, 400);

                // setInit(false);
                return;
            }

            const params: SelectedDataSingle | SelectedDataMulti | SelectedDataFund = {
                investors: JSON.parse(currentInvestors),
                assets: JSON.parse(currentAssets),
            };

            if (useInvestmentStructure) {
                (params as SelectedDataFund).investmentFund = JSON.parse(currentFund!) as SelectedItem;

                if (currentFoF) {
                    (params as SelectedDataFund).fundOfFunds = JSON.parse(currentFoF);
                }
            }

            setUseFoF(useInvestmentStructure && !!currentFoF);

            setSelectedData(params);

            for (const item of JSON.parse(currentAssets)) {
                await setAssets(item.countryCode);
            }

            for (const item of JSON.parse(currentInvestors)) {
                await setInvestors(item.countryCode);
            }

            if (useInvestmentStructure) {
                await setInvestmentFunds(JSON.parse(currentFund!).countryCode);
            }

            // setInit(false);
            setTimeout(() => {
                setReady(true);
            }, 400);
        })();
    }, []);

    function isEmpty(obj: any): boolean {
        if (Array.isArray(obj)) {
            for (const objElement of obj) {
                if (isEmpty(objElement)) return true;
            }
        } else {
            for (let key in obj) {
                if (typeof obj[key] === 'undefined' || obj[key] === null) {
                    return key !== 'fundOfFunds';
                } else if (obj[key] instanceof Object || Array.isArray(obj[key])) {
                    //if the value is 'object'
                    if (isEmpty(obj[key])) return true;
                } else {
                    //if value is string/number/array
                    //if array or string have length is not 0.
                    if (obj[key].toString().length === 0) return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        const empty = isEmpty(selectedData);
        // console.log('empty', empty, selectedData);

        setIsCompleteSelection(!empty);
    }, [selectedData]);

    const handleFormSubmission = async (event: { preventDefault: () => void }) => {
        event.preventDefault();

        const tmp = {
            ...selectedData,
        };

        if ('assets' in tmp) {
            const lastAsset = tmp.assets[tmp.assets.length - 1];
            lastAsset.assetAllocation = getValidAllocation({
                currentValue: lastAsset.assetAllocation,
                value: 100,
                ungroupedData: tmp.assets,
            });
        }

        await onSubmit(tmp);
    };

    const toggleFoF = () => {
        setSelectedData({
            ...selectedData,
            fundOfFunds: {
                id: undefined,
                name: undefined,
                countryCode: undefined,
                countryName: undefined,
                countryId: undefined,
            },
        });
        setUseFoF(true);
    };

    const clearFoF = () => {
        setSelectedData({
            ...selectedData,
            fundOfFunds: undefined,
        });
        setUseFoF(false);
    };

    const setSelectedDataByType = (key: any, type: SelectionLevel) => async (data: any) => {
        const tmp = {
            ...selectedData,
            [key]: data,
        };
        setSelectedData(tmp);

        console.log(key);

        try {
            await validateSelection(tmp);
            setIsValidSelection(true);
        } catch (e) {
            console.log(e);
            setIsValidSelection(false);
            setShowInvalidPopup(true);
        }

        setFocusedType(type);
    };

    return (
        <SelectionPopupBase
            ready={ready}
            onSubmit={handleFormSubmission}
            submitDisabled={!isValidSelection || !isCompleteSelection}
            showError={!isValidSelection && showInvalidPopup}
            setShowError={setShowInvalidPopup}
            useInvestmentStructure={useInvestmentStructure}
            selectionResult={
                <SelectionResult
                    selectedData={selectedData}
                    investors={investors}
                    investmentFunds={investmentFunds}
                    fundOfFunds={fundOfFunds}
                    assets={assets}
                    useInvestmentStructure={useInvestmentStructure}
                    useFoF={useFoF}
                />
            }
        >
            {isMulti ? (
                <SelectionPopupRowMulti
                    isMulti={true}
                    type={SelectionLevel.Investor}
                    onSelect={setSelectedDataByType('investors', SelectionLevel.Investor)}
                    options={investors}
                    countries={countries}
                    initData={'investors' in selectedData ? selectedData.investors : undefined}
                    focusedType={focusedType}
                />
            ) : (
                <SelectionPopupRowSingle
                    type={SelectionLevel.Investor}
                    options={investors}
                    countries={countries}
                    onSelect={setSelectedDataByType('investor', SelectionLevel.Investor)}
                    initData={'investor' in selectedData ? selectedData.investor : undefined}
                />
            )}

            {useInvestmentStructure && (
                <>
                    {useFoF && (
                        <SelectionPopupRowSingle
                            type={SelectionLevel.FundOfFunds}
                            options={fundOfFunds}
                            countries={fundCountries}
                            onSelect={setSelectedDataByType('fundOfFunds', SelectionLevel.FundOfFunds)}
                            initData={'fundOfFunds' in selectedData ? selectedData.fundOfFunds : undefined}
                        >
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    width: 200,
                                }}
                            >
                                <Button
                                    onClick={clearFoF}
                                    type={'button'}
                                    secondary={true}
                                    after={<Icon icon={Icons.closeRounded} />}
                                    fullWidth={true}
                                    align={'space-between'}
                                >
                                    Fund of funds
                                </Button>
                            </div>
                        </SelectionPopupRowSingle>
                    )}

                    <SelectionPopupRowSingle
                        type={SelectionLevel.Fund}
                        options={investmentFunds}
                        countries={fundCountries}
                        onSelect={setSelectedDataByType('investmentFund', SelectionLevel.Fund)}
                        initData={'investmentFund' in selectedData ? selectedData.investmentFund : undefined}
                    >
                        <div
                            style={{
                                marginLeft: 'auto',
                                width: 200,
                            }}
                        >
                            {!useFoF ? (
                                <Button
                                    onClick={toggleFoF}
                                    type={'button'}
                                    secondary={true}
                                    disabled={'investmentFund' in selectedData && !selectedData.investmentFund}
                                    before={<Icon icon={Icons.add} />}
                                    fullWidth={true}
                                    align={'space-between'}
                                >
                                    Add fund of funds
                                </Button>
                            ) : (
                                <Button
                                    onClick={clearFoF}
                                    type={'button'}
                                    secondary={true}
                                    fullWidth={true}
                                    align={'space-between'}
                                    after={<Icon icon={Icons.closeRounded} />}
                                >
                                    Investment vehicle
                                </Button>
                            )}
                        </div>
                    </SelectionPopupRowSingle>
                </>
            )}

            {isMulti ? (
                <SelectionPopupRowMulti
                    isMulti={false}
                    type={SelectionLevel.Asset}
                    options={assets}
                    countries={countries}
                    onSelect={setSelectedDataByType('assets', SelectionLevel.Asset)}
                    initData={'assets' in selectedData ? selectedData.assets : undefined}
                    focusedType={focusedType}
                />
            ) : (
                <SelectionPopupRowSingle
                    type={SelectionLevel.Asset}
                    options={assets}
                    countries={countries}
                    onSelect={setSelectedDataByType('asset', SelectionLevel.Asset)}
                    initData={'asset' in selectedData ? selectedData.asset : undefined}
                />
            )}

            {/*<SelectionProgress*/}
            {/*    useInvestmentStructure={useInvestmentStructure}*/}
            {/*    selectedData={selectedData}*/}
            {/*    useFoF={useFoF}*/}
            {/*/>*/}
        </SelectionPopupBase>
    );
};

export default SelectionPopup;
