import styles from './storage.module.css';
import { LinkedButton } from '../../../components/Button';
import StorageContent from './components/StorageContent';

const Storage = () => {
    return (
        <>
            <div className={styles.page}>
                <div className={styles.link}>
                    <div className={styles.imgWrapper}>
                        <img src="/images/thumb-storage-new.png" alt="" />
                    </div>

                    <LinkedButton to={'/storage/create'}>Add new</LinkedButton>
                </div>

                <StorageContent />
            </div>
        </>
    );
};

export default Storage;
