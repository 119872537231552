const PeopleFormat = (d: any) => {
    const num = parseInt(d);
    if (!d || (d && isNaN(num))) return '-';

    let items = [];
    for (let i = 0; i < num; i++) {
        items.push('/icons/user.svg');
    }

    return items.map((src, index) => <img key={index} alt={''} src={src} />);
};

export default PeopleFormat;
