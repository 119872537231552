// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info_info__5G33f {\n    white-space: nowrap;\n    font-size: 24px;\n    font-weight: 400;\n}\n\n.info_highlight__HuDVb {\n    color: var(--primary-color);\n    font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/LegalStructures/components/Info/info.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".info {\n    white-space: nowrap;\n    font-size: 24px;\n    font-weight: 400;\n}\n\n.highlight {\n    color: var(--primary-color);\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "info_info__5G33f",
	"highlight": "info_highlight__HuDVb"
};
export default ___CSS_LOADER_EXPORT___;
