import create from 'zustand';
import axios from '../utils/axios';
import { useStorageStore } from './useStorageStore';
import {
    StorageNestedTypes,
    StorageServiceProvider,
    StorageServiceProviderParams,
    StorageServiceProviderSelection,
} from '../types/storage';
import { STORAGE_SERVICE_PROVIDERS_API } from '../utils/constants';

interface Domicile {
    domicileName: string;
}

interface StorageStore {
    children: StorageNestedTypes[];

    clearDeleted: () => void;

    serviceProviders: StorageServiceProvider[];
    getProviders: (params: StorageServiceProviderParams) => Promise<StorageServiceProvider[]>;
    setProviders: (id: number, data: StorageServiceProviderSelection) => void;
}

export const useStorageServiceProviderStore = create<StorageStore>((set, get) => ({
    children: [],
    serviceProviders: [],
    getProviders: async (params: StorageServiceProviderParams): Promise<StorageServiceProvider[]> => {
        const { data } = await axios.get(`${STORAGE_SERVICE_PROVIDERS_API}`, {
            params,
        });

        console.log('set serviceProviders', data);

        set({
            serviceProviders: data,
        });

        return data;
    },
    setProviders: async (id: number, data: StorageServiceProviderSelection) => {
        // let sp: undefined | StorageServiceProvider = undefined;

        const { data: savedSP } = await axios.post(`${STORAGE_SERVICE_PROVIDERS_API}`, data);

        const providers = get().serviceProviders.map((item) => {
            if (item.serviceProvidersId === id) {
                // item.selection = data;
                item.id = savedSP.id;
                item = { ...item, ...data };
                // data = item;
            }
            console.log('SP', item, data);
            return item;
        });

        set({
            serviceProviders: providers,
        });
    },
    clearDeleted: () => {
        const itemsToRemove = useStorageStore.getState().itemsToDelete.serviceProvider;
        const tmp = get().serviceProviders;

        for (const serviceProvider of itemsToRemove) {
            const index = tmp.findIndex((i) => i.id === serviceProvider.id);

            tmp[index] = {
                serviceProvidersId: serviceProvider.serviceProvidersId,
                serviceProviders: serviceProvider.serviceProviders,
                domicileId: serviceProvider.domicileId,
                domicileName: serviceProvider.domicileName,

                investmentFund: serviceProvider.investmentFund,
                investmentFundId: serviceProvider.investmentFundId,
            };
        }

        set({
            serviceProviders: tmp,
        });
    },
}));
