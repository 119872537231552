import { SankeyFundNode } from '../../../types/deal-taxes';

interface NodeProps {
    node: SankeyFundNode;
}

const FundNode = ({ node }: NodeProps) => {
    const highlightColor = '#e5ebf5';
    const color = !node.category || node.text ? '#fbfbfb' : highlightColor;

    return (
        <g transform={`translate(${node.x}, ${node.y})`} className={node.className}>
            <rect
                x={!node.text ? 0 : 2}
                y={0}
                width={!node.text ? node.width : node.width - 4}
                height={node.height}
                rx="6"
                fill={color}
                strokeWidth={!node.text ? '' : 2}
                stroke={!node.text ? '' : '#7faaf3'}
            />
            <text
                x={node.width / 2}
                y={17}
                dx={node.width < 80 ? '2.5em' : '0'}
                fontSize={12}
                textAnchor="middle"
                fontWeight={600}
                fill={!node.text ? '#ffffff' : '#000000'}
            >
                {node.text}
            </text>
        </g>
    );
};

export default FundNode;
