import styles from './button-styles.module.css';
import React from 'react';

interface ButtonProps {
    children?: React.ReactNode;
    type?: 'button' | 'submit' | 'reset' | undefined;
    fullWidth?: boolean;
    outlined?: boolean;
    secondary?: boolean;
    onClick?: (_: any) => any;
    disabled?: boolean;
    disabledLight?: boolean;
    before?: React.ReactNode;
    after?: React.ReactNode;
    form?: string;
    align?: 'left' | 'center' | 'right' | 'space-between';
    color?: 'neutral';
    minWidth?: boolean;
}

const Button = ({
    type,
    children,
    before,
    after,
    outlined,
    disabledLight,
    secondary,
    onClick,
    disabled,
    fullWidth,
    align,
    color,
    minWidth,
    ...props
}: ButtonProps) => {
    return (
        <button
            type={type}
            className={`${styles.btn} 
            ${outlined ? styles.outline : ''} 
            ${secondary ? styles.secondary : ''} 
            
            ${color ? styles[`color-${color}`] : ''}
            
            ${fullWidth ? styles.fullWidth : ''} 
            ${minWidth ? styles.minWidth : ''} 
            ${align ? styles[`align-${align}`] : ''}
            ${disabledLight ? styles.disabledLight : ''}
            
            ${before || after ? styles.withIcon : ''}
           `}
            disabled={disabled}
            onClick={onClick}
            {...props}
        >
            {before && before}
            <span>{children}</span>
            {after && after}
        </button>
    );
};

export default Button;
