import TableHeader from '../components/TableHeader';
import { TrServiceProvider } from '../components';

import { useTableStore } from '../../../store/useTableStore';

const SD14Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd14 ? (
        <table>
            <TableHeader sdKey={'sd14'} excludeFeat={true} />

            <tbody>
                <TrServiceProvider />
            </tbody>
        </table>
    ) : null;
};

export default SD14Table;
