const CheckGroupsIcon = () => {
    return (
        <svg
            id="Component_243_10"
            data-name="Component 243 – 10"
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
        >
            <g
                id="Ellipse_23"
                data-name="Ellipse 23"
                fill="var(--icon-fill-color)"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                <circle cx="18.5" cy="18.5" r="17.75" fill="none" />
            </g>
            <g
                id="Ellipse_1"
                data-name="Ellipse 1"
                transform="translate(19 5)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="6.25" cy="6.25" r="6.25" stroke="none" />
                <circle cx="6.25" cy="6.25" r="5.5" fill="none" />
            </g>
            <g
                id="Ellipse_2"
                data-name="Ellipse 2"
                transform="translate(19 19)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="6.25" cy="6.25" r="6.25" stroke="none" />
                <circle cx="6.25" cy="6.25" r="5.5" fill="none" />
            </g>
            <g
                id="Ellipse_3"
                data-name="Ellipse 3"
                transform="translate(5 5)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="6.25" cy="6.25" r="6.25" stroke="none" />
                <circle cx="6.25" cy="6.25" r="5.5" fill="none" />
            </g>
            <g
                id="Ellipse_40"
                data-name="Ellipse 40"
                transform="translate(5 19)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="6.25" cy="6.25" r="6.25" stroke="none" />
                <circle cx="6.25" cy="6.25" r="5.5" fill="none" />
            </g>
            <g id="Group_6247" data-name="Group 6247" transform="translate(-1115 -3678)">
                <line
                    id="Line_7"
                    data-name="Line 7"
                    x1="6.5"
                    y2="6.5"
                    transform="translate(1137 3700)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
                <line
                    id="Line_8"
                    data-name="Line 8"
                    x2="6.5"
                    y2="6.5"
                    transform="translate(1137 3700)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
            </g>
            <rect
                id="Rectangle_986"
                data-name="Rectangle 986"
                width="2.248"
                height="4"
                transform="translate(16.371 6.001) rotate(45)"
                fill="var(--icon-fill-color)"
            />
            <path
                id="Path_3194"
                data-name="Path 3194"
                d="M7669.256,5108.606l-4.354,4.354-1.923,1.923-3.222-3.223"
                transform="translate(-7651.256 -5102.605)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default CheckGroupsIcon;
