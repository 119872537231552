import styles from './Input.module.css';
import { useState } from 'react';
import { Icon } from '../index';
import { Icons } from '../Icon/Icon';

const InputLabel = ({
    children,
    label,
    info,
    fullWidth = true,
    extended,
    labelFullWidth,
    align,
    required,
    isActive,
    isTitleActive,
    style,
    max,
    valueLength,
}: any) => {
    return (
        <label
            className={`${styles.container} 
            ${!!label ? styles.offset : ''} 
            ${isActive ? styles.active : ''} 
            ${isTitleActive ? styles.activeTitle : ''}
            ${!!info ? styles.hasInfo : ''}
            ${extended ? styles.extended : ''}
            ${align ? styles[`align-${align}`] : ''}
            ${fullWidth ? styles.fullWidth : ''}
            ${style === 'text' ? styles.textStyle : ''}
            ${style === 'secondary' ? styles.secondary : ''}
            `}
        >
            {!!label && (
                <span
                    className={`${styles.label}
                            ${labelFullWidth ? styles.labelFullWidth : ''}
                            `}
                >
                    {label}
                </span>
            )}

            {!!children && (
                <div className={styles.inputWrapper}>
                    {children}

                    {style === 'text' && (
                        <>
                            <span className={styles.icon}>
                                <Icon icon={Icons.pen} />
                            </span>

                            <span className={styles.bg}></span>
                        </>
                    )}
                </div>
            )}

            {!!info && <p>{info}</p>}

            {required && !max && <span className={styles.note}>*required</span>}

            {!!max && (
                <span className={styles.max}>
                    <span>Characters</span>
                    <span>
                        {valueLength} / {max}
                    </span>
                </span>
            )}
        </label>
    );
};

export default InputLabel;
