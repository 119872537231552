import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Button, Response } from '../../components';
import { SelectionPopup } from '../../components/SelectionPopup';
import {
    SelectedDataMulti,
    SelectedItem,
    SelectedItemAsset,
    SelectedItemExtended,
} from '../../components/SelectionPopup/SelectionPopup';
import { useDiagramStore } from '../../store/useDiagramStore';
import axios from '../../utils/axios';
import { ResultTableLeft } from '../../components/ResultTable';

import styles from './tax-optimisation.module.css';
import TaxBox from './components/TaxBox/TaxBox';
import Loader from '../../components/Loader/Loader';
import TransitionComponent from '../../components/TransitionComponent/TransitionComponents';
import BooleanImage from '../../components/BooleanImage/BooleanImage';
import { GraphTypes, TCountryCode } from '../../types/general';
import { DealTaxesSelectedData } from '../DealTaxes/DealTaxes';
import TaxOptimisationSelection from './components/TaxOptimisationSelection';

export interface TaxSortedPerStructure {
    groupId: number;
    id: number;
    domicile: string;
    fund: string;
    legalType: string;
    ifOpenEnded: boolean;
    bookingType: string;
    fundStatus: string;
    return: number;
    fundRegime: boolean;
    selfManaged: boolean;
    ifBusinessAssets: boolean;
    link?: string;

    furtherStatus?: string;

    investmentFund?: SelectedItem | number;
}

export interface Taxes {
    frontEndLabels: {
        groupId: number[];
        uniqueReturns: number[];
        domicile: string[];
        legalType: string[];
        openEnded: string[];
        fundStatus: string[][];
        bookingType: string[][];
    };
    sortedTaxesPerStructure: TaxSortedPerStructure[];
}

export interface TaxOptimisationSelectedData {
    investors: SelectedItemExtended[];
    assets: SelectedItemAsset[];
    selectedInvestor: undefined | SelectedItemExtended;
    initialInvestment: number;
    returnOnAsset: number;
    distribution: number;
}

const TaxOptimisation = () => {
    const [pageReady, setPageReady] = useState(false);
    const [controller, setController] = useState(new AbortController());

    const [selectedData, setSelectedData] = useState<TaxOptimisationSelectedData>({
        investors: [],
        assets: [],
        selectedInvestor: undefined,
        initialInvestment: 100,
        returnOnAsset: 20,
        distribution: 10,
    });

    const [selectedItems, setSelectedItems] = useState<{
        type: string;
        items: number[];
    }>({
        type: 'group',
        items: [],
    });

    const [labels, setLabels] = useState<undefined | Taxes>();

    const [groups, setGroups] = useState<
        | undefined
        | {
              [key: string]: TaxSortedPerStructure[];
          }
    >(undefined);

    const [setGraphStoreData] = useDiagramStore((state) => [state.setStoreData]);

    const [error, setError] = useState<boolean>(false);

    const [showPopup, setShowPopup] = useState(false);
    const [toggleRequest, setToggleRequest] = useState(false);

    const navigation = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [openTaxIndex, setOpenTaxIndex] = useState<undefined | number>();

    const [loading, setLoading] = useState(false);

    const [isSelecting, setIsSelecting] = useState(false);
    // const [isPreset, setIsPreset] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [selectedDetail, setSelectedDetail] = useState<TaxSortedPerStructure | undefined>();

    useEffect(() => {
        if (!searchParams.toString().length) {
            (async () => {
                await initPage();
            })();
        }
    }, [searchParams]);

    useEffect(() => {
        setGraphStoreData({
            useInvestmentStructure: false,
            graphType: GraphTypes.Optimisation,
        });
        (async () => {
            await initPage();
        })();
    }, []);

    useEffect(() => {
        if (showPopup) {
            setLoading(true);
            setPageReady(true);
            setOpenTaxIndex(undefined);
        }
    }, [showPopup]);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (toggleRequest) {
                (async () => {
                    await makeRequest();
                })();
            }
        }, 400);

        return () => clearTimeout(getData);
    }, [toggleRequest]);

    useEffect(() => {
        setSelectedDetail(
            typeof openTaxIndex !== 'undefined' && !!groups ? groups[Object.keys(groups)[openTaxIndex]][0] : undefined
        );

        console.log(
            'SET selectedDetail',
            openTaxIndex,
            selectedDetail,
            groups,
            !!groups && Object.keys(groups),
            openTaxIndex && !!groups && groups[Object.keys(groups)[openTaxIndex]],
            openTaxIndex && !!groups ? groups[Object.keys(groups)[openTaxIndex]][0] : undefined
        );
    }, [groups, openTaxIndex]);

    const initPage = async () => {
        const investors = searchParams.get('investors');
        const assets = searchParams.get('assets');

        const initialInvestment = searchParams.get('initialInvestment');
        const returnOnAsset = searchParams.get('returnOnAsset');
        const distribution = searchParams.get('distribution');

        const openTaxIndexParam = searchParams.get('openTaxIndex');

        const selectedTypeParam = searchParams.get('selectedType');
        const selectedItemsParam = searchParams.get('selectedItems');

        const selectedInvestor = searchParams.get('selectedInvestor');

        if (!investors || !assets) {
            setShowPopup(true);
            return;
        }

        if (openTaxIndexParam) {
            setOpenTaxIndex(+openTaxIndexParam);
        }

        if (selectedTypeParam && selectedItemsParam) {
            setSelectedItems({
                type: selectedTypeParam,
                items: (JSON.parse(selectedItemsParam) || []).map((item: string) => +item),
            });
            // setIsPreset(true);
            // setIsSelecting(true);
        } else {
            // setIsPreset(false);
            disableSelection();
        }

        // setInvestors(investorCountry);

        setSelectedData({
            ...selectedData,
            investors: JSON.parse(investors),
            assets: JSON.parse(assets),
            selectedInvestor: selectedInvestor ? JSON.parse(selectedInvestor) : undefined,
            initialInvestment: initialInvestment ? +initialInvestment : 100,
            returnOnAsset: returnOnAsset ? +returnOnAsset : 20,
            distribution: distribution ? +distribution : 10,
        });

        setToggleRequest(true);

        setPageReady(true);
    };

    const getItemUrl = (item: TaxSortedPerStructure) => {
        const tmp: DealTaxesSelectedData = {
            ...selectedData,
            assets: selectedData.assets,
            investors: selectedData.investors,
            investmentFund: item.investmentFund as SelectedItem,
            fundOfFunds: undefined,
            selectedInvestor: selectedData.selectedInvestor,
            openEnded: item.ifOpenEnded,
            corporate: item.legalType === 'corporate',
            // ifCommercialPartnership: item.furtherStatus === 'n/a' ? undefined : item.furtherStatus == 'commercial',
            fundStatus: item.furtherStatus === 'ko' || item.furtherStatus === 'n/a' ? undefined : item.furtherStatus,
            ifBusinessAssets: item.ifBusinessAssets || undefined,
            booked: item.bookingType === 'n/a' ? undefined : item.bookingType == 'at NAV',
        };

        // const entries = Object.entries(tmp).map((item) =>
        //     item.map((sub) => (sub !== null && typeof sub !== 'undefined' ? sub.toString() : ''))
        // );
        const tableUrl = new URLSearchParams(
            // @ts-ignore
            Object.entries(tmp).map((entry) => {
                // @ts-ignore
                entry[1] = typeof entry[1] !== 'undefined' ? JSON.stringify(entry[1]) : undefined;
                return entry;
            })
        );

        tableUrl.forEach((value, name) => {
            if (typeof value === 'undefined' || value === 'undefined') {
                tableUrl.delete(name);
            }
        });

        return tableUrl.toString();
    };

    const makeRequest = async () => {
        if (!pageReady) {
            // setOpenTaxIndex(undefined);
            // setLoading(true);
        }

        setToggleRequest(false);

        if (controller) {
            controller.abort();
        }

        const newController = new AbortController();
        setController(newController);

        const { data } = await axios.get<Taxes>('/tax-optimisation', {
            signal: newController.signal,
            params: selectedData,
        });

        // @ts-ignore
        data.sortedTaxesPerStructure = data.sortedTaxesPerStructure.map((item) => ({
            ...item,
            investmentFund: {
                id: item.investmentFund,
                name: item.fund,
                countryCode: item.domicile as TCountryCode,
                countryName: '',
                countryId: '',
            },
            fundOfFunds: undefined,
        }));

        console.log('selectedItems', selectedItems);
        if (selectedItems.items.length) {
            data.sortedTaxesPerStructure = data.sortedTaxesPerStructure?.filter((item) => {
                return selectedItems.items.includes(selectedItems.type === 'single' ? item.id : item.groupId);
            });
        }

        console.log(' data.sortedTaxesPerStructure', data.sortedTaxesPerStructure);

        const groupsTmp = data.sortedTaxesPerStructure?.reduce(function (obj, item) {
            item.link = `${getItemUrl(item)}&optimisation=true`;
            // @ts-ignore
            (obj[item.groupId] = obj[item.groupId] || []).push(item);
            return obj;
        }, {});

        console.log('groupsTmp', groupsTmp);

        setGroups(groupsTmp);
        setLabels(data);
        setTimeout(() => {
            setLoading(false);

            setPageReady(true);
        }, 100);

        // setOpenTaxIndex(undefined);
        disableSelection();

        setUrlParams(selectedData);
    };

    const handleInputChange = (prop: string, value: number) => {
        setSelectedData({ ...selectedData, [prop]: value });

        // if (selectedData.assets.currentAssetTaxId) {
        //     setToggleRequest(true);
        // }
    };

    const handleFormSubmission = async (formSelectedData: SelectedDataMulti) => {
        setGroups(undefined);
        setShowPopup(false);

        setSelectedData({
            ...selectedData,
            ...formSelectedData,
            selectedInvestor: formSelectedData.investors[0],
        });

        setToggleRequest(true);
    };

    const enableSelection = () => {
        setIsSelecting(true);
    };

    const disableSelection = () => {
        setIsSelecting(false);
        setSelectedItems({
            type: selectedItems.type,
            items: [],
        });
    };

    const handleSelect = (data: TaxSortedPerStructure) => {
        const id = typeof openTaxIndex !== 'undefined' ? data.id : data.groupId;
        const tmp = selectedItems.items.filter((i: number) => i !== id);
        if (tmp.length === selectedItems.items.length) {
            tmp.push(id);
        }

        setSelectedItems({
            type: typeof openTaxIndex !== 'undefined' ? 'single' : 'group',
            items: tmp,
        });
    };

    const savePreset = async (e: any) => {
        e.preventDefault();

        try {
            await axios.post('/presets/tax-optimisation', {
                ...selectedData,
                selectedType: selectedItems.type,
                selectedItems: selectedItems.items,
            });

            // redirect
            navigation('/account?save=1');
        } catch (e) {
            setError(true);
        }
    };

    const validateSelection = async () => {
        return true;
    };

    const setUrlParams = (selectedData: TaxOptimisationSelectedData) => {
        const tableUrl = new URLSearchParams();

        for (const entry of Object.entries(selectedData)) {
            // @ts-ignore
            if (typeof entry[1] !== 'undefined' && entry[1] !== 'undefined') {
                tableUrl.set(entry[0], JSON.stringify(entry[1]));
            }
        }

        // setTableUrl('?' + tableUrl.toString());

        // if (!isOptimisation) {
        setSearchParams(tableUrl.toString());

        // return;
        // }
    };

    return (
        <>
            <TransitionComponent inProp={showPopup} isAbsolute={true} duration={100}>
                <SelectionPopup isMulti={true} onSubmit={handleFormSubmission} validateSelection={validateSelection} />
            </TransitionComponent>

            <TransitionComponent inProp={pageReady && !showPopup} isAbsolute={false} duration={100}>
                <div className={styles.content}>
                    <ResultTableLeft
                        // tableInfo={<BaseInputs selectedData={selectedData} setSelectedData={handleInputChange} />}
                        selectedData={selectedData}
                        hideFundRow={true}
                        onEdit={() => {
                            setShowPopup(true);
                        }}
                    >
                        <TaxOptimisationSelection
                            selectedData={selectedData}
                            setSelectedData={handleInputChange}
                            active={isMenuOpen}
                            setActive={setIsMenuOpen}
                            hidden={showPopup}
                            onEdit={() => {
                                // setIsMenuOpen(false);
                                setShowPopup(true);
                            }}
                            selectedDetails={selectedDetail}
                        />
                    </ResultTableLeft>

                    <div className={`${styles.groupsContainer} ${isMenuOpen ? styles.collapsed : ''}`}>
                        <Loader inProp={loading} isAbsolute={true} top={'-20vh'} />

                        <TransitionComponent inProp={!loading}>
                            {isSelecting && (
                                <div className={styles.groupTitle}>
                                    <h3>Customise items to be stored</h3>
                                </div>
                            )}
                            <div className={`${styles.groups} ${openTaxIndex !== undefined ? styles.open : ''}`}>
                                {groups &&
                                    Object.keys(groups).map((key, index) => (
                                        <div
                                            className={`${styles.group} ${
                                                openTaxIndex === index ? styles.open : styles.closed
                                            } ${
                                                openTaxIndex !== undefined && openTaxIndex !== index
                                                    ? styles.hidden
                                                    : ''
                                            }`}
                                            key={`group-${index}`}
                                        >
                                            <div className={styles.groupTitle}>
                                                <h4>
                                                    <span>
                                                        <span className={styles.groupTitleNumber}>{index + 1}</span>
                                                        Return on investment
                                                    </span>
                                                    <span>
                                                        {labels &&
                                                            (
                                                                (labels.frontEndLabels.uniqueReturns[index] || 0) * 100
                                                            ).toFixed(4)}
                                                        %
                                                    </span>
                                                </h4>
                                            </div>
                                            <div
                                                className={`${styles.groupItems} ${
                                                    openTaxIndex === index ? styles.open : styles.closed
                                                }`}
                                                onClick={() => {
                                                    if (isSelecting && !openTaxIndex) {
                                                        handleSelect(groups[key][0]);
                                                    } else {
                                                        setOpenTaxIndex(index);
                                                        console.log('groups[openTaxIndex]', groups[index]);
                                                        console.log('by key', groups[Object.keys(groups)[index]]);
                                                    }
                                                }}
                                            >
                                                {groups[key].map((item, itemIndex) => (
                                                    <React.Fragment key={itemIndex}>
                                                        <TaxBox
                                                            data={item}
                                                            img={'./images/thumb-taxes.png'}
                                                            name={'Deal taxes'}
                                                            link={`deal-taxes?${item.link}&openTaxIndex=${openTaxIndex}`}
                                                            key={`group-item-${index}-${itemIndex}`}
                                                            isOpen={openTaxIndex === index}
                                                            collapsedOpenDomicile={
                                                                labels?.frontEndLabels?.domicile[index]
                                                            }
                                                            collapsedOpenEnded={
                                                                labels?.frontEndLabels?.openEnded[index]
                                                            }
                                                            collapsedLegalType={
                                                                labels?.frontEndLabels?.legalType[index]
                                                            }
                                                            isSelecting={isSelecting}
                                                            isSelected={
                                                                !!selectedItems.items.find(
                                                                    (i: number) =>
                                                                        i === (openTaxIndex ? item.id : item.groupId)
                                                                )
                                                            }
                                                            handleSelect={handleSelect}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </TransitionComponent>

                        {isSelecting && (
                            <div className={styles.actions}>
                                <Button outlined={true} onClick={disableSelection}>
                                    Cancel
                                </Button>
                                <Button outlined={true} onClick={savePreset} before={<BooleanImage value={true} />}>
                                    Store chosen items
                                </Button>
                            </div>
                        )}

                        {!isSelecting && (
                            <div className={styles.actions}>
                                {(openTaxIndex === 0 || !!openTaxIndex) && (
                                    <Button
                                        onClick={() => {
                                            setOpenTaxIndex(undefined);
                                        }}
                                    >
                                        Collapse all
                                    </Button>
                                )}

                                <Button onClick={enableSelection}>Print review</Button>
                            </div>
                        )}
                    </div>
                </div>
            </TransitionComponent>

            {error && (
                <Response type={'error'} aligned={false} setResponse={setError}>
                    An error occurred.
                </Response>
            )}
        </>
    );
};

export default TaxOptimisation;
