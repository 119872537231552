import { components, default as ReactSelect } from 'react-select';
import styles from './Select.module.css';
import inputStyles from '../InputLabel/Input.module.css';
import { useEffect, useState } from 'react';
import InputLabel from '../InputLabel/InputLabel';
import { Icon } from '../index';
import { Icons } from '../Icon/Icon';

const CustomChevron = ({ innerRef, ...innerProps }: any) => {
    return (
        <components.DropdownIndicator {...innerProps}>
            <svg
                ref={innerRef}
                xmlns="http://www.w3.org/2000/svg"
                width="19.931"
                height="10.673"
                viewBox="0 0 19.931 10.673"
                style={{
                    transform: innerProps.selectProps.menuIsOpen ? 'rotate(-180deg)' : '',
                }}
            >
                <path
                    id="Path_1174"
                    data-name="Path 1174"
                    d="M5271.342,2230l9.612,9.612,9.612-9.612"
                    transform="translate(-5270.988 -2229.646)"
                    fill="none"
                    stroke="currentcolor"
                    strokeWidth="1"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

const CustomMenu = ({
    placement,
    selectProps,
    innerRef,
    innerProps,
    isDisabled,
    children,
    fullWidth,
    ...props
}: any) => {
    return !isDisabled ? (
        <div
            ref={innerRef}
            {...innerProps}
            style={{
                position: 'absolute',
                zIndex: 10,
                background: '#fff',
                width: '100%',
                border: '0.5px solid #7B848E',
                borderRadius: '2px',
                bottom: placement === 'top' ? '100%' : 'initial',
            }}
        >
            {props.isMulti && !!selectProps.value?.length && (
                <div style={{ padding: '8px 3px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '4px',
                            paddingBottom: '8px',
                            borderBottom: '1px solid #D6D6D6',
                        }}
                    >
                        {selectProps.value.map((option: any, index: number) => (
                            <div
                                key={option.value + index}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.setValue(selectProps.value.filter((v: any) => v.value !== option.value));
                                }}
                                style={{
                                    padding: '2px 4px',
                                    background: '#dedede',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                <span>{option.label}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {children}

            {!!selectProps.onCreateOption && (
                <div className={styles.addOptionContainer}>
                    <button
                        type={'button'}
                        className={styles.addOptionBtn}
                        onClick={() => {
                            selectProps.onCreateOption();
                            selectProps.onMenuClose();
                        }}
                    >
                        <Icon icon={Icons.add} /> Add another
                    </button>
                </div>
            )}
        </div>
    ) : null;
};

const CustomValueContainer = (props: any) => {
    const selected = props.getValue();
    const content = ((length) => {
        switch (length) {
            case 0:
                return '';
            case 1:
                return selected[0].label;
            default:
                return `${selected.length} selected`;
        }
    })(selected.length);

    return (
        <components.ValueContainer {...props}>
            {props.isMulti && <div>{content}</div>}
            {props.children}
        </components.ValueContainer>
    );
};

const CustomSelect = ({
    children,
    options = [],
    value,
    valueKey = 'value',
    labelKey = 'label',
    isMulti = false,
    onCreateOption,
    onChange,
    disabled,
    info,
    extended,
    placeholder,
    style,
    ...props
}: any) => {
    const [customValue, setCustomValue] = useState<any>(undefined);

    const getValue = () => {
        const option = !value
            ? ''
            : Array.isArray(value)
            ? value
            : typeof value !== 'object'
            ? options.find((option: any) => (option[valueKey] || option.value) === value)
            : value;

        const endValue = Array.isArray(value)
            ? value
            : option
            ? {
                  label: option[labelKey] || option.label || option.name,
                  value: option[valueKey] || option.value || option.id,
              }
            : '';

        console.log('customValue', customValue);
        console.log('endValue', endValue);
        if (JSON.stringify(customValue) != JSON.stringify(endValue)) {
            setCustomValue(
                Array.isArray(value)
                    ? value
                    : option
                    ? {
                          label: option[labelKey] || option.label || option.name,
                          value: option[valueKey] || option.value || option.id,
                      }
                    : ''
            );
        }
    };

    useEffect(() => {
        getValue();
    }, [value, options]);

    return (
        <InputLabel label={children} extended={extended} info={info} style={style}>
            {/* ts-ignore */}
            <ReactSelect
                onCreateOption={onCreateOption}
                onChange={onChange}
                isMulti={isMulti}
                isDisabled={disabled}
                // defaultValue={customValue}
                value={customValue}
                options={options.map((option: any) => ({
                    label: option[labelKey] || option.label,
                    value: option[valueKey] || option.value,
                }))}
                components={{
                    Menu: CustomMenu,
                    DropdownIndicator: CustomChevron,
                    ValueContainer: CustomValueContainer,
                    MultiValue: () => null, // Hide individual tags
                    IndicatorSeparator: () => null,
                }}
                unstyled={true}
                placeholder={placeholder || 'Select...'}
                menuPlacement="auto"
                minMenuHeight={160}
                maxMenuHeight={200}
                closeMenuOnSelect={!isMulti}
                isClearable={false}
                isSearchable={false}
                // menuPortalTarget={document.body}
                // menuPosition={'fixed'}
                styles={{
                    menuPortal: (provided: any) => ({
                        ...provided,
                        zIndex: 999,
                    }),
                }}
                classNames={{
                    control: (state) =>
                        `${inputStyles.inputField} 
                        ${styles.select} 
                        ${state.isDisabled ? inputStyles.disabled : ''} 
                        ${state.hasValue ? inputStyles.selected : ''}`,
                    option: (state) => styles.option,
                    clearIndicator: (state) => styles.clearIndicator,
                    noOptionsMessage: (state) => `
                        ${styles.noOptionsMessage}
                        ${props.readOnly ? styles.readOnly : ''} 
                    `,
                    multiValue: (state) => styles.multiValue,
                }}
                // closeMenuOnScroll={(e) => {
                //     // if (e.target === document) {
                //     return true;
                //     // } else {
                //     //     return false;
                //     // }
                // }}
            />
        </InputLabel>
    );
};

export default CustomSelect;
