// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_checkboxes__6xrRU {\n    padding-left: 50px;\n}\n\n.styles_checkboxes__6xrRU label {\n    margin-bottom: 10px;\n}\n\n.styles_group__LcCjl:not(:last-child) {\n    margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DealTaxes/components/AdditionalChecks/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".checkboxes {\n    padding-left: 50px;\n}\n\n.checkboxes label {\n    margin-bottom: 10px;\n}\n\n.group:not(:last-child) {\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxes": "styles_checkboxes__6xrRU",
	"group": "styles_group__LcCjl"
};
export default ___CSS_LOADER_EXPORT___;
