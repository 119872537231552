import Select from '../../../../../../components/Select/Select';
import { useEffect, useState } from 'react';
import { Icon } from '../../../../../../components';
import { Icons } from '../../../../../../components/Icon/Icon';
import styles from '../../../../layout/FormLayout/FormLayout.module.css';

const AssetSingle = ({
    selectedData,
    availableItems,
    countries,
    onUpdate,
    handleRemoveItem,
    additionalItems,
    handleShowCreateForm,
}: any) => {
    const [items, setItems] = useState<any[]>(availableItems);

    const [data, setData] = useState({
        domicile: undefined,
        ...selectedData,
    });

    useEffect(() => {
        console.log('items', items, availableItems);
        // setItems([...additionalItems, ...items]);
    }, [items]);

    useEffect(() => {
        console.log('additionalItems', items, additionalItems);
        setItems([...additionalItems, ...availableItems]);
    }, [additionalItems, availableItems]);

    const handleSelectChange = (prop: string) => (selectedData: any) => {
        let value = selectedData;
        console.log('prop', prop, value);
        console.log('availableItems', availableItems);

        if (prop === 'asset') {
            value = items
                .filter((item: any) =>
                    Array.isArray(value)
                        ? selectedData.findIndex((selected: any) => item.assetParticipation === selected.label) > -1
                        : item.assetParticipation === selectedData.label
                )
                .map((v: any) => ({ ...v, label: v.assetParticipation }));
        }

        console.log('value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        console.log('selectedObj', selectedObj);

        setData(selectedObj);
        onUpdate(selectedObj);
    };

    return (
        <>
            <div className={styles.formGridRow}>
                <Select
                    value={data.domicile}
                    onChange={handleSelectChange('domicile')}
                    options={countries}
                    valueKey={'domicileId'}
                    labelKey={'domicileName'}
                >
                    Domicile
                </Select>

                <Select
                    value={data.asset}
                    disabled={!data.domicile}
                    isMulti={true}
                    onChange={handleSelectChange('asset')}
                    options={items}
                    valueKey={'assetId'}
                    labelKey={'assetParticipation'}
                    onCreateOption={handleShowCreateForm}
                >
                    Asset Type
                </Select>

                <button
                    style={{
                        minWidth: 32,
                        marginTop: -10,
                        marginLeft: -20,
                        position: 'absolute',
                    }}
                    type={'button'}
                    onClick={handleRemoveItem}
                >
                    <Icon icon={Icons.closeRounded} />
                </button>
            </div>
        </>
    );
};

export default AssetSingle;
