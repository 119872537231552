import Select from '../../../../../../components/Select/Select';
import { useEffect, useState } from 'react';
import axios from '../../../../../../utils/axios';
import InvestorCreateForm from './InvestorCreateForm';
import { Icon } from '../../../../../../components';
import { Icons } from '../../../../../../components/Icon/Icon';
import styles from '../../../../layout/FormLayout/FormLayout.module.css';

const InvestorSingle = ({
    selectedData,
    countries,
    onUpdate,
    handleRemoveItem,
    optionsByCountry,
    setOptionsByCountry,
}: any) => {
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [items, setItems] = useState<any[]>([]);

    const [data, setData] = useState({
        domicile: undefined,
        investors: [],
        ...selectedData,
    });

    useEffect(() => {
        setData({
            domicile: undefined,
            investors: [],
            ...selectedData,
        });
    }, [selectedData]);

    const handleCreateItems = (newItems: []) => (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setItems([...items, ...newItems]);
        setShowCreateForm(false);

        const obj = {
            ...selectedData,
            investors: [...selectedData.investors, ...newItems],
        };

        setData(obj);
        onUpdate(obj);

        setOptionsByCountry(selectedData.domicile.value, newItems);
    };

    const handleShowCreateForm = () => {
        setShowCreateForm(true);
    };

    const getDataByCountryId = async (id: number) => {
        if (optionsByCountry[id]) {
            setItems(optionsByCountry[id]);
            return;
        }

        const { data } = await axios(`/storage/investors/${id}`);

        setItems(data);
        setOptionsByCountry(id, data);
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        const obj = {
            ...data,
            [prop]: value,
        };

        if (prop === 'domicile') {
            getDataByCountryId(+value.value);

            obj.investors = [];
        }

        setData(obj);
        onUpdate(obj);
    };

    return (
        <>
            {showCreateForm && (
                <InvestorCreateForm onSave={handleCreateItems} onClose={() => setShowCreateForm(false)} />
            )}

            <div className={styles.formGridRow}>
                <Select
                    value={data.domicile}
                    onChange={handleSelectChange('domicile')}
                    options={countries}
                    valueKey={'domicileId'}
                    labelKey={'domicileName'}
                >
                    Domicile
                </Select>

                <Select
                    value={data.investors}
                    disabled={!data.domicile}
                    isMulti={true}
                    onChange={handleSelectChange('investors')}
                    options={items}
                    valueKey={'investorId'}
                    labelKey={'investorType'}
                    onCreateOption={handleShowCreateForm}
                >
                    Investor Type
                </Select>

                <button
                    style={{
                        minWidth: 32,
                        marginTop: -10,
                        marginLeft: -20,
                        position: 'absolute',
                    }}
                    type={'button'}
                    onClick={handleRemoveItem}
                >
                    <Icon icon={Icons.closeRounded} />
                </button>
            </div>
        </>
    );
};

export default InvestorSingle;
