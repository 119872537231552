import { useState } from 'react';
import axios from '../../../../../utils/axios';
import { StorageType } from '../../StorageForm';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import { Button } from '../../../../../components';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import { v4 as uuidv4 } from 'uuid';

enum Forms {
    LegalForm = 'LegalForm',
}

const StorageGeneralPartner = ({ selectedData, generalInfo, setGeneralInfo, handleNextStep, handleClose }: any) => {
    const [activeForm, setActiveForm] = useState<Forms | null>(null);

    const [data, setData] = useState({
        legalForm: undefined,
        legalFormShortNameManual: '',
        ...selectedData,
    });

    const handleInputChange = (prop: string) => (event: any) => {
        const value = event.target.value;

        setData({
            ...data,
            [prop]: value,
        });
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        const tmp = {
            ...data,
            [prop]: value,
        };

        if (prop === 'domicile' || prop === 'fundDomicile') {
            getDataByCountryId(+value.value);
            tmp.legalForm = undefined;
        }

        setData(tmp);
    };

    const getDataByCountryId = async (id: number) => {
        const { data } = await axios(`/storage/general/${id}`);

        console.log('data', data);

        setGeneralInfo({
            ...generalInfo,
            gpLegalForm: data.legalForm,
            gpFundRegime: data.fundRegime,
            gpInvestmentFund: data.investmentFund,
        });
    };

    const closeActiveForm = (type: keyof typeof generalInfo, valueKey: keyof typeof data, label: string) => {
        const id = uuidv4();

        setGeneralInfo({
            ...generalInfo,
            [type]: [
                ...generalInfo[type],
                {
                    value: id,
                    label: label,
                },
            ],
        });

        setData({
            ...data,
            [valueKey]: {
                value: id,
                label: label,
            },
        });

        setActiveForm(null);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'General partner'}
                description={'Please fill in the basic information about general partner.'}
                footerMain={<Button form={'mainForm'}>Next</Button>}
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.Structure)}
            >
                <form id={'mainForm'} onSubmit={handleNextStep(data, StorageType.Investors)}>
                    <Input value={data.gpName} onChange={handleInputChange('gpName')}>
                        GP name
                    </Input>
                    <Input value={data.launchDate} onChange={handleInputChange('launchDate')}>
                        Launch date
                    </Input>
                    <Select
                        value={data.domicile}
                        onChange={handleSelectChange('domicile')}
                        options={generalInfo.countries}
                        valueKey={'domicileId'}
                        labelKey={'domicileName'}
                    >
                        Domicile
                    </Select>

                    <Select
                        value={data.legalForm}
                        disabled={!data.domicile?.value}
                        onChange={handleSelectChange('legalForm')}
                        options={generalInfo.gpLegalForm}
                        valueKey={'legalFormId'}
                        labelKey={'legalFormComb'}
                        onCreateOption={() => {
                            setActiveForm(Forms.LegalForm);
                        }}
                    >
                        Legal form
                    </Select>
                </form>
            </FormLayout>

            {activeForm === Forms.LegalForm && (
                <FormLayout
                    title={'Legal form'}
                    description={'Please provide the full and the short name (if any) of the legal form.'}
                    isAbsolute={true}
                    onClose={() => {
                        setActiveForm(null);
                    }}
                    footerMain={<Button form={'subForm'}>Upload</Button>}
                >
                    <form
                        id={'subForm'}
                        onSubmit={(e) => {
                            e.preventDefault();
                            closeActiveForm('legalForm', 'legalForm', data.legalFormShortNameManual);
                        }}
                    >
                        <Input
                            onChange={handleInputChange('legalFormFullNameManual')}
                            style={'text'}
                            type="textarea"
                            max={300}
                            rows={2}
                        >
                            Full name
                        </Input>
                        <Input onChange={handleInputChange('legalFormShortNameManual')} max={50} style={'text'}>
                            Short name / abbreviation
                        </Input>

                        <Select
                            value={data.legalFormTypeManual}
                            onChange={handleSelectChange('legalFormTypeManual')}
                            options={generalInfo.legalTypes}
                            valueKey={'legalTypeId'}
                            labelKey={'legalType'}
                            style={'secondary'}
                        >
                            Legal form
                        </Select>
                    </form>
                </FormLayout>
            )}
        </FormWrapper>
    );
};

export default StorageGeneralPartner;
