import ComparisonTableSection from '../ComparisonTableSection';

import { useTableStore } from '../../../store/useTableStore';
import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD9Table from './SD9Table';
import { SDProps } from './sd.interface';

const SD9 = ({ triggerCollapse, setTablesCollapsed, tablesCollapsed, collapseOnClick }: SDProps) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd9 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={'Costs and timing'}
            >
                <SD9Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD9;
