export interface ITipData {
    percent: number;
    tax: string;
    fullComments: string;
    legendId: number;
    value: number;
}

interface ITip {
    x: number;
    y: number;
    width: number;
    height: number;
    data: ITipData[];
}

interface TipProps {
    tip: ITip;
    onLegendChange: (id: number | null) => void;
}

const Tip = ({ tip, onLegendChange }: TipProps) => {
    const handleTipEnter = (tip: ITipData) => {
        if (onLegendChange) {
            onLegendChange(tip.legendId);
        }
    };

    const handleMouseLeave = () => {
        if (onLegendChange) {
            onLegendChange(null);
        }
    };

    return (
        <g transform={`translate(${tip.x}, ${tip.y})`}>
            <rect
                width={tip.width}
                height={tip.height * tip.data.length}
                x={0}
                y={0}
                fill="#fff"
                stroke="#ff5900"
                strokeWidth={1}
                rx={5}
            />
            {tip.data.map((tipData: ITipData, index: number) => (
                <g
                    key={index}
                    transform={`translate(0, ${tip.height * index})`}
                    onMouseEnter={() => handleTipEnter(tipData)}
                    onMouseLeave={handleMouseLeave}
                >
                    <text x={tip.width / 2} y={18} fontSize={15} textAnchor="middle" fill="#ff5900">
                        {tipData.percent}
                    </text>
                    <g x={tip.width / 2}>
                        <text x={tip.width / 2} y={32} fontSize={11} textAnchor="middle" fill="black">
                            {tipData.tax}
                            {tipData.fullComments && (
                                <tspan dx={1} dy={-4} fontSize={8}>
                                    {tipData.legendId}
                                </tspan>
                            )}
                        </text>
                    </g>
                    <text x={tip.width / 2} y={48} fontSize={12} textAnchor="middle" fontWeight={700} fill="black">
                        {`$${tipData.value.toFixed(2)}`}
                    </text>

                    {index !== tip.data.length - 1 && (
                        <line
                            x1="8"
                            y1={tip.height * (index + 1)}
                            x2={tip.width - 8}
                            y2={tip.height * (index + 1)}
                            stroke="#ff5900"
                            strokeWidth="1"
                            strokeDasharray="2, 2"
                        />
                    )}
                </g>
            ))}
        </g>
    );
};

export default Tip;
