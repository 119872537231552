import styles from './styles.module.css';

const OverviewFolder = ({ itemLength, image, name }: { name: string; image: string; itemLength: number }) => {
    return (
        <div
            style={{
                position: 'relative',
                margin: 'auto',
            }}
        >
            <div className={styles.folder}>
                <div className={styles.itemWrapper}>{itemLength > 0 && <div className={styles.item}></div>}</div>

                <div className={styles.imgContainer}>
                    <img className={styles.folderImg} src="/images/folder.png" alt="" />
                    <img className={styles.typeImg} src={image} alt="" />
                </div>
            </div>

            <p className={styles.type}>{name}</p>
        </div>
    );
};

export default OverviewFolder;
