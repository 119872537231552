import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useSearchParams } from 'react-router-dom';
import animateScrollTo from 'animated-scroll-to';

import styles from './comparison-table-styles.module.css';
import axios from '../../utils/axios';

import ComparisonTableHighlighted from './ComparisonTableHighlighted';

import SD1 from './tables/SD1';
import SD2 from './tables/SD2';
import SD3 from './tables/SD3';
import SD4 from './tables/SD4';
import SD5 from './tables/SD5';
import SD6 from './tables/SD6';
import SD9 from './tables/SD9';
import SD10 from './tables/SD10';
import SD11 from './tables/SD11';
import SD12 from './tables/SD12';

import { useTableStore } from '../../store/useTableStore';
import { useFooterStore } from '../../store/useFooterStore';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import { Button } from '../../components';
import { LinkedButton } from '../../components/Button';

const ComparisonTable = () => {
    const [ready, setReady] = useState(false);

    const [data, setTableData] = useTableStore((state) => [state.data, state.setTableData]);
    const [setShowFooter] = useFooterStore((state) => [state.setShowFooter]);

    const [triggerCollapse, setTriggerCollapse] = useState(false);
    const [allCollapsed, setAllCollapsed] = useState(true);
    const [tablesCollapsed, setTablesCollapsed] = useState(0);
    const [collapseOnClick, setCollapseOnClick] = useState(false);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        (async () => {
            const selectedData: any = {};

            // @ts-ignore
            for (const [key, value] of searchParams.entries()) {
                selectedData[key] = JSON.parse(value);
            }

            const data = await axios.get('/legal-structures/detailed', {
                params: selectedData,
            });

            setTableData(data.data);

            setTimeout(() => {
                setReady(true);
            }, 100);
        })();
    }, []);

    useEffect(() => {
        setShowFooter(false);
        return () => setShowFooter(true);
    }, []);

    useEffect(() => {
        setAllCollapsed(tablesCollapsed > 0);
        setShowFooter(tablesCollapsed > 0);
    }, [tablesCollapsed]);

    useEffect(() => {
        if (collapseOnClick) {
            setTriggerCollapse(allCollapsed);
        }
    }, [allCollapsed, collapseOnClick]);

    useEffect(() => {
        setTimeout(() => {
            setCollapseOnClick(false);
        }, 1);
    }, [allCollapsed]);

    const collapseAll = () => {
        setCollapseOnClick(true);
        setAllCollapsed(true);
        setTablesCollapsed(0);
    };

    const scrollToTop = async () => {
        await animateScrollTo(0);
    };

    return (
        <LoaderComponent inProp={ready}>
            {data && (
                <>
                    <div className={styles.container}>
                        <div className={styles.comparisonPageHeader}>
                            <h2>Legal structures: expanded view</h2>

                            <CSSTransition
                                in={tablesCollapsed > 0}
                                timeout={300}
                                classNames={{
                                    appear: styles.fadeAnimation,
                                    appearActive: styles.fadeAnimationActive,
                                    appearDone: styles.fadeAnimationActive,
                                    enter: styles.fadeAnimation,
                                    enterActive: styles.fadeAnimationActive,
                                    enterDone: styles.fadeAnimationActive,
                                    exit: styles.fadeAnimation,
                                    exitActive: styles.fadeAnimation,
                                    exitDone: styles.fadeAnimation,
                                }}
                                unmountOnExit
                            >
                                <button onClick={collapseAll}>Collapse all</button>
                            </CSSTransition>
                        </div>

                        <SD1
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD2
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD3
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD4
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD5
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD6
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD9
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD10
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD11
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />

                        <SD12
                            triggerCollapse={triggerCollapse}
                            setTablesCollapsed={setTablesCollapsed}
                            tablesCollapsed={tablesCollapsed}
                            collapseOnClick={collapseOnClick}
                        />
                    </div>

                    <ComparisonTableHighlighted
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <div className={styles.backBtn}>
                        {tablesCollapsed === 0 ? (
                            <LinkedButton
                                to={{
                                    pathname: '/legal-structures',
                                    search: window.location.search,
                                }}
                            >
                                Back to short version
                            </LinkedButton>
                        ) : (
                            <Button onClick={scrollToTop}>Back to top</Button>
                        )}
                    </div>
                </>
            )}
        </LoaderComponent>
    );
};

export default ComparisonTable;
