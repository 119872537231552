import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { SelectionRowContext } from '../../../context/SelectionRowContext';
import { SelectionLevelProps } from '../../../types/general';

const SelectionOptionInfo = () => {
    const { type, selectedOption, options, selectedCountry } = useContext(SelectionRowContext);

    const [option, setOption] = useState();

    useEffect(() => {
        return (
            options &&
            selectedOption &&
            setOption(
                // @ts-ignore
                options[selectedCountry].find(
                    (if2: any) =>
                        if2[SelectionLevelProps[type].valueKey].toString() ===
                        (selectedOption ? selectedOption.toString() : null)
                )
            )
        );
    }, [selectedOption, options]);

    return (
        <>
            {selectedOption && selectedCountry && (
                <div className={styles.selectedInfo}>
                    {/*<h5 className={styles.selectedTitle}>{option && option[SelectionLevelProps[type].titleKey]}</h5>*/}

                    <p className={styles.investorSelectInfo}>{option && option[SelectionLevelProps[type].infoKey]}</p>
                </div>
            )}
        </>
    );
};

export default SelectionOptionInfo;
