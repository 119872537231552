import styles from '../styles.module.css';
import React from 'react';

interface PresetActionsProps {
    createPDF?: any;
    createExcel?: () => void;
    handleDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const PresetActions = ({ createPDF, createExcel, handleDelete }: PresetActionsProps) => {
    return (
        <div className={styles.actions}>
            {createPDF && (
                <button className={styles.pdf} onClick={createPDF}>
                    <p className={styles.tipBox}>Download pdf overview</p>
                </button>
            )}
            {createExcel && (
                <button className={styles.excel} onClick={createExcel}>
                    <p className={styles.tipBox}>Download excel overview</p>
                </button>
            )}
            <button className={styles.delete} onClick={handleDelete}>
                <p className={styles.tipBox}>Delete overview</p>
            </button>
        </div>
    );
};

export default PresetActions;
