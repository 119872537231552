import OverviewNavigation from '../../../../../components/Overview/OverviewNavigation/OverviewNavigation';
import { StorageType } from '../../StorageForm';

const StorageNavigation = ({
    activeStorageType,
    setActiveStorageType,
}: {
    activeStorageType: StorageType;
    setActiveStorageType: any;
}) => {
    const handleCategoryChange = (type: StorageType) => (e: any) => {
        setActiveStorageType(type);
    };

    return (
        <div style={{ margin: 'auto' }}>
            <OverviewNavigation
                menuItems={[
                    { label: 'Investment structure', type: StorageType.Structure },
                    { label: 'General partner', type: StorageType.GeneralPartner },
                    { label: 'Investors', type: StorageType.Investors },
                    { label: 'Assets', type: StorageType.Assets },
                    { label: 'Asset manager', type: StorageType.AssetManager },
                    { label: 'Service providers', type: StorageType.ServiceProviders },
                    { label: 'Other', type: StorageType.Other },
                ]}
                selectedType={activeStorageType}
                onClick={handleCategoryChange}
                menuPosition={'left'}
            />
        </div>
    );
};

export default StorageNavigation;
