import styles from './close-button.module.css';
import { Icon } from '../index';
import { Icons } from '../Icon/Icon';

interface CloseButtonProps {
    onClick: any;
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
    return (
        <button type={'button'} className={styles.close} onClick={onClick}>
            <Icon icon={Icons.close} />
        </button>
    );
};

export default CloseButton;
