import styles from './styles.module.css';

interface TaxOptimisationInfoProps {
    initialInvestment?: number;
    returnOnAsset?: number;
    distribution?: number;
}

const TaxOptimisationInfo = ({ initialInvestment, returnOnAsset, distribution }: TaxOptimisationInfoProps) => {
    return (
        <div className={styles.info}>
            <div>
                <h3>
                    This <span className={styles.highlight}>tax proposals</span> are for:
                </h3>

                <h5>
                    <span>Initial investment: </span>
                    <span>${initialInvestment || 100}</span>
                </h5>
                <h5>
                    <span>Return on asset: </span>
                    <span>{returnOnAsset || 20}%</span>
                </h5>
                <h5>
                    <span>Distribution: </span>
                    <span>${distribution || 10}</span>
                </h5>

                <h5>
                    <span>Investment horizon:</span>

                    <span>1 year</span>
                </h5>
            </div>
        </div>
    );
};

export default TaxOptimisationInfo;
