// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormWrapper_wrapper__sFlt5 {\n    /*position: relative;*/\n    display: flex;\n    flex-direction: column;\n    /*justify-content: center;*/\n    /*align-items: center;*/\n\n    min-height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Storage/layout/FormWrapper/FormWrapper.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;;IAEvB,gBAAgB;AACpB","sourcesContent":[".wrapper {\n    /*position: relative;*/\n    display: flex;\n    flex-direction: column;\n    /*justify-content: center;*/\n    /*align-items: center;*/\n\n    min-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FormWrapper_wrapper__sFlt5"
};
export default ___CSS_LOADER_EXPORT___;
