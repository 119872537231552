import StorageFormSummaryItem from './components/StorageFormSummaryItem';
import styles from './styles.module.css';
import { StorageType } from '../../StorageForm';
import { Button, Icon } from '../../../../../components';
import { Icons } from '../../../../../components/Icon/Icon';

const StorageFormSummary = ({ selectedData, setActiveStorageType, handleSubmit }: any) => {
    console.log('selectedData[StorageType.Assets]', selectedData[StorageType.AssetManager]);
    return (
        <div>
            <div className={styles.container}>
                <StorageFormSummaryItem
                    index={'01'}
                    title={'Investing vehicle'}
                    onClick={() => {
                        setActiveStorageType(StorageType.Structure);
                    }}
                    selected={!!selectedData[StorageType.Structure]?.fundName}
                    data={{
                        lines: [
                            selectedData[StorageType.Structure]?.fundName,
                            selectedData[StorageType.Structure]?.investmentFund?.label,
                        ],
                        domicile: selectedData[StorageType.Structure]?.fundDomicile?.label,
                    }}
                />
                <StorageFormSummaryItem
                    index={'02'}
                    title={'Investors'}
                    onClick={() => {
                        setActiveStorageType(StorageType.Investors);
                    }}
                    selected={!!selectedData[StorageType.Investors]?.length}
                    data={{
                        lines: selectedData[StorageType.Investors]
                            ?.map((item: any) =>
                                item.domicile && item.investors.length
                                    ? `${item.domicile.label}::${item.investors.length} types`
                                    : null
                            )
                            .slice(0, 3),
                    }}
                />
                <StorageFormSummaryItem
                    index={'03'}
                    title={'Assets'}
                    onClick={() => {
                        setActiveStorageType(StorageType.Assets);
                    }}
                    selected={!!selectedData[StorageType.Assets]?.length}
                    data={{
                        lines: selectedData[StorageType.Assets]
                            ?.map((item: any) =>
                                item.domicile && item.asset.length
                                    ? `${item.domicile.label}::${item.asset.length} types`
                                    : null
                            )
                            .slice(0, 3),
                    }}
                />
                <StorageFormSummaryItem
                    index={'04'}
                    title={'Asset manager'}
                    onClick={() => {
                        setActiveStorageType(StorageType.AssetManager);
                    }}
                    selected={!!selectedData[StorageType.AssetManager]?.length}
                    data={{
                        lines: selectedData[StorageType.AssetManager]
                            ?.map((item: any) => `${item.role?.label} :: ${item.name}`)
                            .slice(0, 3),
                    }}
                />
                <StorageFormSummaryItem
                    index={'05'}
                    title={'Service provides'}
                    onClick={() => {
                        setActiveStorageType(StorageType.ServiceProviders);
                    }}
                    selected={!!selectedData[StorageType.ServiceProviders]?.length}
                    data={{
                        lines: selectedData[StorageType.ServiceProviders]
                            ?.map((item: any) => `${item.role?.label} :: ${item.name}`)
                            .slice(0, 3),
                    }}
                />
                <StorageFormSummaryItem
                    index={'06'}
                    title={'Other parameters '}
                    onClick={() => {
                        setActiveStorageType(StorageType.Other);
                    }}
                    selected={!!selectedData[StorageType.Other]?.laws}
                />
            </div>

            <div className={styles.footer}>
                <Button color={'neutral'} after={<Icon icon={Icons.closeRounded} />}>
                    Close
                </Button>

                {!!selectedData[StorageType.Structure]?.fundName && (
                    <Button onClick={handleSubmit()} after={<Icon icon={Icons.check} />}>
                        Save filled
                    </Button>
                )}
            </div>
        </div>
    );
};

export default StorageFormSummary;
