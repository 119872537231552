const PercentFormat = (d: any) => {
    let num;

    if (!isNaN(parseFloat(d))) {
        num = parseFloat(d) * 100;
        d = num + '%';
    }

    return d;
};

export default PercentFormat;
