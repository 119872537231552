import React, { useState } from 'react';
import { TrContext } from '../context/TrContext';

const Tr = ({ children }: { children: React.ReactNode }) => {
    const [expanded, setExpanded] = useState(false);
    const [hasDescription, setHasDescription] = useState(false);

    return (
        <TrContext.Provider
            value={{
                expanded: expanded,
                setExpanded: setExpanded,
                setHasDescription: setHasDescription,
                hasDescription: hasDescription,
            }}
        >
            {children}
        </TrContext.Provider>
    );
};

export default Tr;
