export interface StorageAssetManagerParams {
    currentStructure: undefined | number;
    currentIfSelfManaged: boolean;
    currentFundRegime: number;
    currentInvestorId: number[];
    currentFundLegalForm: number;
}

export interface StorageAssetManagementRole {
    amRoleId: number;
    amRole: string;
}

export interface StorageStructureS1 {
    structureLevelId: number;
    type: string;
}

export interface StorageStructureDomicile {
    domicileId: number;
    domicileName: string;
}

export interface StorageStructureS3 {
    investmentFundId: number;
    investmentFund: string;
    description: string;
}

export interface StorageStructureS4 {
    fundRegimeId: number;
    fundRegime: string;
}

export interface StorageStructureS5 {
    legalFormId: number;
    legalForm: string;
    fullName: string;
}

export interface StorageStructureMD5 {
    ifSelfManaged: boolean;
}

export interface StorageStructureMD6 {
    ifOpenEnded: boolean;
}

export interface StorageStructure {
    s1: StorageStructureS1[];
    md1: StorageStructureDomicile[];
    md2: StorageStructureS3[];
    md3: StorageStructureS5[];
    md4: StorageStructureS4[];
    md5: StorageStructureMD5[];
    md6: StorageStructureMD6[];
    md7: StorageStructureDomicile[];
}

export interface StorageStructureSelection {
    currentStructure: undefined | number;
    currentFundRegime: undefined | number;
    currentStructureDomicile: undefined | number;
    currentFundLegalForm: undefined | number;
    currentStructureLevel: undefined | number;
    currentFundName: string;
    currentOpenEnded: boolean;
    currentIfSelfManaged: boolean;
    currentGPName: string;
    currentFundLaunchDate: string;
}

export interface StorageStructureShortSelection {
    legalForm: string;
    fundName: string;
    domicileName: string;
    corporate: boolean;
    currentStructureId?: number;
}

export interface StorageStructureGPSelection {
    currentGPLegalForm: undefined | number;
    currentGPDomicile: undefined | number;
    currentGPName: string;
    currentGPShortName: string;
    currentGPLaunchDate: string;
    currentGPId?: number;
}

export interface StorageStructureGP extends StorageStructureGPSelection {
    domicileName: string;
    legalForm: string;
}

export interface StorageInvestor {
    id?: number;

    investorId: number;
    investorType: string;
    ifLegalEntity: boolean;
    domicileName: string;
    domicileId?: number;
    investorName: string;
}

export interface StorageInvestorData {
    domicileId: number | undefined;
    domicileName: string;
    items: StorageInvestor[];
}

export interface StorageAsset {
    id?: number;

    jTblAssetDomicileId: number;
    assetClass: string;
    currentAssetHoldingType: string;
    domicileId: number;
    domicileName: string;
}
export interface StorageAssetData {
    domicileId: number;
    domicileName: string;
    items: StorageAsset[];
}

export interface StorageServiceProviderParams {
    currentStructure: undefined | number;
    currentFundRegime: undefined | number;
    currentFundLegalForm: undefined | number;
    currentFundName: string;
    currentOpenEnded: boolean;
    currentIfSelfManaged: boolean;
    currentInvestorId: number[];
}

export interface StorageAssetManagerSelection {
    currentAssetManagerDomicileId: string | undefined;
    currentAMcounterpartyId: string | undefined;
    amCurrentRoleId: number | string | undefined;
    required?: boolean;
    specialCase?: boolean;
}

export interface StorageServiceProviderSelection {
    counterparty?: string | undefined;
    country?: string | undefined;
    serviceProviders: string;
    required?: boolean;
    specialCase?: boolean;

    // labels
    currentServiceProvider?: number;
    currentCounterParty?: string;
    domicileName?: string;

    // special case
    currentStructureId?: number;
    currentGPId?: number;

    // to fill
    currentAgreementId?: number;
    currentEffectiveDate?: string;
}

export interface StorageServiceProvider {
    id?: number;

    // from api
    serviceProvidersId: number;
    serviceProviders: string;
    domicileId: string;
    domicileName: string;
    investmentFund: string;
    investmentFundId: number;
    // required: boolean;

    // role?: string;
    // selection: StorageServiceProviderSelection[];

    // selected structure and gp ids
    currentStructureId?: number;
    currentGPId?: number;

    // to fill
    currentAgreementId?: number;
    currentEffectiveDate?: string;

    currentCounterPartyId?: number;
    currentServiceProvider?: number;

    currentCounterParty?: string;

    agreementParty3?: number;
    agreementParty4?: number;

    // special case
    agreementCurrentStructureId?: number;
    agreementCurrentGPId?: number;
}

export interface StorageAssetManagementSelection {
    // currentAMcounterpartyId: string;
    // currentAssetManagerDomicileId: string | undefined;
    // amCurrentRoleId: string;

    currentAMAgreementId?: number;
    amCurrentRoleId?: number;
    currentAMCounterPartyId?: number;
    currentAssetManagerType?: string;
    currentStructureId?: number;
    currentGPId?: number;
    agreementParty3?: number;
    agreementParty4?: number;
    currentAssetManagerDomicileId?: number;
}
export interface StorageAssetManagement {
    // investmentFundId: number;
    // investmentFund: string;
    // globalRegulationType: string;
    // managerType: string;

    // tmp val for front
    id?: number;

    // rest
    currentAMAgreementId?: number;
    amCurrentRoleId?: number;
    currentAMCounterPartyId?: number;
    currentAssetManagerType?: string;
    currentStructureId?: number;
    currentGPId?: number;
    agreementParty3?: number;
    agreementParty4?: number;
    currentAssetManagerDomicileId?: number;

    // special case
    agreementCurrentStructureId?: number;
    agreementCurrentGPId?: number;

    // from api
    assetManager?: string;
    assetManagerOptionsShort?: string;
    domicileId?: number;
    domicileName?: string;
    // amFull: string;

    // selection?: StorageAssetManagementSelection;

    // currentAssetManagerType: number;

    // to fill
    // currentAMAgreementId?: number;

    counterParty?: string;

    // for front to defined dropdowns
    isNew?: boolean;

    // special case
    // currentStructureId?: number;
    // currentGPId?: number;
}

export enum StorageStates {
    Edit = 'edit',
    Test = 'test',
    View = 'view',
}

export enum StorageNestedTypes {
    investors = 'investors',
    structure = 'structure',
    generalPartner = 'generalPartner',
    serviceProvider = 'serviceProvider',
    assets = 'assets',
    assetManagement = 'assetManagement',
}
