const getUrlParamsFromData = (data: {}) => {
    const tableUrl = new URLSearchParams();

    for (const entry of Object.entries(data)) {
        if (typeof entry[1] !== 'undefined' && entry[1] !== 'undefined') {
            tableUrl.set(entry[0], typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1]));
        }
    }

    return tableUrl;
};

export default getUrlParamsFromData;
