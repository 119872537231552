import { useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button } from '../../../../../../components';
import Select from '../../../../../../components/Select/Select';
import { Portal } from 'react-portal';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { v4 as uuidv4 } from 'uuid';

const AssetCreateForm = ({ onSave, onClose, generalInfo }: any) => {
    const [items, setItems] = useState<any[]>([{}]);

    const handleInputChange = (index: number) => (event: any) => {
        let tmp = [...items];
        tmp[index] = { ...tmp[index], label: event.target.value };
        setItems(tmp);
    };

    const handleSelectChange = (index: number) => (selectedData: any) => {
        let tmp = [...items];
        tmp[index] = {
            ...tmp[index],
            value: selectedData,
            participationFormId: selectedData.value,
            assetParticipation: `${tmp[index].label} // ${selectedData.label}`,
            participationForm: selectedData.label,
        };
        setItems(tmp);
    };

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                value: uuidv4(),
                label: '',
            },
        ]);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Asset type'}
                description={'Please provide asset type(s).'}
                isAbsolute={true}
                footerAdditional={
                    <Button type={'button'} outlined={true} onClick={handleAddItem}>
                        Add item
                    </Button>
                }
                footerMain={<Button form={'subForm'}>Upload</Button>}
                onClose={onClose}
            >
                <form id={'subForm'} onSubmit={onSave(items)}>
                    {items.map((item: any, index: number) => (
                        <div key={index}>
                            <Input required onChange={handleInputChange(index)} />
                            <Select
                                value={item.value}
                                onChange={handleSelectChange(index)}
                                options={generalInfo.participationForms}
                                valueKey={'participationFormId'}
                                labelKey={'participationForm'}
                            >
                                Allocation type
                            </Select>
                        </div>
                    ))}
                </form>
            </FormLayout>
        </Portal>
    );
};

export default AssetCreateForm;
