import styles from './button-styles.module.css';
import React from 'react';
import { Link, Path } from 'react-router-dom';

interface ButtonProps {
    to: string | Partial<Path>;
    children?: React.ReactNode;
    fullWidth?: boolean;
    outlined?: boolean;
    secondary?: boolean;
}

const LinkedButton = ({ to, children, outlined, secondary, fullWidth }: ButtonProps) => {
    return (
        <Link
            to={to}
            className={`${styles.btn} ${outlined ? styles.outline : ''} ${secondary ? styles.secondary : ''} ${
                fullWidth ? styles.fullWidth : ''
            }`}
        >
            {children}
        </Link>
    );
};

export default LinkedButton;
