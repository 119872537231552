// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deal-taxes-expanded_container__oUkD8 {\n    width: var(--container-width);\n    padding: 0 var(--container-padding);\n    margin: 0 auto;\n    position: relative;\n    max-width: calc(100% - 60px);\n    height: calc(100vh - var(--header-height));\n}\n\n.deal-taxes-expanded_pageHeader__0EMRY {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid #7b848e;\n    margin-bottom: 12px;\n}\n\n.deal-taxes-expanded_pageHeader__0EMRY h2 {\n    margin: 0 0 23px;\n}\n\n.deal-taxes-expanded_sectionWrapper__tLHxE {\n    max-height: calc(100vh - 350px);\n    margin-bottom: 40px;\n    padding-right: 10px;\n}\n\n.deal-taxes-expanded_btnWrapper__am50n {\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DealTaxesExpanded/deal-taxes-expanded.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,mCAAmC;IACnC,cAAc;IACd,kBAAkB;IAClB,4BAA4B;IAC5B,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    width: var(--container-width);\n    padding: 0 var(--container-padding);\n    margin: 0 auto;\n    position: relative;\n    max-width: calc(100% - 60px);\n    height: calc(100vh - var(--header-height));\n}\n\n.pageHeader {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid #7b848e;\n    margin-bottom: 12px;\n}\n\n.pageHeader h2 {\n    margin: 0 0 23px;\n}\n\n.sectionWrapper {\n    max-height: calc(100vh - 350px);\n    margin-bottom: 40px;\n    padding-right: 10px;\n}\n\n.btnWrapper {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "deal-taxes-expanded_container__oUkD8",
	"pageHeader": "deal-taxes-expanded_pageHeader__0EMRY",
	"sectionWrapper": "deal-taxes-expanded_sectionWrapper__tLHxE",
	"btnWrapper": "deal-taxes-expanded_btnWrapper__am50n"
};
export default ___CSS_LOADER_EXPORT___;
