const EditIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g id="Group_6124" data-name="Group 6124" transform="translate(-21 -9)">
                <g id="Path_3160" data-name="Path 3160" transform="translate(21 9)" fill="none">
                    <path
                        d="M 12.5 24.5 C 9.294679641723633 24.5 6.281219959259033 23.25177955627441 4.01471996307373 20.98527908325195 C 1.748219966888428 18.71878051757812 0.5 15.70532035827637 0.5 12.5 C 0.5 9.294679641723633 1.748219966888428 6.281219959259033 4.01471996307373 4.01471996307373 C 6.281219959259033 1.748219966888428 9.294679641723633 0.5 12.5 0.5 C 15.70532035827637 0.5 18.71878051757812 1.748219966888428 20.98527908325195 4.01471996307373 C 23.25177955627441 6.281219959259033 24.5 9.294679641723633 24.5 12.5 C 24.5 15.70532035827637 23.25177955627441 18.71878051757812 20.98527908325195 20.98527908325195 C 18.71878051757812 23.25177955627441 15.70532035827637 24.5 12.5 24.5 Z"
                        stroke="none"
                    />
                    <path
                        d="M 12.5 1 C 9.428239822387695 1 6.54033088684082 2.196210861206055 4.368270874023438 4.368270874023438 C 2.196210861206055 6.54033088684082 1 9.428239822387695 1 12.5 C 1 15.5717601776123 2.196210861206055 18.45967102050781 4.368270874023438 20.63172912597656 C 6.54033088684082 22.80379104614258 9.428239822387695 24 12.5 24 C 15.5717601776123 24 18.45967102050781 22.80379104614258 20.63172912597656 20.63172912597656 C 22.80379104614258 18.45967102050781 24 15.5717601776123 24 12.5 C 24 9.428239822387695 22.80379104614258 6.54033088684082 20.63172912597656 4.368270874023438 C 18.45967102050781 2.196210861206055 15.5717601776123 1 12.5 1 M 12.5 0 C 19.40356063842773 0 25 5.596439361572266 25 12.5 C 25 19.40356063842773 19.40356063842773 25 12.5 25 C 5.596439361572266 25 0 19.40356063842773 0 12.5 C 0 5.596439361572266 5.596439361572266 0 12.5 0 Z"
                        stroke="none"
                        fill="var(--icon-color)"
                    />
                </g>
                <path
                    id="Path_3161"
                    data-name="Path 3161"
                    d="M7675.756,5108.606l-10.572,10.573-5.428-5.428"
                    transform="translate(-7634.256 -5092.393)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="1"
                />
            </g>
        </svg>
    );
};

export default EditIcon;
