import { useEffect, useState } from 'react';
import FormWrapper from '../../Storage/layout/FormWrapper/FormWrapper';
import FormLayout from '../../Storage/layout/FormLayout/FormLayout';
import FormCollapsableRow from '../../Storage/layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../components/Input/Input';
import styles from './styles.module.css';

const ComplianceLawToBeUpdatedView = ({ name, onClose, selectedData }: any) => {
    const [groupedData, setGroupedData] = useState<any>({
        1: [],
        2: [],
    });

    useEffect(() => {
        setGroupedData(
            selectedData.reduce((array: any, item: any) => {
                const obj = array[item.position];
                if (!obj) {
                    array[item.position] = [...(array[item.position] || []), item];
                } else {
                    obj.push(item);
                }

                return array;
            }, {})
        );
    }, [selectedData]);

    return (
        <FormWrapper>
            <FormLayout
                title={name}
                description={'Here is the list of laws that will be revised soon.'}
                onClose={onClose}
                useMargin={false}
            >
                <FormCollapsableRow
                    titleLabel={`1. Laws and Legislations that will be revised soon`}
                    hideHeader={false}
                    useGrid={false}
                    labelFullWidth={true}
                >
                    {groupedData[1]?.map((item: any, i: number) => (
                        <div className={styles.row} key={i}>
                            <Input readOnly={true} value={item.law}>
                                Law
                            </Input>
                            <Input readOnly={true} value={item.efDate}>
                                Effective date
                            </Input>
                            <Input disabled={true} checked={false} type={'checkbox'} fullWidth={false}>
                                Reviewed?
                            </Input>
                        </div>
                    ))}
                </FormCollapsableRow>

                <FormCollapsableRow
                    titleLabel={`2. Pending review Here is the list of laws updates.`}
                    hideHeader={false}
                    useGrid={false}
                    labelFullWidth={true}
                >
                    <p className={styles.p}>No status update. </p>

                    {groupedData[2]?.map((item: any, i: number) => (
                        <div className={styles.row} key={i}>
                            <Input readOnly={true} value={item.law}>
                                Law
                            </Input>
                            <Input readOnly={true} value={item.efDate}>
                                Effective date
                            </Input>
                            <Input disabled={true} checked={false} type={'checkbox'} fullWidth={false}>
                                Reviewed?
                            </Input>
                        </div>
                    ))}
                </FormCollapsableRow>
            </FormLayout>
        </FormWrapper>
    );
};

export default ComplianceLawToBeUpdatedView;
