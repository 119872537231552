import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from '../../../../utils/axios';
import styles from '../storage.module.css';
import { Button } from '../../../../components/Button';
import OverviewFolder from '../../../../components/Overview/OverviewCategories/OverviewFolder';
import { PresetActions } from '../../../../components/Preset';
import PresetLink from '../../../../components/Preset/components/PresetLink';

const StorageContent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [structures, setStructures] = useState([]);

    const [countryId, setCountryId] = useState<number | null>(null);

    const handleDelete = () => {};

    console.log(location);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        console.log('params.countryId', params.get('countryId'));

        setCountryId(params.get('countryId') ? +params.get('countryId')! : null);

        (async () => {
            const { data } = await axios.get(
                `storage${params.get('countryId') ? `/country/${params.get('countryId')}` : ''}`
            );
            setStructures(data);
        })();
    }, [location]);

    return (
        <>
            <div className={styles.content}>
                <div className={styles.title}>
                    <h3>You stored structures:</h3>

                    {countryId !== null && <Button onClick={() => navigate('/storage')}>Collapse all</Button>}
                </div>

                <div className={styles.items}>
                    {countryId === null
                        ? structures.map((item: any, i: number) => (
                              <a
                                  href={`/storage?countryId=${item.domicileId}`}
                                  key={i}
                                  onClick={(e) => {
                                      e.preventDefault();
                                      navigate(`/storage?countryId=${item.domicileId || 0}`);
                                  }}
                              >
                                  <OverviewFolder
                                      type={'secondary'}
                                      name={item.domicileName}
                                      image={item.countOfStructures ? '/images/thumb-storage.png' : ''}
                                      itemLength={item.countOfStructures}
                                  />
                              </a>
                          ))
                        : structures.map((item: any, i: number) => (
                              <div key={i} className={styles.item}>
                                  <PresetActions handleDelete={handleDelete} />
                                  <PresetLink
                                      img={'/images/thumb-storage.png'}
                                      name={'Stored structure'}
                                      link={`storage/${item.vehicleId}`}
                                  >
                                      <p>Name: {item.name}</p>
                                      <p>Domicile: {item.domicileName}</p>
                                      <p>Stored: {item.dbDate}</p>
                                  </PresetLink>
                              </div>
                          ))}
                </div>
            </div>
        </>
    );
};

export default StorageContent;
