import create from 'zustand';
import axios from '../utils/axios';
import { useStorageStore } from './useStorageStore';
import { StorageNestedTypes, StorageAsset, StorageInvestorData } from '../types/storage';
import { STORAGE_ASSETS_API } from '../utils/constants';

interface Domicile {
    domicileName: string;
    domicileId: number;
}

interface StorageStore {
    children: StorageNestedTypes[];

    clearDeleted: () => void;

    domiciles: Domicile[];
    assetsData: StorageInvestorData[];
    getSelectedAssets: () => void;
    setAssetsData: (country: string) => void;
    selectedAssets: StorageAsset[];
    // updateSelectedAssets: (domicile: string, investor: StorageAsset) => void;
    getDomiciles: () => Promise<Domicile[]>;
    getAssetsData: any;
    saveSelectedAssets: (AssetsIds: number[], structureId: number) => any;
    setSelectedAssets: (currentStructureId: number, assets: StorageAsset[]) => Promise<void>;
}

export const useStorageAssetsStore = create<StorageStore>((set, get) => ({
    children: [],

    domiciles: [],
    assetsData: [],
    selectedAssets: [],
    getDomiciles: async (): Promise<Domicile[]> => {
        const { data } = await axios.get(`${STORAGE_ASSETS_API}/domiciles`);

        set({
            domiciles: data,
        });

        return data;
    },
    getSelectedAssets: async () => {
        const { data } = await axios.get(`${STORAGE_ASSETS_API}`);

        set({
            selectedAssets: data,
        });

        return data;
    },
    // updateSelectedAssets: (domicile: string, investor: StorageAsset) => {
    //     set((prev) => ({
    //         selectedAssets: [...prev.selectedAssets, investor],
    //     }));
    // },
    setSelectedAssets: async (currentStructureId: number, assets: StorageAsset[]) => {
        const { data } = await axios.post(`${STORAGE_ASSETS_API}`, {
            currentStructureId,
            currentAssets: assets.map((item) => ({
                currentAssetId: item.jTblAssetDomicileId,
                currentAssetHoldingType: item.currentAssetHoldingType,
            })),
        });

        console.log('savedInvestors', data);

        for (const asset of assets) {
            const saved = data.find((i: any) => i.jTblAssetDomicileId === asset.jTblAssetDomicileId);
            asset.id = saved.jTblAssetStructure;
        }

        console.log('investors', assets);

        set(() => ({
            selectedAssets: assets,
        }));
    },
    setAssetsData: async (country: string) => {
        const { data } = await axios.get(`${STORAGE_ASSETS_API}/domicile?country=${country}`);

        // set((prev) => ({
        //     assetsData: [
        //         ...prev.assetsData,
        //         {
        //             domicile: country,
        //             items: data,
        //         },
        //     ],
        // }));

        return data;
    },
    getAssetsData: async (country: string) => {
        console.log('CODOIOVSVJ', country);
        const { data } = await axios.get(`${STORAGE_ASSETS_API}/domicile?country=${country}`);

        console.log('DATTATAAT', data);

        return data;
    },
    saveSelectedAssets: async (assetsIds, structureId) => {
        const { data } = await axios.post(``, {
            currentAssetId: assetsIds,
            currentStructureId: structureId,
        });

        return data;
    },
    clearDeleted: () => {
        const tmp = get().selectedAssets;

        for (const id of useStorageStore.getState().itemsToDelete.assets) {
            const index = tmp.findIndex((i) => i.id === id);
            tmp.splice(index, 1);
        }

        set({
            selectedAssets: tmp,
        });
    },
}));
