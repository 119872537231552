import styles from './header-styles.module.css';
import { Link, NavLink } from 'react-router-dom';
import Aside from '../Aside/Aside';
import { useState, useEffect, useContext } from 'react';

import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../context';
import { useMenuStore } from '../../../store/useMenuStore';

const Header = () => {
    const [setIsOpen] = useMenuStore((state) => [state.setIsOpen]);

    let location = useLocation();

    const { user } = useContext(UserContext);

    const [isOptimisation, setIsOptimisation] = useState(false);

    useEffect(() => {
        setIsOpen(false);
        setIsOptimisation(location.search.indexOf('optimisation=true') > -1);
    }, [location]);

    return (
        <>
            <header className={styles.header} id={'header'}>
                <div className={styles.wrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>
                            {location.pathname === '/login' || location.pathname === '/' ? (
                                <a href={`${process.env.REACT_APP_LANDING_URL}`}>
                                    <img src={'/logo-full.svg'} alt="white-thesis" />
                                </a>
                            ) : (
                                <Link to={user ? '/' : '/login'}>
                                    <img src={'/logo-full.svg'} alt="white-thesis" />
                                </Link>
                            )}
                        </div>
                    </div>

                    <div className={styles.nav}>
                        <NavLink
                            className={({ isActive }) =>
                                (!isOptimisation && isActive ? styles.active : '') +
                                ` ${styles.navItem} ${!user ? styles.inactive : ''}
                                }`
                            }
                            to={'/deal-taxes'}
                        >
                            Deal taxes
                        </NavLink>
                        <NavLink
                            className={({ isActive }) =>
                                (isActive ? styles.active : '') +
                                ` ${styles.navItem} ${!user ? styles.inactive : ''}  ${
                                    location.pathname === '/comparison-table' ? styles.activeOpt : ''
                                }`
                            }
                            to={'/legal-structures'}
                        >
                            Legal structures
                        </NavLink>
                        <NavLink
                            className={({ isActive }) =>
                                (isOptimisation || isActive ? styles.active : '') +
                                ` ${styles.navItem} ${!user ? styles.inactive : ''}`
                            }
                            to={'/tax-optimisation'}
                        >
                            Tax optimisation
                        </NavLink>

                        <NavLink
                            className={({ isActive }) =>
                                (isActive ? styles.active : '') + ` ${styles.navItem} ${!user ? styles.inactive : ''}`
                            }
                            to={'/storage'}
                        >
                            Storage
                        </NavLink>

                        <NavLink
                            className={({ isActive }) =>
                                (isActive ? styles.active : '') +
                                ` ${styles.navItem}
                                }`
                            }
                            to={'/contact'}
                        >
                            Contact us
                        </NavLink>
                    </div>
                    <div className={styles.login}>
                        <Link to={user ? '/account' : '/login'}>
                            {user && <span>Hello, dear {user.username}</span>}

                            <span className={styles.icon}>
                                <img src="/icons/user.svg" alt="" />
                                <img src="/icons/user-active.svg" alt="" className={styles.iconActive} />
                            </span>
                        </Link>
                    </div>
                </div>
            </header>

            <Aside />
        </>
    );
};

export default Header;
