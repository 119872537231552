import { useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button } from '../../../../../../components';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Portal } from 'react-portal';

const NotesCreateForm = ({ selectedData, countries, onSave, onClose }: any) => {
    const [items, setItems] = useState<any[]>(selectedData);

    const handleInputChange = (prop: string) => (event: any) => {
        setItems(event.target.value);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Notes and comments'}
                description={'Your notes and comments for the structure.'}
                isAbsolute={true}
                footerMain={<Button onClick={onSave('notes', items)}>Upload</Button>}
                onClose={onClose}
            >
                <Input
                    type={'textarea'}
                    style={'text'}
                    rows={13}
                    max={5000}
                    name={'notes'}
                    value={items}
                    onChange={handleInputChange('notes')}
                />
            </FormLayout>
        </Portal>
    );
};

export default NotesCreateForm;
