import { Button, Icon } from '../../../../../components';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import { useEffect, useState } from 'react';
import axios from '../../../../../utils/axios';
import { StorageType } from '../../StorageForm';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayoutFooter from '../../../layout/FormLayout/FormLayoutFooter';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from '../../../../../components/Icon/Icon';

enum Forms {
    FundType = 'FundType',
    FundRegime = 'FundRegime',
    LegalForm = 'LegalForm',
}

const StorageOther = ({ selectedData, generalInfo, setGeneralInfo, handleNextStep, handleClose }: any) => {
    const [activeForm, setActiveForm] = useState<Forms | null>(null);

    const [data, setData] = useState<any>({
        investmentFund: undefined,
        fundRegime: undefined,
        fundLegalForm: undefined,
        ...selectedData,
    });

    const handleInputChange = (prop: string) => (e: any) => {
        const value = e.target.value;

        setData({
            ...data,
            [prop]: value,
        });
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        let obj = {
            ...data,
            [prop]: value,
        };

        console.log('prop', prop);
        if (prop === 'fundDomicile') {
            getDataByCountryId(+value.value);

            obj = {
                ...obj,
                investmentFund: undefined,
                fundRegime: undefined,
                fundLegalForm: undefined,
            };
        }

        setData(obj);
    };

    const getDataByCountryId = async (id: number) => {
        const { data } = await axios(`/storage/general/${id}`);

        console.log('getDataByCountryId, ', data);

        setGeneralInfo({ ...generalInfo, ...data });
    };

    const closeActiveForm = (type: keyof typeof generalInfo, valueKey: keyof typeof data, label: string) => {
        const id = uuidv4();

        setGeneralInfo({
            ...generalInfo,
            [type]: [
                ...generalInfo[type],
                {
                    value: id,
                    label: label,
                },
            ],
        });

        setData({
            ...data,
            [valueKey]: {
                value: id,
                label: label,
            },
        });

        console.log(
            {
                [valueKey]: id,
            },
            {
                ...data,
                [valueKey]: id,
            }
        );

        setActiveForm(null);
    };

    // const handleCreateOption = (prop: string) => {
    //     if (prop === 'investmentFund') {
    //         setActiveForm(Forms.FundType);
    //     } else if (prop === 'fundRegime') {
    //         setActiveForm(Forms.FundRegime);
    //     } else if (prop === 'fundLegalForm' ) {
    //         setActiveForm(Forms.LegalForm);
    //     }
    // }

    const handleCloseSubForm = () => {
        setActiveForm(null);
    };

    useEffect(() => {
        console.log('generalInfo.countries', generalInfo.countries);
    }, [generalInfo.countries]);

    return (
        <FormWrapper>
            <FormLayout
                title={'Investment vehicle'}
                description={'Please fill in the basic information about the investment vehicle.'}
                footerMain={
                    <Button form={'mainForm'} disabled={!data.fundName}>
                        Next
                    </Button>
                }
                handleClose={handleClose}
            >
                <form id="mainForm" onSubmit={handleNextStep(data, StorageType.GeneralPartner)}>
                    <Input required value={data.fundName} onChange={handleInputChange('fundName')}>
                        Fund name*
                    </Input>
                    <Input value={data.fundLaunchDate} onChange={handleInputChange('fundLaunchDate')}>
                        Launch date
                    </Input>
                    <Select disabled={true} placeholder={'Choose structure'}>
                        Link to another structure
                    </Select>

                    <Select
                        value={data.fundDomicile}
                        onChange={handleSelectChange('fundDomicile')}
                        options={generalInfo.countries}
                        valueKey={'domicileId'}
                        labelKey={'domicileName'}
                    >
                        Domicile
                    </Select>
                    <Select
                        value={data.fundStructureLevel}
                        onChange={handleSelectChange('fundStructureLevel')}
                        options={generalInfo.structureTypes}
                        valueKey={'structureLevelId'}
                        labelKey={'type'}
                    >
                        Structure type
                    </Select>
                    <Select
                        disabled={!data.fundDomicile}
                        value={data.investmentFund}
                        onChange={handleSelectChange('investmentFund')}
                        options={generalInfo.investmentFund}
                        valueKey={'investmentFundId'}
                        labelKey={'investmentFundComb'}
                        onCreateOption={() => {
                            setActiveForm(Forms.FundType);
                        }}
                    >
                        Fund type
                    </Select>
                    <Select
                        disabled={!data.fundDomicile}
                        value={data.fundRegime}
                        onChange={handleSelectChange('fundRegime')}
                        options={generalInfo.fundRegime}
                        valueKey={'fundRegimeId'}
                        labelKey={'fundRegime'}
                        onCreateOption={() => {
                            setActiveForm(Forms.FundRegime);
                        }}
                    >
                        Fund regime
                    </Select>
                    <Select
                        disabled={!data.fundDomicile}
                        value={data.fundLegalForm}
                        onChange={handleSelectChange('fundLegalForm')}
                        options={generalInfo.legalForm}
                        valueKey={'legalFormId'}
                        labelKey={'legalFormComb'}
                        onCreateOption={() => {
                            setActiveForm(Forms.LegalForm);
                        }}
                    >
                        Legal form
                    </Select>

                    <FormLayoutFooter></FormLayoutFooter>
                </form>
            </FormLayout>

            {activeForm === Forms.FundType && (
                <FormLayout
                    title={'Fund type'}
                    description={'Please provide the full name and the short name (if any) of the fund type.'}
                    isAbsolute={true}
                    onClose={handleCloseSubForm}
                    footerMain={
                        <Button form={'subForm'} disabled={!data.fundFullNameManual}>
                            Upload
                        </Button>
                    }
                >
                    <form
                        id={'subForm'}
                        onSubmit={(e) => {
                            e.preventDefault();
                            closeActiveForm('investmentFund', 'investmentFund', data.fundFullNameManual);
                        }}
                    >
                        <Input
                            onChange={handleInputChange('fundFullNameManual')}
                            type={'textarea'}
                            style={'text'}
                            max={300}
                            rows={2}
                        >
                            Full name
                        </Input>
                        <Input onChange={handleInputChange('fundShortNameManual')} style={'text'} max={50}>
                            Short name / abbreviation
                        </Input>
                    </form>
                </FormLayout>
            )}

            {activeForm === Forms.FundRegime && (
                <FormLayout
                    title={'Fund regime'}
                    description={'Please provide the full name and the short name (if any) of the fund type.'}
                    isAbsolute={true}
                    onClose={handleCloseSubForm}
                    footerMain={
                        <Button form={'subForm'} disabled={!data.fundRegimeFullNameManual}>
                            Upload
                        </Button>
                    }
                >
                    <form
                        id={'subForm'}
                        onSubmit={(e) => {
                            e.preventDefault();
                            closeActiveForm('fundRegime', 'fundRegime', data.fundRegimeShortNameManual);
                        }}
                    >
                        <Input
                            onChange={handleInputChange('fundRegimeFullNameManual')}
                            type={'textarea'}
                            style={'text'}
                            max={300}
                            rows={2}
                        >
                            Full name
                        </Input>
                        <Input onChange={handleInputChange('fundRegimeShortNameManual')} style={'text'} max={50}>
                            Short name / abbreviation
                        </Input>
                    </form>
                </FormLayout>
            )}

            {activeForm === Forms.LegalForm && (
                <FormLayout
                    title={'Legal form'}
                    description={'Please provide the full and the short name (if any) of the legal form.'}
                    isAbsolute={true}
                    onClose={handleCloseSubForm}
                    footerMain={
                        <Button form={'subForm'} disabled={!data.legalFormFullNameManual}>
                            Upload
                        </Button>
                    }
                >
                    <form
                        id={'subForm'}
                        onSubmit={(e) => {
                            e.preventDefault();
                            closeActiveForm('legalForm', 'fundLegalForm', data.legalFormShortNameManual);
                        }}
                    >
                        <Input
                            onChange={handleInputChange('legalFormFullNameManual')}
                            type={'textarea'}
                            style={'text'}
                            max={300}
                            rows={2}
                        >
                            Full name
                        </Input>
                        <Input onChange={handleInputChange('legalFormShortNameManual')} style={'text'} max={50}>
                            Short name / abbreviation
                        </Input>

                        <Select
                            value={data.legalFormTypeManual}
                            onChange={handleSelectChange('legalFormTypeManual')}
                            options={generalInfo.legalTypes}
                            valueKey={'legalTypeId'}
                            labelKey={'legalType'}
                            style={'secondary'}
                        >
                            Legal form
                        </Select>
                    </form>
                </FormLayout>
            )}
        </FormWrapper>
    );
};

export default StorageOther;
