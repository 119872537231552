import React, { useRef } from 'react';
import Loader from '../Loader/Loader';
import TransitionComponent, { TransitionComponentProps } from './TransitionComponents';

interface LoaderComponentProps extends TransitionComponentProps {
    children: React.ReactNode;
    hideLoader?: boolean;
}
const LoaderComponent = ({ children, inProp, hideLoader }: LoaderComponentProps) => {
    const nodeRef = useRef(null);

    return (
        <>
            <Loader inProp={!inProp && !hideLoader} isAbsolute={true} />
            <TransitionComponent inProp={inProp}>{children}</TransitionComponent>
        </>
    );
};

export default LoaderComponent;
