import {
    GroupedSelectionMulti,
    GroupedSelectionSingle,
} from '../components/SelectionPopup/SelectionPopupRowMulti/SelectionPopupRowMulti';
import { SelectedItemAsset, SelectedItemExtended } from '../components/SelectionPopup/SelectionPopup';

export const formatAllocation = (value: number | string, maxValue = 100) => {
    return +Math.max(0, Math.min(+value, maxValue)).toFixed(2);
};

interface GetValidAllocationProps {
    currentValue: number;
    value: number;
    skipCheck?: boolean;
    groupedData?: GroupedSelectionSingle | GroupedSelectionMulti;
    ungroupedData?: SelectedItemExtended[] | SelectedItemAsset[];
}
export const getAllocationTotal = ({
    currentValue,
    groupedData,
    ungroupedData,
    skipCheck,
}: GetValidAllocationProps) => {
    let totalValues = 0;
    const groupedItems = !skipCheck
        ? !groupedData
            ? []
            : groupedData && 'items' in groupedData
            ? groupedData!.items
            : [groupedData.item]
        : [];

    if (currentValue) totalValues = -currentValue;
    if (!skipCheck && groupedItems) {
        for (const item of groupedItems) {
            totalValues += +item.assetAllocation;
        }
    }

    if (!skipCheck && ungroupedData) {
        for (const item of ungroupedData) {
            totalValues += +item.assetAllocation;
        }
    }

    return totalValues;
};

export const getValidAllocation = ({
    currentValue,
    value,
    skipCheck = false,
    groupedData,
    ungroupedData,
}: GetValidAllocationProps): number => {
    const totalValues = getAllocationTotal({
        currentValue,
        value,
        skipCheck,
        groupedData,
        ungroupedData,
    });

    return formatAllocation(value, 100 - totalValues);
};
