import { useEffect, useState } from 'react';
import StorageNavigation from './components/StorageNavigation/StorageNavigation';
import StorageCurrentStatus from './components/StorageCurrentStatus/StorageCurrentStatus';
import StorageStructure from './components/forms/StorageStructure';
import axios from '../../../utils/axios';
import StorageGeneralPartner from './components/forms/StorageGeneralPartner';
import StorageInvestors from './components/forms/StorageInvestors';
import StorageAssets from './components/forms/StorageAssets';
import StorageAssetManager from './components/forms/StorageAssetManager';
import StorageOtherProperties from './components/forms/StorageOtherProperties';
import StorageServiceProviders from './components/forms/StorageServiceProviders';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import StorageFormSummary from './components/StorageFormSummary/StorageFormSummary';

import styles from './styles.module.css';
import selectionStyles from '../../../components/SelectionPopup/SelectionPopupBase/selection-popup.module.css';
import StorageOtherPropertiesPartTwo from './components/forms/StorageOtherPropertiesPartTwo';

export enum StorageType {
    Structure = 'Structure',
    GeneralPartner = 'GeneralPartner',
    Investors = 'Investors',
    Assets = 'Assets',
    AssetManager = 'AssetManager',
    ServiceProviders = 'ServiceProviders',
    Other = 'Other',
    OtherPartTwo = 'OtherPartTwo',
}

const StorageForm = () => {
    const navigate = useNavigate();

    const [activeStorageType, setActiveStorageType] = useState<StorageType | null>(StorageType.Structure);

    const [loading, setLoading] = useState(true);
    const [generalInfo, setGeneralInfo] = useState({
        investmentFund: [],
        fundRegime: [],
        legalForm: [],
        gpFundRegime: [],
        gpLegalForm: [],
        gpInvestmentFund: [],
        legalTypes: [],
        licenses: [],
        laws: [],
        doubleTaxTreaties: [],
        taxStatus: [],
    });

    const [savedData, setSavedData] = useState(null);

    const [showJson, setShowJson] = useState(false);

    const [structureData, setStructureData] = useState(
        JSON.parse(sessionStorage.getItem('storage') || '{}') || {
            investmentFund: {},
            gp: {},
            investors: [],
            assets: [],
        }
    );

    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await axios('/storage/general');

            console.log('data, ', data);

            setGeneralInfo(data);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        setFormIsValid(!!structureData[StorageType.Structure]?.fundName);

        if (
            !loading &&
            structureData[StorageType.Structure]?.fundDomicile?.value &&
            structureData[StorageType.GeneralPartner]?.domicile?.value &&
            structureData[StorageType.Assets]?.length &&
            structureData[StorageType.Investors]?.length
        ) {
            (async () => {
                const { data } = await axios(
                    `/storage/double-tax-treaties?${new URLSearchParams({
                        fundDomicileId: structureData[StorageType.Structure].fundDomicile.value,
                        gpDomicileId: structureData[StorageType.GeneralPartner].domicile.value,
                        assetDomicileId: structureData[StorageType.Assets].map((i: any) => i.domicile.value),
                        investorDomicileId: structureData[StorageType.Investors].map((i: any) => i.domicile.value),
                    })}`
                );

                console.log('data, ', data);
                console.log('generalInfo', generalInfo);

                setGeneralInfo({
                    ...generalInfo,
                    doubleTaxTreaties: data,
                });
            })();
        }
    }, [structureData, loading]);

    const handleNextStep = (data: any, nextType?: StorageType) => (e: any) => {
        e.preventDefault();

        const tmp = {
            ...structureData,
            [activeStorageType!]: data,
        };

        console.log('before save', structureData, data);

        sessionStorage.setItem('storage', JSON.stringify(tmp));

        console.log('!!DATA', tmp);

        setStructureData(tmp);

        setActiveStorageType(nextType || null);
    };

    const handleSubmit = (data?: any) => async (e: any) => {
        e.preventDefault();

        let finalData = {
            ...structureData,
        };

        if (activeStorageType) {
            finalData[activeStorageType] = data;

            setStructureData(finalData);

            console.log('!!DATA', finalData);
        }

        try {
            const res = await axios.post(`/storage`, finalData);

            console.log('res', res);

            // setSavedData(res.data);
            // setShowJson(true);

            sessionStorage.removeItem('storage');

            if (res.data.structureId) {
                navigate(`/storage/${res.data.structureId}`);
            }
        } catch (e) {
            alert(
                JSON.stringify({
                    e,
                })
            );
        }
    };

    const handleClose = () => {
        setActiveStorageType(null);
    };

    return (
        <div
            className={`${styles.page} ${
                activeStorageType === StorageType.Structure || activeStorageType === StorageType.GeneralPartner
                    ? styles.margin
                    : ''
            }`}
        >
            {activeStorageType ? (
                <>
                    <div className={styles.navigation}>
                        <StorageNavigation
                            activeStorageType={activeStorageType}
                            setActiveStorageType={setActiveStorageType}
                        />
                    </div>

                    <div
                        className={`${selectionStyles.selectionBox} ${selectionStyles.main} ${
                            activeStorageType === StorageType.Structure ||
                            activeStorageType === StorageType.GeneralPartner
                                ? selectionStyles.selectionBoxSmaller
                                : ''
                        }`}
                    >
                        <div className={selectionStyles.selectionBoxInner}>
                            {activeStorageType === StorageType.Structure && (
                                <StorageStructure
                                    selectedData={structureData[StorageType.Structure]}
                                    generalInfo={generalInfo}
                                    setGeneralInfo={setGeneralInfo}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}
                            {activeStorageType === StorageType.GeneralPartner && (
                                <StorageGeneralPartner
                                    selectedData={structureData[StorageType.GeneralPartner]}
                                    generalInfo={generalInfo}
                                    setGeneralInfo={setGeneralInfo}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}
                            {activeStorageType === StorageType.Investors && (
                                <StorageInvestors
                                    selectedData={structureData[StorageType.Investors]}
                                    generalInfo={generalInfo}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}
                            {activeStorageType === StorageType.Assets && (
                                <StorageAssets
                                    selectedData={structureData[StorageType.Assets]}
                                    generalInfo={generalInfo}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}
                            {activeStorageType === StorageType.AssetManager && (
                                <StorageAssetManager
                                    selectedData={structureData[StorageType.AssetManager]}
                                    generalInfo={generalInfo}
                                    setGeneralInfo={setGeneralInfo}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}
                            {activeStorageType === StorageType.ServiceProviders && (
                                <StorageServiceProviders
                                    selectedData={structureData[StorageType.ServiceProviders]}
                                    generalInfo={generalInfo}
                                    setGeneralInfo={setGeneralInfo}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}
                            {activeStorageType === StorageType.Other && (
                                <StorageOtherProperties
                                    selectedData={structureData[StorageType.Other]}
                                    generalInfo={generalInfo}
                                    setGeneralInfo={setGeneralInfo}
                                    handleSubmit={handleSubmit}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}

                            {activeStorageType === StorageType.OtherPartTwo && (
                                <StorageOtherPropertiesPartTwo
                                    selectedData={structureData[StorageType.OtherPartTwo]}
                                    generalInfo={generalInfo}
                                    setGeneralInfo={setGeneralInfo}
                                    handleSubmit={handleSubmit}
                                    handleNextStep={handleNextStep}
                                    handleClose={handleClose}
                                />
                            )}

                            {showJson && (
                                <div
                                    style={{
                                        width: '1200px',
                                        margin: ' 200px auto',
                                        position: 'absolute',
                                        zIndex: 10,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background: 'rgba(251, 251, 252)',
                                        height: '100%',
                                        border: '1px solid grey',
                                        padding: '30px',
                                        maxHeight: '80vh',
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            setShowJson(false);
                                        }}
                                        style={{
                                            margin: '20px 0',
                                            border: '1px solid grey',
                                        }}
                                    >
                                        hide
                                    </button>
                                    <div
                                        style={{
                                            display: 'grid',
                                            justifyContent: 'center',
                                            gridGap: '24px',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                        }}
                                    >
                                        <div>
                                            <h6>sent</h6> <br />
                                            <pre
                                                style={{
                                                    wordWrap: 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                    maxHeight: '60vh',
                                                    overflow: 'auto',
                                                }}
                                            >
                                                {JSON.stringify(structureData, null, 4)}
                                            </pre>
                                        </div>

                                        <div>
                                            <h6>saved</h6> <br />
                                            {savedData && (
                                                <pre
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                        maxHeight: '60vh',
                                                        overflow: 'auto',
                                                    }}
                                                >
                                                    {JSON.stringify(savedData, null, 4)}
                                                </pre>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <StorageCurrentStatus
                        canSave={formIsValid}
                        onSave={handleSubmit()}
                        handleClose={handleClose}
                        activeStates={{
                            structure: !!structureData[StorageType.Structure]?.fundName,
                            investors: !!structureData[StorageType.Investors]?.length,
                            assets: !!structureData[StorageType.Assets]?.length,
                            assetManager: !!structureData[StorageType.AssetManager]?.length,
                            serviceProviders: !!structureData[StorageType.ServiceProviders]?.length,
                        }}
                    >
                        <Button
                            onClick={() => {
                                setShowJson(true);
                            }}
                        >
                            Show json
                        </Button>
                    </StorageCurrentStatus>
                </>
            ) : (
                <StorageFormSummary
                    selectedData={structureData}
                    setActiveStorageType={setActiveStorageType}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    );
};

export default StorageForm;
