import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useDiagramStore } from '../../store/useDiagramStore';
import axios from '../../utils/axios';

import styles from './tax-optimisation.module.css';
import TaxBox from '../TaxOptimisation/components/TaxBox/TaxBox';
import { PdfTemplateGraphLayout } from '../../components/PdfTemplate';
import TaxOptimisationInfo from './components/TaxOptimisationInfo';
import { SelectionCombination } from '../../components/SelectedCombination';
import { Taxes, TaxOptimisationSelectedData, TaxSortedPerStructure } from '../TaxOptimisation/TaxOptimisation';
import { GraphTypes, TCountryCode } from '../../types/general';

// const duration = 600;

// const defaultStyle = {
//     transition: `opacity ${duration}ms ease-in-out`,
//     opacity: 0,
// };

const TaxOptimisationTemplate = () => {
    const [selectedData, setSelectedData] = useState<TaxOptimisationSelectedData | undefined>();

    const [labels, setLabels] = useState<undefined | Taxes>();

    const [groups, setGroups] = useState<
        | undefined
        | {
              [key: string]: TaxSortedPerStructure[][];
          }
    >(undefined);

    const [openTaxIndex, setOpenTaxIndex] = useState<undefined | number>();

    const [selectedItems, setSelectedItems] = useState<{
        type: string;
        items: number[];
    }>({
        type: 'group',
        items: [],
    });

    useEffect(() => {
        // @ts-ignore
        console.log('window.pdfData', window.pdfData);

        // @ts-ignore
        setSelectedItems({
            // @ts-ignore
            type: window.pdfData.selectedData.type,
            // @ts-ignore
            items: window.pdfData.selectedData.selectedItems,
        });

        // @ts-ignore
        setSelectedData(window.pdfData.selectedData);
    }, []);

    useEffect(() => {
        // @ts-ignore
        formatData(window.pdfData.data);
    }, [selectedItems]);

    const getItemUrl = (item: TaxSortedPerStructure) => {
        const tmp = {
            ...selectedData,
            investmentFund: item.investmentFund,
            openEnded: item.ifOpenEnded,
            corporate: item.legalType === 'corporate',
            // ifCommercialPartnership: item.furtherStatus == 'commercial',
            fundStatus: item.furtherStatus === 'ko' || item.furtherStatus === 'n/a' ? undefined : item.furtherStatus,
            ifBusinessAssets: item.ifBusinessAssets,
            booked: item.bookingType == 'true',
        };

        const entries = Object.entries(tmp).map((item) => item.map((sub) => sub?.toString() || ''));

        return new URLSearchParams(entries).toString();
    };

    const formatData = (data: any) => {
        if (selectedItems.items.length) {
            data.sortedTaxesPerStructure = data.sortedTaxesPerStructure?.filter((item: any) => {
                return selectedItems.items.includes(selectedItems.type === 'single' ? item.id : item.groupId);
            });
        }

        const tmpGroups = data.sortedTaxesPerStructure?.reduce((obj: any, item: any) => {
            item.link = `${getItemUrl(item)}&optimisation=true`;

            // @ts-ignore
            (obj[item.groupId] = obj[item.groupId] || []).push(item);

            return obj;
        }, {});

        // @ts-ignore
        let groups = Object.keys(tmpGroups).map((key) => {
            // @ts-ignore
            return tmpGroups[key].reduce((array, item, index) => {
                const chunkIndex = Math.floor(index / 12);
                // @ts-ignore
                (array[chunkIndex] = array[chunkIndex] || []).push(item);

                return array;
            }, []);
        });

        // @ts-ignore
        setGroups(groups);
        setLabels(data);
    };

    return (
        <>
            {selectedData && selectedItems.type !== 'single' && (
                <PdfTemplateGraphLayout
                    tableInfo={
                        <TaxOptimisationInfo
                            returnOnAsset={selectedData.returnOnAsset}
                            initialInvestment={selectedData.initialInvestment}
                            distribution={selectedData.distribution}
                        />
                    }
                    tips={<></>}
                    comments={null}
                    selectedData={selectedData}
                    legends={false}
                    alignLeft={true}
                    graphType={GraphTypes.Optimisation}
                >
                    <div className={styles.content}>
                        <div className={styles.groupsContainer}>
                            <div className={styles.groups}>
                                {groups &&
                                    Object.keys(groups).map((key, index) => (
                                        <div
                                            className={`${styles.group} ${
                                                openTaxIndex === index ? styles.open : styles.closed
                                            } ${
                                                openTaxIndex !== undefined && openTaxIndex !== index
                                                    ? styles.hidden
                                                    : ''
                                            }`}
                                            key={`group-${index}`}
                                        >
                                            <div className={styles.groupTitle}>
                                                <h4>
                                                    <span>
                                                        <span className={styles.groupTitleNumber}>{index + 1}</span>
                                                        Return on investment
                                                    </span>
                                                    <span>
                                                        {labels &&
                                                            (
                                                                (labels.frontEndLabels.uniqueReturns[index] || 0) * 100
                                                            ).toFixed(4)}
                                                        %
                                                    </span>
                                                </h4>

                                                <button
                                                    onClick={() => {
                                                        setOpenTaxIndex(undefined);
                                                    }}
                                                    className={openTaxIndex !== index ? styles.hidden : ''}
                                                >
                                                    Collapse all
                                                </button>
                                            </div>
                                            <div
                                                className={`${styles.groupItems} ${
                                                    openTaxIndex === index ? styles.open : styles.closed
                                                }`}
                                                onClick={() => setOpenTaxIndex(index)}
                                            >
                                                {groups[key].map((pages, pageIndex) => (
                                                    <React.Fragment key={`group-wrapper-${pageIndex}}`}>
                                                        {pages.map((item, itemIndex) => (
                                                            <TaxBox
                                                                data={item}
                                                                img={'/images/thumb-taxes.png'}
                                                                name={'Deal taxes'}
                                                                link={`deal-taxes?${item.link}`}
                                                                key={`group-item-${index}-${itemIndex}`}
                                                                isOpen={openTaxIndex === index}
                                                                collapsedOpenEnded={
                                                                    labels?.frontEndLabels?.openEnded[index]
                                                                }
                                                                collapsedLegalType={
                                                                    labels?.frontEndLabels?.legalType[index]
                                                                }
                                                                collapsedOpenDomicile={
                                                                    labels?.frontEndLabels?.domicile[index]
                                                                }
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </PdfTemplateGraphLayout>
            )}

            {selectedData &&
                groups &&
                Object.keys(groups).map((key, index) => (
                    <React.Fragment key={`group-wrapper-${index}}`}>
                        {groups[key].map((pages, itemIndex) => (
                            <PdfTemplateGraphLayout
                                key={`group-${itemIndex}`}
                                tableInfo={
                                    <TaxOptimisationInfo
                                        returnOnAsset={selectedData.returnOnAsset}
                                        initialInvestment={selectedData.initialInvestment}
                                        distribution={selectedData.distribution}
                                    />
                                }
                                tips={<></>}
                                comments={null}
                                selectedData={selectedData}
                                legends={false}
                                alignLeft={true}
                                graphType={GraphTypes.Optimisation}
                            >
                                <div className={`${styles.group} ${styles.open}`}>
                                    <div className={styles.groupTitle}>
                                        <h4>
                                            <span>
                                                <span className={styles.groupTitleNumber}>{index + 1}</span>
                                                Return on investment
                                            </span>
                                            <span>
                                                {labels &&
                                                    ((labels.frontEndLabels.uniqueReturns[index] || 0) * 100).toFixed(
                                                        4
                                                    )}
                                                %
                                            </span>
                                        </h4>
                                    </div>
                                    <div className={`${styles.groupItems} ${styles.open}`}>
                                        {pages.map((item, itemIndex) => (
                                            <div key={itemIndex} className={`${styles.link} ${styles.open}`}>
                                                <div className={styles.imgWrapper}>
                                                    <img src={'/images/thumb-taxes.png'} alt={''} />
                                                    <span>Taxes</span>
                                                </div>

                                                <div className={styles.selection}>
                                                    <SelectionCombination
                                                        selectedData={{
                                                            ...selectedData,
                                                            ...{
                                                                corporate: item.legalType === 'corporate',
                                                                fundStatus: item.fundStatus,
                                                                ifBusinessAssets: item.ifBusinessAssets,
                                                                booked: item.bookingType == 'at NAV',
                                                            },
                                                        }}
                                                        isOptimisation={true}
                                                        hideFundRow={true}
                                                        hideAsset={true}
                                                        hideInvestor={true}
                                                        isPdf={true}
                                                        fundData={{
                                                            country: item.domicile,
                                                            fund: item.fund,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </PdfTemplateGraphLayout>
                        ))}
                    </React.Fragment>
                ))}
        </>
    );
};

export default TaxOptimisationTemplate;
