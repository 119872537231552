import { InvestmentFund } from '../../types/general';

const getUnique = (value: string, index: number, self: any) => {
    return self.indexOf(value) === index;
};

const getGroupValues = (data: any, key: string, investmentFund: InvestmentFund): any => {
    return data[key].filter((d: any) => d.investmentFund?.trim() === investmentFund);
};

const getGroupValuesByLegalForm = (data: any, key: string, fund: any, colKey: string, colValue: any) => {
    return data[key].filter((d: any) => d.investmentFund?.trim() === fund && d[colKey] === colValue);
};

const getInvestmentFunds = (data: any, key: any) => {
    return data[key].map((d: any) => d.investmentFund?.trim()).filter(getUnique);
};

export { getUnique, getGroupValues, getGroupValuesByLegalForm, getInvestmentFunds };
