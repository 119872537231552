const FundOfFundsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
            <g id="str_small" opacity="1">
                <g id="Ellipse_22" data-name="Ellipse 22" fill="none" stroke="var(--icon-color)" strokeWidth="0.5">
                    <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                    <circle cx="18.5" cy="18.5" r="17.75" fill="none" />
                </g>
                <g id="Group_6072" data-name="Group 6072" transform="translate(-540 -1974)">
                    <circle
                        id="Ellipse_34"
                        data-name="Ellipse 34"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(557 1991)"
                        fill="#fff"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_49"
                        data-name="Line 49"
                        y2="9.5"
                        transform="translate(558.5 1979)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_51"
                        data-name="Line 51"
                        x1="9.5"
                        transform="translate(562.5 1992.5)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_53"
                        data-name="Line 53"
                        y1="9.5"
                        transform="translate(558.5 1996.5)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_55"
                        data-name="Line 55"
                        x2="9.5"
                        transform="translate(545 1992.5)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <path
                        id="Path_3116"
                        data-name="Path 3116"
                        d="M5.773,0,1.083,4.875,0,6"
                        transform="translate(561.892 1983.112)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_59"
                        data-name="Line 59"
                        x1="5.773"
                        y2="6"
                        transform="translate(567.912 1995.895) rotate(90)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_60"
                        data-name="Line 60"
                        x1="5.773"
                        y2="6"
                        transform="translate(555.101 2001.844) rotate(180)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                    <line
                        id="Line_61"
                        data-name="Line 61"
                        x1="5.773"
                        y2="6"
                        transform="translate(549.139 1989.134) rotate(-90)"
                        fill="none"
                        stroke="var(--icon-color)"
                        strokeWidth="0.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default FundOfFundsIcon;
