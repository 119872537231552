import { useContext } from 'react';
import styles from './styles.module.css';
import SelectionPopupCountryRow from '../SelectionPopupCountryRow/SelectionPopupCountryRow';
import { SelectionRowContext } from '../../../context/SelectionRowContext';
import SelectionCheckbox from '../SelectionCheckbox/SelectionCheckbox';

const SelectionCheckboxRow = ({ children, useFoF, ...props }: any) => {
    const { options, selectedCountry } = useContext(SelectionRowContext);

    return (
        <SelectionPopupCountryRow {...props}>
            <div
                className={`${styles.row} ${useFoF ? styles.compact : ''} ${
                    options && !!selectedCountry && !!options[selectedCountry!]?.length ? '' : styles.placeholder
                }`}
            >
                {options && !!selectedCountry && !!options[selectedCountry!]?.length ? (
                    // @ts-ignore
                    options[selectedCountry!].map((i: number, key: string) => {
                        return <SelectionCheckbox key={key} option={i} useFoF={useFoF} />;
                    })
                ) : (
                    <>
                        <div className={styles.checkboxPlaceholder} />
                        <div className={styles.checkboxPlaceholder} />
                        <div className={styles.checkboxPlaceholder} />
                        <div className={styles.checkboxPlaceholder} />
                    </>
                )}
            </div>
            {children}
        </SelectionPopupCountryRow>
    );
};

export default SelectionCheckboxRow;
