import styles from './styles.module.css';
import React from 'react';
import { Button, CloseButton } from '../index';

interface DeletePopupProps {
    onCancel: React.MouseEventHandler<HTMLButtonElement>;
    onDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const DeletePopup = ({ onCancel, onDelete }: DeletePopupProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.popup}>
                <div className={styles.close}>
                    <CloseButton onClick={onCancel} />
                </div>
                <h4>Are you sure you want to delete this overview?</h4>
                <p>This item will be deleted immediately. You can't undo this action.</p>
                <div className={styles.bts}>
                    <Button outlined={true} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button outlined={true} onClick={onDelete}>
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;
