import SelectionPopupCountryRow from '../SelectionPopupCountryRow/SelectionPopupCountryRow';
import SelectionSelect from '../SelectionSelect/SelectionSelect';
import SelectionSelectedOptionInfo from '../SelectionOptionInfo/SelectionOptionInfo';
import { SelectionLevel } from '../../../types/general';
import { SelectionLayoutRowWrapperProps } from '../SelectionLayoutRow/SelectionLayoutRow';

interface ISelectionRowProps extends SelectionLayoutRowWrapperProps {
    showRow?: boolean;
    type: SelectionLevel;
    useFoF?: boolean;
    isSingle?: boolean;
    isMulti?: boolean;
}

const SelectionRow = ({ children, isMulti, ...props }: ISelectionRowProps) => {
    return (
        <SelectionPopupCountryRow {...props}>
            <SelectionSelect />

            {!isMulti && <SelectionSelectedOptionInfo />}

            {children}
        </SelectionPopupCountryRow>
    );
};
export default SelectionRow;
