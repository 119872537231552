import React from 'react';

export interface SankeyLine {
    x1: number;
    x2: number;
    y: number;
    className?: string;
}

interface SankeyLineProps {
    line: SankeyLine;
    strokeWidth: number;
}

export const Line = ({ line, strokeWidth }: SankeyLineProps) => {
    return (
        <>
            <line
                x1={line.x1}
                y1={line.y}
                x2={line.x2}
                y2={line.y}
                stroke="#000"
                strokeWidth={strokeWidth}
                className={line.className}
            />
        </>
    );
};

export default Line;
