import styles from './FormWrapper.module.css';

const FormWrapper = ({ children }: any) => {
    return (
        <div id={'formWrapper'} className={styles.wrapper}>
            {children}
        </div>
    );
};

export default FormWrapper;
