import styles from './selected-combination.module.css';
import { useDiagramStore } from '../../store/useDiagramStore';

import SelectedCombinationRow from './components/SelectedCombinationRow';
import AdditionalPdfRows, { AdditionalParamsProps, SELECTION_TYPE } from './components/AdditionalPdfRows';
import AdditionalProposedRows from './components/AdditionalProposedRows';
import React, { useEffect, useState } from 'react';
import { Countries, GraphTypes, SelectionLevel, SelectionLevelProps } from '../../types/general';
import { Icon } from '../index';

interface SelectionCombinationProps extends AdditionalParamsProps {
    // selectedData: LegalStructuresSelectedData | DealTaxesSelectedData | TaxOptimisationSelectedData;
    // selectedData: DealTaxesSelectedData | TaxOptimisationSelectedData;
    isPdf?: boolean;
    isOptimisation?: boolean;
    hideFundRow?: boolean;
    fundData?: any;
    hideInvestor?: boolean;
    hideAsset?: boolean;
    graphType?: GraphTypes;
    isSelectable?: boolean;
    handleSelectedInvestor?: any;
}

type CountryShortName = 'lu' | 'de' | 'ky' | 'ch';

const SelectionCombination = ({
    isPdf,
    isOptimisation,
    hideFundRow,
    fundData,
    hideInvestor,
    hideAsset,
    selectedData,
    graphType,
    isSelectable,
    handleSelectedInvestor,
}: SelectionCombinationProps) => {
    const [investors, investmentFunds, assets, fundOfFunds] = useDiagramStore((state) => [
        state.investors,
        state.investmentFunds,
        state.assets,
        state.fundOfFunds,
    ]);

    const [fofValue, setFofValue] = useState<number | null | undefined>();

    const getCountryFullName = (shortName: CountryShortName) => {
        return Countries[shortName].domicileName;
    };

    useEffect(() => {
        // setFofValue('currentFoF' in selectedData ? selectedData.currentFoF : undefined);
    }, [selectedData]);

    return (
        <>
            {!hideInvestor && (
                <>
                    <SelectedCombinationRow
                        items={investors}
                        itemId={selectedData.investors}
                        type={SelectionLevel.Investor}
                        selectedData={selectedData}
                        isSelectable={isSelectable}
                        handleSelectedInvestor={handleSelectedInvestor}
                    />

                    {isPdf && fofValue && (
                        <AdditionalPdfRows
                            selectedData={selectedData}
                            graphType={graphType}
                            selectionType={SELECTION_TYPE.investor}
                        />
                    )}
                </>
            )}

            {!hideFundRow && 'investmentFund' in selectedData && (
                <>
                    {selectedData.fundOfFunds && (
                        <SelectedCombinationRow
                            items={fundOfFunds}
                            itemId={selectedData.fundOfFunds.id}
                            name={selectedData.fundOfFunds.name}
                            type={SelectionLevel.FundOfFunds}
                            selectedCountry={selectedData.fundOfFunds.countryCode}
                        />
                    )}

                    {isPdf && fofValue && (
                        <AdditionalPdfRows
                            selectedData={selectedData}
                            graphType={graphType}
                            selectionType={SELECTION_TYPE.fof}
                        />
                    )}

                    {!isOptimisation && (
                        <SelectedCombinationRow
                            items={investmentFunds}
                            itemId={selectedData.investmentFund.id}
                            name={selectedData.investmentFund.name}
                            type={SelectionLevel.Fund}
                            selectedCountry={selectedData.investmentFund.countryCode}
                        />
                    )}

                    {isPdf && fofValue && (
                        <AdditionalPdfRows
                            selectedData={selectedData}
                            graphType={graphType}
                            selectionType={SELECTION_TYPE.fund}
                        />
                    )}
                </>
            )}

            {isOptimisation && (
                <>
                    <div className={`${styles.label} ${styles.labelMargin}`}>
                        <Icon icon={SelectionLevelProps.fund.icon} />
                        <div>
                            <h6 className={styles.highlight}>Proposed structure</h6>

                            {fundData && (
                                <div className={`${styles.proposedWrapper} ${isPdf ? styles.pdf : ''}`}>
                                    <div className={`${styles.proposedCheck}`}>
                                        <span>{fundData?.fund}</span>
                                    </div>

                                    <div className={`${styles.proposedCheck}`}>
                                        <span>{getCountryFullName(fundData?.country.toLowerCase())}</span>
                                    </div>
                                </div>
                            )}

                            <div
                                style={{
                                    marginTop: 16,
                                }}
                            >
                                <AdditionalProposedRows
                                    openEnded={'openEnded' in selectedData ? selectedData.openEnded : undefined}
                                    booked={'booked' in selectedData ? selectedData.booked : undefined}
                                    corporate={'corporate' in selectedData ? selectedData.corporate : undefined}
                                    ifBusinessAssets={
                                        'ifBusinessAssets' in selectedData ? selectedData.ifBusinessAssets : undefined
                                    }
                                    fundStatus={'fundStatus' in selectedData ? selectedData.fundStatus : undefined}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!hideAsset && (
                <>
                    <SelectedCombinationRow items={assets} itemId={selectedData.assets} type={SelectionLevel.Asset} />

                    {isPdf && fofValue && (
                        <AdditionalPdfRows
                            selectedData={selectedData}
                            graphType={graphType}
                            selectionType={SELECTION_TYPE.asset}
                        />
                    )}
                </>
            )}

            {!isOptimisation && isPdf && 'openEnded' in selectedData && !fofValue && (
                <AdditionalPdfRows selectedData={selectedData} graphType={graphType} />
            )}
        </>
    );
};

export default SelectionCombination;
