import InputLabel from '../InputLabel/InputLabel';
import styles from './styles.module.css';

const SelectedValuesList = ({ values, children }: { values: string[]; children: any }) => {
    return (
        <InputLabel label={children} align={'top'}>
            <ul className={styles.list}>
                {values.map((value, index) => (
                    <li key={index}>{value}</li>
                ))}
            </ul>
        </InputLabel>
    );
};

export default SelectedValuesList;
