// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_selectedInfo__3pcZR {\n    flex: 1 1;\n    margin: auto 0;\n    /*width: 30%;*/\n}\n\n.styles_selectedTitle__Jhrs- {\n    text-transform: capitalize;\n    margin-bottom: 10px;\n}\n\n.styles_investorSelectInfo__JEm1u {\n    font-size: 13px;\n    font-weight: 300;\n    margin: 0;\n    transition: color 0.4s;\n    text-align: left;\n    background: #fff;\n    border: 0.25px solid #707070;\n    padding: 5px 9px;\n}\n\n.styles_selectionBox__v4DqX .styles_investorSelect__R27JE .styles_investorSelectInfo__JEm1u {\n    text-align: center;\n}\n\n.styles_investorSelectInfo__JEm1u:first-letter {\n    text-transform: uppercase;\n}\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + " {\n    .styles_selectedInfo__3pcZR {\n        display: none;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/SelectionPopup/SelectionOptionInfo/styles.module.css"],"names":[],"mappings":"AAEA;IACI,SAAO;IACP,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@value screen-md-max from '../../../breakpoints.css';\n\n.selectedInfo {\n    flex: 1;\n    margin: auto 0;\n    /*width: 30%;*/\n}\n\n.selectedTitle {\n    text-transform: capitalize;\n    margin-bottom: 10px;\n}\n\n.investorSelectInfo {\n    font-size: 13px;\n    font-weight: 300;\n    margin: 0;\n    transition: color 0.4s;\n    text-align: left;\n    background: #fff;\n    border: 0.25px solid #707070;\n    padding: 5px 9px;\n}\n\n.selectionBox .investorSelect .investorSelectInfo {\n    text-align: center;\n}\n\n.investorSelectInfo:first-letter {\n    text-transform: uppercase;\n}\n@media screen-md-max {\n    .selectedInfo {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen-md-max": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + "",
	"selectedInfo": "styles_selectedInfo__3pcZR",
	"selectedTitle": "styles_selectedTitle__Jhrs-",
	"investorSelectInfo": "styles_investorSelectInfo__JEm1u",
	"selectionBox": "styles_selectionBox__v4DqX",
	"investorSelect": "styles_investorSelect__R27JE"
};
export default ___CSS_LOADER_EXPORT___;
