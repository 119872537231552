import { SelectionLevel, SelectionLevelProps } from '../../../types/general';
import styles from './styles.module.css';
import { Icon } from '../../index';
import React from 'react';

interface SelectionLayoutLevelContainerProps {
    type: SelectionLevel;
    children: React.ReactNode;
    headerChildren?: React.ReactNode;
}

const SelectionLayoutLevelContainer = ({ type, children, headerChildren }: SelectionLayoutLevelContainerProps) => {
    return (
        <div className={styles.containerLevel}>
            <div className={styles.containerLevelHeader}>
                <Icon icon={SelectionLevelProps[type].icon} />

                <h5>{SelectionLevelProps[type].title}</h5>

                {headerChildren}
            </div>

            {children}
        </div>
    );
};

export default SelectionLayoutLevelContainer;
