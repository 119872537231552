import styles from './legal-structures-template.module.css';

import React, { useEffect, useRef, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';

interface LegalStructurePdfTableProps {
    noFixed?: boolean;
    children: any;
    index?: number;
}

const LegalStructurePdfTable = ({ noFixed, children, index }: LegalStructurePdfTableProps) => {
    const element = useRef<HTMLHeadingElement>(null);
    const [ready, setReady] = useState(false);

    const debounced = useDebounce(ready, 500);

    const visibleTable = 760;

    useEffect(() => {
        setReady(true);

        return () => {
            setReady(false);
        };
    }, []);

    useEffect(() => {
        if (ready && element) {
            onLoad();
        }
    }, [debounced]);

    const handleRows = (elements: Array<any>, element: HTMLHeadingElement | any | null, lastIndex: number) => {
        if (!element) return;

        const table = element.querySelector('div > table');
        const rows = element.querySelectorAll('div > table > tbody > tr');
        const columns = element.querySelectorAll('div > table > thead > tr > th');

        const cloneVertically = (columns.length > 5 && !noFixed) || (columns.length > 4 && noFixed);

        if (cloneVertically) {
            table.style.width = '200%';
        }

        let totalHeight = 0;
        let clone;
        for (const [index, row] of rows.entries()) {
            if (lastIndex > 0 && lastIndex > index && (!noFixed || (noFixed && index > 0))) {
                // remove cloned rows
                row.remove();
            } else {
                const height = row.clientHeight;
                totalHeight += height;

                // in this case rows should be equal
                if (cloneVertically) {
                    if (height > 0) {
                        // on taxes somehow height is defined incorrectly
                        row.style.height = `${height > 350 ? height / 1.5 : height}px`;
                    }
                }

                // if total rows more that page height,
                // clone vertically
                if (totalHeight > visibleTable) {
                    if (index < rows.length && !clone) {
                        lastIndex = index;

                        // clone page
                        clone = element.cloneNode(true);
                        elements.push(clone);

                        element.after(clone);

                        handleRows(elements, clone, lastIndex);
                    }

                    row.remove();
                }
            }
        }

        // clone table horizontally
        if (cloneVertically) {
            const tableParent = element.querySelector('.table');
            const verticalClone = element.querySelector('.table .page').cloneNode(true);

            tableParent.appendChild(verticalClone);
        }
    };

    const onLoad = () => {
        const elements = [element.current];

        handleRows(elements, element.current, 0);

        const textContent = element?.current?.querySelector('h2')?.textContent;

        // reset heights
        const tables: any = document.querySelectorAll('table');
        for (const table of tables) {
            table.style.width = '100%';
        }

        const headers: any = document.querySelectorAll('.tables h3');

        for (const header of headers) {
            if (header.textContent === textContent) {
                const columns = element?.current?.querySelectorAll('div > table > thead > tr > th');

                const nodes = Array.prototype.slice.call(document.querySelector('#root')?.children);

                const staticPages = 2;
                const pageIndex = nodes.indexOf(element.current);
                let index = columns!.length <= 5 ? pageIndex + 1 : (pageIndex - staticPages) * 2 + staticPages;
                header.querySelector('div').innerHTML = index;
            }
        }
    };

    return (
        <div ref={element} className={`${styles.tableOuter} ${noFixed ? styles.tableSecondary : ''}`}>
            <div className={`${styles.table} table`}>{children}</div>
        </div>
    );
};

export default LegalStructurePdfTable;
