import React from 'react';

import styles from './tr-styles.module.css';
import { getGroupValues, getInvestmentFunds } from '../utils';
import { CurrencyFormat } from '../formats';
import { useTableStore } from '../../../store/useTableStore';

const TrServiceProvider = () => {
    const [data, setActiveFund, activeFund] = useTableStore((state) => [
        state.data,
        state.setActiveFund,
        state.activeFund,
    ]);

    const funds = getInvestmentFunds(data, 'sd14');

    let max = 0;

    const fundData: any = {};

    for (const fund of funds) {
        const d = getGroupValues(data, 'sd14', fund);
        fundData[fund] = d;
        if (d.length > max) {
            max = d.length;
        }
    }

    const trs = [];

    for (let i = 0; i < max; i++) {
        if (i === 0) {
            trs.push(
                <tr key={i}>
                    {funds &&
                        funds.map((f: any, key: number) => {
                            return (
                                <td key={key} onClick={() => setActiveFund(f)}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Provider</th>
                                                <th>Domicile</th>
                                                <th>Costs</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            );
                        })}
                </tr>
            );
        }

        trs.push(
            <tr>
                {funds &&
                    funds.map((f: any, key: number) => {
                        const d = fundData[f][i];

                        return (
                            <td
                                key={key}
                                className={`${styles.tdGroupRow} ${f === activeFund ? styles.activeTable : ''}`}
                                onClick={() => setActiveFund(f)}
                            >
                                <table className={`${styles.tdGroupTableRow}`}>
                                    <tbody>
                                        <tr>
                                            {d && (
                                                <td>
                                                    {d !== '' && d.serviceProviders}
                                                    {(d === '' || d === null) && '-'}
                                                </td>
                                            )}
                                            {d && (
                                                <td>
                                                    {d !== '' && d.domicile}
                                                    {(d === '' || d === null) && '-'}
                                                </td>
                                            )}
                                            {d && (
                                                <td>
                                                    {d !== '' && CurrencyFormat(d.costs)}
                                                    {(d === '' || d === null) && '-'}
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        );
                    })}
            </tr>
        );
    }

    const result: any = [];

    trs.map((tr, key) => {
        result.push(<React.Fragment key={key}>{tr}</React.Fragment>);
    });

    return result;
};

export default TrServiceProvider;
