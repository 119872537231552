import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEffect, useState } from 'react';

import styles from './deal-taxes-expanded.module.css';
import axios from '../../utils/axios';
import { DealTaxesExpandedTable } from '../DealTaxes/components/DealTaxesExpandedTable';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import { LinkedButton } from '../../components/Button';
import { DealTaxesExpandedTableItem } from '../../types/deal-taxes';

const DealTaxesExpanded = () => {
    const [data, setData] = useState<undefined | DealTaxesExpandedTableItem>(undefined);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get<DealTaxesExpandedTableItem>('/deal-taxes/table' + window.location.search);
            setData(data);
        })();
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.pageHeader}>
                    <h2>Tax review: expanded view</h2>
                </div>
                <LoaderComponent inProp={!!data}>
                    {data && (
                        <PerfectScrollbar className={`${styles.sectionWrapper}`}>
                            <DealTaxesExpandedTable data={data} />
                        </PerfectScrollbar>
                    )}

                    <div className={styles.btnWrapper}>
                        <LinkedButton to={'/deal-taxes' + window.location.search}>Back to short version</LinkedButton>
                    </div>
                </LoaderComponent>
            </div>
        </>
    );
};

export { DealTaxesExpanded };
