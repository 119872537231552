import styles from './styles.module.css';
import React from 'react';

export interface SelectionLayoutRowWrapperProps {
    children?: React.ReactNode;
    isSecondary?: boolean;
    afterRow?: React.ReactNode;
    onClick?: any;
}
const SelectionLayoutRowWrapper = ({
    isSecondary = false,
    children,
    afterRow,
    onClick,
}: SelectionLayoutRowWrapperProps) => {
    return (
        <div className={`${styles.containerRow} ${isSecondary ? styles.secondary : ''}`} onClick={onClick}>
            {children}

            {!!afterRow && <div className={styles.containerRowAfter}>{afterRow}</div>}
        </div>
    );
};

export default SelectionLayoutRowWrapper;
