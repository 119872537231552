import create from 'zustand';

export type MenuType = 'legal' | 'aside';
export const menuTypes: { [key in MenuType]: MenuType } = {
    legal: 'legal',
    aside: 'aside',
};

interface MenuStore {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    menuType: MenuType;
    setMenuType: (value: MenuType) => void;
}

export const useMenuStore = create<MenuStore>((set) => ({
    isOpen: false,
    setIsOpen: (value: boolean) => set({ isOpen: value }),
    menuType: menuTypes.aside,
    setMenuType: (value: MenuType) => set({ isOpen: false, menuType: value }),
}));
