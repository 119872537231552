import styles from './styles.module.css';
import DealTaxesDetailRow from './DealTaxesDetailRow';
import { CloseButton } from '../../../../components';
import { DiagramDataTableShortItem } from '../../../../types/deal-taxes';

interface DealTaxesDetailsProps {
    activeCategory: any;
    tips: DiagramDataTableShortItem[];
    onClose: () => void;
    legendChange: any;
}

const DealTaxesDetails = ({ activeCategory, tips, onClose, legendChange }: DealTaxesDetailsProps) => {
    const investment = tips.filter((t) => t.stage === 'investment');
    const holding = tips.filter((t) => t.stage === 'holding');
    const distribution = tips.filter((t) => t.stage === 'distribution');
    const selling = tips.filter((t) => t.stage === 'selling');

    return (
        <div className={styles.box}>
            <div className={styles.closeBtnWrapper}>
                <CloseButton onClick={() => onClose()} />
            </div>

            {investment.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={'Investment'}
                    data={investment}
                    isActive={activeCategory === 'investment' ?? true}
                    legendChange={legendChange}
                />
            )}

            {holding.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={'Holding'}
                    data={holding}
                    isActive={activeCategory === 'holding' ?? true}
                    legendChange={legendChange}
                />
            )}

            {distribution.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={'Distribution'}
                    data={distribution}
                    isActive={activeCategory === 'distribution' ?? true}
                    legendChange={legendChange}
                />
            )}

            {selling.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={'Selling'}
                    data={selling}
                    isActive={activeCategory === 'selling' ?? true}
                    legendChange={legendChange}
                />
            )}
        </div>
    );
};

export default DealTaxesDetails;
