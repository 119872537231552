import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { DescriptionFormat, OpenClosedFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD11Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd10 ? (
        <table>
            <TableHeader sdKey={'sd11'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd11'} colKey={'legalForm'} />

                <Tr featName={'Legal type'} dataKey={'sd11'} colKey={'legalType'} />

                <Tr featName={'If open ended'} dataKey={'sd11'} colKey={'ifOpenEnded'} formatFunc={OpenClosedFormat} />

                <Tr featName={'Tax status'} dataKey={'sd11'} colKey={'taxStatusForCurrentInvestors'} />

                <Tr featName={'Access to double taxation treaties'} dataKey={'sd11'} colKey={'dtt'} />

                <TrProvider>
                    <Tr
                        featName={'TCountryCode eligible for double taxation area'}
                        dataKey={'sd11'}
                        colKey={'dttCountries'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>

                <Tr featName={'US check-the-box eligibility'} dataKey={'sd11'} colKey={'usCheckTheBoxEligibility'} />
            </tbody>
        </table>
    ) : null;
};

export default SD11Table;
