import styles from './styles.module.css';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../context';
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '../../components';

const Disclaimer = () => {
    const { user, setUser } = useContext(UserContext);

    const navigate = useNavigate();

    const [agree, setAgree] = useState(false);

    const handleCheckbox = () => {
        setAgree(!agree);
    };

    const cancel = async () => {
        await axios.post('/auth/logout');
        setUser(null);
        navigate('/login');
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!agree) {
            await cancel();
            return;
        }

        if (user) {
            const res = await axios.patch(`/users/agreement`, {
                agreement: agree,
            });

            if (res.status === 200) {
                window.location.href = '/';
            } else {
                await cancel();
            }
        }
    };

    return (
        <>
            <div className={styles.container}>
                <h2>Disclaimer</h2>
                <div className={styles.agreement}>
                    <p>This is a prototype of White Thesis web-app (Web-app).</p>
                    <h5>Absence of advice or offer.</h5>
                    <p>
                        The content of this Web-app has been prepared by White Thesis, its project group members and its
                        affiliates (“White Thesis”) for informational purposes only. It does not constitute legal or tax
                        advice, an advertisement, a personal recommendation or investment advice. The information on
                        this Web-app shall not be considered as an offer to procure or purchase the services or
                        products, is general in nature and has not taken into account your personal financial position
                        or objectives. The information on this Web-app is provided solely on the basis that you will
                        make your own investment decisions. Before proceeding please refer to a licensed adviser or tax
                        agent.
                    </p>
                    <h5>No assurance or guarantee.</h5>
                    <p>
                        All data and material have been obtained from sources believed to be reliable, though due to the
                        prototype phase, their accuracy is not yet guaranteed.They are subject to modification without
                        prior notice. Information provided in this Web-app shall not be considered as any kind of
                        undertaking or guarantee whatsoever from White Thesis. Persons accessing the Web-app are aware
                        that they are solely responsible for compliance with applicable laws and regulation of their
                        country of residence and/or citizenship. They shall seek advice from their independent tax
                        advisor, legal counsel, and/or financial advisor.
                    </p>
                    <h5>Limitation of liability</h5>
                    <p>
                        White Thesis is not responsible for any errors or omissions in the content of this Web-app or
                        for damages arising from the use or performance of this Web-app, or for the results obtained
                        from the use of information under any circumstances. The content on this Web-app is provided "as
                        is", with no representations being made that the content is error-free, correct, complete or up
                        to date. <br /> All liability with respect to any decisions made or not made, actions taken or
                        not taken, based on the contents of this web-app, are hereby expressly disclaimed.
                    </p>
                </div>
                {user && !user.agreement && (
                    <div className={styles.submit}>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <div className={styles.formInner}>
                                <button className={styles.cancel} type={'button'} onClick={cancel}>
                                    Cancel
                                </button>

                                <Checkbox
                                    value={agree}
                                    onChange={handleCheckbox}
                                    label={`
                                By clicking the "I accept" button, you
                                acknowledge that you have read and understood
                                this page and agree to abide by its content and
                                confirm that you are accessing this website in
                                compliance with the laws and regulations of the
                                jurisdiction or country in which you are
                                residing.`}
                                    checked={agree}
                                />
                            </div>

                            <button className={styles.submitBtn}>Continue</button>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
};

export default Disclaimer;
