import FormWrapper from '../../Storage/layout/FormWrapper/FormWrapper';
import FormLayout from '../../Storage/layout/FormLayout/FormLayout';
import FormCollapsableRow from '../../Storage/layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../components/Input/Input';
import styles from './styles.module.css';

const ComplianceView = ({ name, onClose, selectedData }: any) => {
    return (
        <FormWrapper>
            <FormLayout
                title={name}
                description={'Here is the list of identified potential compliance issues.'}
                onClose={onClose}
                useMargin={false}
            >
                <FormCollapsableRow
                    titleLabel={`1. Investors`}
                    hideHeader={false}
                    useGrid={false}
                    labelFullWidth={true}
                >
                    <p className={styles.p}>
                        Stored structure cannot accept subscriptions from the following investor(s):
                    </p>

                    {selectedData.investors.map((item: any, i: number) => (
                        <div className={styles.row} key={i}>
                            <Input readOnly={true} value={item.investorName}>
                                Investor
                            </Input>
                            <Input readOnly={true} checked={false} type={'checkbox'} fullWidth={false}>
                                Ignore?
                            </Input>
                        </div>
                    ))}
                </FormCollapsableRow>

                <FormCollapsableRow titleLabel={`2. Assets`} hideHeader={false} useGrid={false} labelFullWidth={true}>
                    <p className={styles.p}>Stored structure cannot allocate its assets to the following asset(s): </p>

                    {selectedData.assets.map((item: any, i: number) => (
                        <div className={styles.row} key={i}>
                            <Input readOnly={true} value={item.assetName}>
                                Asset
                            </Input>
                            <Input disabled={true} checked={false} type={'checkbox'} fullWidth={false}>
                                Ignore?
                            </Input>
                        </div>
                    ))}
                </FormCollapsableRow>

                <FormCollapsableRow
                    titleLabel={`3. Asset managers`}
                    hideHeader={false}
                    useGrid={false}
                    labelFullWidth={true}
                >
                    <p className={styles.p}>Stored structure shall have one of the following asset manager(s):</p>

                    {selectedData.assetManagers.map((item: any, i: number) => (
                        <div className={styles.row} key={i}>
                            <Input readOnly={true} value={item.assetName}>
                                Asset manager
                            </Input>
                            <Input disabled={true} checked={false} type={'checkbox'} fullWidth={false}>
                                Ignore?
                            </Input>
                        </div>
                    ))}
                </FormCollapsableRow>

                <FormCollapsableRow
                    titleLabel={`4. Service provider`}
                    hideHeader={false}
                    useGrid={false}
                    labelFullWidth={true}
                >
                    <p className={styles.p}>Stored structure shall have the following service provider(s):</p>

                    {selectedData.serviceProviders.map((item: any, i: number) => (
                        <div className={styles.row} key={i}>
                            <Input readOnly={true} value={item.spTitle}>
                                Service providers
                            </Input>
                            <Input disabled={true} checked={false} type={'checkbox'} fullWidth={false}>
                                Ignore?
                            </Input>
                        </div>
                    ))}
                </FormCollapsableRow>
            </FormLayout>
        </FormWrapper>
    );
};

export default ComplianceView;
