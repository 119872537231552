const InvestmentIcon = () => {
    return (
        <svg
            id="Component_80_13"
            data-name="Component 80 – 13"
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
        >
            <g id="Group_307" data-name="Group 307" transform="translate(8 8)">
                <g
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    transform="translate(6 6)"
                    fill="var(--icon-fill-color, none)"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                </g>
                <g
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    transform="translate(8 16)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                    <circle cx="2.5" cy="2.5" r="1.75" fill="none" />
                </g>
                <g
                    id="Ellipse_14"
                    data-name="Ellipse 14"
                    transform="translate(8)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                    <circle cx="2.5" cy="2.5" r="1.75" fill="none" />
                </g>
                <g
                    id="Ellipse_15"
                    data-name="Ellipse 15"
                    transform="translate(16 13) rotate(-90)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                    <circle cx="2.5" cy="2.5" r="1.75" fill="none" />
                </g>
                <g
                    id="Ellipse_16"
                    data-name="Ellipse 16"
                    transform="translate(0 13) rotate(-90)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                    <circle cx="2.5" cy="2.5" r="1.75" fill="none" />
                </g>
                <g
                    id="Ellipse_17"
                    data-name="Ellipse 17"
                    transform="translate(13.083 16.311) rotate(-45)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.283" cy="2.283" r="2.283" stroke="none" />
                    <circle cx="2.283" cy="2.283" r="1.533" fill="none" />
                </g>
                <g
                    id="Ellipse_18"
                    data-name="Ellipse 18"
                    transform="translate(1.461 4.689) rotate(-45)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.283" cy="2.283" r="2.283" stroke="none" />
                    <circle cx="2.283" cy="2.283" r="1.533" fill="none" />
                </g>
                <g
                    id="Ellipse_19"
                    data-name="Ellipse 19"
                    transform="translate(16.31 7.918) rotate(-135)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.283" cy="2.283" r="2.283" stroke="none" />
                    <circle cx="2.283" cy="2.283" r="1.533" fill="none" />
                </g>
                <g
                    id="Ellipse_20"
                    data-name="Ellipse 20"
                    transform="translate(4.69 19.539) rotate(-135)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                >
                    <circle cx="2.283" cy="2.283" r="2.283" stroke="none" />
                    <circle cx="2.283" cy="2.283" r="1.533" fill="none" />
                </g>
            </g>
            <g id="Ellipse_22" data-name="Ellipse 22" fill="none" stroke="var(--icon-color)" strokeWidth="0.5">
                <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                <circle cx="18.5" cy="18.5" r="17.75" fill="none" />
            </g>
        </svg>
    );
};

export default InvestmentIcon;
