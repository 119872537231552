import styles from './styles.module.css';
import { DealTaxesExpandedTableItem, DealTaxesExpandedTableItemData } from '../../../../types/deal-taxes';
import { SelectedItemAsset } from '../../../../components/SelectionPopup/SelectionPopup';

interface DealTaxesExpandedTableProps {
    data: DealTaxesExpandedTableItem;
    assets?: SelectedItemAsset[];
}

const DealTaxesExpandedTable = ({ data }: DealTaxesExpandedTableProps) => {
    const headerKey = Object.keys(data)[0];
    const header = data[headerKey];
    const body = { ...data };
    delete body[headerKey];

    const isExpanded = !!header.f;

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>{header.d}</th>
                    {isExpanded && <th>{header.f}</th>}
                    <th>{header.h}</th>
                    <th>{header.j}</th>
                    <th>{header.l}</th>
                    <th className={!!header.m ? '' : styles.empty}>{header.m}</th>
                    <th
                        style={{
                            minWidth: isExpanded ? 220 : 160,
                        }}
                    >
                        {header.n}
                    </th>
                    <th>{header.p}</th>
                </tr>
            </thead>
            <tbody>
                {data &&
                    Object.keys(body).map((key: string, index: number) => {
                        const item: DealTaxesExpandedTableItemData = body[key];
                        return (
                            <tr key={index}>
                                <td>{item.d}</td>
                                {isExpanded && <td width={220}>{item.f}</td>}
                                <td width={160}>{item.h}</td>
                                <td>{item.j}</td>
                                <td className={styles.comments}>{item.l}</td>
                                <td
                                    className={`${styles.bold} ${styles.right} ${
                                        item.color === 'blue' ? styles.highlight : ''
                                    } ${item.color === 'orange' ? styles.highlightRed : ''}`}
                                >
                                    {item.m}
                                </td>
                                <td
                                    className={`${styles.bold} ${styles.right} ${
                                        item.color === 'blue' ? styles.highlight : ''
                                    } ${item.color === 'orange' ? styles.highlightRed : ''}`}
                                >
                                    {item.n}
                                </td>
                                <td
                                    className={`${styles.bold} ${styles.right} ${
                                        item.color === 'blue' ? styles.highlight : ''
                                    } 
                                        ${item.color === 'orange' ? styles.highlightRed : ''}`}
                                >
                                    {item.p}
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

export default DealTaxesExpandedTable;
