import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormCollapsableRow from '../../../layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../../../components/Input/Input';
import SelectedValuesList from '../../../../../components/SelectedValuesList/SelectedValuesList';

const StorageViewAssetManager = ({ selectedData, onClose }: any) => {
    return (
        <FormWrapper>
            <FormLayout title={'Asset manager(s)'} description={'Here are the asset manager(s).'} onClose={onClose}>
                {selectedData.map((item: any, index: number) => (
                    <FormCollapsableRow
                        key={index}
                        titleLabel={`${index + 1}. asset manager`}
                        titleValue={`${item.role} :: ${item.counterparty}`}
                    >
                        <Input disabled value={item.role}>
                            Role
                        </Input>

                        <Input disabled value={'Counterparty'}>
                            Agreement
                        </Input>

                        <Input disabled value={item.counterparty}>
                            Name
                        </Input>

                        <Input disabled value={item.regulationFullName}>
                            Regulatory status
                        </Input>
                    </FormCollapsableRow>
                ))}
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageViewAssetManager;
