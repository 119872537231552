import DealTaxes from './DealTaxes/DealTaxes';
import DealTaxesTemplate from './DealTaxesTemplate/DealTaxesTemplate';
import { DealTaxesExpanded } from './DealTaxesExpanded/DealTaxesExpanded';
import LegalStructures from './LegalStructures/LegalStructures';
import Login from './Login/Login';
import Options from './Options/Options';
import Disclaimer from './Disclaimer/Disclaimer';
import Account from './Account/Account';
import Contact from './Contact/Contact';
import TaxOptimisation from './TaxOptimisation/TaxOptimisation';

export {
    DealTaxes,
    DealTaxesTemplate,
    DealTaxesExpanded,
    LegalStructures,
    Login,
    Options,
    Disclaimer,
    Account,
    Contact,
    TaxOptimisation,
};
