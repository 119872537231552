import { useEffect, useState } from 'react';
import CheckboxRounded from '../CheckboxRounded/CheckboxRounded';
import InputLabel from '../InputLabel/InputLabel';
import inputStyles from '../InputLabel/Input.module.css';

const Input = ({ btnChildren, children, required, info, style, max, extended, fullWidth = true, ...props }: any) => {
    const [isActive, setIsActive] = useState(!!props.value?.trim());
    const [isTitleActive, setIsTitleActive] = useState(false);

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        console.log('value', value, props.value);
        if (value !== props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    const onInput = (e: any) => {
        console.log('e.target.value', e.target.value);
        const value = e.target.value?.trim() || '';
        setIsActive(!!value);

        console.log(max, value.length);

        if (max && value.length > max) {
            setValue(value.slice(0, max));
            e.target.value = value.slice(0, max);
        } else {
            setValue(value);
        }
    };

    const onMouseEnter = (e: any) => {
        const isOverflowing = e.target.scrollWidth > e.target.clientWidth;

        if (isOverflowing && (e.target.readOnly || e.target.disabled)) {
            setIsTitleActive(true);
            e.target.parentNode.setAttribute('data-title', e.target.value);
            // e.target.parentNode.classList.add(styles.hasTitle);
        }
    };

    return (
        <InputLabel
            label={children}
            info={info}
            required={required}
            isActive={isActive}
            isTitleActive={isTitleActive}
            max={max}
            style={style}
            extended={extended}
            valueLength={value?.length || 0}
            fullWidth={fullWidth}
        >
            {props.type === 'checkbox' ? (
                <CheckboxRounded {...props} />
            ) : // : props.type === 'button' ? (
            // <Button {...props}>{btnChildren}</Button>
            // )
            props.type === 'textarea' ? (
                <>
                    <textarea
                        className={inputStyles.inputField}
                        required={required}
                        onInput={onInput}
                        cols={1}
                        rows={1}
                        {...props}
                    >
                        {value}
                    </textarea>
                </>
            ) : (
                <input
                    className={inputStyles.inputField}
                    required={required}
                    {...props}
                    value={value}
                    onMouseEnter={onMouseEnter}
                    onInput={onInput}
                />
            )}
        </InputLabel>
    );
};

export default Input;
