import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useEventListener from '@use-it/event-listener';

import {
    Account,
    Contact,
    DealTaxes,
    DealTaxesExpanded,
    DealTaxesTemplate,
    Disclaimer,
    LegalStructures,
    Login,
    Options,
    TaxOptimisation,
} from './pages';
import { Main } from './layouts';
import ComparisonTable from './pages/ComparisonTable/ComparisonTable';
import PrivateRouteWithLayout from './layouts/PrivateRouteWithLayout';
import Glossary from './pages/Glossary/Glossary';
import LegalStructuresTemplate from './pages/LegalStructuresTemplate/LegalStructuresTemplate';
import TaxOptimisationTemplate from './pages/TaxOptimisationTemplate/TaxOptimisationTemplate';
import StorageView from './pages/Storage/StorageView';
import Storage from './pages/Storage/Storage';
import StorageForm from './pages/Storage/StorageForm/StorageForm';

const BACK_KEYS = ['Delete', 'Backspace'];

const AppRouter = () => {
    const location = useLocation();
    const navigate = useNavigate();

    function handler({ key, target }: any) {
        const path = location.pathname;

        if (
            path === '/' ||
            path === '/login' ||
            path === '/contact' ||
            path === '/disclaimer' ||
            target.tagName === 'INPUT'
        )
            return;

        if (BACK_KEYS.includes(String(key))) {
            navigate(-1);
        }
    }

    useEventListener('keydown', handler);

    return (
        <Routes>
            <Route element={<Main />}>
                <Route path="/login" element={<Login />} />

                <Route path="/contact" element={<Contact />} />
                <Route element={<PrivateRouteWithLayout />}>
                    <Route path="/" element={<Options />} />
                    <Route path={'/disclaimer'} element={<Disclaimer />} />
                    <Route path={'/glossary'} element={<Glossary />} />
                    <Route path={'/account'} element={<Account />} />
                    <Route path={'/deal-taxes'} element={<DealTaxes />} />
                    <Route path={'/legal-structures'} element={<LegalStructures />} />
                    <Route path={'/tax-review'} element={<DealTaxesExpanded />} />
                    <Route path={'/comparison-table'} element={<ComparisonTable />} />
                    <Route path={'/storage'} element={<Storage />} />
                    <Route path={'/storage/:id'} element={<StorageView />} />
                    <Route path={'/storage/create'} element={<StorageForm />} />
                    <Route path={'/tax-optimisation'} element={<TaxOptimisation />} />
                </Route>
            </Route>
            <Route path="/templates/deal-taxes" element={<DealTaxesTemplate />} />
            <Route path="/templates/legal-structures" element={<LegalStructuresTemplate />} />
            <Route path="/templates/tax-optimisation" element={<TaxOptimisationTemplate />} />
        </Routes>
    );
};

export default AppRouter;
