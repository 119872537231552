import ComparisonTableSection from '../ComparisonTableSection';

import { useTableStore } from '../../../store/useTableStore';
import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD14Table from './SD14Table';
import { SDProps } from './sd.interface';

const SD14 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
    setInitCollapsed,
    initCollapsed,
}: SDProps) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd14 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                title={'Required service providers'}
                row={true}
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                setInitCollapsed={setInitCollapsed}
                initCollapsed={initCollapsed}
                excludeFeat={true}
            >
                <SD14Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD14;
