import React, { createContext } from 'react';
import { ItemsByCountry } from '../store/useDiagramStore';
import { Asset, InvestmentFund, Investor, SelectionLevel, TCountryCode } from '../types/general';

interface SelectionRowContext {
    type: SelectionLevel;
    options: ItemsByCountry<Investor> | ItemsByCountry<Asset> | ItemsByCountry<InvestmentFund>;
    selectedOption: any;
    handleSelect?: (prop: any) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => any;
    showCountries: boolean;
    countries?: any;
    onCountrySelect?: any;
    selectedCountry?: TCountryCode | undefined | null;
    disabled?: boolean;
    inputType?: 'radio' | 'checkbox';
}

export const SelectionRowContext = createContext<SelectionRowContext>({
    type: SelectionLevel.Investor,
    options: {},
    selectedOption: null,
    handleSelect: (prop: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {},
    showCountries: true,
    countries: [],
    inputType: 'radio',
});
