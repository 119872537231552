import ComparisonTableSection from '../ComparisonTableSection';

import { useTableStore } from '../../../store/useTableStore';
import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD10Table from './SD10Table';
import { SDProps } from './sd.interface';

const SD10 = ({ triggerCollapse, setTablesCollapsed, tablesCollapsed, collapseOnClick }: SDProps) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd10 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={'Privacy'}
            >
                <SD10Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD10;
