import styles from './styles.module.css';
import InvestorIcon from './icons/InvestorIcon';
import InvestmentIcon from './icons/InvestmentIcon';
import AssetIcon from './icons/AssetIcon';
import ServiceProviderIcon from './icons/ServiceProviderIcon';
import AssetManagment from './icons/AssetManagment';
import CloseIcon from './icons/CloseIcon';
import AddIcon from './icons/AddIcon';
import FundOfFundsIcon from './icons/FundOfFundsIcon';
import CloseRoundedIcon from './icons/CloseRoundedIcon';
import ZoomIcon from './icons/ZoomIcon';
import CheckIcon from './icons/CheckIcon';
import EditIcon from './icons/EditIcon';
import QuestionMarkIcon from './icons/QuestionMarkIcon';
import CheckGroupsIcon from './icons/CheckGroupsIcon';
import ChevronDown from './icons/ChevronDown';
import OtherIcon from './icons/OtherIcon';

export enum Icons {
    investor = 'investor',
    investment = 'investment',
    asset = 'asset',
    serviceProvider = 'serviceProvider',
    assetManagement = 'assetManagement',
    close = 'close',
    closeRounded = 'closeRounded',
    add = 'add',
    fundOfFunds = 'fundOfFunds',
    zoom = 'zoom',
    edit = 'edit',
    check = 'check',
    questionMark = 'questionMark',
    checksGroup = 'checksGroup',
    chevronDown = 'chevronDown',
    other = 'other',
}

interface IconProps {
    icon: Icons;
}

const Icon = ({ icon }: IconProps) => {
    const icons = {
        investor: <InvestorIcon />,
        investment: <InvestmentIcon />,
        asset: <AssetIcon />,
        serviceProvider: <ServiceProviderIcon />,
        assetManagement: <AssetManagment />,
        close: <CloseIcon />,
        closeRounded: <CloseRoundedIcon />,
        add: <AddIcon />,
        fundOfFunds: <FundOfFundsIcon />,
        zoom: <ZoomIcon />,
        edit: <EditIcon />,
        check: <CheckIcon />,
        questionMark: <QuestionMarkIcon />,
        checksGroup: <CheckGroupsIcon />,
        chevronDown: <ChevronDown />,
        other: <OtherIcon />,
    };

    return <span className={styles.icon}>{icons[icon]}</span>;
};

export default Icon;
