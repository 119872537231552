import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import styles from './login-styles.module.css';
import axios from '../../utils/axios';
import { UserContext } from '../../context';
import Checkbox from '../../components/Checkbox/Checkbox';
import { Button } from '../../components';
import { LinkedButton } from '../../components/Button';

const Login = () => {
    const navigate = useNavigate();

    const { user, setUser } = useContext(UserContext);

    const [data, setData] = useState({
        email: '',
        password: '',
        rememberMe: false,
    });
    const [error, setError] = useState(false);

    useEffect(() => {
        if (user) navigate('/');
    }, [user]);

    const handleInputChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [prop]: event.target.value });
    };

    const handleLogin = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        setError(false);

        if (data.email && data.password) {
            try {
                const res = await axios.post('/auth/login', {
                    email: data.email,
                    password: data.password,
                    rememberMe: data.rememberMe,
                });

                const user = res.data;

                setUser(user);
            } catch (e) {
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    return (
        <div className={styles.box}>
            <div>
                {/* ID 1.4 */}
                <h2 className={styles.title}>Login</h2>

                {/* ID 1.G */}
                {error && (
                    <div className={styles.error}>
                        <img src={'/icons/error.svg'} alt="" />

                        <span>
                            Incorrect username or password. <br />
                            Please try again or contact us.
                        </span>
                    </div>
                )}

                <form onSubmit={handleLogin}>
                    {/* ID 1.A */}
                    <label className={`${styles.label} ${styles.email}`}>
                        <input
                            required
                            className={`${styles.input} ${styles.email}`}
                            type={'email'}
                            placeholder={'Enter your email'}
                            onChange={handleInputChange('email')}
                        />
                    </label>

                    {/* ID 1.B */}
                    <label className={`${styles.label} ${styles.password}`}>
                        <input
                            required
                            className={`${styles.input} ${styles.password}`}
                            type={'password'}
                            placeholder={'Enter your password'}
                            onChange={handleInputChange('password')}
                        />
                    </label>

                    {/* ID 1.C */}

                    <Checkbox
                        label={'Remember me'}
                        value={1}
                        onChange={handleInputChange('rememberMe')}
                        checked={data.rememberMe}
                    />

                    <div className={styles.actions}>
                        {/* ID 1.D */}
                        <Button fullWidth={true}>Login</Button>

                        {/* ID 1.E   */}
                        <LinkedButton to={'/contact'} outlined={true}>
                            Create account
                        </LinkedButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
