export const data = [
    {
        term: 'Administrated Fund',
        decryption: '',
        definition: `Cayman Islands open-ended fund with no minimum initial investment amount supervised by Cayman Islands Monetary Authority (CIMA).`,
    },
    {
        term: 'AIF',
        decryption: 'Alternative Investment Fund',
        definition: `Alternative Investment Fund: a collective investment undertaking, including investment compartments thereof, which: 
        a) raise capital from a number of investors, with a view to investing it in accordance with a defined investment policy for the benefit of those investors; and
        b) do not require authorisation pursuant to Article 5 of Directive 2009/65/EC (the UCITS Directive).`,
    },
    {
        term: 'Booked at NAV',
        decryption: '',
        definition: `If the investment was booked at NAV or at cost.`,
    },
    {
        term: 'Closed-ended',
        decryption: '',
        definition: `Collective investment scheme whose investors do not have a legal right to redeem or withdraw their shares or interest at least once per year.`,
    },
    {
        term: 'Corporate',
        decryption: '',
        definition: `A corporation is an independent legal entity owned by shareholders, in which the shareholders decide on how the company is run and who manages it.`,
    },
    {
        term: 'Debt Fund',
        decryption: '',
        definition: `Cayman Islands fund that issues debt instruments and is not subject to supervision by local authorities.`,
    },
    {
        term: 'Exempt Fund',
        decryption: '',
        definition: `Cayman Islands open-ended fund with up to 15 investors and no minimum initial investment supervised by Cayman Islands Monetary Authority (CIMA).`,
    },
    {
        term: 'Fixed capital',
        decryption: '',
        definition: `It’s a type of investment fund in Luxembourg that has a fixed capital. Its share capital can only be increased by the company’s shareholders (via amendment of the constitutional documents) and any changes to the capital must be notified and published.`,
    },
    {
        term: 'Licensed Fund',
        decryption: '',
        definition: `Cayman Islands open-ended fund licensed by Cayman Islands Monetary Authority (CIMA).`,
    },
    {
        term: 'Limited partnership',
        decryption: '',
        definition: `A limited partnership is a partnership that is established by contract for a limited or unlimited term between one or more unlimited partners (or general partners) with unlimited and joint liability for the obligations of the partnership and one or more limited partners whose liability is limited to the amount committed to the partnership, such amount constituting partnership interests, that may or may not be represented by securities depending on the terms of the partnership agreement.`,
    },
    {
        term: 'Lux LP',
        decryption: 'Luxembourg Limited Partnership',
        definition: `Luxembourg unregulated limited partnership that qualified as an Alternative Investment Fund (AIF). It is commonly used for private markets investments.`,
    },
    {
        term: 'Mandate',
        decryption: '',
        definition: `Cayman Island investment vehicle dedicated for one investor only.`,
    },
    {
        term: 'Open-ended',
        decryption: '',
        definition: `Collective investment scheme whose investors have a legal right to redeem or withdraw their shares or interest at least once per year.`,
    },
    {
        term: 'Private Fund',
        decryption: '',
        definition: `Cayman Islands closed-ended fund regulated by Cayman Islands Monetary Authority (CIMA).`,
    },
    {
        term: 'RAIF',
        decryption: 'Reserved Alternative Investment Fund',
        definition: `The Reserved Alternative Investment Fund (RAIF) is an investment fund that can invest in all types of assets. It qualifies as alternative investment fund (AIF) and is not itself subject to CSSF product approval
        The RAIF will be subject to a regime similar to that applicable to SIFs, except that it will not be subject to the supervision of the CSSF.
        The RAIF initiative aims to synchronise the Luxembourg fund regime with the new regulatory focus, which has moved from “product supervision” to “management supervision”`,
    },
    {
        term: 'Registered Fund',
        decryption: '',
        definition: `Cayman Islands open-ended fund supervised by Cayman Islands Monetary Authority (CIMA).`,
    },
    {
        term: 'Self-managed',
        decryption: '',
        definition: `A collective investment scheme (CIS) should be deemed self-managed when the management functions are performed by the governing body or any internal resource of the CIS.`,
    },
    {
        term: 'SIF',
        decryption: 'Specialised Investment Fund',
        definition: `A specialised investment fund (“SIF”) is an investment vehicle designed for sophisticated investors that is regulated by the Luxembourg Commission de Surveillance du Secteur Financier “CSSF”). A SIF provides a great deal of flexibility in the form of organisation, investment policy and the types of underlying assets in which it can invest.
        lightly regulated and tax-efficient regulatory regime in Luxembourg aimed for a broader range of eligible investors falling under AIFMD.`,
    },
];
