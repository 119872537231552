import styles from '../styles.module.css';

const StorageFormSummaryItem = ({ index, title, selected, data, ...props }: any) => {
    return (
        <div className={`${styles.item} ${selected ? styles.selected : ''}`} {...props}>
            <span className={styles.index}>{index}</span>

            <h5 className={styles.header}>{title}</h5>

            {data?.lines?.map((line: any, index: number) => (
                <span key={index} className={styles.subheader}>
                    {line}
                </span>
            ))}
            {!!data?.domicile && <span className={styles.domicile}>- {data.domicile} -</span>}
        </div>
    );
};

export default StorageFormSummaryItem;
