import styles from './selection-popup.module.css';
import LoaderComponent from '../../TransitionComponent/LoaderContent';
import { Button, Response } from '../../index';
import TransitionComponent from '../../TransitionComponent/TransitionComponents';
import React from 'react';

interface SelectionPopupBaseProps {
    ready: boolean;
    onSubmit?: any;
    children: React.ReactNode;
    selectionResult?: React.ReactNode;
    submitDisabled: boolean;
    showError: boolean;
    setShowError: any;
    useInvestmentStructure?: boolean;
}

const SelectionPopupBase = ({
    ready,
    onSubmit,
    children,
    selectionResult,
    submitDisabled,
    showError,
    setShowError,
    useInvestmentStructure,
}: SelectionPopupBaseProps) => {
    return (
        <>
            <div className={styles.modal}>
                <LoaderComponent inProp={ready}>
                    <form className={styles.modalContent} onSubmit={onSubmit}>
                        <div id={'selectionPopupBox'} className={styles.selectionBox}>
                            <h3>Please fill in information about your structure:</h3>

                            {children}
                        </div>

                        <div className={styles.selectedBox}>
                            <h5>Your structure</h5>

                            {selectionResult}

                            <Button type={'submit'} disabled={submitDisabled}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </LoaderComponent>
            </div>

            <TransitionComponent inProp={showError} isAbsolute={true} zIndex={120} duration={100}>
                <div className={styles.response}>
                    <Response type={'error'} aligned={true} setResponse={setShowError}>
                        This combination of investor
                        {useInvestmentStructure && ' - fund'} - asset is not possible legally. Your last entry will be
                        removed.
                    </Response>
                </div>
            </TransitionComponent>
        </>
    );
};

export default SelectionPopupBase;
