interface Link {
    class: string;
    source: number[];
    target: number[];
    color: string;
    opacity: number;
}

interface TriangleProps {
    link: Link;
    i: number;
}

const Triangle = ({ link, i }: TriangleProps) => {
    return (
        <>
            <line
                className={link.class}
                key={i}
                x1={link.source[0]}
                y1={link.target[1]}
                x2={link.source[0]}
                y2={link.target[1] + 40}
                stroke={link.color}
                strokeDasharray={'4'}
                fill="none"
                opacity={link.opacity}
                strokeWidth={3}
            />

            <g transform={`translate(${link.source[0] - 12}, ${link.source[1] - 52})`}>
                <line x1={0} y1={0} x2={24} y2={0} stroke={link.color} strokeWidth={2} opacity={link.opacity} />
                <line x1={4} y1={4} x2={20} y2={4} stroke={link.color} strokeWidth={2} opacity={link.opacity} />
                <line x1={8} y1={8} x2={16} y2={8} stroke={link.color} strokeWidth={2} opacity={link.opacity} />
                <line x1={12} y1={12} x2={12} y2={12} stroke={link.color} strokeWidth={2} opacity={link.opacity} />
            </g>
        </>
    );
};

export default Triangle;
