const AssetManagment = () => {
    return (
        <svg id="mment_small" xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
            <g
                id="Ellipse_26"
                data-name="Ellipse 26"
                fill="var(--icon-fill-color, none)"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            >
                <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                <circle cx="18.5" cy="18.5" r="17.75" fill="none" />
            </g>
            <g id="Group_5653" data-name="Group 5653" transform="translate(-547.98 -2129.5)">
                <circle
                    id="Ellipse_31"
                    data-name="Ellipse 31"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(580.154 2140.5) rotate(90)"
                    fill="var(--icon-fill-color, #fff)"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
                <circle
                    id="Ellipse_32"
                    data-name="Ellipse 32"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(570.729 2143.5) rotate(90)"
                    fill="var(--icon-fill-color, #fff)"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
                <circle
                    id="Ellipse_33"
                    data-name="Ellipse 33"
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    transform="translate(563.729 2146.5) rotate(90)"
                    fill="var(--icon-fill-color, #fff)"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
                <circle
                    id="Ellipse_34"
                    data-name="Ellipse 34"
                    cx="1.4"
                    cy="1.4"
                    r="1.4"
                    transform="translate(554.781 2147.5) rotate(90)"
                    fill="var(--icon-fill-color, #fff)"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
            </g>
        </svg>
    );
};

export default AssetManagment;
