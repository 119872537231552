import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Icon } from '../../';
import { SelectedDataFund, SelectedDataMulti, SelectedDataSingle, SelectedItem } from '../SelectionPopup';
import { ItemsByCountry } from '../../../store/useDiagramStore';
import {
    Asset,
    Countries,
    InvestmentFund,
    Investor,
    SelectionLevel,
    SelectionLevelProps,
    TCountryCode,
} from '../../../types/general';

type SelectionResultProps = {
    selectedData: SelectedDataFund | SelectedDataMulti | SelectedDataSingle;
    investors: ItemsByCountry<Investor>;
    investmentFunds?: ItemsByCountry<InvestmentFund>;
    fundOfFunds?: ItemsByCountry<InvestmentFund>;
    assets: ItemsByCountry<Asset>;
    useInvestmentStructure?: boolean;
    useFoF?: boolean;
};

const capitalizeString = (value: any) => {
    return value
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, (w: string) => w.replace(/^\w/, (c: string) => c.toUpperCase()));
};

const SelectionResult = ({ selectedData }: SelectionResultProps) => {
    return (
        <>
            {'investors' in selectedData ? (
                <ItemRow
                    type={SelectionLevel.Investor}
                    currentMultiSelection={{
                        selectedData: selectedData.investors,
                    }}
                    nextMultiSelection={
                        'investmentFund' in selectedData
                            ? undefined
                            : {
                                  selectedData: selectedData.assets,
                              }
                    }
                    nextSingleSelection={
                        'investmentFund' in selectedData
                            ? selectedData.fundOfFunds
                                ? {
                                      selectedValue: selectedData.fundOfFunds.name,
                                      selectedCountry: selectedData.fundOfFunds.countryCode,
                                  }
                                : {
                                      selectedValue: selectedData.investmentFund.name,
                                      selectedCountry: selectedData.investmentFund.countryCode,
                                  }
                            : undefined
                    }
                />
            ) : (
                <ItemRow
                    type={SelectionLevel.Investor}
                    currentSingleSelection={{
                        selectedValue: selectedData.investor.name,
                        selectedCountry: selectedData.investor.countryCode,
                    }}
                    nextSingleSelection={{
                        selectedValue: selectedData.asset.name,
                        selectedCountry: selectedData.asset.countryCode,
                    }}
                />
            )}

            {'investmentFund' in selectedData && (
                <>
                    {selectedData.fundOfFunds && (
                        <ItemRow
                            type={SelectionLevel.FundOfFunds}
                            currentSingleSelection={{
                                selectedValue: selectedData.fundOfFunds.name,
                                selectedCountry: selectedData.fundOfFunds.countryCode,
                            }}
                            nextSingleSelection={{
                                selectedValue: selectedData.investmentFund.name,
                                selectedCountry: selectedData.investmentFund.countryCode,
                            }}
                        />
                    )}

                    <ItemRow
                        type={SelectionLevel.Fund}
                        currentSingleSelection={{
                            selectedValue: selectedData.investmentFund.name,
                            selectedCountry: selectedData.investmentFund.countryCode,
                        }}
                        nextMultiSelection={{
                            selectedData: selectedData.assets,
                        }}
                    />
                </>
            )}

            {'assets' in selectedData ? (
                <ItemRow
                    type={SelectionLevel.Asset}
                    currentMultiSelection={{
                        selectedData: selectedData.assets,
                    }}
                />
            ) : (
                <ItemRow
                    type={SelectionLevel.Asset}
                    currentSingleSelection={{
                        selectedValue: selectedData.asset.name,
                        selectedCountry: selectedData.asset.countryCode,
                    }}
                />
            )}

            <hr className={styles.separator} />
        </>
    );
};

interface ItemRowMulti {
    selectedData: SelectedItem[];
}

interface ItemRowSingle {
    selectedValue: number | string | null | undefined;
    selectedCountry: TCountryCode | null | undefined;
}
interface ItemRowProps {
    type: SelectionLevel;

    currentSingleSelection?: ItemRowSingle;
    nextSingleSelection?: ItemRowSingle;

    currentMultiSelection?: ItemRowMulti;
    nextMultiSelection?: ItemRowMulti;
}
const ItemRow = ({
    type,
    currentSingleSelection,
    currentMultiSelection,
    nextSingleSelection,
    nextMultiSelection,
}: ItemRowProps) => {
    // selectedData array or string/number
    const [selectedFullCombination, setSelectedFullCombination] = useState(false);
    const [selectedPartialRow, setSelectedPartialRow] = useState(false);
    const [selectedFullRow, setSelectedFullRow] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [selectedValue, setSelectedValue] = useState<any>();

    const [currentUniqueMulti, setUniqueMulti] = useState<undefined | SelectedItem[]>();

    const getMultiSelectionValues = ({ selectedData }: ItemRowMulti) => {
        const singleSelection = selectedData && selectedData[0] ? selectedData[0] : undefined;
        const selectedCountry = singleSelection ? singleSelection.countryCode : undefined;
        const selectedValue = singleSelection ? singleSelection.name : undefined;

        return {
            selectedCountry,
            selectedValue,
        };
    };

    const getSelectionValue = (singleData: ItemRowSingle | undefined, multiData: ItemRowMulti | undefined) => {
        if (singleData) {
            return singleData;
        } else if (multiData) {
            return getMultiSelectionValues(multiData);
        } else {
            return {
                selectedCountry: undefined,
                selectedValue: undefined,
            };
        }
    };

    useEffect(() => {
        if (currentMultiSelection) {
            setUniqueMulti(
                currentMultiSelection.selectedData.filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.countryCode === value.countryCode && value.countryCode)
                )
            );
        }

        const currentSelection = getSelectionValue(currentSingleSelection, currentMultiSelection);
        const nestSelection = getSelectionValue(nextSingleSelection, nextMultiSelection);

        setSelectedFullCombination(
            !!(
                currentSelection.selectedCountry &&
                currentSelection.selectedValue &&
                nestSelection.selectedCountry &&
                nestSelection.selectedValue
            )
        );

        setSelectedPartialRow(!!(currentSelection.selectedCountry && nestSelection.selectedCountry));
        setSelectedFullRow(!!(currentSelection.selectedCountry && currentSelection.selectedValue));

        setSelectedCountry(currentSelection.selectedCountry);
        setSelectedValue(currentSelection.selectedValue);
    }, [currentSingleSelection, currentMultiSelection, nextSingleSelection, nextMultiSelection]);

    return (
        <>
            {typeof currentUniqueMulti !== 'undefined' && currentUniqueMulti!.length > 1 ? (
                <div
                    className={`${styles.selectedBoxRow} 
                    ${selectedFullRow ? styles.selected : ''} 
                    ${selectedFullCombination ? styles.full : ''} 
                    ${selectedPartialRow ? styles.partial : ''}`}
                    style={{
                        marginTop: 28 * (currentUniqueMulti.length - 1),
                        // height: `${100 / (currentUniqueMulti.length - 1)}%`,
                    }}
                >
                    {currentUniqueMulti.map((selection, i: number) => (
                        <SelectionGroup
                            key={i}
                            index={currentUniqueMulti.length - i - 1}
                            type={type}
                            selectedCountry={selection.countryCode}
                            selectedLabel={selection.name}
                            isMulti={true}
                        />
                    ))}
                </div>
            ) : (
                <div
                    className={`${styles.selectedBoxRow} 
                    ${selectedFullRow ? styles.selected : ''} 
                    ${selectedFullCombination ? styles.full : ''} 
                    ${selectedPartialRow ? styles.partial : ''}`}
                >
                    <SelectionSingleRow type={type} selectedCountry={selectedCountry} selectedLabel={selectedValue} />
                </div>
            )}
        </>
    );
};

interface SelectionSingleRowProps {
    type: SelectionLevel;
    selectedCountry: TCountryCode | null | undefined;
    selectedLabel?: string | number;
}
const SelectionSingleRow = ({ type, selectedCountry, selectedLabel }: SelectionSingleRowProps) => {
    return (
        <SelectionGroup type={type} selectedCountry={selectedCountry} selectedLabel={selectedLabel}>
            <div className={styles.countryImg}>
                {selectedCountry && <img src={Countries[selectedCountry?.toLowerCase() as TCountryCode]?.img} alt="" />}
            </div>

            <p>
                Domicile:{' '}
                {selectedCountry ? Countries[selectedCountry?.toLowerCase() as TCountryCode]?.domicileName : ''}
            </p>
            <p>
                {SelectionLevelProps[type].title}: {selectedLabel}
            </p>
        </SelectionGroup>
    );
};

const SelectionGroup = ({ children, type, selectedCountry, selectionLabel, index = 0, isMulti = false }: any) => {
    return (
        <>
            <div
                className={`${styles.selectedBoxRowTypeWrapper}
                ${isMulti ? styles.multi : ''}`}
                style={{
                    marginTop: 25 * index! * -1,
                    marginLeft: 23 * index! * -1,
                }}
            >
                <div
                    className={`${styles.selectedBoxRowType} 
                ${selectedCountry ? styles.selected : ''} 
                ${selectionLabel ? styles.selectedIcon : ''} 
                ${isMulti ? styles.multi : ''}`}
                >
                    {/*  @ts-ignore */}
                    <Icon icon={SelectionLevelProps[type].icon} />

                    {!children && (
                        <>
                            <div className={styles.countryImg}>
                                {selectedCountry && (
                                    // @ts-ignore
                                    <img src={Countries[selectedCountry?.toLowerCase()]?.img} alt="" />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {children && <div className={styles.info}>{children}</div>}
        </>
    );
};

export default SelectionResult;
