import create from 'zustand';

interface TableData {
    data: any;
    activeFund: any;
    setTableData: (data: any) => void;
    setActiveFund: (data: any) => void;
}

export const useTableStore = create<TableData>((set) => ({
    data: {},
    activeFund: {},
    setTableData: (data: any) => set((state) => ({ data: data })),
    setActiveFund: (data: any) => set((state) => ({ activeFund: data })),
}));
