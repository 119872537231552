import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

export interface TransitionComponentProps {
    inProp: boolean;
    isAbsolute?: boolean;
    zIndex?: number;
    duration?: number;
    top?: string;
}

interface TransitionComponentLocal extends TransitionComponentProps {
    children: React.ReactNode;
}

const TransitionComponent = ({ children, inProp, isAbsolute, zIndex, duration = 250 }: TransitionComponentLocal) => {
    const nodeRef = useRef(null);

    return (
        <Transition nodeRef={nodeRef} in={inProp} timeout={duration} mountOnEnter={true} unmountOnExit={true}>
            {(state) => (
                <div
                    ref={nodeRef}
                    style={{
                        ...{
                            transition: `opacity ${duration}ms ease-in-out`,
                        },
                        // @ts-ignore
                        ...transitionStyles[state],
                        // margin: 'auto',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        position: isAbsolute ? 'absolute' : '',
                        zIndex: zIndex || 'initial',
                    }}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default TransitionComponent;
