import { CurrencyFormat } from './index';

import styles from './formats-styles.module.css';
import { getUnique } from '../utils';

const DescriptionCurrencyFormat = (data: any, d: any, dataKey: any, colKey: any) => {
    if (d === null || d === '' || typeof d === 'undefined') return '-';

    const uniqueVal = data[dataKey].map((d: any) => d[colKey]).filter(getUnique);

    const arr = d.split(' ');
    const num = parseInt(arr[0].toString().trim());

    let fullData = uniqueVal
        .filter((d: any) => d !== null && d !== '' && typeof d !== 'undefined')
        .map((d: any) => {
            const arr = d.split(' ');
            return parseInt(arr[0].toString().trim());
        });

    const max = Math.max(...fullData);

    const percent = (num / max) * 100;

    return (
        <>
            <div className={styles.progressPerc}>
                <div style={{ width: `${percent}%` }} />
            </div>

            {CurrencyFormat(d)}
        </>
    );
};

export default DescriptionCurrencyFormat;
