import styles from './main-structures-detail.module.css';
import React from 'react';
import { CloseButton } from '../../../../components';
import MainStructuresDetailField from './components/MainStructuresDetailField/MainStructuresDetailField';

interface MainStructuresDetailProps {
    activeInvestment: any;
    onClose: any;
}

const MainStructuresDetail = ({ activeInvestment, onClose }: MainStructuresDetailProps) => {
    const legalForms = activeInvestment?.ribbons.map((r: any) => r.to);

    return (
        <>
            {activeInvestment && (
                <div className={styles.boxOuter}>
                    <div className={styles.box}>
                        <CloseButton onClick={() => onClose()} />
                        <h6 className={styles.fundTitle}>Investment fund</h6>
                        <h5 className={styles.fundTitleFN}>{activeInvestment.data.fullName}</h5>
                        <div className={styles.data}>
                            <MainStructuresDetailField title={'DOMICILE'} value={activeInvestment.data.domicileName} />
                            <MainStructuresDetailField
                                title={'REGULATION'}
                                value={activeInvestment.data.regulationType}
                            />
                            {activeInvestment.data.applicableTax && (
                                <MainStructuresDetailField
                                    title={'TAX STATUS'}
                                    value={activeInvestment.data.applicableTax.replace(/\w\S*/g, (w: any) =>
                                        w.replace(/^\w/, (c: any) => c.toUpperCase())
                                    )}
                                />
                            )}
                            <MainStructuresDetailField
                                title={'LEVEL OF REGULATION'}
                                value={activeInvestment.data.levelOfRegulation.replace(/\w\S*/g, (w: any) =>
                                    w.replace(/^\w/, (c: any) => c.toUpperCase())
                                )}
                            />
                            <MainStructuresDetailField
                                title={'COMPLEXITY'}
                                value={activeInvestment.data.regulationComplexity}
                            />
                            <MainStructuresDetailField
                                title={'IF AIF'}
                                value={activeInvestment.data.ifAif
                                    .toString()
                                    .replace(/\w\S*/g, (w: any) => w.replace(/^\w/, (c: any) => c.toUpperCase()))}
                                useCheckbox={true}
                                info={'Alternative Investment Fund'}
                            />
                            <MainStructuresDetailField
                                title={'MAX SHAREHOLDERS'}
                                value={activeInvestment.data.maxNumOfShareholders}
                            />
                            <MainStructuresDetailField
                                title={'COST TO LAUNCH'}
                                value={`$${activeInvestment.data.costsToLaunchUsdFormated}`}
                                footnote={1}
                            />
                            <MainStructuresDetailField
                                title={'ASSET MANAGER'}
                                value={activeInvestment.data.assetManagerToShow}
                            />
                            <MainStructuresDetailField
                                title={'ANNUAL COST FOR $100M'}
                                value={`$${activeInvestment.data.annualCostsFormated}`}
                                footnote={1}
                            />
                        </div>

                        <div className={styles.borderDataTop}>
                            <h6>Legal form</h6>
                            <h6>
                                Days to launch <span className={styles.footnote}>2</span>
                            </h6>
                        </div>
                        {legalForms.map((lf: any, key: any) => (
                            <div key={key} className={styles.borderData}>
                                <h5>{lf.name}</h5>
                                <h5>
                                    {
                                        lf.launchBubbles.find((lb: any) => lb.investmentFund === activeInvestment.name)
                                            .cost
                                    }
                                </h5>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default MainStructuresDetail;
