// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__W1P6A {\n    font-family: var(--font-secondary);\n    color: var(--primary-color-active);\n    margin: 35px 0;\n    font-size: 35px;\n    font-weight: 700;\n}\n\n.styles_item__qITSz {\n    width: 140px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Compliance/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kCAAkC;IAClC,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\n    font-family: var(--font-secondary);\n    color: var(--primary-color-active);\n    margin: 35px 0;\n    font-size: 35px;\n    font-weight: 700;\n}\n\n.item {\n    width: 140px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__W1P6A",
	"item": "styles_item__qITSz"
};
export default ___CSS_LOADER_EXPORT___;
