import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { DescriptionFormat, PercentFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD12Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd12 ? (
        <table>
            <TableHeader sdKey={'sd12'} />

            <tbody>
                <Tr featName={'Tax'} dataKey={'sd12'} fundKey={'sd11'} colKey={'tax'} firstCol={'tax'} />
                <Tr
                    featName={'Tax rate'}
                    dataKey={'sd12'}
                    colKey={'taxRate'}
                    fundKey={'sd11'}
                    formatFunc={PercentFormat}
                    firstCol={'tax'}
                />
                <TrProvider>
                    <Tr
                        featName={'Exemptions'}
                        dataKey={'sd12'}
                        fundKey={'sd11'}
                        colKey={'exemptions'}
                        formatFunc={DescriptionFormat}
                        firstCol={'tax'}
                    />
                </TrProvider>
            </tbody>
        </table>
    ) : null;
};

export default SD12Table;
