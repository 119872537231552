import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import { CloseButton, DeletePopup, Response } from '../../components';
import { PresetInterface } from '../../components/Preset/Preset';
import OverviewCategories from '../../components/Overview/OverviewCategories/OverviewCategories';
import TransitionComponent from '../../components/TransitionComponent/TransitionComponents';
import OverviewCategoryItems from '../../components/Overview/OverviewCategoryItems';
import OverviewNavigation from '../../components/Overview/OverviewNavigation/OverviewNavigation';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import { GraphTypes } from '../../types/general';

const Account = () => {
    const [selectedType, setSelectedType] = useState<undefined | null | GraphTypes>(undefined);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [presets, setPresets] = useState<PresetInterface[]>([]);
    const [loading, setLoading] = useState(true);

    const [showPopup, setShowPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [currentPreset, setCurrentPreset] = useState<number | null>(null);
    const [showDownload, setShowDownload] = useState(false);

    useEffect(() => {
        if (searchParams.has('save')) {
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 2500);
            // setSearchParams('');
        }

        setSelectedType(searchParams.get('t') as GraphTypes);
    }, [searchParams]);

    useEffect(() => {
        (async () => {
            const presets = await axios.get('/presets');
            const storage = await axios.get('/storage');
            const compliance = await axios.get('/compliance');

            console.log({
                presets,
                storage,
                compliance,
            });

            setPresets([
                ...presets.data,
                ...compliance.data.map((item: any) => ({
                    ...item,
                    id: item.vehicleId,
                    presetType: 'compliance',
                })),
            ]);

            console.log('types', [
                ...presets.data,
                ...compliance.data.map((item: any) => ({
                    ...item,
                    presetType: 'compliance',
                })),
            ]);
            setLoading(false);
        })();
    }, []);

    const handleDeletePopup = async (data: PresetInterface) => {
        setShowDeletePopup(true);
        setCurrentPreset(data.id);
    };

    const handleDelete = async () => {
        await axios.delete(`/presets/${currentPreset}`);
        setPresets(presets.filter((p) => p.id !== currentPreset));
        setShowDeletePopup(false);
        setCurrentPreset(null);
    };

    const handleCategoryChange = (type: GraphTypes) => (e: any) => {
        setSelectedType(type);
        if (type === GraphTypes.Storage) {
            navigate(`/storage`);
        } else if (type === GraphTypes.Compliance) {
            navigate(`/dashboard/${type}`);
        } else if (!!type) {
            navigate(`/dashboard?t=${type}`);
        } else {
            navigate(`/dashboard`);
        }
    };

    return (
        <>
            {showDeletePopup && (
                <DeletePopup onDelete={() => handleDelete()} onCancel={() => setShowDeletePopup(false)} />
            )}
            {showDownload && (
                <div className={styles.popupContainer}>
                    <div className={styles.popup}>
                        <div className={styles.close}>
                            <CloseButton
                                onClick={() => {
                                    setShowDownload(false);
                                }}
                            />
                        </div>
                        <h4>Downloading</h4>
                        <div className={styles.linearActivity}>
                            <div className={styles.indeterminate}></div>
                        </div>
                        <p>It takes just couple of minutes…</p>
                    </div>
                </div>
            )}

            <LoaderComponent inProp={!loading}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <TransitionComponent inProp={!selectedType} isAbsolute={!!selectedType}>
                            <div className={styles.categoriesWrapper}>
                                <div className={styles.categories}>
                                    <OverviewCategories onClick={handleCategoryChange} presets={presets} />
                                </div>
                            </div>
                        </TransitionComponent>

                        <TransitionComponent inProp={!!selectedType} isAbsolute={!selectedType}>
                            <div className={styles.grid}>
                                <div>
                                    {Object.keys(GraphTypes).map((key: any) => (
                                        <TransitionComponent
                                            key={key}
                                            // @ts-ignore
                                            inProp={selectedType === GraphTypes[key]}
                                            // @ts-ignore
                                            isAbsolute={selectedType !== GraphTypes[key]}
                                        >
                                            <OverviewCategoryItems
                                                // @ts-ignore
                                                type={GraphTypes[key]}
                                                presets={presets}
                                                handleDeletePopup={handleDeletePopup}
                                                setShowDownload={setShowDownload}
                                            />
                                        </TransitionComponent>
                                    ))}
                                </div>
                            </div>
                        </TransitionComponent>
                    </div>

                    {showPopup && (
                        <Response type={'success'} aligned={false} setResponse={setShowPopup}>
                            Saved successfully
                        </Response>
                    )}
                </div>
            </LoaderComponent>
        </>
    );
};

export default Account;
