import React from 'react';
import styles from './result-table-styles.module.css';

import { Button } from '../index';
import { SelectionCombination } from '../SelectedCombination';
import { DealTaxesSelectedData } from '../../pages/DealTaxes/DealTaxes';
import { LegalStructuresSelectedData } from '../../pages/LegalStructures/LegalStructures';
import { TaxOptimisationSelectedData } from '../../pages/TaxOptimisation/TaxOptimisation';
import TransitionComponent from '../TransitionComponent/TransitionComponents';

interface ResultTableLeftProps {
    selectedData: DealTaxesSelectedData | LegalStructuresSelectedData | TaxOptimisationSelectedData;
    tableInfo?: string | React.ReactNode;
    onEdit: () => void;
    comments?: any;
    showComments?: boolean;
    legend?: boolean;
    isOptimisation?: boolean;
    hideFundRow?: boolean;
    fundData?: any;
    children?: React.ReactNode;
}

const ResultTableLeft = ({
    selectedData,
    tableInfo,
    onEdit,
    comments,
    legend,
    showComments = false,
    isOptimisation,
    hideFundRow,
    fundData,
    children,
}: ResultTableLeftProps) => {
    const legends: string[] = [
        // `1: Approximate costs to cover all required service providers and setup. Please check the detailed overview for the breakdown.`,
        // `2: Minimum numbers of days to launch the structure (excluding any legal draftings, negotiations etc). Please check the detailed overview for the breakdown.`,
    ];

    return (
        <div className={styles.tableLeft}>
            {children}

            {!children && (
                <>
                    <div className={styles.tableInfo}>{tableInfo}</div>

                    <div className={styles.tableCenter}>
                        <SelectionCombination
                            selectedData={selectedData}
                            isOptimisation={isOptimisation}
                            hideFundRow={hideFundRow}
                            fundData={fundData}
                        />

                        {!isOptimisation && (
                            <div className={styles.btnContainer}>
                                <Button type={'button'} outlined={true} onClick={onEdit}>
                                    Edit
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            )}

            <div className={styles.tableBottom}>
                <TransitionComponent inProp={!!legends && showComments} duration={100}>
                    <p className={styles.graphTip}>
                        {comments ? `${comments.legendId} : ${comments.fullComments}` : ''}
                        {legends
                            ? legends.map((l, i) => (
                                  <React.Fragment key={i}>
                                      {l} <br />
                                  </React.Fragment>
                              ))
                            : ''}
                    </p>
                </TransitionComponent>
            </div>
        </div>
    );
};
export default ResultTableLeft;
