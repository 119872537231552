// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_p__ywukV {\n    min-width: 100%;\n}\n\n.styles_row__Kbii4 {\n    display: flex;\n    min-width: 100%;\n    justify-content: space-between;\n    grid-gap: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Compliance/components/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,cAAc;AAClB","sourcesContent":[".p {\n    min-width: 100%;\n}\n\n.row {\n    display: flex;\n    min-width: 100%;\n    justify-content: space-between;\n    grid-gap: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"p": "styles_p__ywukV",
	"row": "styles_row__Kbii4"
};
export default ___CSS_LOADER_EXPORT___;
