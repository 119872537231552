import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormCollapsableRow from '../../../layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../../../components/Input/Input';
import SelectedValuesList from '../../../../../components/SelectedValuesList/SelectedValuesList';
import Select from '../../../../../components/Select/Select';
import { Button } from '../../../../../components';
import { StorageType } from '../../../StorageForm/StorageForm';

const StorageOther = ({ selectedData, onClose, handleNextStep }: any) => {
    return (
        <FormWrapper>
            <FormLayout
                title={'Other items (1/2)'}
                description={'Other information about this structure needed for regulatory and compliance tracking.'}
                onClose={onClose}
                footerMain={<Button onClick={() => handleNextStep(StorageType.OtherPartTwo)}>Next</Button>}
            >
                <Input type={'checkbox'} checked={selectedData.openEnded} disabled>
                    Open ended structure?
                </Input>
                <Input type={'checkbox'} checked={selectedData.evergreen} disabled>
                    Evergreen
                </Input>

                <Select
                    value={selectedData.licenses?.map((item: any) => ({
                        label: item.regulatoryStatus,
                        value: item.regulatoryStatus,
                    }))}
                    options={selectedData.licenses}
                    valueKey={'regulatoryStatus'}
                    labelKey={'regulatoryStatus'}
                    isMulti={true}
                    info={'Licences and regulatory approvals obtained by the investment vehicle.'}
                    placeholder={'No licenses selected'}
                    readOnly={true}
                    disabled={!selectedData.licenses?.length}
                >
                    Licenses
                </Select>

                <br />

                <Select
                    value={selectedData.countries?.map((item: any) => ({
                        label: item.domicile,
                        value: item.domicile,
                    }))}
                    options={selectedData.countries}
                    valueKey={'domicile'}
                    labelKey={'domicile'}
                    isMulti={true}
                    placeholder={'No countries selected'}
                    info={'Countries where the fund can be offered / marketed.'}
                    readOnly={true}
                    disabled={!selectedData.countries?.length}
                >
                    Distribution countries
                </Select>

                <br />

                <Select
                    value={selectedData.doubleTreaties?.map((item: any) => ({
                        label: item.dttTitle,
                        value: item.dttTitle,
                    }))}
                    options={selectedData.doubleTreaties}
                    valueKey={'dttTitle'}
                    labelKey={'dttTitle'}
                    isMulti={true}
                    placeholder={'No treaties selected'}
                    info={'Double tax treaties that actually allow for withholding tax reductions.'}
                    readOnly={true}
                    disabled={!selectedData.doubleTreaties?.length}
                >
                    Double treaties countries
                </Select>

                <br />

                <Select
                    value={selectedData.localTaxStatus?.map((item: any) => ({
                        label: item.taxStatus,
                        value: item.taxStatus,
                    }))}
                    options={selectedData.localTaxStatus}
                    valueKey={'taxStatus'}
                    labelKey={'taxStatus'}
                    isMulti={true}
                    placeholder={'No local tax status selected'}
                    info={'Local tax status of the investment vehicle in the domicile of investors/assets.'}
                    readOnly={true}
                    disabled={!selectedData.doubleTreaties?.length}
                >
                    Local tax status
                </Select>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageOther;
