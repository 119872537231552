import styles from './pdf-template-layout.module.css';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface PdfTemplateLayoutProps {
    invisible?: boolean;
    children?: React.ReactNode;
}

const PdfTemplateLayout = ({ invisible, children }: PdfTemplateLayoutProps) => {
    const [searchParams] = useSearchParams();

    let date = searchParams.get('createdAt') || null;
    if (date) {
        date = new Date(date).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    }

    useEffect(() => {
        const root = document.querySelector('#root') as HTMLElement | null;
        root?.style.setProperty('display', 'block');
    }, []);

    return (
        <div className={`${styles.page} page`}>
            <div className={styles.container}>
                <header
                    className={styles.header}
                    style={{
                        opacity: invisible ? 0 : 1,
                    }}
                >
                    <img src="/logo.svg" alt="White Thesis" />

                    {date}
                </header>

                {children}
            </div>
        </div>
    );
};
export default PdfTemplateLayout;
