import styles from './styles-roller.module.css';
const LoaderVariantRoller = () => {
    return (
        <div className={styles.ldsRoller}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default LoaderVariantRoller;
