import React from 'react';

export const TableDescriptionContext = React.createContext({
    expandedRows: 0,
    setExpandedRows: (value: number) => {},
    globalExpanded: false,
    setGlobalExpanded: (value: boolean) => {},
    totalExpandableRows: 0,
    setTotalExpandableRows: (value: number) => {},
});
