import styles from './styles.module.css';
import { Button, Icon } from '../../../../../components';
import { Icons } from '../../../../../components/Icon/Icon';
import selectionStyles from '../../../../../components/SelectionPopup/SelectionPopupBase/selection-popup.module.css';

const StorageCurrentStatus = ({ onSave, canSave, children, activeStates }: any) => {
    return (
        <div className={selectionStyles.selectedBox}>
            <div className={styles.content}>
                <div
                    style={{
                        marginBottom: '40px',
                        textAlign: 'right',
                    }}
                >
                    {children}
                </div>

                <div
                    className={`${styles.item} 
                    ${styles.horizontal} 
                    ${activeStates?.investors ? styles.active : ''}
                    `}
                >
                    <div className={styles.box}>
                        <Icon icon={Icons.investor} />
                    </div>
                    <h6>Investors</h6>

                    <span className={styles.arrow} />
                </div>
                <div className={styles.row}>
                    <div className={`${styles.item} ${activeStates?.serviceProviders ? styles.active : ''}`}>
                        <h6>Service providers</h6>
                        <div className={styles.box}>
                            <Icon icon={Icons.serviceProvider} />
                        </div>
                        <span className={styles.arrow} />
                    </div>

                    <div className={`${styles.item} ${activeStates?.assetManager ? styles.active : ''}`}>
                        <h6>Asset management</h6>
                        <div className={styles.box}>
                            <Icon icon={Icons.assetManagement} />
                        </div>
                        <span className={styles.arrow} />
                    </div>
                </div>

                <div
                    className={`${styles.item} 
                    ${styles.horizontal} 
                    ${styles.structure}
                    ${activeStates?.structure ? styles.active : ''}
                `}
                >
                    <div className={styles.box}>
                        <Icon icon={Icons.investment} />
                    </div>
                    <h6>Investment structure</h6>
                    <span className={styles.arrow} />
                </div>

                <div
                    className={`${styles.item} 
                    ${styles.horizontal} 
                    ${styles.last}
                    ${activeStates?.assets ? styles.active : ''}
                `}
                >
                    <div className={styles.box}>
                        <Icon icon={Icons.asset} />
                    </div>
                    <h6>Assets</h6>
                </div>

                {/*<div>*/}
                {/*    <h6>Other</h6>*/}
                {/*</div>*/}
            </div>

            {!!onSave && (
                <div className={styles.footer}>
                    <Button disabled={!canSave}>Save</Button>
                </div>
            )}
        </div>
    );
};

export default StorageCurrentStatus;
