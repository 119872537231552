import { useEffect, useState } from 'react';
import { StorageType } from '../../StorageForm';
import { Button, Icon } from '../../../../../components';
import AssetSingle from './components/AssetSingle';
import axios from '../../../../../utils/axios';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import AssetCreateForm from './components/AssetCreateForm';
import { Icons } from '../../../../../components/Icon/Icon';

const StorageAssets = ({ selectedData, generalInfo, handleNextStep, handleClose }: any) => {
    const [additionalItems, setAdditionalItems] = useState<any[]>([]);
    const [showCreateForm, setShowCreateForm] = useState(false);

    const [data, setData] = useState<any[]>(selectedData ? [...selectedData] : [{}]);

    const [availableItems, setAvailableItems] = useState<any[]>([]);

    const [selectionsValid, setSelectionsValid] = useState<any>(false);

    useEffect(() => {
        setSelectionsValid(!data.some((item) => !(item.domicile?.value && item.asset?.length)));
    }, [data]);

    useEffect(() => {
        (async () => {
            const { data } = await axios(`/storage/assets`);

            console.log('setAvailableItems, ', data);

            setAvailableItems(data);
        })();
    }, []);

    const handleAddItem = () => {
        setData([...data, {}]);
    };

    const handleRemoveItem = (index: number) => () => {
        const tmp = [...data];
        tmp.splice(index, 1);
        setData(tmp);
    };

    const handleCreateItems = (newItems: []) => (e: any) => {
        e.preventDefault();
        setAdditionalItems([...additionalItems, ...newItems]);
        setShowCreateForm(false);
    };

    const handleShowCreateForm = (index: number) => () => {
        setShowCreateForm(true);
    };

    const handleItemUpdate = (index: number) => (item: any) => {
        const tmp = [...data];
        tmp[index] = item;
        setData(tmp);
    };

    return (
        <FormWrapper>
            <FormLayout
                title={'Asset'}
                description={'Please fill in information about asset(s).'}
                footerMain={<Button form={'mainForm'}>Next</Button>}
                footerAdditional={
                    <Button
                        secondary={true}
                        outlined={true}
                        type={'button'}
                        before={<Icon icon={Icons.add} />}
                        onClick={handleAddItem}
                        fullWidth={true}
                        disabled={!selectionsValid}
                    >
                        Add line
                    </Button>
                }
                handleClose={handleClose}
                handlePrevStep={handleNextStep(data, StorageType.Investors)}
            >
                <form id={'mainForm'} onSubmit={handleNextStep(data, StorageType.AssetManager)}>
                    {data.map((item: any, index: number) => (
                        <AssetSingle
                            selectedData={item}
                            key={index}
                            countries={generalInfo.countries}
                            availableItems={availableItems}
                            handleRemoveItem={handleRemoveItem(index)}
                            handleShowCreateForm={handleShowCreateForm(index)}
                            onUpdate={handleItemUpdate(index)}
                            additionalItems={additionalItems}
                        />
                    ))}
                </form>
            </FormLayout>

            {showCreateForm && (
                <AssetCreateForm
                    onSave={handleCreateItems}
                    generalInfo={generalInfo}
                    onClose={() => {
                        setShowCreateForm(false);
                    }}
                />
            )}
        </FormWrapper>
    );
};

export default StorageAssets;
