import ComparisonTableSection from '../ComparisonTableSection';

import { useTableStore } from '../../../store/useTableStore';
import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD12Table from './SD12Table';
import { SDProps } from './sd.interface';

const SD12 = ({ triggerCollapse, setTablesCollapsed, tablesCollapsed, collapseOnClick }: SDProps) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd12 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={'Tax features 2/2'}
            >
                <SD12Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD12;
