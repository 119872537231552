import create from 'zustand';
import axios from '../utils/axios';
import { useStorageStore } from './useStorageStore';
import {
    StorageNestedTypes,
    StorageAssetManagement,
    StorageAssetManagementRole,
    StorageAssetManagerParams,
    StorageStructureDomicile,
} from '../types/storage';
import { STORAGE_ASSET_MANAGEMENT_API } from '../utils/constants';

interface Counterparty {
    amDomicile: string;
    assetManagerId: number;
    cDomicile: string;
    counterpartyId: number;
    domicileId: number;
    name: string;
}

interface RegulatoryStatus {
    regulatoryStatus: string;
}

interface StorageStore {
    children: StorageNestedTypes[];

    clearDeleted: () => void;

    assetManagement: StorageAssetManagement[];
    getAssetManagements: (params: StorageAssetManagerParams) => Promise<StorageAssetManagement[]>;
    setAssetManagements: (item: StorageAssetManagement) => Promise<void>;

    getAssetManagementOptions: (params: StorageAssetManagerParams) => Promise<void>;

    g3CountryOptions: StorageStructureDomicile[];
    countryFreeOptions: StorageStructureDomicile[];

    im1RegulatoryStatusOptions: RegulatoryStatus[];
    im18RegulatoryStatusOptions: RegulatoryStatus[];
    im19RegulatoryStatusOptions: RegulatoryStatus[];

    im2PrimaryCounterpartyOptions: any[];

    im6AgreementTitleOptions: any;

    im8RoleOptions: StorageAssetManagementRole[];

    im12CounterPartyOptions: Counterparty[];

    counterPartyFreeOptions: Counterparty[];
    // getFreeOptions: () => void;

    // all options
    g1CountryOptions: StorageStructureDomicile[];
    im16AgreementCounterpartyOptions: Counterparty[];

    getCounterpartiesByDomicile: (domicileId: number) => Promise<any>;

    getIM19: (domicileId: number) => Promise<void>;

    updateAssetManagementOptions: (key: string, value: any) => void;
}

export const useStorageAssetManagement = create<StorageStore>((set, get) => ({
    children: [],

    assetManagement: [],

    g1CountryOptions: [],
    g3CountryOptions: [],

    im1RegulatoryStatusOptions: [],
    im18RegulatoryStatusOptions: [],
    im19RegulatoryStatusOptions: [],

    im2PrimaryCounterpartyOptions: [],
    im12CounterPartyOptions: [],

    im6AgreementTitleOptions: [],

    im8RoleOptions: [],

    countryFreeOptions: [],
    counterPartyFreeOptions: [],

    im16AgreementCounterpartyOptions: [],

    getAssetManagements: async (params: StorageAssetManagerParams): Promise<StorageAssetManagement[]> => {
        const { data } = await axios.get(STORAGE_ASSET_MANAGEMENT_API, {
            params,
        });

        const { assets, ...rest } = data;

        set({
            assetManagement: assets,
            ...rest,
        });

        return data;
    },
    updateAssetManagementOptions: (key: string, value: any): void => {
        set({
            [key]: value,
        });
    },
    getAssetManagementOptions: async (params: StorageAssetManagerParams): Promise<void> => {
        const { data } = await axios.get(`${STORAGE_ASSET_MANAGEMENT_API}/options`, {
            params,
        });

        set({
            ...data,
        });
    },

    // getFreeOptions: async () => {
    //     const { data } = await axios.get(`/storage/asset-management/free-options`, {});
    //
    //     set({
    //         countryFreeOptions: data.countryOptions,
    //         counterPartyFreeOptions: data.counterPartyOptions,
    //     });
    // },

    getCounterpartiesByDomicile: async (domicileId: number) => {
        const { data } = await axios.get(`${STORAGE_ASSET_MANAGEMENT_API}/counterparty-options`, {
            params: {
                currentAssetManagerDomicileId: domicileId,
            },
        });

        set({
            countryFreeOptions: data.countryOptions,
            counterPartyFreeOptions: data.counterPartyOptions,
        });
    },

    getIM19: async (domicileId: number) => {
        const { data } = await axios.get(`${STORAGE_ASSET_MANAGEMENT_API}/im19`, {
            params: {
                currentAssetManagerDomicileId: domicileId,
            },
        });

        set({
            im19RegulatoryStatusOptions: data,
        });
    },

    setAssetManagements: async (data: StorageAssetManagement) => {
        let am: undefined | StorageAssetManagement = undefined;

        const { data: savedAM } = await axios.post(`${STORAGE_ASSET_MANAGEMENT_API}`, data);

        data.id = savedAM.id;

        const items = get().assetManagement;

        if (data.isNew) {
            const items = get().assetManagement.map((item) => {
                if (data.id === item.id) {
                    item = data;
                    am = item;
                }
                return item;
            });

            if (!am) {
                items.push(data);
            }
        } else {
            items[0] = data;
        }

        set({
            assetManagement: items,
        });
    },
    clearDeleted: () => {
        const itemsToRemove = useStorageStore.getState().itemsToDelete.assetManagement;
        const tmp = get().assetManagement;

        for (const storageAssetManagement of itemsToRemove) {
            const index = tmp.findIndex((i) => i.id === storageAssetManagement.id);
            // if (storageAssetManagement.isNew) {
            //     tmp.splice(index, 1);
            // } else {
            tmp[index] = {
                assetManager: storageAssetManagement.assetManager,
                assetManagerOptionsShort: storageAssetManagement.assetManagerOptionsShort,
                domicileId: storageAssetManagement.domicileId,
                domicileName: storageAssetManagement.domicileName,
            };
            // }
        }

        set({
            assetManagement: tmp,
        });
    },
}));
