import styles from './formats-styles.module.css';
import React, { useContext } from 'react';
import { TrContext } from '../context/TrContext';

const DescriptionFormat = (d: any, options: any) => {
    const { expanded, setHasDescription } = useContext(TrContext);

    const descriptionVal = options ? options.descriptionVal : null;

    if (!d && !descriptionVal) return '-';

    d = d.toString().trim();
    if (d === null || d === '') return '-';

    if (d.length <= 50 && !descriptionVal) return d;

    setHasDescription(true);

    const arr = d.split(' ');

    const short = descriptionVal ? d : `${arr[0]} ${arr[1]}...`;

    return (
        <>
            {!expanded && (
                <div className={styles.tip}>
                    <button className={styles.tipBtn}>
                        <span>{short}</span>
                        <span className={styles.tipBtnImg}>
                            <img alt={''} src={'/icons/info.png'} />
                            <img alt={''} src={'/icons/info-active.png'} />
                        </span>
                    </button>

                    <div className={`${styles.tipBox}`}>{descriptionVal || d}</div>
                </div>
            )}

            {expanded && <div className={styles.expandedTip}>{descriptionVal || d}</div>}
        </>
    );
};
export default DescriptionFormat;
