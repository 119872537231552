import { Link } from 'react-router-dom';

import styles from './styles.module.css';

const Options = () => {
    return (
        <div className={styles.wrapper}>
            {/* ID 2.A   */}
            <Link to={'/deal-taxes'} className={styles.box}>
                <img src={'./images/thumb-taxes.png'} alt={''} />
                <span className={styles.button}>Deal taxes</span>
                <p>Get a comprehensive tax review of your deal</p>
            </Link>

            {/* ID 2.B   */}
            <Link to={'/legal-structures'} className={styles.box}>
                <img src={'./images/thumb-structure.png'} alt={''} />
                <span className={styles.button}>Legal structures</span>
                <p>Optimal structures for your investments needs</p>
            </Link>

            <Link to={'/tax-optimisation'} className={styles.box}>
                <img src={'./images/optimisation.png'} alt={''} />
                <span className={styles.button}>Tax optimisation</span>
                <p>Get optimal way to invest for your deal</p>
            </Link>

            <Link to={'/storage'} className={styles.box}>
                <img src={'./images/thumb-storage.png'} alt={''} />
                <span className={styles.button}>Storage & compliance</span>
                <p>Store and check compliance of your structures</p>
            </Link>

            {/* ID 2.C   */}
            <Link to={'/contact'} className={styles.box}>
                <img src={'./images/thumb-requests.png'} alt={''} />
                <span className={styles.button}>Other request</span>
                <p>Let us know if you have other requests!</p>
            </Link>
        </div>
    );
};

export default Options;
