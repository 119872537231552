import styles from './response.module.css';

interface ResponseProps {
    type: 'success' | 'error';
    children: any;
    setResponse: (value: any) => void;
    aligned?: boolean;
}

const Response = ({ type, children, setResponse, aligned }: ResponseProps) => {
    return (
        <div
            onClick={() => {
                setResponse(null);
            }}
            className={`${styles.response} ${type === 'error' ? styles.error : styles.success} ${
                aligned ? styles.aligned : styles.fixed
            }`}
        >
            <img src={`${type === 'error' ? '/icons/error.svg' : '/icons/check.svg'}`} alt="" />

            <span>{children}</span>

            <button
                onClick={() => {
                    setResponse(null);
                }}
            />
        </div>
    );
};

export default Response;
