// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_aside__cAF8n {\n    position: fixed;\n    z-index: 180;\n    height: 100vh !important;\n    top: 0;\n    left: 0;\n    background: rgba(245, 245, 245);\n    width: 115px;\n    transition: width 0.2s, opacity 0.1s;\n}\n\n.styles_aside__cAF8n.styles_hidden__PpVwM {\n    opacity: 0;\n    pointer-events: none;\n}\n\n.styles_aside__cAF8n.styles_active__NsNAr {\n    width: 385px;\n}\n\n.styles_info__riEfn {\n    margin-left: 71px;\n    height: 100%;\n}\n\n.styles_info__riEfn.styles_hidden__PpVwM {\n    opacity: 0;\n    transition: opacity 0.2s;\n}\n\n.styles_icons__6dHg5 {\n    --icon-fill-color-custom: #1650b4;\n    --icon-color-custom: #d6d6d6;\n    margin-top: 244px;\n    padding-left: 39px;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 26px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/LegalStructures/components/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,wBAAwB;IACxB,MAAM;IACN,OAAO;IACP,+BAA+B;IAC/B,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,iCAAiC;IACjC,4BAA4B;IAC5B,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".aside {\n    position: fixed;\n    z-index: 180;\n    height: 100vh !important;\n    top: 0;\n    left: 0;\n    background: rgba(245, 245, 245);\n    width: 115px;\n    transition: width 0.2s, opacity 0.1s;\n}\n\n.aside.hidden {\n    opacity: 0;\n    pointer-events: none;\n}\n\n.aside.active {\n    width: 385px;\n}\n\n.info {\n    margin-left: 71px;\n    height: 100%;\n}\n\n.info.hidden {\n    opacity: 0;\n    transition: opacity 0.2s;\n}\n\n.icons {\n    --icon-fill-color-custom: #1650b4;\n    --icon-color-custom: #d6d6d6;\n    margin-top: 244px;\n    padding-left: 39px;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": "styles_aside__cAF8n",
	"hidden": "styles_hidden__PpVwM",
	"active": "styles_active__NsNAr",
	"info": "styles_info__riEfn",
	"icons": "styles_icons__6dHg5"
};
export default ___CSS_LOADER_EXPORT___;
