import styles from './styles.module.css';
import { Icons } from '../../../../../../components/Icon/Icon';
import { Icon } from '../../../../../../components';
import { useStorageStore } from '../../../../../../store/useStorageStore';
import React, { useEffect, useState } from 'react';
import { StorageNestedTypes } from '../../../../../../types/storage';

interface StorageBoxInfoProps {
    name: string;
}

interface StorageBoxSingleProps {
    name?: string;
    isSmaller?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    country?: string;
    position?: string;
    useTriangle?: boolean;
    isHorizontal?: boolean;
    isVertical?: boolean;
    onClick?: () => void | undefined;
    icon?: Icons;
    holdingType?: string;
    isOpen?: boolean;
    isMainStructure?: boolean;
    isPlaceholder?: boolean;

    type?: StorageNestedTypes;
    id?: number;
    isGP?: boolean;
    isOther?: boolean;
    isBottom?: boolean;
    hasPlaceholder?: boolean;
}

const StorageBoxSingle = ({
    name,
    isSmaller,
    isSelected,
    isDisabled,
    isHidden,
    country,
    position,
    isMainStructure,
    isHorizontal,

    icon,
    holdingType,
    isOpen,
    isPlaceholder,
    isGP,

    type,
    id,
    isOther,
    isBottom,
    hasPlaceholder,
    ...props
}: StorageBoxSingleProps) => {
    const [openType, setOpenType, isInDeleteView, toggleDeleteItem] = useStorageStore((state) => [
        state.openType,
        state.setOpenType,
        state.isInDeleteView,
        state.toggleDeleteItem,
    ]);

    // const [isOpen, setIsOpen] = useState(false);

    // useEffect(() => {
    //     setIsOpen(!!type && type === openType);
    // }, [openType]);
    //
    // const handleDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log('checked', e.target.checked);
    //     !!type && toggleDeleteItem({ type, id, selected: e.target.checked });
    // };
    //
    // const handleClick = () => {
    //     if (onClick) {
    //         onClick();
    //     } else {
    //         if (openType === type) {
    //             setOpenType(undefined);
    //         } else {
    //             setOpenType(type);
    //         }
    //     }
    // };

    return (
        <div
            className={`${styles.container} ${styles.box} ${styles.selected}  
            ${(isGP && isSmaller) || (isOther && isSmaller) ? styles.smaller : ''} 
            ${isSelected && !isDisabled ? styles.selected : ''} 
            ${!isMainStructure && isOpen && !isDisabled ? styles.open : ''} 
            ${!isPlaceholder ? styles.secondary : styles.primary} 
            ${isMainStructure || isGP || isOther ? '' : isHorizontal ? styles.horizontal : styles.vertical} 
            ${isHidden ? styles.hidden : ''}
            ${isMainStructure ? styles.triangle : ''}
            ${isOther ? styles.absolute : ''}
            ${isDisabled ? styles.disabled : ''}
            ${isBottom ? styles.bottom : ''}
            ${hasPlaceholder ? styles.hasPlaceholder : ''}
            `}
            {...props}
        >
            {hasPlaceholder && (
                <div className={styles.openPlaceholder}>
                    <div className={styles.row}>
                        {!!icon && <Icon icon={icon} />} <span>{name}</span>
                    </div>
                </div>
            )}
            <div className={styles.innerBox}>
                {(!isOther || (isOther && isPlaceholder)) && (
                    <>
                        {icon ? (
                            <div className={styles.row}>
                                <Icon icon={icon} />
                                <h6>{name}</h6>
                            </div>
                        ) : (
                            <>{name && <h6>{name}</h6>}</>
                        )}
                    </>
                )}

                {position && <p>{position}</p>}
                {country && <p>- {country} -</p>}
            </div>
        </div>
    );
};

export default StorageBoxSingle;
