import { Asset } from '../types/general';
import { DealTaxesSelectedData } from '../pages/DealTaxes/DealTaxes';

export default async (
    searchParams: URLSearchParams
): Promise<{
    data: null | DealTaxesSelectedData;

    optimisation: string | null;
    initialInvestmentParam: string | null;
    returnOnAssetParam: string | null;
    distributionParam: string | null;
    openTaxIndex: string | null;
}> => {
    const investors = searchParams.get('investors');
    const assets = searchParams.get('assets');
    const investmentFund = searchParams.get('investmentFund');
    const fundOfFunds = searchParams.get('fundOfFunds');

    const booked = searchParams.get('booked');
    const corporate = searchParams.get('corporate');
    const openEnded = searchParams.get('openEnded');
    const ifBusinessAssets = searchParams.get('ifBusinessAssets');
    const ifCommercialPartnership = searchParams.get('ifCommercialPartnership');

    const ukInvesting = searchParams.get('ukInvesting');
    const fofUkInvesting = searchParams.get('fofUkInvesting');

    const optimisation = searchParams.get('optimisation');

    const fofIfCommercialPartnership = searchParams.get('fofIfCommercialPartnership');

    const initialInvestmentParam = searchParams.get('initialInvestment');
    const returnOnAssetParam = searchParams.get('returnOnAsset');
    const distributionParam = searchParams.get('distribution');
    const openTaxIndex = searchParams.get('openTaxIndex');

    const selectedInvestor = searchParams.get('selectedInvestor');

    if (!investors || !assets || !investmentFund) {
        return {
            data: null,
            optimisation,
            initialInvestmentParam,
            returnOnAssetParam,
            distributionParam,
            openTaxIndex,
        };
    }

    return {
        data: {
            investors: JSON.parse(investors),
            assets: JSON.parse(assets),
            investmentFund: JSON.parse(investmentFund),
            fundOfFunds: fundOfFunds ? JSON.parse(fundOfFunds) : undefined,

            selectedInvestor: selectedInvestor ? JSON.parse(selectedInvestor) : undefined,

            booked: booked ? booked === 'true' : undefined,
            corporate: corporate === 'true',
            openEnded: openEnded === 'true',
            ifBusinessAssets: ifBusinessAssets ? ifBusinessAssets === 'true' : undefined,
            ifCommercialPartnership: ifCommercialPartnership ? ifCommercialPartnership === 'true' : undefined,
            fofOpenEnded: searchParams.get('fofOpenEnded') ? searchParams.get('fofOpenEnded') === 'true' : undefined,
            fofCorporate: searchParams.get('fofCorporate') ? searchParams.get('fofCorporate') === 'true' : undefined,
            fofIfCommercialPartnership: fofIfCommercialPartnership ? fofIfCommercialPartnership === 'true' : undefined,
            ukInvesting: ukInvesting ? ukInvesting === 'true' : undefined,
            fofUkInvesting: fofUkInvesting ? fofUkInvesting === 'true' : undefined,

            fundStatus: searchParams.get('fundStatus') || undefined,
        },
        optimisation,
        initialInvestmentParam,
        returnOnAssetParam,
        distributionParam,
        openTaxIndex,
    };
};
