import { useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button } from '../../../../../../components';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Portal } from 'react-portal';
import Select from '../../../../../../components/Select/Select';
import { v4 as uuidv4 } from 'uuid';

const LawsCreateForm = ({ countries, onSave }: any) => {
    const [items, setItems] = useState<any[]>([{}]);

    const handleInputChange = (index: number, prop: string) => (event: any) => {
        let tmp = [...items];
        tmp[index] = { ...tmp[index], [prop]: event.target.value };
        setItems(tmp);
    };

    const handleSelectChange = (index: number, prop: string) => (value: any) => {
        let tmp = [...items];
        tmp[index] = { ...tmp[index], [prop]: value };
        setItems(tmp);
    };

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                value: uuidv4(),
                label: '',
            },
        ]);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Laws and regulations'}
                description={'Please provide the law or regulation name and its domicile.'}
                isAbsolute={true}
                footerMain={<Button onClick={onSave('laws', items)}>Upload</Button>}
                footerAdditional={
                    <Button outlined={true} onClick={handleAddItem}>
                        Add Law
                    </Button>
                }
            >
                {items.map((item: any, index: number) => (
                    <div key={index}>
                        <Input onChange={handleInputChange(index, 'label')}>Full name</Input>
                        <Select
                            value={item.domicileId}
                            onChange={handleSelectChange(index, 'domicile')}
                            options={countries}
                            valueKey={'domicileId'}
                            labelKey={'domicileName'}
                        >
                            Domicile
                        </Select>
                    </div>
                ))}
            </FormLayout>
        </Portal>
    );
};

export default LawsCreateForm;
