import styles from '../selected-combination.module.css';
import BooleanImage from '../../BooleanImage/BooleanImage';

export interface AdditionalParamsProps {
    booked?: boolean;
    corporate?: boolean;
    openEnded?: boolean;
    ifBusinessAssets?: boolean;
    fundStatus?: string;
}

const AdditionalProposedRows = ({
    corporate,
    openEnded,
    booked,
    ifBusinessAssets,
    fundStatus,
}: AdditionalParamsProps) => {
    return (
        <>
            <div className={`${styles.proposedCheck} }`}>
                <span>open-ended</span>
                <BooleanImage value={openEnded} />
            </div>
            <div className={`${styles.proposedCheck} `}>
                <span>corporate</span>

                <BooleanImage value={corporate} />
            </div>
            {typeof booked !== 'undefined' && (
                <div className={`${styles.proposedCheck} `}>
                    <span>booked at NAV</span>
                    <BooleanImage value={booked} />
                </div>
            )}

            {typeof ifBusinessAssets !== 'undefined' && (
                <div className={`${styles.proposedCheck} `}>
                    <span>business asset</span>
                    <BooleanImage value={ifBusinessAssets} />
                </div>
            )}

            {typeof fundStatus !== 'undefined' && (
                <div className={`${styles.proposedCheck} `}>
                    <span>{fundStatus}</span>
                </div>
            )}
        </>
    );
};

export default AdditionalProposedRows;
