import { useStorageStore } from '../../../../store/useStorageStore';
import StorageBoxGroup from './components/StorageBoxGroup/StorageBoxGroup';
import { StorageInvestor, StorageNestedTypes } from '../../../../types/storage';
import StorageBoxSingle from './components/StorageBoxSingle/StorageBoxSingle';
import { Icons } from '../../../../components/Icon/Icon';
import { useState } from 'react';

const StorageMainView = ({
    visible = true,
    selectedData,
    type,
    isLargeStructure,
    isHorizontal,
    name,
    isSmaller,
    itemProps,
    icon,
    isAbsolute,
    isMainStructure,
    isGP,
    hasYOffest,
    isOther,
    isBottom,
}: any) => {
    const [isSmallStructure, isOpen, setIsOpen, openType, setOpenType, disabledState, setOpenModal, handleClosePopup] =
        useStorageStore((state) => [
            state.isSmallStructure,
            state.isOpen,
            state.setIsOpen,
            state.openType,
            state.setOpenType,
            state.disabledState,
            state.setOpenModal,
            state.handleCloseModal,
        ]);

    const parseValue = (selectedData: any, prop: any) => {
        return !prop
            ? undefined
            : Array.isArray(prop)
            ? prop
                  .filter((key: any) => !!selectedData[key])
                  .map((key: any) => selectedData[key])
                  .join(', ')
            : selectedData[prop];
    };

    return (
        <>
            {(!isGP || (isGP && isOpen) || (isGP && !isSmallStructure)) && (
                <StorageBoxGroup
                    type={StorageNestedTypes.investors}
                    isHorizontal={isHorizontal}
                    isMainStructure={isMainStructure}
                    isGP={isGP}
                    isOpen={isOpen}
                    isOther={isOther}
                    name={name}
                    icon={icon}
                    hasYOffest={hasYOffest}
                    isDisabled={Array.isArray(selectedData) && !selectedData.length}
                    isBottom={isBottom}
                >
                    <StorageBoxSingle
                        isMainStructure={isMainStructure}
                        name={isMainStructure || isGP ? itemProps.nameRaw || selectedData[itemProps.name] : name}
                        position={
                            isOther && isOpen
                                ? itemProps.positionRaw
                                : isMainStructure
                                ? parseValue(selectedData, itemProps.position)
                                : isGP
                                ? itemProps.positionRaw || 'General partner'
                                : undefined
                        }
                        country={isGP ? parseValue(selectedData, itemProps.country) : undefined}
                        icon={icon}
                        isSmaller={(isGP && isSmaller) || (isOther && isSmaller)}
                        type={type}
                        isHorizontal={isHorizontal}
                        isOpen={isOpen}
                        isPlaceholder={!isGP && !(isOther && isOpen)}
                        isGP={isGP}
                        isOther={isOther}
                        isDisabled={Array.isArray(selectedData) && !selectedData.length}
                        hasPlaceholder={!isGP}
                        onClick={() => {
                            if (isOpen || !isSmallStructure) {
                                setOpenType(type);
                            } else if (isSmallStructure) {
                                setIsOpen(!isOpen);
                            }
                        }}
                    />

                    {Array.isArray(selectedData) && !!selectedData.length && (
                        <>
                            {selectedData.map((item: any, index: number) => (
                                <StorageBoxSingle
                                    key={index}
                                    name={item[itemProps.name]}
                                    position={parseValue(item, itemProps.position)}
                                    country={parseValue(item, itemProps.country)}
                                    isOpen={isOpen}
                                    type={type}
                                    id={item.id}
                                    isHorizontal={isHorizontal}
                                    onClick={() => {
                                        if (isOpen) {
                                            setOpenType(type);
                                        }
                                    }}
                                />
                            ))}
                        </>
                    )}

                    {/*{isOther && (*/}
                    {/*    <StorageBoxSingle*/}
                    {/*        position={itemProps.positionRaw}*/}
                    {/*        isOpen={isOpen}*/}
                    {/*        type={type}*/}
                    {/*        isOther={isOther}*/}
                    {/*        onClick={() => {*/}
                    {/*            setOpenType(type);*/}
                    {/*        }}*/}
                    {/*        isSmaller={true}*/}
                    {/*    />*/}
                    {/*)}*/}
                </StorageBoxGroup>
            )}
        </>
    );
};

export default StorageMainView;
