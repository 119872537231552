import ComparisonTableSection from '../ComparisonTableSection';

import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD3Table from './SD3Table';
import { SDProps } from './sd.interface';
import { useTableStore } from '../../../store/useTableStore';

const SD3 = ({ triggerCollapse, setTablesCollapsed, tablesCollapsed, collapseOnClick }: SDProps) => {
    const [data, setTableData] = useTableStore((state) => [state.data, state.setTableData]);

    return data && data.sd3 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={'Dealings / redemptions'}
            >
                <SD3Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD3;
