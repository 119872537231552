const PenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.147" viewBox="0 0 20 20.147">
            <defs>
                <clipPath id="clipPath">
                    <rect id="Rectangle_1078" data-name="Rectangle 1078" width="20" height="20.147" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_7066" data-name="Group 7066" transform="translate(-61 -159.852)">
                <g id="Group_7087" data-name="Group 7087" transform="translate(61 159.853)">
                    <g id="Group_7085" data-name="Group 7085" transform="translate(0 0)" clipPath="url(#clipPath)">
                        <line
                            id="Line_132"
                            data-name="Line 132"
                            x2="13.332"
                            transform="translate(0.687 19.83)"
                            fill="none"
                            stroke="#d6d6d6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M25.277.339a.638.638,0,0,1,.9,0l2.339,2.33a.631.631,0,0,1,0,.9L27.37,4.706,24.581,1.928a.633.633,0,0,1,0-.9Z"
                            transform="translate(-8.908 -0.056)"
                            fill="#d6d6d6"
                        />
                        <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M2.493,14.325.749,18.452m1.744-4.127.956.952m-.956-.952,12.746-12.7M.749,18.452l-.2.469a.636.636,0,0,0,.835.832l.47-.2m-1.107-1.1,1.107,1.1M6,17.817,1.856,19.555M6,17.817l-.955-.952M6,17.817,18.745,5.12M5.043,16.865,3.449,15.278m1.593,1.587,12.745-12.7M3.449,15.278,16.2,2.581m-.956-.952.747-.756a1.278,1.278,0,0,1,1.8,0l1.7,1.7a1.267,1.267,0,0,1,0,1.8l-.747.756M15.239,1.628l.956.952M18.745,5.12l-.956-.952M16.2,2.581l1.593,1.587"
                            transform="translate(-0.183 -0.182)"
                            fill="none"
                            stroke="#0c3b8d"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PenIcon;
