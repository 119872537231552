import styles from './styles.module.css';

const HamburgerButton = ({ active, ...props }: any) => {
    return (
        <button {...props} className={`${styles.hamburger} ${active ? styles.active : ''}`}>
            <span />
        </button>
    );
};

export default HamburgerButton;
