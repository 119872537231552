import { ProgressBar } from './index';

const ProgressFormat = (d: any, options: any) => {
    const currencyFormat = options ? options.currencyFormat : null;
    const progressValue = options ? options.progressVal : d;

    let progress = ProgressBar(progressValue ?? d);

    if (d === 'no requirements') {
        progress = <></>;
    }

    if (currencyFormat && d) {
        const arr = d.split(' ');

        if (!isNaN(parseInt(arr[0]))) {
            d = new Intl.NumberFormat('en-US').format(arr[0]).replace(/,/g, "'") + ' ' + arr[1];
        }
    }

    return (
        <>
            {progress}
            {d}
        </>
    );
};
export default ProgressFormat;
