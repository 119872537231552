import create from 'zustand';

interface FooterStore {
    show: boolean;
    setShowFooter: (value: boolean) => void;
}

export const useFooterStore = create<FooterStore>((set) => ({
    show: false,
    setShowFooter: (value: boolean) => set({ show: value }),
}));
