import { createContext } from 'react';

export interface User {
    username: string;
    email: string;
    agreement: any;
}

export interface IUserContext {
    setUser: (user: User | null) => void;
    isLoading: boolean;
    user: User | null;
}

export const UserContext = createContext<IUserContext>({
    setUser: (user: User | null) => {},
    isLoading: true,
    user: null,
});
