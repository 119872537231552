import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormLayout from '../../../layout/FormLayout/FormLayout';
import Input from '../../../../../components/Input/Input';

const StorageViewStructure = ({ selectedData, onClose }: any) => {
    return (
        <FormWrapper>
            <FormLayout title={'Vehicle'} description={'Here is the vehicle.'} onClose={onClose}>
                <Input disabled value={selectedData.name}>
                    Name
                </Input>
                <Input disabled value={selectedData.type}>
                    Type
                </Input>
                <Input disabled value={selectedData.domicileName}>
                    Domicile
                </Input>
                <Input disabled value={selectedData.investmentFund}>
                    Investment fund
                </Input>
                <Input disabled value={selectedData.legalForm}>
                    Legal form
                </Input>
                <Input disabled value={selectedData.legalType}>
                    Legal type
                </Input>
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageViewStructure;
