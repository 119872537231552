import styles from './info.module.css';

const Info = () => {
    return (
        <h3 className={styles.info}>
            Best-fit <span className={styles.highlight}>legal structures</span> :
        </h3>
    );
};

export default Info;
