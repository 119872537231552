import styles from './styles.module.css';
import { PresetInterface } from '../../Preset/Preset';
import { useEffect, useState } from 'react';
import { GraphTypes } from '../../../types/general';
import OverviewFolder from './OverviewFolder';

const Box = ({
    name,
    number,
    image,
    onClick,
    type,
    presets,
}: {
    name: string;
    number: string;
    image: string;
    onClick: any;
    type: GraphTypes;
    presets: PresetInterface[];
}) => {
    const [items, setItems] = useState<PresetInterface[]>([]);

    useEffect(() => {
        setItems(presets.filter((item) => item.presetType === type));
    }, [presets]);

    return (
        <div className={styles.box} onClick={onClick(type)}>
            <div className={styles.number}>{number}</div>

            <OverviewFolder image={image} name={name} itemLength={items.length} />
        </div>
    );
};

const OverviewCategories = ({ onClick, presets }: { onClick: any; presets: PresetInterface[] }) => {
    return (
        <>
            <div className={styles.container}>
                <Box
                    number="01"
                    name={'Deal taxes'}
                    image={'/images/thumb-taxes.png'}
                    onClick={onClick}
                    type={GraphTypes.Taxes}
                    presets={presets}
                />
                <Box
                    number="02"
                    name={'Legal structures'}
                    image={'/images/thumb-structure.png'}
                    onClick={onClick}
                    type={GraphTypes.Legal}
                    presets={presets}
                />
                <Box
                    number="03"
                    name={'Stored structures'}
                    image={'/images/thumb-structures.png'}
                    onClick={onClick}
                    type={GraphTypes.Storage}
                    presets={presets}
                />
                <Box
                    number="04"
                    name={'Tax optimisation'}
                    image={'/images/optimisation.png'}
                    onClick={onClick}
                    type={GraphTypes.Optimisation}
                    presets={presets}
                />
            </div>
        </>
    );
};

export default OverviewCategories;
