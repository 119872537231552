import { getInvestmentFunds } from '../utils';
import styles from './tr-styles.module.css';
import { useTableStore } from '../../../store/useTableStore';

interface TableHeaderProps {
    sdKey: string;
    excludeFeat?: boolean;
}

const TableHeader = ({ sdKey, excludeFeat }: TableHeaderProps) => {
    const [data, setActiveFund, activeFund] = useTableStore((state) => [
        state.data,
        state.setActiveFund,
        state.activeFund,
    ]);

    return (
        <thead>
            <tr>
                {!excludeFeat && <th className={'fund-th'}>Feature</th>}

                {getInvestmentFunds(data, sdKey).map((fund: string, key: string) => {
                    return (
                        <th
                            key={key}
                            onClick={() => setActiveFund(fund)}
                            className={`fund-th ${fund === activeFund ? styles.activeHeader : ''}`}
                        >
                            {fund}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHeader;
