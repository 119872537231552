import styles from './styles.module.css';

interface DeleteButtonProps {
    onClick: () => void;
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
    return <button className={styles.btn} type={'button'} onClick={onClick} />;
};

export default DeleteButton;
