import styles from '../../main-structures-detail.module.css';
import React from 'react';

interface MainStructuresDetailFieldProps {
    title: any;
    value: any;
    useCheckbox?: any;
    footnote?: any;
    info?: any;
}

const MainStructuresDetailField = ({ title, value, useCheckbox, footnote, info }: MainStructuresDetailFieldProps) => {
    const trimmed = value ? value.toString().trim() : '';

    return (
        <div>
            <h6 className={styles.fieldHeader}>
                {title}
                {footnote ? <span className={styles.footnote}>{footnote}</span> : ''}

                {info && (
                    <span className={`${styles.tip} ${styles.tipSmaller}`}>
                        <span className={styles.tipBtn}>
                            <span className={styles.tipBtnImg}>
                                <img alt={''} src={'/icons/info.png'} />
                                <img alt={''} src={'/icons/info-active.png'} />
                            </span>
                        </span>

                        <p className={styles.tipBox}>{info}</p>
                    </span>
                )}
            </h6>

            {useCheckbox ? (
                <div className={`${styles.check} ${trimmed.toLowerCase() === 'true' ? styles.active : ''}`} />
            ) : (
                <span>{trimmed}</span>
            )}
        </div>
    );
};

export default MainStructuresDetailField;
