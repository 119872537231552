import ComparisonTableSection from '../ComparisonTableSection';

import { useTableStore } from '../../../store/useTableStore';
import ComparisonTableSectionProvider from '../ComparisonTableSectionProvider';
import SD13Table from './SD13Table';
import { SDProps } from './sd.interface';

const SD13 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
    setInitCollapsed,
    initCollapsed,
}: SDProps) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd13 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                setInitCollapsed={setInitCollapsed}
                initCollapsed={initCollapsed}
                title={'Asset manager'}
            >
                <SD13Table />
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD13;
