import { useContext } from 'react';
import styles from './styles.module.css';
import { SelectionRowContext } from '../../../context';
import { SelectionLevel } from '../../../types/general';

interface SelectionCheckboxProps {
    option: any;
    info?: string;
    disabled?: boolean;
    useFoF?: boolean;
}

const Checkboxes: { [key in SelectionLevel]: any } = {
    investor: {
        name: 'currentInvestor',
        valueKey: 'investorId',
        labelKey: 'investor',
        titleKey: 'investor',
        infoKey: 'description',
    },
    fundOfFunds: {
        name: 'currentFoF',
        valueKey: 'investmentFundId',
        labelKey: 'investmentFund',
        titleKey: 'investmentFund',
        infoKey: 'fullName',
    },
    fund: {
        name: 'currentFund',
        valueKey: 'investmentFundId',
        labelKey: 'investmentFund',
        titleKey: 'investmentFund',
        infoKey: 'fullName',
    },
    asset: {
        name: 'currentAsset',
        valueKey: 'assetId',
        labelKey: 'assetClass',
        titleKey: 'assetClass',
        infoKey: 'description',
    },
};

const SelectionCheckbox = ({ option, info, disabled, useFoF }: SelectionCheckboxProps) => {
    const { type, selectedOption, handleSelect, inputType } = useContext(SelectionRowContext);

    const isChecked = () => {
        return selectedOption
            ? typeof selectedOption === 'object'
                ? selectedOption.findIndex((inv: any) => inv.id === option[Checkboxes[type].valueKey]) > -1
                : option[Checkboxes[type].valueKey].toString() === selectedOption.toString()
            : false;
    };

    return (
        <>
            {option && (
                <label
                    className={`${styles.investorSelect} ${isChecked() ? styles.selected : ''} ${
                        useFoF ? styles.compact : ''
                    }`}
                >
                    <input
                        disabled={disabled}
                        type={inputType || 'radio'}
                        value={option[Checkboxes[type].valueKey]}
                        checked={isChecked()}
                        onChange={handleSelect ? handleSelect(Checkboxes[type].name) : undefined}
                        className={styles.investorSelectInput}
                    />

                    <h5 className={styles.investorSelectTitle}>{option[Checkboxes[type].titleKey]}</h5>

                    {!useFoF && <p className={styles.investorSelectInfo}>{info || option[Checkboxes[type].infoKey]}</p>}
                </label>
            )}
        </>
    );
};

export default SelectionCheckbox;
