import FormLayout from '../../../layout/FormLayout/FormLayout';
import FormWrapper from '../../../layout/FormWrapper/FormWrapper';
import FormCollapsableRow from '../../../layout/FormCollapsableRow/FormCollapsableRow';
import Input from '../../../../../components/Input/Input';
import SelectedValuesList from '../../../../../components/SelectedValuesList/SelectedValuesList';

const StorageViewServiceProvider = ({ selectedData, onClose }: any) => {
    return (
        <FormWrapper>
            <FormLayout
                title={'Service provider(s)'}
                description={'Here are the service providers(s).'}
                onClose={onClose}
            >
                {selectedData.map((item: any, index: number) => (
                    <FormCollapsableRow
                        key={index}
                        titleLabel={`${index + 1}. service provider`}
                        titleValue={`${item.role} :: ${item.counterparty}`}
                    >
                        <Input disabled value={item.role}>
                            Role
                        </Input>

                        <Input disabled value={'Counterparty'}>
                            Agreement
                        </Input>

                        <Input disabled value={item.counterparty}>
                            Name
                        </Input>

                        <Input disabled value={item.regulationFullName}>
                            Regulatory status
                        </Input>
                    </FormCollapsableRow>
                ))}
            </FormLayout>
        </FormWrapper>
    );
};

export default StorageViewServiceProvider;
