// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_row__5SV5B {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    /*grid-gap: 20px;*/\n    /*margin: 0 0 20px;*/\n    /*padding: 14px 40px;*/\n}\n\n.styles_left__GbEtp {\n    display: flex;\n    grid-gap: 40px;\n    width: 260px;\n}\n\n.styles_right__tR9fj {\n    display: flex;\n    align-items: center;\n}\n\n.styles_right__tR9fj select {\n    width: 260px;\n    min-width: 260px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SelectionPopup/SelectionPopupCountryRow/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".row {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    /*grid-gap: 20px;*/\n    /*margin: 0 0 20px;*/\n    /*padding: 14px 40px;*/\n}\n\n.left {\n    display: flex;\n    grid-gap: 40px;\n    width: 260px;\n}\n\n.right {\n    display: flex;\n    align-items: center;\n}\n\n.right select {\n    width: 260px;\n    min-width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "styles_row__5SV5B",
	"left": "styles_left__GbEtp",
	"right": "styles_right__tR9fj"
};
export default ___CSS_LOADER_EXPORT___;
