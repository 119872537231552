import Select from '../../../../../../components/Select/Select';
import { useEffect, useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import { Portal } from 'react-portal';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Icons } from '../../../../../../components/Icon/Icon';
import { v4 as uuidv4 } from 'uuid';

enum Forms {
    Role = 'Role',
    Base = 'Base',
}

const ServiceProviderSingle = ({ selectedData, generalInfo, onUpdate, handleRemoveItem, additionalRoles }: any) => {
    const [showRegulatoryStatusInput, setShowRegulatoryStatusInput] = useState(false);

    const [activeForm, setActiveForm] = useState<Forms | undefined | null>();

    const [roles, setRoles] = useState<any[]>(generalInfo.spRoles);

    const [data, setData] = useState<any>({
        domicile: undefined,
        roleManual: '',
        role: undefined,
        ...selectedData,
    });

    useEffect(() => {
        console.log('additionalInvestors', roles, additionalRoles);
        setRoles([...additionalRoles, ...roles]);
    }, [additionalRoles]);

    const handleInputChange = (prop: string) => (e: any) => {
        let value = e.target.value;
        console.log('event.target.value', value);

        console.log('prop', prop, value);
        // console.log('availableItems', availableItems);
        if (prop === 'domicileId') {
            // getDataByCountryId(+value);
        } else if (prop === 'assetId') {
            // value = availableItems.filter((item: any) =>
            //     Array.isArray(value)
            //         ? selectedData.findIndex((selected: any) => item.assetParticipation === selected.label) > -1
            //         : item.assetParticipation === selectedData.label
            // );
        }

        console.log('value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        setData(selectedObj);
        onUpdate(selectedObj);
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        console.log('value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        setData(selectedObj);
        onUpdate(selectedObj);
    };

    const handleShowCreateForm = (form: Forms) => () => {
        setActiveForm(form);
    };

    const closeActiveForm = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('closeActiveForm');

        if (activeForm === Forms.Role) {
            const selectedObj = {
                roleId: uuidv4(),
                role: data.roleManual,
            };

            console.log('selectedObj', { ...data, role: selectedObj });
            console.log('[selectedObj, ...roles', [selectedObj, ...roles]);

            setData({ ...data, role: selectedObj });
            setRoles([selectedObj, ...roles]);
        }

        setActiveForm(null);
    };

    return (
        <>
            <div
                style={{
                    gridGap: '48px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, calc(50% - 24px))',
                }}
            >
                <button
                    style={{
                        minWidth: 32,
                        marginTop: -10,
                        marginLeft: -20,
                        position: 'absolute',
                    }}
                    type={'button'}
                    onClick={handleRemoveItem}
                >
                    <Icon icon={Icons.closeRounded} />
                </button>

                <Select
                    value={data.role}
                    onChange={handleSelectChange('role')}
                    options={roles}
                    valueKey={'roleId'}
                    labelKey={'role'}
                    onCreateOption={handleShowCreateForm(Forms.Role)}
                >
                    Service provider role
                </Select>

                <Select disabled={true}>Service provider agreement with</Select>
            </div>

            <div
                style={{
                    marginBottom: '40px',
                    gridGap: '48px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, calc(50% - 24px))',
                }}
            >
                <Input
                    type={'button'}
                    value={data.name || 'Service provider name'}
                    onClick={handleShowCreateForm(Forms.Base)}
                >
                    Service provider name
                </Input>

                <Input
                    type={'button'}
                    value={data.regulatoryStatusManual || data.regulatoryStatus?.label || 'Service provider role'}
                    onClick={handleShowCreateForm(Forms.Base)}
                >
                    Service provider role
                </Input>
            </div>

            {activeForm === Forms.Role && (
                <Portal node={document && document.getElementById('formWrapper')}>
                    <FormLayout
                        title={'Service provider'}
                        description={'Please provide Service provider name, domicile and regulatory status.'}
                        isAbsolute={true}
                        footerMain={<Button form={'subForm'}>Save</Button>}
                        onClose={() => {
                            setActiveForm(null);
                        }}
                    >
                        <form id={'subForm'} onSubmit={closeActiveForm}>
                            <Input required value={data.roleManual} onChange={handleInputChange('roleManual')}>
                                Short name
                            </Input>
                        </form>
                    </FormLayout>
                </Portal>
            )}

            {activeForm === Forms.Base && (
                <Portal node={document && document.getElementById('formWrapper')}>
                    <FormLayout
                        title={'Service provider'}
                        description={'Please provide Service provider name, domicile and regulatory status.'}
                        isAbsolute={true}
                        footerMain={<Button form={'subForm'}>Save</Button>}
                        onClose={() => {
                            setActiveForm(null);
                        }}
                    >
                        <form id={'subForm'} onSubmit={closeActiveForm}>
                            <Input value={data.name} onChange={handleInputChange('name')}>
                                Name
                            </Input>

                            <Select
                                value={data.domicile}
                                onChange={handleSelectChange('domicile')}
                                options={generalInfo.countries}
                                valueKey={'domicileId'}
                                labelKey={'domicileName'}
                            >
                                Domicile
                            </Select>

                            <Select
                                value={data.regulatoryStatus}
                                onChange={handleSelectChange('regulatoryStatus')}
                                options={generalInfo.amRegulatoryStatuses}
                                valueKey={'regulatoryStatusId'}
                                labelKey={'regulatoryStatus'}
                                onCreateOption={() => {
                                    setShowRegulatoryStatusInput(true);
                                }}
                            >
                                Regulatory status
                            </Select>

                            {showRegulatoryStatusInput && (
                                <Input onChange={handleInputChange('regulatoryStatusManual')}>Name</Input>
                            )}
                        </form>
                    </FormLayout>
                </Portal>
            )}
        </>
    );
};

export default ServiceProviderSingle;
