// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_btn__ylOw4 {\n    background: transparent;\n    padding: 0;\n    width: 34px;\n    height: 34px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: auto 0;\n    position: relative;\n    border: 1px solid #000000;\n}\n\n.styles_btn__ylOw4:before,\n.styles_btn__ylOw4:after {\n    content: '';\n    display: block;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    margin: auto;\n    width: 1px;\n    height: 20px;\n    background: #000000;\n}\n\n.styles_btn__ylOw4:before {\n    transform: rotate(45deg);\n}\n\n.styles_btn__ylOw4:after {\n    transform: rotate(-45deg);\n}\n", "",{"version":3,"sources":["webpack://./src/components/DeleteButton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;;IAEI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".btn {\n    background: transparent;\n    padding: 0;\n    width: 34px;\n    height: 34px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: auto 0;\n    position: relative;\n    border: 1px solid #000000;\n}\n\n.btn:before,\n.btn:after {\n    content: '';\n    display: block;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    margin: auto;\n    width: 1px;\n    height: 20px;\n    background: #000000;\n}\n\n.btn:before {\n    transform: rotate(45deg);\n}\n\n.btn:after {\n    transform: rotate(-45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "styles_btn__ylOw4"
};
export default ___CSS_LOADER_EXPORT___;
