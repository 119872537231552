import { useEffect, useState } from 'react';

import axios from '../utils/axios';
import { IUserContext, User } from '../context/UserContext';

const useFindUser = (): IUserContext => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const findUser = async () => {
            try {
                const { data } = await axios.get('/auth/me');

                setUser(data);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        if (!user) {
            (async () => {
                await findUser();
            })();
        } else {
            setIsLoading(false);
        }
    }, []);

    return {
        user,
        setUser,
        isLoading,
    };
};

export default useFindUser;
