import Select from '../../../../../../components/Select/Select';
import { useEffect, useState } from 'react';
import Input from '../../../../../../components/Input/Input';
import { Button, Icon } from '../../../../../../components';
import { Portal } from 'react-portal';
import FormLayout from '../../../../layout/FormLayout/FormLayout';
import { Icons } from '../../../../../../components/Icon/Icon';
import { v4 as uuidv4 } from 'uuid';
import styles from '../../../../layout/FormLayout/FormLayout.module.css';

enum Forms {
    Role = 'Role',
    Base = 'Base',
}

const ServiceProviderRoleForm = ({ selectedData, onClose, onSubmit }: any) => {
    const [data, setData] = useState<any>({
        roleManual: '',
        ...selectedData,
    });

    const handleInputChange = (prop: string) => (e: any) => {
        let value = e.target.value;
        console.log('event.target.value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        setData(selectedObj);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Service provider'}
                description={'Please provide Service provider name, domicile and regulatory status.'}
                isAbsolute={true}
                footerMain={<Button form={'subForm'}>Save</Button>}
                onClose={onClose}
            >
                <form
                    id={'subForm'}
                    onSubmit={(e) =>
                        onSubmit(e, data, {
                            roleId: uuidv4(),
                            role: data.roleManual,
                        })
                    }
                >
                    <Input
                        required
                        value={data.roleManual}
                        onChange={handleInputChange('roleManual')}
                        style={'text'}
                        max={50}
                    >
                        Short name
                    </Input>
                </form>
            </FormLayout>
        </Portal>
    );
};

const ServiceProviderBaseForm = ({ selectedData, generalInfo, onClose, onSubmit }: any) => {
    const [showRegulatoryStatusInput, setShowRegulatoryStatusInput] = useState(!!selectedData.regulatoryStatusManual);

    console.log('selectedData', selectedData);

    const [data, setData] = useState<any>({
        domicile: undefined,
        regulatoryStatus: undefined,
        name: '',
        regulatoryStatusManual: '',
        ...selectedData,
    });

    const handleInputChange = (prop: string) => (e: any) => {
        let value = e.target.value;

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        if (prop === 'regulatoryStatusManual' && !!value) {
            selectedObj.regulatoryStatus = undefined;
        }

        setData(selectedObj);
    };

    const handleSelectChange = (prop: string) => (value: any) => {
        console.log('value', prop, value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        if (prop === 'regulatoryStatus') {
            selectedObj.regulatoryStatusManual = '';
            setShowRegulatoryStatusInput(false);
        }

        setData(selectedObj);
    };

    return (
        <Portal node={document && document.getElementById('formWrapper')}>
            <FormLayout
                title={'Service provider'}
                description={'Please provide Service provider name, domicile and regulatory status.'}
                isAbsolute={true}
                footerMain={<Button form={'subForm'}>Save</Button>}
                onClose={onClose}
            >
                <form id={'subForm'} onSubmit={(e) => onSubmit(e, data)}>
                    <Input value={data.name} onChange={handleInputChange('name')} style={'text'} max={100}>
                        Full name
                    </Input>

                    <Select
                        value={data.domicile}
                        onChange={handleSelectChange('domicile')}
                        options={generalInfo.countries}
                        valueKey={'domicileId'}
                        labelKey={'domicileName'}
                        style={'secondary'}
                    >
                        Domicile
                    </Select>

                    <Select
                        value={data.regulatoryStatus}
                        onChange={handleSelectChange('regulatoryStatus')}
                        options={generalInfo.spRegulatoryStatuses}
                        valueKey={'regulatoryStatusId'}
                        labelKey={'regulatoryStatus'}
                        onCreateOption={() => {
                            setShowRegulatoryStatusInput(true);
                        }}
                        style={'secondary'}
                    >
                        Regulatory status
                    </Select>

                    {showRegulatoryStatusInput && (
                        <Input
                            value={data.regulatoryStatusManual}
                            onChange={handleInputChange('regulatoryStatusManual')}
                            style={'text'}
                            max={100}
                        ></Input>
                    )}
                </form>
            </FormLayout>
        </Portal>
    );
};

const ServiceProviderSingle = ({
    selectedData,
    generalInfo,
    setGeneralInfo,
    onUpdate,
    handleRemoveItem,
    additionalRoles,
}: any) => {
    const [activeForm, setActiveForm] = useState<Forms | undefined | null>();

    const [data, setData] = useState<any>({
        domicile: undefined,
        roleManual: '',
        role: undefined,
        ...selectedData,
    });

    const handleSelectChange = (prop: string) => (value: any) => {
        console.log('value', value);

        const selectedObj = {
            ...data,
            [prop]: value,
        };

        setData(selectedObj);
        onUpdate(selectedObj);
    };

    const handleShowCreateForm = (form: Forms) => () => {
        setActiveForm(form);
    };

    const submitForm = (e: any, selectedData: any, selectedObj?: any) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('closeActiveForm', selectedObj);

        const tmp = {
            ...data,
            ...selectedData,
        };

        if (selectedObj) {
            console.log('selectedObj', { ...data, role: selectedObj });
            console.log('[selectedObj, ...roles', [selectedObj, ...generalInfo.spRoles]);

            tmp.role = selectedObj;

            setGeneralInfo({
                ...generalInfo,
                spRoles: [selectedObj, ...generalInfo.spRoles],
            });
        }

        console.log('tmp', tmp);

        setData(tmp);
        onUpdate(tmp);
        setActiveForm(null);
    };

    return (
        <>
            <div className={styles.formGridRow}>
                <button
                    style={{
                        minWidth: 32,
                        marginTop: -10,
                        marginLeft: -20,
                        position: 'absolute',
                    }}
                    type={'button'}
                    onClick={handleRemoveItem}
                >
                    <Icon icon={Icons.closeRounded} />
                </button>

                <Select
                    value={data.role}
                    onChange={handleSelectChange('role')}
                    options={generalInfo.spRoles}
                    valueKey={'roleId'}
                    labelKey={'role'}
                    onCreateOption={handleShowCreateForm(Forms.Role)}
                >
                    Service provider role
                </Select>

                <Select disabled={true}>Service provider agreement with</Select>
            </div>

            <div
                className={styles.formGridRow}
                style={{
                    marginBottom: '40px',
                }}
            >
                <Input
                    type={'button'}
                    value={data.name || 'Service provider name'}
                    onClick={handleShowCreateForm(Forms.Base)}
                >
                    Service provider name
                </Input>

                <Input
                    type={'button'}
                    value={data.regulatoryStatusManual || data.regulatoryStatus?.label || 'Service provider role'}
                    onClick={handleShowCreateForm(Forms.Base)}
                >
                    Service provider role
                </Input>
            </div>

            {activeForm === Forms.Role && (
                <ServiceProviderRoleForm
                    selectedData={data}
                    onSubmit={submitForm}
                    onClose={() => setActiveForm(null)}
                />
            )}

            {activeForm === Forms.Base && (
                <ServiceProviderBaseForm
                    selectedData={data}
                    generalInfo={generalInfo}
                    onSubmit={submitForm}
                    onClose={() => setActiveForm(null)}
                />
            )}
        </>
    );
};

export default ServiceProviderSingle;
