import styles from './styles.module.css';
import React from 'react';

const DealTaxesTips = () => {
    return (
        <>
            <div className={`${styles.graphTip} ${styles.initial}`}>Dollar amount at each level</div>
            <div className={`${styles.graphTip} ${styles.taxed}`}>Taxes</div>
            <div className={`${styles.graphTip} ${styles.embedded}`}>Taxes embedded in the structure</div>
            <div className={`${styles.graphTip} ${styles.notEmbedded}`}>Taxes not embedded in the structure</div>
        </>
    );
};

export default DealTaxesTips;
