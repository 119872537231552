import { useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { useParams } from 'react-router-dom';

import styles from './storage.module.css';
import StorageMainView from './StorageMainViews/StorageMainView';
import { Icons } from '../../../components/Icon/Icon';
import { useStorageStore } from '../../../store/useStorageStore';
import { StorageType } from '../StorageForm/StorageForm';
import StorageViewInvestors from './StorageMainViews/components/StorageViewInvestors';
import StorageViewAssets from './StorageMainViews/components/StorageViewAssets';
import StorageViewAssetManager from './StorageMainViews/components/StorageViewAssetManager';
import StorageViewStructure from './StorageMainViews/components/StorageViewStructure';
import StorageOther from './StorageMainViews/components/StorageOther';
import selectionStyles from '../../../components/SelectionPopup/SelectionPopupBase/selection-popup.module.css';
import StorageServiceProviders from './StorageMainViews/components/StorageViewServiceProvider';
import StorageViewGeneralPartner from './StorageMainViews/components/StorageViewGeneralPartner';
import StorageCurrentStatus from '../StorageForm/components/StorageCurrentStatus/StorageCurrentStatus';
import { Button } from '../../../components';
import StorageOtherPartTwo from './StorageMainViews/components/StorageOtherPartTwo';

const StorageView = () => {
    const [isOpen, setIsOpen, isSmallStructure, setIsSmallStructure, activeStorageType, setOpenType] = useStorageStore(
        (state) => [
            state.isOpen,
            state.setIsOpen,
            state.isSmallStructure,
            state.setIsSmallStructure,
            state.openType,
            state.setOpenType,
        ]
    );

    const [data, setData] = useState<any>({
        investors: [],
        assets: [],
        serviceProviders: [],
        assetManagers: [],
        gp: {},
        structure: {},
        other: {},
    });
    const params = useParams();

    useEffect(() => {
        setIsOpen(false);
        setOpenType(undefined);

        (async () => {
            const { data } = await axios.get(`storage/${params.id}`);
            setData(data);

            console.log(
                'is small',
                data.investors.length <= 1 &&
                    data.assets.length <= 4 &&
                    data.serviceProviders.length <= 6 &&
                    data.assetManagers.length <= 2
            );

            setIsSmallStructure(
                data.investors.length <= 4 &&
                    data.assets.length <= 4 &&
                    data.serviceProviders.length <= 6 &&
                    data.assetManagers.length <= 2
            );

            console.log(data);
        })();
    }, []);

    const handleCloseViewModal = () => {
        setOpenType(undefined);
    };

    return (
        <div className={styles.page}>
            <div className={`${selectionStyles.selectionBox} ${selectionStyles.main}`}>
                <div className={selectionStyles.selectionBoxInner}>
                    {isSmallStructure && isOpen && (
                        <div className={styles.actions}>
                            <Button
                                color={'neutral'}
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                Collapse
                            </Button>
                        </div>
                    )}

                    <div className={styles.content}>
                        <div className={styles.row}>
                            <StorageMainView
                                selectedData={data.investors}
                                isHorizontal={true}
                                name={'Investors'}
                                isSmaller={true}
                                itemProps={{
                                    name: 'investorType',
                                    country: 'domicileName',
                                }}
                                icon={Icons.investor}
                                type={StorageType.Investors}
                                isTop={true}
                            />

                            {(data.gp?.gpName || data.gp?.legalForm) && (
                                <div className={styles.rowAbsolute}>
                                    <StorageMainView
                                        selectedData={data.gp}
                                        name={'General Partner'}
                                        itemProps={{
                                            name: 'gpName',
                                            position: 'General Partner',
                                            country: ['legalForm', 'domicileName'],
                                        }}
                                        isSmaller={true}
                                        visible={false}
                                        isGP={true}
                                        type={StorageType.GeneralPartner}
                                        isBottom={true}
                                    />
                                </div>
                            )}
                        </div>

                        <div className={`${styles.row} ${styles.rowMiddle}`}>
                            <StorageMainView
                                selectedData={data.serviceProviders}
                                name={'Service providers'}
                                itemProps={{
                                    name: 'counterparty',
                                    position: 'role',
                                    country: 'domicileName',
                                }}
                                isAbsolute={true}
                                icon={Icons.serviceProvider}
                                type={StorageType.ServiceProviders}
                            />

                            <StorageMainView
                                selectedData={data.structure}
                                name={'Structure'}
                                itemProps={{
                                    name: 'name',
                                    position: ['legalForm', 'domicileName'],
                                }}
                                isMainStructure={true}
                                type={StorageType.Structure}
                            />

                            <StorageMainView
                                selectedData={data.assetManagers}
                                name={'Asset managers'}
                                itemProps={{
                                    name: 'counterparty',
                                    position: 'role',
                                    country: 'domicileName',
                                }}
                                isAbsolute={true}
                                icon={Icons.assetManagement}
                                type={StorageType.AssetManager}
                                hasYOffest={true}
                            />
                        </div>

                        <div className={styles.row}>
                            <StorageMainView
                                selectedData={data.assets}
                                isHorizontal={true}
                                name={'Assets'}
                                isSmaller={true}
                                itemProps={{
                                    name: 'assetClass',
                                    country: 'domicileName',
                                }}
                                icon={Icons.asset}
                                type={StorageType.Assets}
                                isBottom={true}
                            />

                            <div className={`${styles.rowAbsolute} ${styles.bottom}`}>
                                <StorageMainView
                                    selectedData={data.other}
                                    name={'Other'}
                                    itemProps={{
                                        nameRaw: 'Other',
                                        positionRaw: (
                                            <>
                                                Laws <br />
                                                Licenses and regulations <br />
                                                Distributions
                                            </>
                                        ),
                                    }}
                                    isSmaller={false}
                                    isOther={true}
                                    type={StorageType.Other}
                                    icon={Icons.other}
                                    isBottom={true}
                                />
                            </div>
                        </div>
                    </div>

                    {activeStorageType && (
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 10,
                                top: 0,
                                left: 0,
                                right: 0,
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                background: 'rgba(214, 214, 214,.85)',
                                width: '100%',
                                height: '100%',
                                padding: '12px',
                                overflow: 'auto',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                }}
                                className={`${selectionStyles.selectionBox}`}
                            >
                                {activeStorageType === StorageType.Structure && (
                                    <StorageViewStructure
                                        selectedData={data.structure}
                                        onClose={handleCloseViewModal}
                                    />
                                )}
                                {activeStorageType === StorageType.GeneralPartner && (
                                    <StorageViewGeneralPartner selectedData={data.gp} onClose={handleCloseViewModal} />
                                )}
                                {activeStorageType === StorageType.Investors && (
                                    <StorageViewInvestors
                                        selectedData={data.investors}
                                        onClose={handleCloseViewModal}
                                    />
                                )}
                                {activeStorageType === StorageType.Assets && (
                                    <StorageViewAssets selectedData={data.assets} onClose={handleCloseViewModal} />
                                )}
                                {activeStorageType === StorageType.AssetManager && (
                                    <StorageViewAssetManager
                                        selectedData={data.assetManagers}
                                        onClose={handleCloseViewModal}
                                    />
                                )}
                                {activeStorageType === StorageType.ServiceProviders && (
                                    <StorageServiceProviders
                                        selectedData={data.serviceProviders}
                                        onClose={handleCloseViewModal}
                                    />
                                )}
                                {activeStorageType === StorageType.Other && (
                                    <StorageOther
                                        selectedData={data.other}
                                        onClose={handleCloseViewModal}
                                        handleNextStep={setOpenType}
                                    />
                                )}

                                {activeStorageType === StorageType.OtherPartTwo && (
                                    <StorageOtherPartTwo
                                        selectedData={data.other}
                                        onClose={handleCloseViewModal}
                                        handleNextStep={setOpenType}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <StorageCurrentStatus
                activeStates={{
                    structure: true,
                    investors: !!data?.investors?.length,
                    assets: !!data?.assets?.length,
                    assetManager: !!data?.assetManagers?.length,
                    serviceProviders: !!data?.serviceProviders?.length,
                    other: !!data?.other,
                }}
            />
        </div>
    );
};

export default StorageView;
