import React, { useEffect, useState } from 'react';
import { useDiagramStore } from '../../../store/useDiagramStore';

import { SelectionLevel, SelectionLevelProps, TCountryCode } from '../../../types/general';

import SelectionRow from '../SelectionRow/SelectionRow';
import { SelectionRowContext } from '../../../context';
import { SelectedItem, SelectedItemAsset } from '../SelectionPopup';
import SelectionLayoutLevelContainer from '../SelectionLayoutRow/SelectionLayoutLevelContainer';

const SelectionPopupRowSingle = ({ initData, type, options, onSelect, countries, children }: any) => {
    const setDataFromContext = (allowEmpty = false) => {
        const base = {
            countryId: undefined,
            countryCode: undefined,
            countryName: undefined,
            id: undefined,
            name: undefined,
        };

        return type === SelectionLevel.Asset
            ? ({ ...base, currentAssetTaxId: undefined } as SelectedItemAsset)
            : ({ ...base } as SelectedItem);
    };

    const [setInvestors, setInvestmentFunds, setFundOfFunds, setAssets] = useDiagramStore((state) => [
        state.setInvestors,
        state.setInvestmentFunds,
        state.setFundOfFunds,
        state.setAssets,
    ]);

    const [showCountries] = useState(true);

    const [selectedData, setSelectedData] = useState<SelectedItem | SelectedItemAsset>(setDataFromContext());

    useEffect(() => {
        !!initData && setSelectedData(initData);
    }, []);

    useEffect(() => {
        onSelect(selectedData);
    }, [selectedData]);

    const handleSelect =
        (prop: keyof SelectedItem) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | { value: number }) => {
            // @ts-expect-error
            const value = +event.value || event.target.value;

            // @ts-ignore
            const item = options[selectedData.countryCode].find(
                // @ts-ignore
                (item: any) => item[SelectionLevelProps[type].valueKey] === value
            );

            const tmp = {
                ...selectedData,
                id: value,
                // @ts-ignore
                name: item[SelectionLevelProps[type].labelKey],
            };

            if ('currentAssetTaxId' in setDataFromContext()) {
                (tmp as SelectedItemAsset).currentAssetTaxId = item.jTblAssetDomicileId;
            }

            setSelectedData(tmp);
        };

    const handleCountrySelect =
        (propCountry: string) =>
        ({ value }: { value: TCountryCode }) => {
            const country = countries.find((i: any) => i.domicile === value);

            setSelectedData({
                ...setDataFromContext(),
                countryCode: value as TCountryCode,
                countryName: country?.domicileName,
                countryId: country?.domicileId,
            });

            if (type === SelectionLevel.Asset) {
                setAssets(value);
            } else if (type === SelectionLevel.Investor) {
                setInvestors(value);
            } else if (type === SelectionLevel.Fund) {
                setInvestmentFunds(value);
            } else if (type === SelectionLevel.FundOfFunds) {
                setFundOfFunds(value);
            }
        };

    return (
        <SelectionLayoutLevelContainer type={type} headerChildren={children}>
            <SelectionRowContext.Provider
                value={{
                    type: type,
                    options: options,
                    handleSelect: handleSelect,
                    selectedOption: selectedData.id,
                    countries: countries,
                    showCountries: showCountries,
                    onCountrySelect: handleCountrySelect(''),
                    selectedCountry: selectedData.countryCode,
                }}
            >
                <SelectionRow type={type} isSingle={true} />
            </SelectionRowContext.Provider>
        </SelectionLayoutLevelContainer>
    );
};

export default SelectionPopupRowSingle;
