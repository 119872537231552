// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_loader__8rU1t {\n    position: absolute;\n    margin: auto;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    z-index: 50;\n    width: 80px;\n    height: 80px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + " {\n    .styles_loader__8rU1t {\n        transform: scale(0.8);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Loader/styles.module.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["@value screen-md-max from '../../breakpoints.css';\n\n.loader {\n    position: absolute;\n    margin: auto;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    z-index: 50;\n    width: 80px;\n    height: 80px;\n}\n\n@media screen-md-max {\n    .loader {\n        transform: scale(0.8);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen-md-max": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-md-max"] + "",
	"loader": "styles_loader__8rU1t"
};
export default ___CSS_LOADER_EXPORT___;
