import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './legal-structures.module.css';

import axios from '../../utils/axios';
import { ResultTable, ResultTableLeft, ResultTableRight } from '../../components/ResultTable';
import { ArchDiagram } from './components/ArchDiagram';
import { MainStructuresDetail } from './components/MainStructuresDetail';
import { Tips } from './components/Tips';
import { useDiagramStore } from '../../store/useDiagramStore';
import { SelectedDataMulti, SelectedDataParams, SelectedItem } from '../../components/SelectionPopup/SelectionPopup';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import TransitionComponent from '../../components/TransitionComponent/TransitionComponents';
import { LinkedButton } from '../../components/Button';
import { Button } from '../../components';
import LegalStructureSelection from './components/LegalStructureSelection';
import { ArchData, ArchNodeItem } from '../../utils/generate-arch';
import { GraphTypes } from '../../types/general';
import { SelectionPopup } from '../../components/SelectionPopup';

export interface LegalStructuresSelectedData {
    assets: SelectedItem[];
    investors: SelectedItem[];

    selfManaged: boolean;
    noFixedCapital: boolean;
    openEnded: boolean;
    corporate: boolean;
}

const LegalStructures = () => {
    const [resetData, setGraphStoreData, getCountries] = useDiagramStore((state) => [
        state.resetData,
        state.setStoreData,
        state.getCountries,
    ]);

    const [selectedData, setSelectedData] = useState<LegalStructuresSelectedData>({
        selfManaged: false,
        noFixedCapital: true,
        openEnded: false,
        corporate: false,
        assets: [],
        investors: [],
    });

    const [showPopup, setShowPopup] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [archDiagramData, setArchDiagramData] = useState<ArchData[] | null>(null);
    const [activeInvestment, setActiveInvestment] = useState<ArchNodeItem | null>(null);
    const [checkClicked, setCheckClicked] = useState(false);
    const [toggleRequest, setToggleRequest] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [tableUrl, setTableUrl] = useState('');

    const [pageReady, setPageReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (showPopup) {
            setLoading(true);
            setPageReady(true);
        }
    }, [showPopup]);

    useEffect(() => {
        if (!searchParams.toString().length) {
            (async () => {
                await initPage();
            })();
        }
    }, [searchParams]);

    useEffect(() => {
        setGraphStoreData({
            useInvestmentStructure: false,
            graphType: GraphTypes.Legal,
        });

        (async () => {
            await initPage();
        })();
    }, []);

    useEffect(() => {
        if (toggleRequest) {
            (async () => await getArchDiagramData(true))();
        }
    }, [toggleRequest]);

    useEffect(() => {
        if (checkClicked) {
            (async () => await getArchDiagramData(false))();
        }
    }, [
        checkClicked,
        selectedData.selfManaged,
        selectedData.noFixedCapital,
        selectedData.openEnded,
        selectedData.corporate,
    ]);

    useEffect(() => {
        setIsMenuOpen(!showPopup);
    }, [showPopup]);

    const initPage = async () => {
        await getCountries();

        const investors = searchParams.get('investors');
        const assets = searchParams.get('assets');

        const selfManaged = searchParams.get('selfManaged');
        const noFixedCapital = searchParams.get('noFixedCapital');
        const corporate = searchParams.get('corporate');
        const openEnded = searchParams.get('openEnded');

        if (!investors || !assets) {
            resetData();
            setShowPopup(true);
            return;
        }

        setSelectedData({
            ...selectedData,
            investors: JSON.parse(investors),
            assets: JSON.parse(assets),
            selfManaged: selfManaged === 'true',
            noFixedCapital: noFixedCapital === 'true',
            corporate: corporate === 'true',
            openEnded: openEnded === 'true',
        });

        setCheckClicked(true);
        setPageReady(true);
    };

    const onDataChange = (investment: ArchNodeItem | null) => {
        setActiveInvestment(investment);
        if (investment) {
            setShowDetails(true);
        } else {
            setShowDetails(false);
            setActiveInvestment(null);
        }
    };

    const closeDetails = () => {
        setShowDetails(false);
        setActiveInvestment(null);
    };

    const handleCheckbox = (prop: string) => async (event: React.FormEvent<HTMLInputElement>) => {
        const tmp = { ...selectedData, [prop]: (event.target as HTMLInputElement).checked };
        // @ts-ignore
        const params = new URLSearchParams([...Object.entries(tmp)]);
        setSearchParams(params.toString());
        setCheckClicked(true);

        setSelectedData(tmp);
    };

    const handleFormSubmission = async (formSelectedData: SelectedDataParams) => {
        // if (!archDiagramData) {
        // setLoading(true);
        // }

        const tmp = { ...selectedData, ...formSelectedData };

        setSelectedData({ ...tmp, selfManaged: false, noFixedCapital: true });
        setShowPopup(false);
        setToggleRequest(true);
    };

    const getArchDiagramData = async (toggleCheckboxes: boolean) => {
        setCheckClicked(false);
        setToggleRequest(false);

        let isCorporate = selectedData.corporate;
        let isOpenEnded = selectedData.openEnded;

        const asset = selectedData.assets[0];

        let tmp = {
            ...selectedData,
        };

        if (toggleCheckboxes) {
            const openEnded = await getOpenEnded(asset.name!);

            isCorporate = openEnded.legalType.trim() === 'corporate';
            isOpenEnded = openEnded.ifRecommendedOpenEnded;

            tmp = {
                ...tmp,
                corporate: isCorporate,
                openEnded: isOpenEnded,
            };
        }

        setSelectedData(tmp);

        try {
            const { data } = await axios.get('/legal-structures/diagram', {
                params: tmp,
            });

            setArchDiagramData(data);
        } catch (e) {
            setArchDiagramData(null);
        } finally {
            closeDetails();

            try {
                const tableUrl = new URLSearchParams(
                    // @ts-ignore
                    Object.entries(selectedData).map((entry) => {
                        // @ts-ignore
                        entry[1] = JSON.stringify(entry[1]);
                        return entry;
                    })
                );

                // let tableUrl = new URLSearchParams();
                // Object.entries(urlParams).forEach((entry) => {
                //     if (Array.isArray(entry[1])) {
                //         entry[1].forEach((value) => {
                //             tableUrl.append(entry[0], JSON.stringify(value));
                //         });
                //     } else {
                //         tableUrl.append(entry[0], JSON.stringify(entry[1]));
                //     }
                // });

                setTableUrl('?' + tableUrl.toString());
                setSearchParams(tableUrl.toString());
            } catch (e) {
                console.log(e);
            }
            // tableUrl.set('corporate', `${isCorporate}`);
            // tableUrl.set('openEnded', `${isOpenEnded}`);

            setLoading(false);
        }
    };

    const getOpenEnded = async (assetClass: string) => {
        const { data } = await axios.get('/base/check/open-ended', {
            params: {
                assetClass: assetClass,
            },
        });

        return data;
    };

    const savePreset = async (event: any) => {
        event.preventDefault();

        try {
            await axios.post('/presets/legal-structures', selectedData);

            // redirect
            await navigate('/account?save=1');
        } catch (err) {
            console.log(err);
            // setError(true);
        }
    };

    const getLegalSelection = async (
        currentInvestor: number,
        assetClass: string,
        legalType: string,
        openEnded: boolean,
        selfManaged: boolean,
        noFixedCapital: boolean
    ) => {
        const { data } = await axios.get(`/base/check/legal-selection`, {
            params: {
                currentInvestor: currentInvestor,
                assetClass: assetClass,
                legalType: legalType,
                openEnded: openEnded,
                selfManaged: selfManaged,
                noFixedCapital: noFixedCapital,
            },
        });

        return data;
    };

    const validateSelection = async (data: SelectedDataMulti) => {
        if (
            !data.assets.length ||
            !data.investors.length ||
            data.investors.filter((inv) => !inv.id).length ||
            data.assets.filter((inv) => !inv.id).length
        )
            return;

        for (const asset of data.assets) {
            if (asset.name) {
                const assetClass = asset.name;
                const result = await getOpenEnded(assetClass);

                await axios.get(`/legal-structures/validate-selection`, {
                    params: {
                        selfManaged: false,
                        noFixedCapital: true,
                        openEnded: result.ifRecommendedOpenEnded,
                        corporate: result.legalType === 'corporate',
                        assets: data.assets,
                        investors: data.investors,
                    },
                });
            }
        }
    };

    return (
        <>
            <TransitionComponent inProp={showPopup} isAbsolute={true} duration={100}>
                <SelectionPopup isMulti={true} onSubmit={handleFormSubmission} validateSelection={validateSelection} />
            </TransitionComponent>

            <LoaderComponent inProp={pageReady} hideLoader={true}>
                <ResultTable>
                    <ResultTableLeft
                        // tableInfo={<Info />}
                        legend={!isMenuOpen}
                        selectedData={selectedData}
                        onEdit={() => {
                            setShowPopup(true);
                        }}
                    >
                        <LegalStructureSelection
                            handleCheckbox={handleCheckbox}
                            selectedData={selectedData}
                            active={isMenuOpen}
                            setActive={setIsMenuOpen}
                            hidden={showPopup}
                            onEdit={() => {
                                setIsMenuOpen(false);
                                setShowPopup(true);
                            }}
                        />
                    </ResultTableLeft>

                    <div className={styles.graph}>
                        <LoaderComponent inProp={!loading}>
                            {archDiagramData && (
                                <>
                                    <ArchDiagram
                                        dataFull={archDiagramData}
                                        onDataChange={onDataChange}
                                        activeCategory={activeInvestment}
                                    />
                                    <div className={styles.actions}>
                                        <LinkedButton
                                            to={{
                                                pathname: '/comparison-table',
                                                search: tableUrl,
                                            }}
                                        >
                                            Learn more
                                        </LinkedButton>

                                        <Button onClick={savePreset}>Print review</Button>
                                    </div>
                                </>
                            )}
                        </LoaderComponent>
                    </div>

                    <ResultTableRight
                        showDetails={showDetails}
                        details={<MainStructuresDetail activeInvestment={activeInvestment} onClose={closeDetails} />}
                        tips={<Tips />}
                    />
                </ResultTable>
            </LoaderComponent>
        </>
    );
};

export default LegalStructures;
