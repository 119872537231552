import styles from './storage.module.css';
import { PresetActions } from '../../../components/Preset';
import { useEffect, useState } from 'react';
import PresetLink from '../../../components/Preset/components/PresetLink';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from '../../../utils/axios';
import OverviewFolder from '../../../components/Overview/OverviewCategories/OverviewFolder';
import { LinkedButton } from '../../../components/Button';

const Storage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [structures, setStructures] = useState([]);

    const [countryId, setCountryId] = useState<number | null>(null);

    const handleDelete = () => {};

    console.log(location);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        console.log('params.countryId', params.get('countryId'));

        setCountryId(params.get('countryId') ? +params.get('countryId')! : null);

        (async () => {
            const { data } = await axios.get(
                `storage${params.get('countryId') ? `/country/${params.get('countryId')}` : ''}`
            );
            setStructures(data);
        })();
    }, [location]);

    return (
        <>
            <div className={styles.page}>
                <div className={styles.link}>
                    <div className={styles.imgWrapper}>
                        <img src="./images/thumb-storage-new.png" alt="" />
                    </div>

                    <LinkedButton to={'/storage/create'}>Add new</LinkedButton>
                </div>

                <div className={styles.content}>
                    <div className={styles.title}>
                        <h3>You stored structures:</h3>
                    </div>
                    <div className={styles.items}>
                        {countryId === null
                            ? structures.map((item: any, i: number) => (
                                  <a
                                      href={`/storage?countryId=${item.domicileId}`}
                                      key={i}
                                      onClick={(e) => {
                                          e.preventDefault();
                                          navigate(`/storage?countryId=${item.domicileId || 0}`);
                                      }}
                                  >
                                      <OverviewFolder
                                          name={item.domicileName}
                                          image={item.countOfStructures ? './images/thumb-storage.png' : ''}
                                          itemLength={item.countOfStructures}
                                      />
                                  </a>
                              ))
                            : structures.map((item: any, i: number) => (
                                  <div key={i} className={styles.item}>
                                      <PresetActions handleDelete={handleDelete} />
                                      <PresetLink
                                          img={'./images/thumb-storage.png'}
                                          name={'Stored structure'}
                                          link={`storage/${item.vehicleId}`}
                                      >
                                          <p>Name: {item.name}</p>
                                          <p>Domicile: {item.domicileName}</p>
                                          <p>Stored: {item.dbDate}</p>
                                      </PresetLink>
                                  </div>
                              ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Storage;
