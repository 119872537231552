const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.494" height="15.494" viewBox="0 0 15.494 15.494">
            <g id="Component_35_1" data-name="Component 35 – 1" transform="translate(0.354 0.354)">
                <line
                    id="Line_7"
                    data-name="Line 7"
                    x1="14.787"
                    y2="14.787"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="1"
                />
                <line
                    id="Line_8"
                    data-name="Line 8"
                    x2="14.787"
                    y2="14.787"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="1"
                />
            </g>
        </svg>
    );
};

export default CloseIcon;
