import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import selectionStyles from '../../components/SelectionPopup/SelectionPopupBase/selection-popup.module.css';

import axios from '../../utils/axios';
import OverviewFolder from '../../components/Overview/OverviewCategories/OverviewFolder';
import PresetLink from '../../components/Preset/components/PresetLink';
import ComplianceView from './components/ComplianceView';
import ComplianceLawView from './components/ComplianceLawView';
import ComplianceLawToBeUpdatedView from './components/ComplianceLawToBeUpdatedView';
import { Button } from '../../components';

export enum ComplianceType {
    Structures = 'Structures',
    Laws = 'Laws',
    LawsToBeUpdated = 'LawsToBeUpdated',
}

const Compliance = () => {
    const [activeItem, setActiveItem] = useState<any>();

    const [loading, setLoading] = useState<boolean>(false);

    const [activeOpenType, setOpenType] = useState<ComplianceType | undefined>();

    const [items, setItems] = useState<any[]>([]);

    const [data, setData] = useState<any>();

    useEffect(() => {
        (async () => {
            const compliance = await axios.get('/compliance');
            console.log('compliance.data', compliance.data);
            setItems(compliance.data);
        })();
    }, []);

    useEffect(() => {
        if (!activeItem) return;
        (async () => {
            setLoading(true);

            const { data } = await axios.get(`compliance/${activeItem.vehicleId}`);
            setData(data);

            console.log('single data', data);

            console.log(data);

            setLoading(false);
        })();
    }, [activeItem]);

    const handleCloseViewModal = () => {
        setActiveItem(undefined);
        setData(null);
    };

    return (
        <div className={styles.page}>
            {!activeOpenType && (
                <div>
                    <h3 className={styles.header}>Compliance monitoring of the stored structures.</h3>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gridGap: '32px',
                            paddingTop: '32px',
                        }}
                    >
                        <div>
                            <OverviewFolder
                                type={'secondary'}
                                name={'Stored structures: items to be reviewed'}
                                image={'/images/thumb-compliance.png'}
                                itemLength={3}
                                onClick={() => {
                                    setOpenType(ComplianceType.Structures);
                                }}
                            />
                        </div>
                        <div>
                            <OverviewFolder
                                type={'secondary'}
                                name={'Law(s) were updated: structures to be reviewed '}
                                image={'/images/thumb-compliance.png'}
                                itemLength={3}
                                onClick={() => {
                                    setOpenType(ComplianceType.Laws);
                                }}
                            />
                        </div>
                        <div>
                            <OverviewFolder
                                type={'secondary'}
                                name={'Laws will be updated: keep in mind'}
                                image={'/images/thumb-compliance.png'}
                                itemLength={3}
                                onClick={() => {
                                    setOpenType(ComplianceType.LawsToBeUpdated);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}

            {!!activeOpenType && (
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h3 className={styles.header}>Compliance monitoring of the stored structures.</h3>

                        <Button onClick={() => setOpenType(undefined)}>Collapse</Button>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gridGap: '32px',
                            paddingTop: '32px',
                        }}
                    >
                        {items.map((item, i) => (
                            <div key={i} className={styles.item}>
                                <PresetLink
                                    img={'/images/thumb-storage.png'}
                                    name={'Stored structure'}
                                    onClick={() => setActiveItem(item)}
                                    // link={`storage/${item.vehicleId}`}
                                >
                                    <p>Name: {item.name}</p>
                                    <p>Domicile: {item.domicileName}</p>
                                    <p>Stored: {item.dbDate}</p>
                                </PresetLink>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {!!activeItem && !loading && data && (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        top: 0,
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'rgba(214, 214, 214,.85)',
                        width: '100%',
                        height: '100%',
                        padding: '12px',
                        overflow: 'auto',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                        }}
                        className={`${selectionStyles.selectionBox}`}
                    >
                        {activeOpenType === ComplianceType.Structures && (
                            <ComplianceView name={activeItem.name} selectedData={data} onClose={handleCloseViewModal} />
                        )}
                        {activeOpenType === ComplianceType.Laws && (
                            <ComplianceLawView
                                name={activeItem.name}
                                selectedData={data.laws}
                                onClose={handleCloseViewModal}
                            />
                        )}
                        {activeOpenType === ComplianceType.LawsToBeUpdated && (
                            <ComplianceLawToBeUpdatedView
                                name={activeItem.name}
                                selectedData={data.lawsToBeUpdated}
                                onClose={handleCloseViewModal}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Compliance;
