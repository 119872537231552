import React from 'react';

import styles from './pdf-template-layout.module.css';
import PdfTemplateLayout from './PdfTemplateLayout';
import { LegalStructuresSelectedData } from '../../pages/LegalStructures/LegalStructures';
import { DealTaxesSelectedData } from '../../pages/DealTaxes/DealTaxes';
import { TaxOptimisationSelectedData } from '../../pages/TaxOptimisation/TaxOptimisation';
import { GraphTypes } from '../../types/general';
import { SelectionCombination } from '../SelectedCombination';

interface PdfTemplateGraphLayoutProps {
    children: any;
    tableInfo: any;
    tips: any;
    comments: any;
    legends?: boolean;
    alignLeft?: boolean;
    fundData?: any;
    isOptimisation?: boolean;
    selectedData: LegalStructuresSelectedData | DealTaxesSelectedData | TaxOptimisationSelectedData;
    graphType: GraphTypes;
}

const PdfTemplateGraphLayout = ({
    children,
    comments,
    tableInfo,
    tips,
    legends,
    alignLeft,
    fundData,
    isOptimisation,
    selectedData,
    graphType,
}: PdfTemplateGraphLayoutProps) => {
    const legendsArr = [
        `1: Approximate costs to cover all required service providers and setup. Please check the detailed overview for the breakdown.`,
        `2: Minimum numbers of days to launch the structure (excluding any legal draftings, negotiations etc). Please check the detailed overview for the breakdown.`,
    ];

    return (
        <PdfTemplateLayout>
            <div className={`${styles.row} ${styles.rowTop}`}>
                <div className={`${styles.left} ${alignLeft ? styles.leftSmaller : ''}`}>
                    <div className={`${styles.leftTop}`}>{tableInfo}</div>

                    <div className={styles.leftCenter}>
                        <SelectionCombination
                            selectedData={selectedData}
                            isPdf={true}
                            isOptimisation={isOptimisation}
                            fundData={fundData}
                            graphType={graphType}
                        />
                    </div>

                    <div className={styles.leftBottom}>
                        <div>
                            {comments &&
                                comments.map((c: any, i: number) => {
                                    return (
                                        <p className={styles.comment} key={i}>
                                            {c.legendId} : {c.fullComments}
                                        </p>
                                    );
                                })}

                            {legends && (
                                <p className={styles.comment}>
                                    {legendsArr.map((l, i) => (
                                        <React.Fragment key={i}>
                                            {l} <br />
                                        </React.Fragment>
                                    ))}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${styles.right} ${alignLeft ? styles.alignLeft : ''}`}>
                    <div className={styles.graph}>{children}</div>

                    <div className={styles.bottomRight}>
                        <div className="bottom">{tips}</div>
                    </div>
                </div>
            </div>
        </PdfTemplateLayout>
    );
};
export default PdfTemplateGraphLayout;
